/***
*
*   useSignupInvite hook
*   fetch, format and return the signup invite
*
**********/

import { useCallback, useContext, useState, useEffect } from 'react';
import { AuthContext } from 'components/lib';
import Axios from 'axios';

export function useSignupInvite(queryString){

    const context = useContext(AuthContext);
    const [state, setState] = useState({ data: null, loading: false });

    const getInvite = useCallback(async () => {
        try {

            if (!queryString) {

                setState({ data: {}, loading: false });
                return false;

            }
            
            const searchParams = new URLSearchParams(queryString);
            const inviteId = searchParams.get('id');
            const inviteCompanyEmail = searchParams.get('email');
        
            if (!inviteId || !inviteCompanyEmail) context.signout();
    
  
          const res = await Axios.get('/api/auth/signup-invite', {
              params: {
                id: inviteId,
                email: inviteCompanyEmail
              }
          });
  
          if (res.status === 200) {
            setState({ data: res.data.data?.length ? res.data.data[0] : {}, loading: false });
          }          
        }
        catch (err) {
            context?.current &&
            context.current.handleError(err);
        }

    }, [queryString, context]);

    useEffect(() => {
        
        setState({ loading: true });
        getInvite();
    
    }, [getInvite]);

    return state
}