/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React, { useCallback, useContext, useEffect, useState } from 'react';
import Axios from 'axios';

import { AccountContext, ViewContext, Card, StatTwo, Chart,
  Message, Grid, Animate, Select } from 'components/lib';

import { Col, DatePicker, Row, Typography } from 'antd';

import { PointService } from 'services';

import moment from 'moment';

const { Text, Title } = Typography;

export function Dashboard(props){
  const context = useContext(ViewContext);
  const { accountState } = useContext(AccountContext);

  const dateFormat = 'YYYY-MM-DD';
  const midPointDateTypes = [
    { label: 'Chose A Year', value: 0 },
    { label: 'Chose A Month', value: 1 },
    { label: '1 Month', value: 2 },
    { label: '3 Month', value: 3 },
    { label: '6 Month', value: 4 },
    { label: '1 Year', value: 5 }
  ];

  const getOptionData = (data) => data ? data.map((obj) => ({
    label: obj.name,
    value: obj.name
  })) : [];

  const [totalVolSold, setTotalVolSold] = useState(undefined);
  const [totalVolBought, setTotalVolBought] = useState(undefined);
  const [soldMonthlyTotal, setSoldMonthlyTotal] = useState(undefined);
  const [boughtMonthlyTotal, setBoughtMonthlyTotal] = useState(undefined);
  const [totalDeals, setTotalDeals] = useState(undefined);
  const [midPoints, setMidPoints] = useState(undefined);
  const [midPointDateType, setMidPointDateType] = useState(undefined);
  const [midPointDateSearch, setMidPointDateSearch] = useState(undefined);

  const [midPointDateStart, setMidPointDateStart] = useState(undefined);
  const [midPointDateEnd, setMidPointDateEnd] = useState(undefined);

  const [searchPoint, setSearchPoint] = useState(undefined);
  const [points] = useState(getOptionData(accountState.points));

  const getDateList = useCallback((dateString) => {
    const dateStart = dateString ? moment(dateString).startOf('year') : moment().startOf('year');
    const dateEnd = dateString ? moment(dateString).endOf('year') : moment().endOf('year');

    const interim = dateStart.clone();
    const timeValues = [];

    while (dateEnd > interim || interim.format('M') === dateEnd.format('M')) {
        timeValues.push(interim.format('YYYY-MM'));
        interim.add(1,'month');
    }

    return timeValues;
  }, []);

  const getDayListFromMonth = useCallback((dateString) => {
    const date = new Date(dateString);
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1;
  
    return [...Array(new Date(currentYear, currentMonth, 0).getDate()).keys()].map(num => num + 1);
  }, []);

  const getMonthYearList = useCallback(() => {
    const dateStart = moment(midPointDateStart);
    const dateEnd = moment(midPointDateEnd);
    const interim = dateStart.clone();
    const timeValues = [];

    while (dateEnd > interim || interim.format('M') === dateEnd.format('M')) {
      timeValues.push(interim.format('YYYY-MM'));
      interim.add(1,'month');
    }

    return timeValues;
  }, [midPointDateStart, midPointDateEnd])


  const getMonthDateList = useCallback(() => {
    if (midPointDateType === 1) {
      return getDayListFromMonth(midPointDateSearch);
    } else {
      const monthYearList = getMonthYearList();

      return monthYearList.map(my => {
        const currentMonth = new Date(my).getMonth() + 1;
        const dayNums = getDayListFromMonth(my);

        return dayNums.map(d => ({ day: d, month: currentMonth }));
      });
    }
  }, [midPointDateType, getDayListFromMonth, getMonthYearList, midPointDateSearch]);

  useEffect(() => {
    const prepareMonthlyDealChartData = (position, data) => {
      const currentMonthYears = data
        .sort((a, b) =>  moment(`${a.year}-${a.month}`) - moment(`${b.year}-${b.month}`))
        .map(monthlyFlow => `${monthlyFlow.year}-${String(monthlyFlow.month).length === 1 ? `0${monthlyFlow.month}` : monthlyFlow.month}`);

      const latestCurrentMonthYear = currentMonthYears[currentMonthYears.length - 1];
      const dateList = getDateList();

      for (let dList of dateList) {
          if(dList === latestCurrentMonthYear) break;

          if(currentMonthYears.indexOf(dList) === -1) {
              const datePair = dList.split('-');
              const fillerObj = position === 'sell' ?
                {
                  long_term_sell_total_price: 0,
                  long_term_sell_volume: 0,
                  spot_sell_total_price: 0,
                  spot_sell_volume: 0,
                } :
                {
                  long_term_buy_total_price: 0,
                  long_term_buy_volume: 0,
                  spot_buy_total_price: 0,
                  spot_buy_volume: 0,
                }

              data.push({
                  ...fillerObj,
                  month: parseInt(datePair[1]),
                  year: parseInt(datePair[0]),
                  name: position,
              });
          }
      }

      return data.sort((a, b) => a.month - b.month);
    }

    const getDealMonthlyTotal = async () => {
        const currentDate = moment();
        const startDate = currentDate.startOf('year').toISOString();
        const endDate = currentDate.endOf('year').toISOString();

        try {
            const res = await Axios.get('/api/deal/monthly-total', {  params: { 
                start_date: startDate,
                end_date: endDate
              } 
            });
      
            if (res.status === 200) {
                const totalData = res.data.data;
                const currentMonth= moment().month();

                const soldMonthlyChartData = prepareMonthlyDealChartData('sell', totalData.sell); 
                const boughtMonthlyChartData = prepareMonthlyDealChartData('buy', totalData.buy);

                const lastMonthBuyObj = boughtMonthlyChartData.filter(bData => bData.month === currentMonth + 1).shift();
                const lastMonthSellObj = soldMonthlyChartData.filter(sData => sData.month === currentMonth + 1).shift();

                const totalVolBought = !lastMonthBuyObj ? 0 : (lastMonthBuyObj.spot_buy_volume + lastMonthBuyObj.long_term_buy_volume);
                const totalVolSold = !lastMonthSellObj ? 0 : (lastMonthSellObj.spot_sell_volume + lastMonthSellObj.long_term_sell_volume);

                setTotalVolBought(totalVolBought);
                setTotalVolSold(totalVolSold);

                setSoldMonthlyTotal(soldMonthlyChartData.map(b => b.spot_sell_total_price + b.long_term_sell_total_price ));
                setBoughtMonthlyTotal(boughtMonthlyChartData.map(b => b.spot_buy_total_price + b.long_term_buy_total_price ));
            }          
          }
          catch (err){
            context.handleError(err);
          }
    };  

    if(totalVolSold === undefined) {
      getDealMonthlyTotal();
    }
  }, [context, totalVolSold, setTotalVolSold, getDateList]);

  useEffect(() => {
    const getDealCount = async () => {
      try {
          const currentDate = moment();
          const startDate = currentDate.startOf('month').format('YYYY-MM-DD hh:mm');
          const endDate = currentDate.endOf('month').format('YYYY-MM-DD hh:mm');

          const res = await Axios.get('/api/deal/count', {  
              params: { 
                  start_date: startDate, 
                  end_date: endDate
              } 
          });
        
          if (res.status === 200) {
              setTotalDeals({...res.data.data[0], ...res.data.data[1]});
          } 
      } catch (err) {
          context.handleError(err);
      }
    }

    getDealCount();
  }, [getDateList, context]);

  useEffect(() => {

    const getMidPointsData = async () => {

      let params = {
        date_type: midPointDateType,
        point: searchPoint
      };

      if (midPointDateType > 1) {
        params = {
          ...params,
          ...{
            start_date: midPointDateStart,
            end_date: midPointDateEnd
          }
        }
      } else {
        params = {
          ...params,
          ...{ date: midPointDateSearch }
        }
      }
      
      const res = await PointService.getMidPointPrices(params);

      if (res.status === 200) {

        if (midPointDateType > 1) {


          const data = res.data.data;
          const monthDays = getMonthDateList();

          const midPointBatch = monthDays.flat(Infinity).map(dayData => {
            const match = data.find(obj => moment(obj.flow_date).date() === dayData.day && moment(obj.flow_date).month() === dayData.month);

            return match ? parseFloat(match.midpoint) : 0.00;
          })

          setMidPoints(midPointBatch);

        } else if (midPointDateType === 0) {
          const data = res.data.data;
          const yearMonths = getDateList(midPointDateSearch);


          const midPointBatch = yearMonths.map(ym => {
            const match = data.find(obj => {
              const ymStr = `${obj.year}-${String(obj.month).length === 1 ? '0' + obj.month : obj.month}`; 

              return ymStr === ym;
            });

            return match ? parseFloat(match.midpoint) : 0.00;
          });

          setMidPoints(midPointBatch);
        } else {
          const data = res.data.data;
          const monthDays = getDayListFromMonth(midPointDateSearch);

          const midPointBatch = monthDays.map(day => {
            const match = data.find(obj => moment(obj.flow_date).date() === day);

            return match ? parseFloat(match.midpoint) : 0.00;
          })

          setMidPoints(midPointBatch);
        }
        
      } else {
          context.handleError(res);
      }
    }

    if (midPointDateType !== undefined && (midPointDateSearch || (midPointDateStart && midPointDateEnd)) && searchPoint) getMidPointsData();

  }, [midPointDateSearch, midPointDateType, midPointDateStart, midPointDateEnd, searchPoint, getDayListFromMonth, getDateList, context]);

  const prepareChartData = () => {
    return {
        datasets: [
            {
                data: boughtMonthlyTotal || [],
                label: 'Bought Total'
            },
            {
              data: soldMonthlyTotal || [],
              label: 'Sold Total'
            }
        ],
        labels: getDateList()
    };
  };

  const prepareMidPointChartData = () => {
    return {
      datasets: [
          {
              data: midPoints || [],
              label: midPointDateType === 0 ? 'Midpoint Price Monthly Average' : 'Midpoint Price Per Day',
          }
      ],
      labels: midPointDateType ? midPointDateType === 1 ? getMonthDateList() : getMonthDateList().flat(Infinity).map(obj => `${obj.month}-${obj.day}`) : getDateList(midPointDateSearch)
  };
  }

  const prepareVolPieChartData = () => {
    const pieData = [
      totalVolSold || 0, 
      totalVolBought || 0
    ];

    const data = {
      labels: [
        'Sold',
        'Bought'
      ],
      datasets: [{
        label: 'My First Dataset',
        data: pieData,
        hoverOffset: 4
      }]
    };

    return data;
  }

  const prepareTotalPieChartData = () => {
    let pieData = [];


      const soldMontlyTotalNum = soldMonthlyTotal ? soldMonthlyTotal.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0) : 0;
      const boughtMontlyTotalNum = boughtMonthlyTotal ? boughtMonthlyTotal.reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0) : 0;

      pieData = [
        soldMontlyTotalNum, 
        boughtMontlyTotalNum
      ];
    
      
    const data = {
      labels: [
        'Sold',
        'Bought'
      ],
      datasets: [{
        label: 'My First Dataset',
        data: pieData,
        hoverOffset: 4
      }]
    };

    return data;
  }

  const monthlyTotalAvailable = () => {
    if (!soldMonthlyTotal || !boughtMonthlyTotal) return false;

    const availSold = soldMonthlyTotal.find(s => s > 0);
    const availBought = boughtMonthlyTotal.find(b => b > 0);

    return availSold || availBought;
  }

  const onMidpointDateChange = (date) => {
    setMidPointDateSearch(date.format(dateFormat));
  }

  const onSelectDateTypeChange = (id, value) => {
    const valueType = parseInt(value);

    if (valueType > 1) {
      let monthsPass = 1;

      if (valueType === 3) {
        monthsPass = 2;
      } else if (valueType === 4) {
        monthsPass = 5;
      } else if (valueType === 5) {
        monthsPass = 11;
      }

      const startDate = moment().subtract(monthsPass, 'months').startOf('day').toISOString();
      const endDate = moment().endOf('day').toISOString();

      setMidPointDateStart(startDate);
      setMidPointDateEnd(endDate);

    }

    setMidPointDateType(valueType);
  }

  const currentDate = moment().format('MM-YYYY');

  return (
    <Animate type='pop'>

      <Message
        closable
        title='Welcome to DM Natural!'
        text='This is an overview of your current deals.'
        type='info'
      />

      <Grid cols='4'>
        <StatTwo
          loading={ totalDeals === undefined }
          value={ totalDeals?.spot_deal_count }
          label={`Spot Deals ~ ${currentDate}`}
          icon='briefcase'
          color='rosybrown'
        />
        <StatTwo
          loading={ totalDeals === undefined }
          value={ totalDeals?.long_term_deal_count }
          label={`Long Term Deals ~ ${currentDate}`}
          icon='briefcase'
          color='shellblue'
        />
        <StatTwo
          loading={ totalVolSold === undefined }
          value={ totalVolSold || 0 }
          label={`Total Vol. Sold ~ ${currentDate}`}
          icon='bar-chart'
          color='orange'
        />
        <StatTwo
          loading={ totalVolBought === undefined }
          value={ totalVolBought || 0 }
          label={`Total Vol. Bought ~ ${currentDate}`}
          icon='bar-chart'
          color='darkblue'
        />
      </Grid>

      <Card name='midpoint' title="Midpoints">

        <Row gutter={[16, 16]}>
          <Col lg={{ span: 5}} md={{ span: 8}} xs={24}>
            <Select
              customLabel="Select Point"
              required
              name="point_id"
              id="point_id"
              onChange={(id, value) => {
                setSearchPoint(value)
              }}
              value={searchPoint}
              options={points}
            />
          </Col>
          <Col lg={{ span: 5}} md={8} xs={24}>
            <Select
              name="date_type"
              value={midPointDateType}
              onChange={onSelectDateTypeChange}
              options={midPointDateTypes}
            />
          </Col>
          <Col lg={{ span: 5}} md={{ span: 8}} xs={24}>
            {midPointDateType < 2 && 
              <DatePicker 
                style={{ width: '100%', height: '57.5px', marginBottom: '1.25em' }} 
                onChange={onMidpointDateChange} 
                picker={midPointDateType === 0 ? 'year' : 'month' } 
              />
            }
          </Col>    
        </Row>

        {midPoints && 
          <Chart
            type='line'
            legend
            loading={ !midPoints }
            data={ prepareMidPointChartData() }
            color={['red', 'blue']}
          />
        }

      </Card>

      <Card name='revenues' title={`Deals Total Price Per Month (${moment().format('YYYY')})`}>
        <Chart
          type='line'
          legend
          loading={ soldMonthlyTotal === undefined || !boughtMonthlyTotal === undefined }
          data={ prepareChartData() }
          color={['red', 'blue']}
        />
      </Card>

      <Grid cols='2'>
        <Card title='Annual Total Deals Price'>
          {!monthlyTotalAvailable() &&
            <p>No volume has been traded this month yet.</p>
          }
          {monthlyTotalAvailable() &&
          <Chart
            type='pie'
            legend={ true }
            data={ prepareTotalPieChartData() }
            loading={ soldMonthlyTotal === undefined || boughtMonthlyTotal === undefined }
            color={['blue', 'red']}
          />
          }
        </Card>
        <Card title='Current Month Total Deals Volume'>
          {!totalVolSold && !totalVolBought &&
            <p>No volume has been traded this month yet.</p>
          }
          {(totalVolSold > 0 || totalVolBought > 0) &&
            <Chart
              type='pie'
              legend={ true }
              data={ prepareVolPieChartData() }
              loading={ totalVolSold === undefined || totalVolBought === undefined }
              color={['red', 'blue']}
            />
          }
        </Card>
      </Grid>

    </Animate>
  );
}
