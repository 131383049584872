/***
*
*   PROFILE
*   Update the user profile or close the account
*
**********/

import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import Axios from 'axios';

import { AccountContext, getUtilities, Animate, ViewContext } from 'components/lib';

import { CreateUtility } from './create-utility';

import { Alert, Button as AntButton, Collapse, List, Tag, Typography } from 'antd';
import { EyeOutlined } from '@ant-design/icons';


const { Panel } = Collapse;
const { Paragraph, Text } = Typography;


export function Utilities({ isPremium }){

    const viewContext = useContext(ViewContext);
    const { accountState, accountDispatch } = useContext(AccountContext);
    const [reveals, setReveals] = useState({});

    const prepareUtilityData = useCallback(async () => {
        try {
            await getUtilities(accountDispatch, {
                params: {
                    with_pipelines: 1,
                    with_settings: 1
                }
            });

        } catch (err) {
            viewContext.handleError(err);
        }
    }, [viewContext, accountDispatch]);

    useEffect(() => {

        if (accountState.utilities) {
            const revealOpts = accountState.utilities.reduce((opts, obj) => {
                if (obj.settings) {
                    if (!opts[obj.settings.id]) opts[obj.settings.id] = false;
                }

                return opts;
            }, {});

            setReveals(revealOpts);
        }

    }, [accountState.utilities]);

    useEffect(() => {

        prepareUtilityData();
        
    }, []);


    const openEditWebsiteSettings = (item) => {
        const isUpdate = item?.settings?.id;
        const formObj = {
            website_url: {
                label: "Website URL",
                type: "text",
                required: true,
                name: "website_url",
                id: "website_url",
                value: item?.settings?.website_url
            },
            username: {
                label: "User Name",
                type: "text",
                required: true,
                name: "username",
                id: "username",
                value: item?.settings?.username
            },
            password: {
                label: "Password",
                type: "text",
                required: true,
                name: "password",
                id: "password",
                value: item?.settings?.password
            },
            utility_id: {
                type: "hidden",
                name: "utility_id",
                id: "utility_id",
                value: item?.id
            }
        };

        if (isUpdate) {
            formObj['id'] = {
                type: "hidden",
                name: "id",
                id: "id",
                value: item?.settings?.id
            };
        }

        viewContext.modal.show({

            title: 'Edit Utility Information',
            form: formObj,
            buttonText: 'Submit',
            url: '/api/company-utility/account-setting',
            method: isUpdate ? 'PATCH' : 'POST'
      
          }, () => {
            prepareUtilityData();
          });
    }

    const removeUtility = async (item) => {
        try {
            const res = await Axios.delete('/api/company-utility', { data: { id: item.id } });
      
            if (res.status === 200) {
                viewContext.notification.show('Utility was removed', 'success', true);
                await prepareUtilityData();
            }          
          }
          catch (err){
            viewContext.handleError(err);
          }
    }

    const renderUtilities = (item) => {
        const renderPipelineList = (pls) => {


            return (
                <Collapse defaultActiveKey={['1']} ghost>
                    <Panel header="Pipelines" key={item.id}>
                        { pls.map(obj => (<p>{obj.name}</p>)) }
                    </Panel>
                </Collapse>
            )
        }

        return (
            <List.Item
                actions={[
                    <AntButton 
                        key="utility-edit" 
                        onClick={() => openCreatUtilityModal(item)}>
                            Edit
                    </AntButton>,
                    <AntButton 
                        key="utility-remove" 
                        onClick={async() => await removeUtility(item)}>
                            Delete
                    </AntButton>,
                    <AntButton 
                        key="utility-website-setting-edit" 
                        onClick={() => openEditWebsiteSettings(item)}>
                            Edit Website Details
                    </AntButton>,
                ]}
            >
                <ul>
                    <li>
                        <Text>{item.name}</Text>
                        {!item.settings && isPremium && 
                            <Tag color="yellow">
                                Must add website credentials    
                            </Tag>
                        }
                    </li>
                    
                </ul>
            </List.Item>
        );
    }

    const openCreatUtilityModal = (utilityData) => {
        viewContext.modal.show({
            title: utilityData ? 'Edit Utility' : 'Create Utility',
            customForm: CreateUtility,
            formProps: {
                utilityData
            },
        }, (form, res) => {
            if (res) {
                prepareUtilityData();
                viewContext.notification.show('New utility has been created.', 'success', true);
            }
        });
    }

    const hasNoPipelines = () => !accountState?.pipelines || !accountState?.pipelines?.length;

    return (
        <Fragment>
          <Animate>
            <>
                {hasNoPipelines() ?
                    <Alert
                        message={"You must create some pipelines first."}
                        type="info"
                        style={{ marginBottom: '20px' }}
                    /> :
                    <AntButton
                        style={{ margin: '10px 0' }}
                        size="middle"
                        onClick={() => openCreatUtilityModal() }
                    >
                        Create Utility
                    </AntButton>
                }

                {accountState.utilities?.length > 0 && 
                    <>
                        {isPremium &&
                            <Alert
                                message={"Make sure you add website credentials for each utility company."}
                                type="error"
                                style={{ marginBottom: '20px' }}
                            />
                        }

                        <List
                            size="small"
                            bordered
                            dataSource={accountState.utilities}
                            renderItem={renderUtilities}
                        />
                    </>
                }
                
            </>
          </Animate>
        </Fragment>
      );
}