import React, { Fragment, useContext, useState } from 'react';
import { Animate, ViewContext, Button, Grid } from 'components/lib';

import { Table, Typography, Space } from 'antd';;

const { Text } = Typography;

export function OutOfBalanceList({ outOfBalanceContracts }) {
    const context = useContext(ViewContext);

    const [outOfBalanceReceiptLocs] = useState(outOfBalanceContracts);

    const renderTable = () => {

        const columns = [
            {
                title: 'Out of Balance Receipt Locations',
                render: (record) => {
                    return (
                        <Fragment>
                            Receipt Location Name: {record.receipt_location_name}
                            <br />
                            Status: {record.status}
                            <br />
                            Receipt Location Total: {record.receipt_location_total}
                            <br />
                            Purchased Gas: {record.purchased_gas}
                            <br />
                            Difference: {record.difference}
                        </Fragment>
                    );
                },
                responsive: ["xs"]
            },
            {
                title: 'Receipt Location Name',
                dataIndex: 'receipt_location_name',
                key: 'receipt_location_name',
                responsive: ["sm"]
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                responsive: ["sm"]
            },
            {
                title: 'Receipt Location Total',
                dataIndex: 'receipt_location_total',
                key: 'receipt_location_total',
                responsive: ["sm"]
            },
            {
                title: 'Purchased Gas',
                dataIndex: 'purchased_gas',
                key: 'purchased_gas',
                responsive: ["sm"]
            },
            {
                title: 'Difference',
                dataIndex: 'difference',
                key: 'difference',
                responsive: ["sm"]
            }
        ];

        return (
            <Table
                columns={columns}
                dataSource={outOfBalanceReceiptLocs}
                hideOnSinglePage
                bordered
                pagination={false}
            >
            </Table>
        );
    }

    return (
        <Animate type="pop">
            <Grid cols="1">
                <div style={{ paddingBottom: '20px' }}>
                    {renderTable()}
                </div>
                <Space direction="vertical">
                    <Text italic><strong>Short -</strong> The total volume for a receipt location is below the purchased gas volume for that receipt location.</Text>
                    <Text italic><strong>Long -</strong> The total volume for a receipt location is above the purchased gas volume for that receipt location.</Text>
                </Space>
            </Grid>
            <Grid col="1">
                <Button outline text='Close' action={ () => context.modal.hide(true) } />
            </Grid>
        </Animate>
    );
}