/***
*
*   SIGN UP STEP 1
*   Signup form for account owners
*   Step 1: create account
*   Step 2: select plan and make payment
*
**********/

import React, { useContext, useEffect } from 'react';
import { AuthContext, Animate, Row, Card, Form, Link, useSignupInvite } from 'components/lib';

export function Signup(props){

  const context = useContext(AuthContext);
  const signupInvite = useSignupInvite(window.location.search);

  useEffect(() => {
    if(!signupInvite.loading) {
      if ((signupInvite.data && !Object.keys(signupInvite.data).length) || signupInvite?.data?.used) {
        setTimeout(() => {
          context.signout();
        }, 1000);
      }
    }
  }, [signupInvite]);

  return(
    <Animate type='pop'>
      <Row title='Create Your Account'>
        <Card loading={ false } restrictWidth center>

          <Form
            data={{
              name: {
                label: 'Company Name',
                value: '',
                type: 'text',
                required: true,
                errorMessage: 'Please enter your company name'
              },
              email: {
                label: 'Email',
                value: '',
                type: 'email',
                required: true,
              },
              password: {
                label: 'Password',
                type: 'password',
                required: true,
                complexPassword: true
              },
              confirm_password: {
                type: 'hidden',
                value: null,
              },
            }}
            url='/api/account'
            method='POST'
            buttonText='Create Account'
            callback={ (data) => context.signin(data, { id: signupInvite?.data?.id, email: signupInvite?.data?.email }) }
          />

          <span>Already registered? <Link url='/' text='Sign In' /></span>

        </Card>
      </Row>
    </Animate>
  );
}
