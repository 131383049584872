/***
*
*   ACCOUNT NAV
*   Acount sub nav that renders different options based
*   on user permissions. Shows billing & user admin to only account owners
*
**********/

import React from 'react';
import { NavLink } from "react-router-dom";
import './sub.scss';

export function SettingNav(props){

  return(
    <nav className='subnav'>

      <NavLink exact to='/settings' activeClassName='active'>Features</NavLink>
      <NavLink exact to='/settings/companies' activeClassName='active'>Companies</NavLink>
      <NavLink exact to='/settings/utilities' activeClassName='active'>Utilities</NavLink>
      <NavLink exact to='/settings/pipelines' activeClassName='active'>Pipelines</NavLink>
      <NavLink exact to='/settings/locations' activeClassName='active'>Locations</NavLink>

    </nav>
  );
}
