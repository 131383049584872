import React, { useContext, useState } from 'react';
import Axios from 'axios';
import { Button, Grid, ViewContext } from 'components/lib';

export function ConfirmDeleteInterruptible({ interruptible }) {
    const context = useContext(ViewContext);

    const submit = async () => {
        try {
            const res = await Axios.delete('/api/interruptible', { data: { id: interruptible.id } });
      
            if (res.status === 200) {
                context.modal.hide(false, res.data.data);
            }          
          }
          catch (err){
            context.handleError(err);
          }
    }

    return (
        <>
            <p>Removing this interruptible will also remove it's dths flow logs as well.</p>
            <p>Are you sure you want to remove this interruptible?</p>
            <Grid cols="2">
                <Button
                    fullWidth
                    text="Yes"
                    action={ submit }
                />
                <Button fullWidth outline text='No' action={ () => context.modal.hide(true) } />
            </Grid>
        </>
    );
}