import { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { ViewContext, Button, Grid } from 'components/lib';
import { IDLData, IDelCycleData } from 'ts/interfaces/contract';
import { Table, Tag, Typography } from 'antd';
import moment from 'moment';

const { Text } = Typography;

export function CycleQuantityHistory({ delLocation, currentDate }: { delLocation: IDLData, currentDate: string }) {
    const context = useContext(ViewContext);

    const [cycles, setCycles] = useState<IDelCycleData[] | undefined>(undefined);

    useEffect(() => {

        const getCycles = async () => {
            try {
                const res = await Axios.get('/api/contract/delivery-cycle', {  
                    params: { 
                        contract_id: delLocation.contract_id,
                        delivery_location_quantity_id: delLocation.latest_quantity_id,
                        current_date: currentDate
                    } 
                });
    
                if (res.status === 200) {
                    setCycles(res.data.data);
                }
            } catch (err) {
                context.handleError(err);
            }
        }
        
        if (!cycles && delLocation.latest_quantity_id) getCycles();

    }, []);

    const renderTable = () => {

        if (!cycles) return null;

        const columns = [
            {
                title: 'Date',
                dataIndex: 'date',
                render: (date: string) => moment(date).format('YYYY-MM-DD')
            },
            {
                title: 'Total Quantity',
                dataIndex: 'delivery_total_amount',
                key: 'delivery_total_amount',
                responsive: ["sm"],
                render: (data: any, row: any) => (<>{row.delivery_total_amount} <Tag color="blue">Received: {row.receipt_total_amount}</Tag></>)
            },
            {
                title: 'Timely',
                dataIndex: 'timely',
                key: 'timely',
                responsive: ["sm"] ,
                render: (data: any, row: any) => (<>{data} {row[`receipt_quantity_timely`] && <Tag color="blue">Received: {row.receipt_quantity_timely}</Tag>}</>)  
                },
            {
                title: 'Evening',
                dataIndex: 'evening',
                key: 'evening',
                responsive: ["sm"],
                render: (data: any, row: any) => (<>{data} {row[`receipt_quantity_evening`] && <Tag color="blue">Received: {row.receipt_quantity_evening}</Tag>}</>)  
            },
            {
                title: 'ID1',
                dataIndex: 'id1',
                key: 'id1',
                responsive: ["sm"],
                render: (data: any, row: any) => (<>{data} {row[`receipt_quantity_id1`] && <Tag color="blue">Received: {row.receipt_quantity_id1}</Tag>}</>)  
            },
            {
                title: 'ID2',
                dataIndex: 'id2',
                key: 'id2',
                responsive: ["sm"],
                render: (data: any, row: any) => (<>{data} {row[`receipt_quantity_id2`] && <Tag color="blue">Received: {row.receipt_quantity_id2}</Tag>}</>)  
            },
            {
                title: 'ID3',
                dataIndex: 'id3',
                key: 'id3',
                responsive: ["sm"],
                render: (data: any, row: any) => (<>{data} {row[`receipt_quantity_id3`] && <Tag color="blue">Received: {row.receipt_quantity_id3}</Tag>}</>)  
            }
        ];
        
        const tableData = cycles.reduce((batch: any, cycle: IDelCycleData) => {
            const dateKey = moment(cycle.date).format('YYYY-MM-DD');
            let foundObj = batch.find((obj: IDelCycleData) => obj.date === dateKey);

            if (!foundObj) {
                foundObj = { date: dateKey };
            };

            const cycleNameKey = cycle.cycle_name.toLowerCase();

            if (!foundObj[cycleNameKey]) {
                foundObj[cycleNameKey] = cycle.quantity;
                foundObj[`receipt_quantity_${cycleNameKey}`] = cycle.receipt_cycle_quantity;
                foundObj['delivery_total_amount'] = cycle.delivery_total_amount;
                foundObj['receipt_total_amount'] = cycle.receipt_total_amount;
            }

            return [...batch, foundObj];
        }, [] as any);

        return (
            <Table
                bordered
                dataSource={tableData}
                pagination={{
                    pageSize: 5
                }}
                columns={columns as Array<any>}
            />
        );
    }

    return (
        <Grid>
            {!delLocation.latest_quantity_id &&
                <>
                    <Text>Currently this delivery location has yet to have any quantity assigned to it.</Text>
                    <Text>Assign quantity to this delivery location in order to split it up amongst the cycles.</Text>
                </>
                
            }
            { renderTable() }
            <Button outline text='Close' action={ () => context.modal.hide(true) } />
        </Grid>
    );

}