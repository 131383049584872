import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { ViewContext, Button, Grid, Radio, Select, useAPI } from 'components/lib';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import Style from './reports.module.scss';

export function EmailSetup(props) {
    const context = useContext(ViewContext);

    const emailJobData = useAPI('/api/email-job');

    const [loading, setLoading] = useState(false);
    const [recipients, setRecipients] = useState([]);
    const [emailType, setEmailType] = useState(false);
    const [emailJobs, setEmailJobs] = useState([]);
    const [selectedEmailJob, setSelectedEmailJob] = useState(undefined);

    useEffect(() => {
        if(emailJobData && emailJobData.data && emailJobData.data.length) {
            setEmailJobs(emailJobData.data);
        }
    }, [emailJobData]);

    const submit = async () => {
        setLoading(true);
            try {
                const res = await Axios.post('/api/daily-nomination/email', { 
                    emails: recipients,
                    flowDate: props.flowDate,
                    dailyNomination: props.dailyNomination,
                    deals: props.deals
                });
          
                if (res.status === 200) {
                    setLoading(false);
                    
                    // send the user some where else
                    context.modal.hide(true);
                }          
            } catch (err){
                setLoading(false);
                context.handleError(err);
            }
    }

    const submitSchedule = async () => {
        try {
            const res = await Axios.post('/api/daily-nomination/email', { 
                emails: recipients,
                flowDate: props.flowDate,
                dailyNomination: props.dailyNomination,
                deals: props.deals,
                emailJobId: selectedEmailJob
            });
      
            if (res.status === 200) {
                setLoading(false);
                
                // send the user some where else
                context.modal.hide(true);
                context.notification.show('Email is scheduled to be sent at', 'success', true);
            }          
        } catch (err){
            setLoading(false);
            context.handleError(err);
        }
    }

    const getTimeString = (jobData) => {
        let meridiemType = 'AM';
        let recurringHour = jobData.recurring_time_hour;
        const recurringMin = jobData.recurring_time_minute;

        if(parseInt(recurringHour) > 12) {
            recurringHour = recurringHour - 12;
            meridiemType = 'PM';
        }

        return `${recurringHour}:${recurringMin} ${meridiemType}`;
    }

    const getEmailJobOptions = () => (emailJobs.map(emailJob => ({
            label: `${emailJob.name} - ${getTimeString(emailJob)}`,
            value: emailJob.id
        })))

    return (
        <div className={Style.emailSetupFormWrap}>
            <Grid col="2">
                <Radio
                    name="email-type"
                    option={{
                        value: 0,
                        label: "Send Email Manually"
                    }}
                    checked={!emailType}
                    callback={(index, checked, value) => {
                        setEmailType(Boolean(value));
                    }}
                />
                <Radio
                    name="email-type"
                    option={{
                        value: 1,
                        label: "Pick a Scheduled Email Job"
                    }}
                    checked={emailType}
                    callback={(index, checked, value) => {
                        setEmailType(Boolean(value));
                    }}
                />
            </Grid>
            <Grid cols="1">
                {!emailType &&
                    <div>
                        <ReactMultiEmail
                                className={Style.multiEmail}
                                placeholder="Add Receipient Emails"
                                emails={recipients}
                                onChange={(emails) => setRecipients(emails)}
                                validateEmail={email => {
                                    return isEmail(email); // return boolean
                                }}
                                getLabel={(email, index, removeEmail) => {
                                    return (
                                    <div data-tag key={index}>
                                        {email}
                                        <span data-tag-handle onClick={() => removeEmail(index)}>
                                        ×
                                        </span>
                                    </div>
                                    );
                                }}
                                />
                        <Grid cols="2">
                            {recipients.length > 0 &&
                                <Button
                                    fullWidth
                                    loading={ loading }
                                    text="Send Email"
                                    action={ submit }
                                />
                            }
                            <Button outline fullWidth text='Cancel' action={ () => context.modal.hide(true) } />
                        </Grid>
                    </div>
                }
                {emailType &&
                    <div>
                        <Select
                                required
                                customLabel="Select scheduled email job for email to be sent."
                                placeholder="Select Email Job"
                                name="email-job-select"
                                value={selectedEmailJob}
                                onChange={(id, value) => setSelectedEmailJob(value)}
                                options={getEmailJobOptions()}
                        >
                        </Select>
                        <Grid cols="2">
                            {selectedEmailJob &&
                                    <Button
                                        fullWidth
                                        loading={ loading }
                                        text="Schedule Email"
                                        action={ submitSchedule }
                                    />
                            }
                            <Button outline fullWidth text='Cancel' action={ () => context.modal.hide(true) } />
                        </Grid>
                        
                    </div>
                }
            </Grid>
        </div>
    );
}