import { Dashboard } from 'views/dashboard/dashboard';
import { FlowDatesOverview, FlowDateOverview } from 'views/spot-deals';
import { LongTermDeals } from 'views/long-term-deals/long-term-deals';
import { LongTermDeal } from 'views/long-term-deals/long-term-deal';
import { CreateDailyNomination } from 'views/daily-nomination/create-daily-nomination';
import { Reports } from 'views/reports/reports';
import { DealLogs } from 'views/deal-logs/deal-logs';
import { Interruptible } from 'views/interruptibles/interruptible';
import { Interruptibles } from 'views/interruptibles/interruptibles';
import { EmailJobs } from 'views/email-jobs/email-jobs';
import { Contracts } from 'views/contracts/contracts';
import { Contract } from 'views/contracts/contract';
import { CreateContract } from 'views/contracts/new-contract';
import { UtilityReqs } from 'views/utility-reqs/utility-reqs';
import { Utility } from 'views/utility-reqs/utility';
import { Help } from 'views/dashboard/help';
import { OnboardingView } from 'views/onboarding/onboarding';

const Routes = [
  {
    path: '/dashboard',
    view: Dashboard,
    layout: 'app',
    permission: 'user',
    title: 'Dashboard'
  },
  {
    path: '/spot-deals',
    view: FlowDatesOverview,
    layout: 'app',
    permission: 'user',
    title: 'Flow Dates Overview'
  },
  {
    path: '/spot-deals/:id',
    view: FlowDateOverview,
    layout: 'app',
    permission: 'user',
    title: 'Flow Date Overview'
  },
  {
    path: '/long-term-deals',
    view: LongTermDeals,
    layout: 'app',
    permission: 'user',
    title: 'Long Term Deals'
  },
  {
    path: '/long-term-deals/:id',
    view: LongTermDeal,
    layout: 'app',
    permission: 'user',
    title: 'Long Term Deals'
  },
  {
    path: '/daily-nomination',
    view: CreateDailyNomination,
    layout: 'app',
    permission: 'user',
    title: 'Daily Nomination'
  },
  {
    path: '/reports',
    view: Reports,
    layout: 'app',
    permission: 'user',
    title: 'Reports'
  },
  {
    path: '/deal-logs',
    view: DealLogs,
    layout: 'app',
    permission: 'user',
    title: 'Deal Logs'
  },
  {
    path: '/interruptibles',
    view: Interruptibles,
    layout: 'app',
    permission: 'user',
    title: 'Interruptibles'
  },
  {
    path: '/interruptibles/:id',
    view: Interruptible,
    layout: 'app',
    permission: 'user',
    title: 'Interruptible'
  },
  {
    path: '/email-jobs',
    view: EmailJobs,
    layout: 'app',
    permission: 'user',
    title: 'Email Jobs'
  },
  {
    path: '/contracts',
    view: Contracts,
    layout: 'app',
    permission: 'user',
    title: 'Contracts'
  },
  {
    path: '/contracts/:id',
    view: Contract,
    layout: 'app',
    permission: 'user',
    title: 'Contract'
  },
  {
    path: '/new-contract',
    view: CreateContract,
    layout: 'app',
    permission: 'user',
    title: 'Create Contract'
  },
  {
    path: '/utility-reqs',
    view: UtilityReqs,
    layout: 'app',
    permission: 'user',
    title: 'Utility Reqs'
  },
  {
    path: '/utility/:id',
    view: Utility,
    layout: 'app',
    permission: 'user',
    title: 'Utility'
  },
  {
    path: '/welcome',
    view: OnboardingView,
    layout: 'onboarding',
    permission: 'user',
    title: 'Welcome'
  },
  {
    path: '/help',
    view: Help,
    layout: 'app',
    permission: 'user',
    title: 'Get Help'
  },
]

export default Routes;
