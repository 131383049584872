import React, { useContext, useState } from 'react';
import { ViewContext, Button, Label, Grid } from 'components/lib';
import { InterruptibleService } from 'services';

import moment from 'moment';
import * as FileSaver from 'file-saver';
import { Alert, DatePicker } from 'antd';

export function InterruptibleExport({ interruptible }) {

    const context = useContext(ViewContext);
    const [loading, setLoading] = useState(false);
    const [chosenMonth, setChosenMonth] = useState(undefined);
    const [noFlowsMessage, setNoFlowsMessage] = useState(false);

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    
    const getMaxDate = () => {
        // moment obj day / month are zero based
        return moment().add(1, 'M').format('YYYY-MM');
    }

    const exportData = async ({ excelBuffer, fileName }) => {

        if (excelBuffer) {
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, fileName);
        }
    }

    const submit = async () => {
        if(noFlowsMessage) setNoFlowsMessage(false);

        try {
            setLoading(true);

            const res = await InterruptibleService.getExportInterrupbles({ 
                interruptible_id: interruptible.id,
                start_date: chosenMonth.startOf('month').format('YYYY-MM-DD hh:mm'),
                end_date: chosenMonth.endOf('month').format('YYYY-MM-DD hh:mm'),
                export: true
            });

            const fileName = res.headers['content-disposition'].split("=")[1].replace(/['"]+/g, '');

            if (res.status === 200 && fileName) {
                if(res.data) {
                    exportData({
                        excelBuffer: res.data,
                        fileName
                    });

                    setLoading(false);
                    context.modal.hide(true);
                } else {
                    setNoFlowsMessage(true);
                }
            } 
        } catch (err) {
            setLoading(false);
            context.handleError(err);
        }
    }

    return (
        <div>
            <Grid>
                {noFlowsMessage && 
                    <Alert
                        message="This month does not have any flow dths data to export." 
                        type="error"
                    />
                }
                <Label text="Select Month" />
                <DatePicker 
                    style={{ width: '100%' }}
                    onChange={(dateObj, date) => setChosenMonth(dateObj)} 
                    picker="month"
                    disabledDate={d => !d || !d.isAfter(moment(interruptible.start_date).format('YYYY-MM')) || d.isAfter(getMaxDate())} 
                    direction="ltr"
                />
            </Grid>
            <Grid>
                {chosenMonth &&
                    <Button
                        loading={ loading }
                        text="Export"
                        action={ submit }
                    />
                }
                <Button outline text='Cancel' action={ () => context.modal.hide(true) } />
            </Grid>
        </div>
    );
}