import React, { createContext, useReducer } from 'react';
import AccountReducer from "./accountReducer";

export type InitialState = {
    points?: [],
    positions?: [],
    pipelines?: [],
    companies?: [],
    utilities?: [],
    hasOnboardData: boolean,
    loading: boolean,
    error?: string
}

const initialState: InitialState = {
    points: undefined,
    pipelines: undefined,
    positions: undefined,
    companies: undefined,
    utilities: undefined,
    hasOnboardData: false,
    loading: false,
    error: undefined
}

export type AccountContextType = {
    accountState: InitialState,
    accountDispatch?: React.DispatchWithoutAction
}

export const AccountContext = createContext<AccountContextType>({ accountState: initialState });

export const AccountProvider = ({ children }: any) => {

    const [state, dispatch] = useReducer(AccountReducer, initialState);

    return (
        <AccountContext.Provider 
            value={{
               accountState: state,
               accountDispatch: dispatch 
            }}
        >
        {children}
        </AccountContext.Provider>
    );
}