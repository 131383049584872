import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { Animate, AccountContext, ViewContext, Button, Card, Grid, Search, Switch, useWindowSize } from 'components/lib';

import { CreateLinkContract } from './create-link-contract';
import { CreateSplit } from './create-split';
import { DeliveryLocationQuantity } from './delivery-location-quantity';
import { CycleQuantity } from './cycle-quantity';
import { DownOutlined } from '@ant-design/icons';

import { useHistory } from 'react-router-dom';

import { isNumber } from 'utilities/general';

import { KeyPair } from "ts/interfaces/general";
import { IContract, IContractParams, IContractTableData, IDLData, ILinkData } from 'ts/interfaces/contract';
import { IAvailableSpace } from 'ts/interfaces/pipeline';

import Style from './contracts.module.scss';
import moment, { Moment } from 'moment';

import { Button as AntButton, Collapse, DatePicker, Dropdown, Empty, Spin, Table, Tag, Tooltip, Space, Row, Col, Typography, Divider } from 'antd';

import { FilterOutlined } from '@ant-design/icons';

import ClassNames from 'classnames';

import 'ts/types/ant-design';
import { Icon } from '@fortawesome/fontawesome-svg-core';

const { Panel } = Collapse;
const { Text } = Typography;

export function Contracts() {
    const context = useContext(ViewContext);
    const { accountState } = useContext(AccountContext);
    const { width } = useWindowSize();

    const history = useHistory();

    const limit = 100;
    const dateFormat = 'YYYY-MM-DD';

    const css = ClassNames([
        Style.switchWrap, 
        'gutter-row'
    ])

    const getOptionData = (data: Array<{name: string, id: string}> | undefined):Array<KeyPair<string, string | undefined>> => data?.map(obj => ({
        label: obj.name,
        value: obj.id
    })) || [];

    const [delLocQuantityView, setDelLocQuantityView] = useState(false);
    const [contracts, setContracts] = useState<Array<IContract> | undefined>(undefined);
    const [pipelineAvails, setPipelineAvails] = useState<Array<IAvailableSpace> | undefined>(undefined);
    const [pipelineAvailDate, setPipelineAvailDate] = useState<Moment | null>(moment());
    

    const [search, setSearch] = useState(undefined);
    const [currentDate] = useState(moment().format(dateFormat));
    const [loading, setLoading] = useState(false);
    const [pipelineAvailLoading, setPipelineAvailLoading] = useState(false);
    const [offset] = useState(0);
    const [pageSizeCount] = useState(10);
    const [pageData, setPageData] = useState({
        current: 1,
        pageSize: 10,
        total: 100
    });

    const timeFilterOptions = [
        {
            value: 0,
            label: 'All Timeframes'
        },
        {  
            value: 1,
            label: 'Current'
        },
        {
            value: 2,
            label: 'Expired'
        },
        {
            value: 3,
            label: 'Has Not Started'
        }
    ];

    const prepareUtilFilterOpts = () => {
        const allUtilOpt = { label: 'All Utilities', value: undefined};
        const opts = getOptionData(accountState.utilities);

        opts.unshift(allUtilOpt);

        return opts;
    }

    const preparePipelineFilterOpts = () => {
        const allPipelineOpt = { label: 'All Pipelines', value: undefined};
        const opts = getOptionData(accountState.pipelines);

        opts.unshift(allPipelineOpt);

        return opts;
    }

    const [timeFilter, setTimeFilter] = useState(timeFilterOptions[1]);
    const [utilities] = useState(prepareUtilFilterOpts());
    const [utilFilter, setUtilFilter] = useState(prepareUtilFilterOpts()[0]);
    const [pipelines] = useState(preparePipelineFilterOpts());
    const [pipelineFilter, setPipelineFilter] = useState(preparePipelineFilterOpts()[0]);
    const [gateFilter, setGateFilter] = useState(false);

    const [initLoad, setInitLoad] = useState(false);

    const getContracts = useCallback(async (filterChange?: boolean | undefined) => {
        try {
            setLoading(true);
            let params: IContractParams = {
                limit,
                offset,
                current_date: currentDate,
                time_filter_type: parseInt((timeFilter?.value as unknown) as string),
                utility_id: utilFilter?.value,
                pipeline_id: pipelineFilter?.value,
                is_gate: gateFilter
            };

            
            if (search) params = {...params, search };

            const res = await Axios.get('/api/contract', {  
                params
            });
          
            if (res.status === 200) {     
                const currentContracts = !contracts ? [] : contracts;
                const newSearchBatch = filterChange ? res.data.data : [ ...currentContracts, ...res.data.data];

                setContracts(newSearchBatch);

                if(res.data.count !== pageData.total) setPageData({...pageData, total: res.data.count });

                if (!initLoad) getPipelineAvailableSpace();

                setInitLoad(true);
                setLoading(false);
            } 
        } catch (err) {
            setLoading(false);
            context.handleError(err);
        }
    }, [setLoading, setContracts, gateFilter, timeFilter, pipelineFilter, utilFilter, search]);

    const getPipelineAvailableSpace = useCallback(async () => {
        
        try {
            setPipelineAvailLoading(true);

            const res = await Axios.get('/api/pipeline/available-space', {  
                params: {
                    current_date: pipelineAvailDate?.format(dateFormat)
                }
            });
          
            if (res.status === 200) {     
                setPipelineAvails(res.data.data);
                setPipelineAvailLoading(false);
            } 
        } catch (err) {
            setPipelineAvailLoading(false);
            context.handleError(err);
        }

    }, [pipelineAvailDate, setPipelineAvails]);
    

    const handleTableChange = (pagination: Record<string, any>) => {
        const { current, pageSize } = pagination;
        const totalPages = Math.floor(pageSizeCount * pageSize);

        setPageData({ ...pageData, current });

        if (totalPages === contracts?.length) {
            getContracts();
        }
    }

    useEffect(() => {
        if (!contracts && !loading) {
            getContracts();
        }
    }, [contracts]);

    useEffect(() => {

        if(!loading && initLoad) getContracts(true);

    }, [search, gateFilter, timeFilter, pipelineFilter, utilFilter]);

    useEffect(() => {
        if (initLoad && pipelineAvailDate && contracts?.length) {
            getPipelineAvailableSpace();
        }

    }, [initLoad, pipelineAvailDate, getPipelineAvailableSpace]);

    const openCreateContractLinkModal = (contractObj: IContract) => {
        context.modal.show({
            title: `Link Contract - ${contractObj.contract_num}`,
            customForm: CreateLinkContract,
            formProps: {
                primaryContract: contractObj
            },
            lgModal: true
        }, (form: any, res: any) => {
            if (res) {
                getContracts();
                context.notification.show('Contract has been linked.', 'success', true);
            }
        });
    } 

    const openCreateSplitModal = (contractObj: IContract) => {
        context.modal.show({
            title: `Split Contract - ${contractObj.contract_num}`,
            customForm: CreateSplit,
            formProps: {
                contract: contractObj
            },
            lgModal: true
        }, (form: any, res: any) => {
            if (res) {
                getContracts();
                context.notification.show('Contract has been split.', 'success', true);
            }
        });
    } 

    const openDelLocationQuantityModal = (contract: IContract) => {
        context.modal.show({
            title: `Update Receipt / Deilvery Location Volume Amount for Contract ${contract.contract_num}`,
            customForm: DeliveryLocationQuantity,
            formProps: {
                contractData: contract
            },
            lgModal: true
        }, () => {
            getContracts();
            context.notification.show('Contract delivery location quantity has been updated.', 'success', true);
        });
    }

    const openCycleQuantityModal = (contract: IContract) => {
        context.modal.show({
            title: `Update Cycle Amount for Contract ${contract.contract_num}`,
            customForm: CycleQuantity,
            formProps: {
                contractData: contract
            },
            lgModal: true
        }, () => {
            getContracts();
            context.notification.show('Delivery / Reciept cycle quantity has been updated.', 'success', true);
        }); 
    }

    const sendToCreateContract = (contract: IContract) => {
        history.push({
            pathname: '/new-contract',
            state: { updateContract: contract }
        });
    }

    const renderDelLocContracts = (): JSX.Element | React.FC => {
        if (!contracts || !contracts?.length) {
            return renderEmptyDataEl();
        }

        const data = contracts ? contracts.map((contract, idx): IContractTableData => ({...contract, key: idx})) : [];

        const renderAction = (contract: IContractTableData) => {
            return (
                <Space size="middle">
                    <AntButton onClick={() => openDelLocationQuantityModal(contract)}>Update Receipt / Delivery Location Amount</AntButton>
                </Space>
            );
        }

        const getLatestDelLocUpdateDate = (data: IContract):string => {
            const moments = data.delivery_locations.map(d => moment(d.latest_quantity_date));

            return moments.length ? moment.max(moments).format('MMM, DD YYYY') : 'N/A';
        }


        const columns: Array<any> = [
            {
                title: 'Contracts',
                render: (record: IContractTableData) => {
                    return (
                        <Fragment>
                            Contract Num: {record.contract_num}
                            <br />
                            Last Updated: {getLatestDelLocUpdateDate(record)}
                            <Divider />
                            { renderAction(record) }
                        </Fragment>
                    );
                },
                responsive: ["xs"]
            },
            {
                title: 'Contract Num',
                dataIndex: 'contract_num',
                key: 'contract_num',
                responsive: ["sm"]
            },
            {
                title: 'Last Time Updated',
                key: 'last_time_updated',
                responsive: ["sm"],
                render: (data: IContract) => getLatestDelLocUpdateDate(data)
            },
            {
                title: 'Action',
                key: 'action',
                responsive: ["sm"],
                render: renderAction
            }
        ];

        function expandedRowRender(row: IContractTableData) {
            const columns: Array<any> = [
                {
                    title: 'Delivery Locations',
                    render: (record: ILinkData) => {
                        return (
                            <Fragment>
                                Name: {record.location_num}
                                <br />
                                Location Num: {record.location_num}
                                <br />
                                Receipt Amount: {record.current_receipt_amount}
                                <br />
                                Max Capacity: {record.max_quantity}
                                <br />
                                Current Quantity: {record.current_delivery_amount}
                                <br />
                                Last Time Updated: {moment(record.latest_quantity_date).format('MMM, DD YYYY')}
                            </Fragment>
                        );
                    },
                    responsive: ["xs"]
                },
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    responsive: ["sm"]
                },
                {
                    title: 'Location Num',
                    dataIndex: 'location_num',
                    key: 'location_num',
                    responsive: ["sm"]
                },
                {
                    title: 'Receipt Amount',
                    dataIndex: 'current_receipt_amount',
                    key: 'current_receipt_amount',
                    responsive: ["sm"]
                },
                {
                    title: 'Max Capacity',
                    dataIndex: 'max_quantity',
                    key: 'max_quantity',
                    responsive: ["sm"]
                },
                {
                    title: 'Current Quantity',
                    dataIndex: 'current_delivery_amount',
                    key: 'current_delivery_amount',
                    responsive: ["sm"]
                },
                {
                    title: 'Last Time Updated',
                    dataIndex: 'latest_quantity_date',
                    key: 'latest_quantity_date',
                    responsive: ["sm"],
                    render: (data: Date) => moment(data).format('MMM, DD YYYY')
                }
            ];

            if (row?.linked_contracts && row.linked_contracts.length) {
                row.linked_contracts.forEach(linkedContract => {
                    row.delivery_locations = row.delivery_locations.map((dlObj:IDLData) => {
                        if (linkedContract.primary_linked_locations.length) {
                            const primaryTotal = linkedContract.primary_linked_locations.reduce((total: number, pll) => {
                                const quantity = pll?.quantity || 0;
                                return (pll.location_id === dlObj.location_id) ? total + quantity : total; 
                            }, 0);
                            
                            if (primaryTotal) dlObj.send_quantity = primaryTotal;
                        }

                        if (linkedContract.sub_linked_locations.length) {
                            const subTotal = linkedContract.sub_linked_locations.reduce((total, sll) => {
                                return (sll.location_id === dlObj.location_id) ? total + (sll?.additional_quantity || 0) : total;
                            }, 0);
                            
                            if (subTotal) dlObj.receive_quantity = subTotal;
                        }

                        return dlObj;
                    });

                });
            }

            const data = row.delivery_locations;

            return (
                <>
                    <p>Delivery Locations</p>
                    <Table columns={columns as Array<any>} dataSource={data} pagination={false} bordered />
                </>
            )
        }

        return (
            <Table
                columns={columns}
                dataSource={data}
                expandable={ {expandedRowRender} }
                onChange={handleTableChange} 
                pagination={pageData}
                loading={loading}
                bordered
            >
            </Table>
        );
    }

    const renderEmptyDataEl = () => {
        if (loading) {
            return (<Spin className={Style.emptyLoad} />);
        }

        return  (
            <Empty
                description={
                    <span>
                        No contracts available.
                    </span>
                }
            ></Empty>
        );
    }

    const renderContracts = () => {
        if (!contracts || !contracts?.length) {
            return renderEmptyDataEl();
        }

        const data = contracts ? contracts.map((contract, idx): IContractTableData => ({...contract, key: idx})) : [];

        
        const menu = (record: IContractTableData) => {
            const emptyQuantity = record.delivery_locations.filter(dl => !dl?.current_delivery_amount);
            const hasEmptyQuantity = record.delivery_locations.length === emptyQuantity.length;

            return {
                items: [
                    { label: 'Edit', key: '1' },
                    { label: 'Update Receipt / Delivery Amount', key: '2' },
                    { label: 'Update Cycle Amount', key: '3' },
                    ...(!hasEmptyQuantity ? [{ label: 'Link Contract', key: '4' }, { label: 'Split Contract', key: '5' }] : [])
                ],
                onClick: (e: any) => {
                    const menuEvents: {[x: string]: () => any} = {
                        1: () => sendToCreateContract(record),
                        2: () => openDelLocationQuantityModal(record),
                        3: () => openCycleQuantityModal(record),
                        4: () => openCreateContractLinkModal(record),
                        5: () => openCreateSplitModal(record),
                    }
    
                    if (menuEvents[e.key]) menuEvents[e.key]();
                }
            }
        };
        
        const renderActions = (record: IContractTableData) => ((
            <Space wrap>
                <Dropdown.Button 
                    onClick={() => {
                        history.push({
                            pathname: `/contracts/${record.id}`
                        });
                    }} 
                    menu={menu(record)}
                >
                    View Contract
                </Dropdown.Button>    
            </Space>
        ));

        const renderStatus = (record: IContractTableData) => {
            const hasSplit = record.delivery_locations && record.delivery_locations.find(dl => dl.current_splits && dl.current_splits.length);
            const linkedContracts = record?.linked_contracts ? record.linked_contracts.map(lkObj => 
                (
                    <p><strong>{lkObj.primary_contract_id === record.id ? lkObj.sub_contract_num : lkObj.primary_contract_num}</strong>:  {moment(lkObj.start_date).format(dateFormat)} - {moment(lkObj.end_date).format(dateFormat)} </p>
                )
            ) : null;
            const renderLinkedContracts = (
                <>
                    <p>Linked Contracts</p>
                    {linkedContracts}
                </>
            );

            return (
                <>
                    {record?.linked_contracts && record.linked_contracts.length > 0 &&
                        <Tooltip title={renderLinkedContracts}>
                            <Tag color="green">
                                LINKED    
                            </Tag>                        
                        </Tooltip>
                    }
                    {hasSplit &&
                        <Tag color="yellow">
                            SPLIT    
                        </Tag>
                    }
                    {Boolean(record?.is_gate) &&
                        <Tag color="blue">
                            GATE    
                        </Tag>
                    }
                </>
            );
        }

        const renderInvalidSpaceTag = (status: boolean) => {

            const tagMessage = !status ? 'Not enough space available!' : 'Not enough volume to send out!'
            const renderInvalidTooltip = !status ? (
                <>
                   <p>
                       Currently this contract does not have enough space to receive the volume its scheduled to receive.
                       <br />
                       You probably have this contract set to receive volume from another linked contract and / or a delivery amount on one of its delivery locations.
                       <br />
                       Change the delivery amount on of its delivery locations or the linked contract details.
                       <br />
                       Until this is fixed, this contract cannot be used in a daily nomination
                   </p>
                </>
            ) : (
                <>
                    <p>
                        Currently this contract does not have enough volume to send to either a linked contract or split on one of its delivery locations.
                        <br />
                        You may need to increase the volume on one of the delivery locations or change the linked contract / split details.
                        <br />
                        Until this is fixed, this contract cannot be used in a daily nomination.
                    </p>
                </>
            );

            return (
                <Tooltip title={renderInvalidTooltip}>
                    <Tag color="red">
                        {tagMessage}
                    </Tag>
                </Tooltip>
            );
        }

        const columns: Array<any> = [
            {
                title: 'Contracts',
                render: (record: IContractTableData) => {
                    return (
                        <Fragment>
                            Contract Num: <Text strong>{record.contract_num}</Text>
                            <br />
                            Pipeline: <Text strong ellipsis>{record.pipeline}</Text>
                            <br />
                            Receipt Name: <Text strong ellipsis>{record.receipt_location_name}</Text>
                            <br />
                            Start Date: <Text strong>{moment(record.start_date).format('MMM, DD YYYY')}</Text>
                            <br />
                            End Date: <Text strong>{moment(record.end_date).format('MMM, DD YYYY')}</Text>
                            <Divider />
                            { renderActions(record) }
                        </Fragment>
                    );
                },
                responsive: ["xs"]
            },
            {
                title: 'Contract Num',
                dataIndex: 'contract_num',
                key: 'contract_num',
                responsive: ["sm"]
            },
            {
                title: 'Pipeline',
                dataIndex: 'pipeline',
                key: 'pipeline',
                responsive: ["sm"]
            },
            {
                title: 'Receipt Name',
                dataIndex: 'receipt_location_name',
                key: 'receipt_location_name',
                responsive: ["sm"]
            },
            {
                title: 'Start Date',
                dataIndex: 'start_date',
                key: 'start_date',
                responsive: ["sm"],
                render: (data: Date) => moment(data).format('MMM, DD YYYY')
            },
            {
                title: 'End Date',
                dataIndex: 'end_date',
                key: 'end_date',
                responsive: ["sm"],
                render: (data: Date) => moment(data).format('MMM, DD YYYY')
            },
            {
                title: 'Max Receipt Capacity',
                dataIndex: 'receipt_location_max_quantity',
                responsive: ["sm"]
            },
            {
                title: 'Original Quantity',
                responsive: ["sm"],
                render: (data: IContractTableData) => data.delivery_locations.reduce((sum, obj) => sum + obj.current_delivery_amount, 0)
            },
            {
                title: 'Available Space',
                key: 'available_space',
                responsive: ["sm"],
                render: (data: IContractTableData) => {
                    if (data.available_space < 0) {
                        return renderInvalidSpaceTag(false);
                    } else if (data.available_space > data.total_delivery_max_quantity) {
                        return renderInvalidSpaceTag(true);
                    }

                    return data.available_space;
                }
            },
            {
                title: 'Status',
                key: 'status',
                responsive: ["sm"],
                render: renderStatus
            },
            {
                title: 'Action',
                key: 'action',
                responsive: ["sm"],
                render: renderActions,
                width: 180
            },
        ];

        const renderLinkStatus = (record: ILinkData) => (
            <>
            {record.send_quantity && 
                <Tag color="green">
                SENDING: {record.send_quantity}    
                </Tag>
            }
            {record.receive_quantity && 
                <Tag color="green">
                RECEIVING: {record.receive_quantity}    
                </Tag>
            }
            </>
        )

        function expandedRowRender(row: IContractTableData) {
            const delLocColumns = [
                {
                    title: 'Delivery Locations',
                    render: (record: ILinkData) => {
                        return (
                            <Fragment>
                                Name: <Text strong>{record.name}</Text>
                                <br />
                                Location Num: <Text strong>{record.location_num}</Text>
                                <br />
                                Max Capacity: <Text strong>{record.max_quantity}</Text>
                                <br />
                                Original Quantity: <Text strong>{moment(record.current_delivery_amount).format('MMM, DD YYYY')}</Text>
                                <br />
                                Avialable Quantity: <Text strong>{moment(record.final_quantity).format('MMM, DD YYYY')}</Text>
                                {(record.send_quantity || record.receive_quantity) &&
                                    <>
                                        <br />
                                        Current Link <Text strong>{renderLinkStatus(record)}</Text>
                                    </>
                                }
                                
                            </Fragment>
                        );
                    },
                    responsive: ["xs"]
                },
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    responsive: ["sm"]
                },
                {
                    title: 'Location Num',
                    dataIndex: 'location_num',
                    key: 'location_num',
                    responsive: ["sm"]
                },
                {
                    title: 'Max Capacity',
                    dataIndex: 'max_quantity',
                    key: 'max_quantity',
                    responsive: ["sm"]
                },
                {
                    title: 'Original Quantity',
                    dataIndex: 'current_delivery_amount',
                    key: 'current_delivery_amount',
                    responsive: ["sm"]
                },
                {
                    title: 'Avialable Quantity',
                    dataIndex: 'final_quantity',
                    key: 'final_quantity',
                    responsive: ["sm"]
                },
                {
                    title: 'Current Link',
                    key: 'linked',
                    render: renderLinkStatus,
                    responsive: ["sm"]
                },
                {
                    title: 'Current Split',
                    key: 'split',
                    render: (record: ILinkData) => (
                        <>
                            {record.current_splits && record.current_splits.length > 0 &&
                                <Tag color="yellow">
                                SPLIT: {record.current_splits.reduce((sum, obj) => sum + obj.split_quantity, 0)}    
                                </Tag>
                            }
                        </>
                    ),
                    responsive: ["sm"]
                }
            ];

            if (row?.linked_contracts && row.linked_contracts.length) {
                row.linked_contracts.forEach(linkedContract => {
                    row.delivery_locations = row.delivery_locations.map((dlObj: IDLData) => {
                        if (linkedContract.primary_linked_locations.length) {
                            const primaryTotal = linkedContract.primary_linked_locations.reduce((total, pll) => {
                                const quantity = pll?.quantity || 0;
                                return (pll.location_id === dlObj.location_id) ? total + quantity : total;
                            }, 0);
                            
                            if (primaryTotal) dlObj.send_quantity = primaryTotal;
                        }

                        if (linkedContract.sub_linked_locations.length) {
                            const subTotal = linkedContract.sub_linked_locations.reduce((total, sll) => {
                                const quantity = sll.additional_quantity || 0;
                                return (sll.location_id === dlObj.location_id) ? total + quantity : total; 
                            }, 0);
                            
                            if (subTotal) dlObj.receive_quantity = subTotal;
                        }

                        return dlObj;
                    });

                });
            }

            const data = row.delivery_locations;

            return (
                <div className={Style.subTableWrap}>
                    <p>Delivery Locations</p>
                    <Table columns={delLocColumns as Array<any>} dataSource={data} pagination={false} bordered />
                </div>
            )
        }

        const mediumScreenWidth = width && (width < 1150 && width > 574);
        
        if (mediumScreenWidth) {
            columns[columns.length - 1]['fixed'] = 'right';
        } else {
            delete columns[columns.length - 1]['fixed'];
        }

        return (
            <Table
                columns={columns}
                dataSource={data}
                expandable={ {expandedRowRender} }
                onChange={handleTableChange} 
                scroll={{ x: mediumScreenWidth ? 1300 : undefined }}
                pagination={pageData}
                loading={loading}
                bordered
                summary={() => {
                    let totalSpace = contracts ? contracts.reduce((sum, contractObj) => contractObj.available_space + sum, 0) : 0;

                    if (width && width < 769) {
                        return (
                            <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} colSpan={4}>
                                        Total Available Space:
                                        <Text strong>
                                            {totalSpace}
                                        </Text>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </>
                        );
                    }

                    return (
                        <>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={8}>Total Available Space</Table.Summary.Cell>
                                <Table.Summary.Cell index={1} colSpan={3}>
                                    <Text strong>{totalSpace}</Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );

                }}
            >
            </Table>
        );
    }

    const onPipelineAvailDateChange = (date: Moment | null) => {
        setPipelineAvailDate(date);
    }

    const renderPipelineAvails = () => {
        if (!getPipelinesWithSpace.length) {
            return (
                <Space>
                    <Text>There are no pipelines with available space for this chosen date.</Text>
                </Space>
            );
        }

        return (
            <Collapse defaultActiveKey={['1']}>
                <Panel 
                    key={1}
                    header="Pipelines Available Space"
                >
                    { getPipelinesWithSpace.map(obj => (
                        <Row>
                            <Space direction='horizontal'>
                                <Text>{obj.name}</Text>
                                <Text strong>{obj.available_space}</Text>
                            </Space>
                        </Row>
                      )) 
                    }
                    
                </Panel>
            </Collapse>
        )
    }

    const doSearch = (value: any) => {
        if (value !== search) {
            setSearch(value);
        }
    }

    const onChangeTimeFilter = (obj: any) => {
        setTimeFilter({
            label: obj.label,
            value: parseInt(obj.value)
        });
    }

    const handleFiltering = (key: string) => {
        const keyParts = key.split(':');
        const filterType = keyParts[0];
        const value = keyParts[1];
        const label = keyParts[2];

        const filterEvents: {[key: string]: (obj: { value: string | number | undefined, label: string}) => void} = {
            time: (obj) => onChangeTimeFilter(obj),
            utility: (obj) => onChangeUtilFilter(obj),
            pipeline: (obj) => onChangePipelineFilter(obj),
            isGated: (obj) => onChangeGateFilter(obj)
        };

        const eventKeys = Object.keys(filterEvents);

        if (eventKeys.indexOf(filterType) > -1) {
            filterEvents[filterType]({
                value: value === 'undefined' ? undefined : value,
                label
            });
        }
    }

    const onChangeGateFilter = (obj: any) => {
        setGateFilter(Boolean(parseInt(obj.value)));
    }

    const hasFilterSet = () => gateFilter || pipelineFilter?.value || utilFilter?.value || timeFilter?.value !== 0;

    const getFilterData = () => {
        const items = [];

        

        const timeTypeMenu = {
            key: '1',
            label: 'Timeframe',
            children: [
                {
                    key: `time:${0}:All`,
                    label: "All",
                },
                {
                    key: `time:${1}:Current`,
                    label: "Current",
                },
                {
                    key: `time:${2}:Expired`,
                    label: "Expired",
                },
                {
                    key: `time:${3}:Has Not Begun`,
                    label: "Has Not Begun",
                },
            ]
          };
        
        const utilMenu = {
            key: '2',
            label: 'Utility',
            children: utilities.map(util => ({
                key: `utility:${util.value}:${util.label}`,
                label: util.label
            }))
          };
        
        const pipelineMenu = {
            key: '3',
            label: 'Pipeline',
            children: pipelines.map(pl => ({
                key: `pipeline:${pl.value}:${pl.label}`,
                label: pl.label
            }))
          };
        
        const isGatedMenu = {
            key: '4',
            label: 'Contract Type',
            children: [
                {
                    key: `isGated:${0}:All Contracts`,
                    label: "All Contracts",
                },
                {
                    key: `isGated:${1}:Only Gated`,
                    label: "Only Gated",
                }
            ]
          };

        items.push(utilMenu);
        items.push(timeTypeMenu);
        items.push(pipelineMenu);
        items.push(isGatedMenu);

        return items;
    }
    
    const onChangeUtilFilter = (util: any) => {
        setUtilFilter(util);
    }

    const onChangePipelineFilter = (pl: any) => {
        setPipelineFilter(pl);
    }

    const getPipelinesWithSpace = pipelineAvails ? pipelineAvails?.filter(obj => isNumber(obj.available_space)) : [];

    return (
        <Animate type='pop'>
            { contracts && contracts?.length > 0 && 
                <Card loading={pipelineAvailLoading || contracts === undefined}>
                    
                        <>
                            <Row style={{ marginBottom: '20px' }}>
                                <Col xs={24} md={14}>
                                    <p className={Style.selectDatePrompt}>Choose date to display pipelines available space.</p>
                                </Col>

                                <Col xs={24} md={{ span: 6, offset: 4}}>
                                    <DatePicker 
                                        style={{ width: '100%' }}
                                        onChange={onPipelineAvailDateChange}
                                        direction="ltr"
                                        value={pipelineAvailDate}
                                    />
                                </Col>
                            </Row>
                            <Grid cols="1">
                                { contracts.length > 0 && 
                                    renderPipelineAvails()
                                }
                            </Grid>
                        </>
                    
                </Card>
            }
            
            <Card loading={!contracts}>
                <Row gutter={[16, 16]}>
                    <Col className="gutter-row" lg={6} xs={16}>
                        <Search 
                                placeholder="Search Contract Num"
                                callback={ (value: any) => doSearch(value) } 
                                throttle={ 1000 }
                        />
                    </Col>
                    <Col className="gutter-row" lg={4} xs={8}>
                        <Space>
                            <Dropdown menu={{ 
                                items: getFilterData(),
                                onClick: ({ key }) => handleFiltering(key)
                                }}
                            >
                                <AntButton size='large' style={{ height: '46px' }} icon={<FilterOutlined />}>
                                    <Space>
                                        Filter
                                        <DownOutlined />
                                    </Space>
                                </AntButton>
                                
                            </Dropdown>
                        </Space>
                    </Col>

                    <Col lg={{span: 6, offset: 4}} xs={12} className={css}>
                        {Array.isArray(contracts) && contracts.length > 0 &&
                            <Switch 
                                label="View Receipt / Delivery Amount"
                                default={delLocQuantityView}
                                name="del-loc-quantity-toggle"
                                onChange={(id: any, status: any) => setDelLocQuantityView(status)} 
                            />
                        }
                    </Col>

                    
                    <Col className="gutter-row" lg={{ span: 4 }} xs={{ span: 12 }}>
                        <Button 
                            small
                            fullWidth
                            className={Style.createContractBtn} 
                            text='Create Contract'
                            action={ sendToCreateContract }
                        />
                    </Col>
                </Row>
                {hasFilterSet() &&
                    <Row gutter={16}>
                        <Col lg={24}>
                            <Space size={[0, 8]} wrap style={{marginBottom: '20px'}}>
                                <Text strong style={{marginRight: '20px'}}>Filters:</Text>
                                {timeFilter && timeFilter.value > 0 && 
                                    <Tag color="magenta">
                                        <Text ellipsis className={Style.filterTag}>{timeFilter.label}</Text>
                                    </Tag>
                                }
                                
                                {pipelineFilter && pipelineFilter.value && 
                                    <Tag color="red">
                                        <Text ellipsis className={Style.filterTag}>{pipelineFilter.label}</Text>
                                    </Tag> 
                                }
                                {utilFilter && utilFilter.value && <Tag color="volcano">{utilFilter.label}</Tag>}
                                {gateFilter && <Tag color="orange">Gated Contracts Only</Tag>}
                            </Space>
                        </Col>
                    </Row>
                }
                { delLocQuantityView ? renderDelLocContracts() : renderContracts() }
            </Card>
        </Animate>
    );
}
