import React, { Fragment, useContext, useEffect, useState } from 'react';
import { AccountContext, Animate, Card, Grid, Label, Loader, ViewContext } from 'components/lib';

import Axios from 'axios';

import moment, { Moment } from 'moment';

import { message, Alert, Button as AntButton, Collapse, DatePicker, Divider, Result, Typography, Space } from 'antd';
import {
    DeleteFilled,
  } from '@ant-design/icons';

const { Text } = Typography;
const { Panel } = Collapse;

export function DeleteDailyNominationConfirm({ selectedDate, closeConfirm }: { selectedDate: Moment, closeConfirm: Function}) {

    const context = useContext(ViewContext);
    const [loading, setLoading] = useState<boolean>(false);

    const selectDailyNomDate = async () => {
        setLoading(true);

        try {
            const res = await Axios.post('/api/flow-date/clear-daily-nom', {
                date: selectedDate.format('YYYY-MM-DD')
            });

            if (res.status === 200) {
                closeConfirm(true);
            }
        } catch (err) {
            setLoading(false);
            context.handleError(err);
            closeConfirm(false);
        }
    }

    return (
        <Result
        status="error"
        title={`Are you sure you want to remove daily nomination on ${selectedDate.format("MMMM Do YYYY")}?`}
        extra={[
            <AntButton loading={loading} type="primary" key="console" onClick={async() => {
                await selectDailyNomDate();
            }}>
                Yes
            </AntButton>,
            <AntButton loading={loading} key="buy" onClick={() => {
                closeConfirm(false);
            }}>No</AntButton>,
        ]}
    />
    );

}