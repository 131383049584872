/***
*
*   MASTER FEATURES
*   Manage all feature flags within applications
*
**********/

import React, { useContext } from 'react';
import { ViewContext, Button, Card, Grid, Table, Animate, useAPI } from 'components/lib';

export function MasterFeatureSettings(){

    const context = useContext(ViewContext);
    const features = useAPI('/api/master/features');

    const createFeatureFlag = () => {
        context.modal.show({
            title: "",
            form: {
                name: {
                    label: "Name",
                    type: "text",
                    required: true,
                    name: "name",
                    id: "name"
                },
                description: {
                    label: "Description",
                    type: "textarea",
                    required: true,
                    name: "description",
                    id: "description"
                }
            },
            buttonText: 'Add Feature Flag',
            url: '/api/master/feature',
            method: 'POST'
        }, () => {

        });
    }
    
    function deleteFeatureFlag(data, callback){
        if(data.current_use) {
            context.notification.show('Feature flag is currently in use.', 'warning', true);
            return;
        }

        context.modal.show({
          title: 'Delete Feature Flag',
          form: {},
          buttonText: 'Delete Feature',
          text: `Are you sure you want to delete feature flage: ${data.name}?`,
          url: `/api/master/feature/${data.id}`,
          method: 'DELETE'
    
        }, () => {
    
          context.notification.show(`${data.name} was deleted`, 'success', true);
          callback();
    
        });
      }

    return(
        <Animate>
            <Card
                title="Feature Flags"
                loading={false}
            >
                <Grid cols="2">
                    <div>
                    </div>
                    <div>
                        <Button 
                            small
                            text='Create Feature Flag'
                            action={ createFeatureFlag }
                        />
                    </div>
                </Grid>
                <Table
                    search
                    hide={['id']}
                    data={ features?.data }
                    loading={ features?.loading }
                    badge={{ col: 'name', color: 'blue' }}
                    actions={{ 
                        delete: deleteFeatureFlag, 
                    }}
                />
            </Card>
        </Animate>
    );
}