import React, { useCallback, useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { ViewContext, Button, Grid, useAPI } from 'components/lib';


import { Button as AntButton, List, Spin, Typography } from 'antd';

import {
    DeleteFilled,
  } from '@ant-design/icons';

const { Text } = Typography;


export function EmailList() {
    const context = useContext(ViewContext);
    const primaryEmailList = useAPI('/api/features/primary-email-list');

    const [recipients, setRecipients] = useState(undefined);

    useEffect(() => {
        if (primaryEmailList && primaryEmailList.data && primaryEmailList.data.length > 0) {
            setRecipients(primaryEmailList.data);
        }
        
      }, [primaryEmailList]);

    const renderCurrentEmailList = (item) => {

        return (
            <List.Item>
                <ul>
                    <li>
                        <Text italic>{item}</Text>
                    </li>
                </ul>
            </List.Item>
        );
    }

    return (
        <>
            {!primaryEmailList.loading && !recipients && 
                <p>You currently do not have an email list.</p>
            }
            
            <Spin spinning={primaryEmailList.loading}>
                {!primaryEmailList.loading && recipients?.length > 0 &&
                    <Grid>
                        <List
                                    header={<h2>Current Emails</h2>}
                                    size="small"
                                    bordered
                                    dataSource={recipients}
                                    renderItem={renderCurrentEmailList}
                        />
                    </Grid>
                }
            </Spin>

            <Grid>
                <Button fullWidth outline text='Close' action={ () => context.modal.hide(true) } />
            </Grid>
        </>
    );
}