/***
*
*   PROFILE
*   Update the user profile or close the account
*
**********/

import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import Axios from 'axios';

import { AccountContext, Card, getUtilities,
    SettingNav, Animate, Message, ViewContext } from 'components/lib';

import { CreateUtility } from './create-utility';

import Style from './settings.module.scss';

import { Button as AntButton, Col, Collapse, List, Typography, Row, Switch } from 'antd';
import { EyeOutlined } from '@ant-design/icons';


const { Panel } = Collapse;
const { Paragraph, Text } = Typography;


export function Utilities(){

    const viewContext = useContext(ViewContext);
    const { accountState, accountDispatch } = useContext(AccountContext);
    const [utilities, setUtilities] = useState(undefined);
    const [reveals, setReveals] = useState({});
    const [loading, setLoading] = useState(false);

    const prepareUtilityData = useCallback(async () => {
        try {
            const res = await getUtilities(accountDispatch, {
                params: {
                    with_pipelines: 1,
                    with_settings: 1,
                    all_utilities: 1
                }
            }, true);

            if (res.status === 200) {
                setUtilities(res.data.data);
            }

        } catch (err) {
            viewContext.handleError(err);
        }
    }, [viewContext, accountDispatch]);

    useEffect(() => {

        if (utilities) {
            const revealOpts = utilities.reduce((opts, obj) => {
                if (obj.settings) {
                    if (!opts[obj.settings.id]) opts[obj.settings.id] = false;
                }

                return opts;
            }, {});

            setReveals(revealOpts);
        }

    }, [utilities]);

    useEffect(() => {

        prepareUtilityData();
        
    }, []);


    const openEditWebsiteSettings = (item) => {
        const isUpdate = item?.settings?.id;
        const formObj = {
            website_url: {
                label: "Website URL",
                type: "text",
                required: true,
                name: "website_url",
                id: "website_url",
                value: item?.settings?.website_url
            },
            username: {
                label: "User Name",
                type: "text",
                required: true,
                name: "username",
                id: "username",
                value: item?.settings?.username
            },
            password: {
                label: "Password",
                type: "text",
                required: true,
                name: "password",
                id: "password",
                value: item?.settings?.password
            },
            utility_id: {
                type: "hidden",
                name: "utility_id",
                id: "utility_id",
                value: item?.id
            }
        };

        if (isUpdate) {
            formObj['id'] = {
                type: "hidden",
                name: "id",
                id: "id",
                value: item?.settings?.id
            };
        }

        viewContext.modal.show({

            title: 'Edit Utility Information',
            form: formObj,
            buttonText: 'Submit',
            url: '/api/company-utility/account-setting',
            method: isUpdate ? 'PATCH' : 'POST'
      
          }, () => {
            prepareUtilityData();
          });
    }

    const removeUtility = async (item) => {
        try {
            const res = await Axios.delete('/api/company-utility', { data: { id: item.id } });
      
            if (res.status === 200) {
                viewContext.notification.show('Utility was removed', 'success', true);
                await prepareUtilityData();
            }          
          }
          catch (err){
            viewContext.handleError(err);
          }
    }

    const toggleUtilEnableStatus = async (item, enabled) => {
        const status = !enabled ? 1 : 0;
        try {
            setLoading(true);
            const res = await Axios.post('/api/company-utility/account-setting/disable', { utility_id: item.id, disabled: status });
      
            if (res.status === 200) {
                accountDispatch({
                    type: "SET_UTILITIES",
                    payload: utilities.map(u => {
                        if (u.id === item.id) u.disabled = status;
                        return u;
                    }).filter(u => !u.disabled)
                });
                viewContext.notification.show('Utility was updated', 'success', true);
                setLoading(false);
            }          
          }
          catch (err){
            viewContext.handleError(err);
            setLoading(false);
          }
    }

    const renderUtilities = (item) => {
        const renderPipelineList = (pls) => {


            return (
                <Collapse defaultActiveKey={['1']} ghost>
                    <Panel header="Pipelines" key={item.id}>
                        { pls.map(obj => (<p>{obj.name}</p>)) }
                    </Panel>
                </Collapse>
            )
        }

        const renderSettingsData = (settings) => {
            return (
                <>
                    {settings.website_url &&
                        <li>
                            <Text className={Style.title} strong>Website:</Text>
                            <Text>{settings.website_url}</Text>
                        </li>
                    }
                    {settings.username &&
                        <li>
                            <Text className={Style.title} strong>User Name:</Text>
                            <Text>{settings.username}</Text>
                        </li> 
                    }
                    {settings.password &&
                        <li>
                            <div>
                            <Text className={[Style.title, Style.passwordTitle]} strong>Password:</Text>
                            <Paragraph className={[Style.title, Style.passwordTitle]} copyable={{ text: settings.password }}>
                                {reveals[settings.id] ? settings.password : '**********'}
                            </Paragraph>
                            </div>
                            
                            <div className={[Style.title, Style.passwordTitle]}>
                                <AntButton 
                                    shape="circle" 
                                    icon={<EyeOutlined />} 
                                    size="small" 
                                    onClick={() => { 
                                        
                                        setReveals({...reveals, ...{[settings.id]: !reveals[settings.id]}}) 
                                    }} 
                                />
                            </div>
                        </li> 
                    }
                </>
            );
        }

        return (
            <List.Item
                actions={[
                    <AntButton 
                        key="utility-edit" 
                        onClick={() => openCreatUtilityModal(item)}>
                            Edit
                    </AntButton>,
                    <AntButton 
                        key="utility-remove" 
                        onClick={async() => await removeUtility(item)}>
                            Delete
                    </AntButton>,
                    <AntButton 
                        key="utility-website-setting-edit" 
                        onClick={() => openEditWebsiteSettings(item)}>
                            Edit Website Details
                    </AntButton>,
                    <Switch 
                        checkedChildren="Enabled" 
                        unCheckedChildren="Disabled"
                        defaultChecked={!item.disabled}
                        onChange={(enabled) => toggleUtilEnableStatus(item, enabled)}
                        loading={loading}
                    />
                ]}
            >
                <ul className={Style.dataList}>
                    <li>
                        <Text>{item.name}</Text>
                    </li>
                    {item.pipelines && renderPipelineList(item.pipelines)}
                    {item.settings && renderSettingsData(item.settings)}
                </ul>
            </List.Item>
        );
    }

    const openCreatUtilityModal = (utilityData) => {
        viewContext.modal.show({
            title: utilityData ? 'Edit Utility' : 'Create Utility',
            customForm: CreateUtility,
            formProps: {
                utilityData
            },
        }, (form, res) => {
            if (res) {
                prepareUtilityData();
                viewContext.notification.show('New utility has been created.', 'success', true);
            }
        });
    }

    return (
        <Fragment>
          <SettingNav />
          <Animate>
            <Message
                closable
                title='Utility Options List'
                text={(
                    <p>
                        These are the utility options that are assigned to your account. You can edit each utility details here.
                    </p>
                )}
                type='info'
            />
            <Card
                loading={accountState.loading}
            >
                {accountState?.pipelines?.length > 0 &&
                    <Row gutter={[16, 16]}>
                        <Col offset={18} span={6}>
                            <AntButton
                                style={{ float: 'right', margin: '10px 0' }}
                                size="middle"
                                onClick={() => openCreatUtilityModal() }
                            >
                                Create Utility
                            </AntButton>
                        </Col>
                    </Row>
                }
                <List
                    size="small"
                    bordered
                    dataSource={utilities}
                    renderItem={renderUtilities}
                />
            </Card>
          </Animate>
        </Fragment>
      );
}