/***
*
*   SIGN UP STEP 2
*   Signup form for account owners
*   Step 1: create account
*   Step 2: select plan and make payment
*
**********/

import React, { useContext, useEffect, useState } from 'react';
import { Animate, AuthContext, Card, History, PaymentForm, Row, usePlans, useSignupInvite, ViewContext } from 'components/lib';
import Axios from 'axios';

export function SignupPlan(props){

  const context = useContext(AuthContext);
  const viewContext = useContext(ViewContext);

  const plans = usePlans();

  const [plan, setPlan] = useState(undefined);
  const [inviteExcepted, setInviteExcepted] = useState(false);

  const signupInvite = useSignupInvite(window.location.search);

  useEffect(() => {
    if(!signupInvite.loading && !inviteExcepted) {
      if ((signupInvite.data && !Object.keys(signupInvite.data).length) || signupInvite?.data?.used) {
        setTimeout(() => {
          context.signout();
        }, 1000);
      }
    }
  }, [signupInvite]);

  useEffect(() => {

    if (signupInvite.data && Object.keys(signupInvite.data).length && plans?.data?.list) {
      const selectedPlan = plans.data.list.find(p => p.value === signupInvite.data.plan);
      setPlan(selectedPlan);

    }

  }, [plan, plans]);

  const setIniteAsUsed = async (paymentRes) => {
    try {
      const res = await Axios.patch('/api/auth/signup-invite/except', { id: signupInvite.data.id });

      if (res.status === 200) {
        setInviteExcepted(true);
        // save the plan to context, then redirect
        context.update({ plan: paymentRes.data.plan, subscription: paymentRes.data.subscription });  
        History.push(paymentRes.data.onboarded ? '/dashboard' : '/welcome');
      }

    } catch (e) {
      viewContext.handleError(e);
    }
  }

  if (!plans.data || !plan)
    return false;

  return(
    <Animate type='pop'>
      <Row title='Select Your Plan'>

          <Card loading={ false } restrictWidth center>

            <PaymentForm
              data={{
                plan: {
                  label: 'Plan',
                  type: 'select',
                  options: [plan],
                  disaabled: true,
                  default: plan?.value,
                  
                  required: true,
                },
                token: {
                  label: 'Credit Card',
                  type: 'creditcard',
                  required: true,
                }
              }}
              url='/api/account/plan'
              method='POST'
              buttonText='Save Plan'
              callback={ async (res) => {

                await setIniteAsUsed(res);
                

              }}
            />

          </Card>
      
      </Row>
    </Animate>
  );
}
