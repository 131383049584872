import Axios from "axios";

export const setLoading = (dispatch, status) => {
    dispatch({
        type: "SET_LOADING",
        payload: status
    });
}

export const getPoints = async (dispatch) => {
    setLoading(dispatch, true);

    try {
        const res = await Axios.get('/api/point');

        if (res.status === 200) {
            dispatch({
                type: "SET_POINTS",
                payload: res.data.data
            });
            setLoading(dispatch, false);
        }
    } catch (e) {
        dispatch({
            type: "SET_ERROR",
            payload: e
        });
    }
}

export const getPipelines = async (dispatch, params) => {
    setLoading(dispatch, true);

    try {
        const res = await Axios.get('/api/pipeline', params);

        if (res.status === 200) {
            dispatch({
                type: "SET_PIPELINES",
                payload: res.data.data
            });
            setLoading(dispatch, false);
        }
    } catch (e) {
        dispatch({
            type: "SET_ERROR",
            payload: e
        });
    }
}

export const getPositions = async (dispatch) => {
    setLoading(dispatch, true);

    try {
        const res = await Axios.get('/api/position');

        if (res.status === 200) {
            dispatch({
                type: "SET_POSITIONS",
                payload: res.data.data
            });
            setLoading(dispatch, false);
        }
    } catch (e) {
        dispatch({
            type: "SET_ERROR",
            payload: e
        });
    }
}

export const getCompanies = async (dispatch) => {
    setLoading(dispatch, true);

    try {
        const res = await Axios.get('/api/company');

        if (res.status === 200) {
            dispatch({
                type: "SET_COMPANIES",
                payload: res.data.data
            });
            setLoading(dispatch, false);
        }
    } catch (e) {
        dispatch({
            type: "SET_ERROR",
            payload: e
        });
    }
}

export const getUtilities = async (dispatch, params, returnRes) => {
    setLoading(dispatch, true);

    try {
        const res = await Axios.get('/api/company-utility', params);

        if (res.status === 200) {
            if (!returnRes) {
                dispatch({
                    type: "SET_UTILITIES",
                    payload: res.data.data
                });
            }

            setLoading(dispatch, false);

            if (returnRes) {
                return res;
            }
        }
    } catch (e) {
        dispatch({
            type: "SET_ERROR",
            payload: e
        });
    }
}

const getRes = async (url, dispatch, params) => {
    try {
        const res = await Axios.get(url, params);

        if (res.status === 200) {
            return res.data.data;
        }
    } catch (e) {
        dispatch({
            type: "SET_ERROR",
            payload: e
        });
    }
} 

export const getAllAccountData = async (dispatch) => {
    setLoading(dispatch, true);

    const pointUrl = '/api/point';
    const pipelineUrl = '/api/pipeline';
    const positionUrl = '/api/position';
    const companyUrl = '/api/company';
    const utilityUrl = '/api/company-utility';

    const points = await getRes(pointUrl, dispatch);
    const pipelines = await getRes(pipelineUrl, dispatch, { params: {with_settings: 1 }});
    const positions = await getRes(positionUrl, dispatch);
    const companies = await getRes(companyUrl, dispatch);
    const utilities = await getRes(utilityUrl, dispatch, { params: { with_settings: 1 }});
    const hasOnboardData = pipelines?.length > 0 && utilities?.length > 0 && companies?.length > 0;

    dispatch({
        type: "SET_ACCOUNT_DATA",
        payload: {
            points,
            pipelines,
            positions,
            companies,
            utilities,
            hasOnboardData
        }
    });

    setLoading(dispatch, false);
}