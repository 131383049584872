import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { ViewContext, Card, Grid, IconCard, useWindowSize } from 'components/lib';
import { CreateLongTermDeal } from './create-long-term-deal';

import { currency } from 'utilities/currency';
import { useHistory } from 'react-router-dom';
import { Divider, Dropdown, Menu, Popconfirm, Space, Table, Typography } from 'antd';

import {
    ProjectOutlined
  } from '@ant-design/icons';

import moment from 'moment';

import { IDeal, ILTDealForm } from 'ts/interfaces/deal';

const { Text } = Typography;

export function OpenLongTermDeals() {
    const context = useContext(ViewContext);
    const history = useHistory();
    const { width } = useWindowSize();

    const defaultPageData = {
        current: 1,
        pageSize: 10,
        total: 100
    };

    const [deals, setDeals] = useState<Array<IDeal> | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const [offset, setOffSet] = useState<number>(0);
    const [pageData, setPageData] = useState<{[x: string]: number}>(defaultPageData);

    const limit = 100;
    
    useEffect(() => {
        if(!deals) {
            getLongTermDeals();
        }
    }, [deals]);

    const getLongTermDeals = useCallback(async () => {
        try {
            setLoading(true);
            const res = await Axios.get('/api/deal', {  
                params: {
                    long_term_status: 1,
                    //limit,
                    offset
                } 
            });
          
            if (res.status === 200) {
                const currentDeals = !deals ? [] : deals;

                setDeals([ ...currentDeals, ...res.data.data]);
                if(res.data.count !== pageData.total) setPageData({...pageData, total: res.data.count });
                setLoading(false);
            } 
        } catch (err) {
            setLoading(false);
            context.handleError(err);
        }
    }, [setLoading, setDeals]);

    const handleTableChange = (pagination: {[x: string]: any}) => {
        const { current, pageSize } = pagination;
        const totalPages = Math.floor(current * pageSize);

        if (deals && totalPages === deals.length) {
            setOffSet(totalPages - 1);
            getLongTermDeals();
        }

        setPageData({ ...pageData, current });
        
    }

    const createLongTermDeal = (deal: IDeal) => {
        context.modal.show({
            title: 'Create Long Term Deal',
            customForm: CreateLongTermDeal,
            formProps: {
                updateDeal: deal
            },
            lgModal: true
        }, (form: ILTDealForm, res: { data: IDeal }) => {
            if(deal) {
                const updatedDeals = deals?.map(dealObj => {
                    if(dealObj.id === res.data.id) return res.data;
                    return dealObj;
                });
                setDeals(updatedDeals);
                context.notification.show('Long Term Deal was updated', 'success', true);
            } else {
                deals?.push(res.data);
                setDeals(deals);
                context.notification.show('Long Term Deal was created', 'success', true);
            }
        });
    }

    const renderDealsList = () => {   
        const data = deals?.map(deal => {
            const dealObj: {[x: string]: any} = {...deal};

            dealObj.date_range = `${moment(deal.start_date).format('MMM, DD YYYY')} - ${moment(deal.end_date).format('MMM, DD YYYY')}`
            dealObj['fix_slots'] = deal.fix_price_slot_data ? deal.fix_price_slot_data.length : 0;

            return dealObj;
        });

        const sendToLongTermView = (deal: IDeal) => {
            history.push({
                pathname: `/long-term-deals/${deal.id}`
            });
        };

        const deleteLongTermDeal = async (deal: IDeal) => {
            try {
                const res = await Axios.delete('/api/deal', { data: { id: deal.id } });
          
                if (res.status === 200 && deals) {
                    const newDealBatch = deals.filter(d => d.id !== deal.id);
                    setDeals([...newDealBatch]);
                }          
              }
              catch (err){
                context.handleError(err);
            }
        }

        const confirmDelete = async (dealObj: IDeal) => {
            await deleteLongTermDeal(dealObj);
        }

        const menu = (deal: IDeal) => {
            const cancel = () => {}

            return (
            <Menu>
                <Menu.Item
                    onClick={() => createLongTermDeal(deal)}
                >
                    Edit
                </Menu.Item>
                <Popconfirm
                            title="Are you sure to delete this long term deal?"
                            onConfirm={async () => await confirmDelete(deal)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                >
                    <Menu.Item>
                        Delete
                    </Menu.Item>
                </Popconfirm>
            </Menu>
            );
        };

        const renderLongTermDealActionBtns = (deal: IDeal) => {
            return (
                <Space wrap>
                    <Dropdown.Button 
                        onClick={() => sendToLongTermView(deal)}
                        overlay={menu(deal)}
                    >
                        View Long Term Deal
                    </Dropdown.Button>    
                </Space>
            );
        };

        const getTotalFixedVolume = (d: IDeal) => {
            return d?.fix_price_slot_data?.length ? d.fix_price_slot_data.reduce((sum, obj) => {
                return (moment().isBetween(obj.start_date, obj.end_date, null, "[]")) ? sum + obj.volume : sum;
            }, 0) : 0;
        }
                
        const getVolumeLeft = (d: IDeal) => d?.volume - getTotalFixedVolume(d);

        const columns: Array<any> = [
            {
                title: 'Long Term Deal',
                render: (record: IDeal) => {

                    return (
                        <Fragment>
                        Volume Per Day: <Text strong>{record.volume}</Text>
                        <br />
                        Base Price: <Text strong>{currency(record.base_price, 4)}</Text>
                        <br />
                        Pipeline: <Text strong ellipsis>{record.pipeline}</Text>
                        <br />
                        Position: <Text strong>{record.position}</Text>
                        <br />
                        Point: <Text strong>{record.point}</Text>
                        <br />
                        Company: <Text strong>{record.company}</Text>
                        <br />
                        Date Range: <Text strong>{record.date_range}</Text>
                        <br />
                        Fixed Price Slots <Text strong>{record.fix_slots}</Text>
                        <Divider />
                        {renderLongTermDealActionBtns(record)}
                    </Fragment>
                    );
                },
                responsive: ["xs"]
            },
            {
              title: 'Volume Per Day',
              dataIndex: 'volume',
              key: 'volume',
              sorter: (a: IDeal, b: IDeal) => a.volume - b.volume,
              responsive: ["sm"]
            },
            {
                title: 'Current Volume Left',
                key: 'current_volume_left',
                render: (dealObj: IDeal) => getVolumeLeft(dealObj),
                sorter: (a: IDeal, b: IDeal) => getVolumeLeft(a) - getVolumeLeft(b),
                responsive: ["sm"]    
            },
            {
              title: 'Base Price',
              dataIndex: 'base_price',
              key: 'base_price',
              render: (base_price: number) => currency(base_price, 4),
              sorter: (a: IDeal, b: IDeal) => a.base_price - b.base_price,
              responsive: ["sm"]
            },
            {
                title: 'Pipeline',
                dataIndex: 'pipeline',
                key: 'pipeline',
                sorter: (a: IDeal, b: IDeal) => a.pipeline.toLowerCase().localeCompare(b.pipeline.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Position',
                dataIndex: 'position',
                key: 'position',
                sorter: (a: IDeal, b: IDeal) => a.position.toLowerCase().localeCompare(b.position.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Point',
                dataIndex: 'point',
                key: 'point',
                sorter: (a: IDeal, b: IDeal) => a.point.toLowerCase().localeCompare(b.point.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Company',
                dataIndex: 'company',
                key: 'company',
                sorter: (a: IDeal, b: IDeal) => a.company.toLowerCase().localeCompare(b.company.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Date Range',
                dataIndex: 'date_range',
                key: 'date_range',
                sorter: (a: IDeal, b: IDeal) => a.start_date && b.start_date ? moment(a.start_date).diff(moment(b.start_date)) : 'N/A',
                responsive: ["sm"]
            },
            {
                title: 'Fixed Price Slots',
                dataIndex: 'fix_slots',
                key: 'fix_slots',
                sorter: (a: IDeal, b: IDeal) => a.fix_slots && b.fix_slots ? a.fix_slots - b.fix_slots : 'N/A',
                responsive: ["sm"]
            },
            {
                title: 'Action',
                key: 'action',
                render: renderLongTermDealActionBtns,
                responsive: ["sm"],
                width: 240
              },
          ];

          const mediumScreenWidth = width && (width < 1240 && width > 574);

          if (mediumScreenWidth) {
            columns[columns.length - 1]['fixed'] = 'right';
          } else {
            delete columns[columns.length - 1]['fixed'];
          }

          return (
            <Table 
                columns={columns} 
                dataSource={data} 
                onChange={handleTableChange} 
                //pagination={pageData}
                loading={loading}
                scroll={{ x: mediumScreenWidth ? 1300 : undefined }}
                bordered
            />
        );
    }
    

    return(
        <Fragment>
            <Grid cols='1'>
                <IconCard
                    onClick={ createLongTermDeal }
                    icon={ProjectOutlined}
                    text="Create Long Term Deal"
                    hover
                    color='darkblue'
                />
            </Grid>
            <Card
                title="Long Term Deals (Open)"
                loading={!deals}
                >
                {deals && !deals.length &&
                    <p>No long term deals with fixed price slots exist.</p>
                }
                {deals && deals.length > 0 &&
                    renderDealsList()
                }
            </Card>
        </Fragment>
    );
}