/***
*
*   ONBOARDING
*   Example of onboarding flow*
*
**********/

import React, { Fragment, useContext, useEffect, useState } from 'react';
import { AccountContext, AuthContext, getAllAccountData, Onboarding, Message, Image, usePlans } from 'components/lib';
import { Companies } from './companies';
import { Pipelines } from './pipelines';
import { Utilities } from './utilities';
import LogoImage from './images/dm-flame-dark-logo.svg';

export function OnboardingView(props){

  const context = useContext(AuthContext);
  const { accountState, accountDispatch } = useContext(AccountContext);
  const plans = usePlans();

  const [activePlan, setActivePlan] = useState(undefined);

  const [isPremium, setIsPremium] = useState(undefined);

  useEffect(() => {

    if (!accountState.loading) {
      if (!accountState.points && !accountState.pipelines && !accountState.positions && !accountState.companies && !accountState.utilities) getAllAccountData(accountDispatch);
    }

  }, [accountState, accountDispatch]);

  useEffect(() => {    
    if (plans?.data?.raw) {
      const plansData = plans.data.raw.plans;
      setActivePlan(plansData.find(obj => obj.id === plans.data.raw.active));
    }

    if (plans?.data?.isPremium !== undefined && !isPremium !== undefined) {
      setIsPremium(plans?.data?.isPremium);
    }

  }, [plans, isPremium]);

  const views = [
    {

      name: 'Getting Started',
      description: `Welcome, ${context.user.name}!`,
      component: <Welcome/>

    },
    {

      name: 'Companies', 
      description: (
        <>
          <p>Enter companies your organization will trade gas with.</p>
          <p>These company options will be availab;e for you to select when create deals.</p>
        </>
        
      ),
      component: <Companies />,
      
    },
    {

      name: 'Pipelines', 
      description: (
        <>
          <p>Enter pipelines your organization will transfer gas through.</p>
          <p>These pipeline options will be available to choose from when creating deals, contracts, etc..</p>
        </>
        
      ),
      component: <Pipelines isPremium={isPremium} />,
      
    },
    {

      name: 'Utilities', 
      description: (
        <>
          <p>Enter utility companies that your organization will send gas to.</p>
          <p>These utility options will be available to choose from when creating deals, contracts, etc..</p>
        </>
        
      ),
      component: <Utilities isPremium={isPremium} />,
      
    }
  ]

  if (context.user.duplicate_user){
    views.unshift({

      name: 'Important!',
      description: '',
      component: <DuplicateUser/>

    })
  }

  const user = JSON.parse(localStorage.getItem('user'));

  if (user.onboarded) {
    window.location = '/dashboard';
  }

  const accountIsReadyToSave = () => {
    if (!activePlan || !accountState) return false;

    const utilsWithSettings = accountState?.utilities?.filter(util => util.settings);
    const validData = isPremium ? utilsWithSettings && utilsWithSettings?.length === accountState?.utilities?.length : true;

    return Boolean(accountState.hasOnboardData) && validData;
  }

  return <Onboarding 
      save={accountIsReadyToSave()} 
      onFinish='/dashboard' 
      views={ views }
    />

}

function DuplicateUser(){

  return (
    <Message
      type='warning'
      title={ `You already have an account` }
      text='We noticed you have already registered an account, so we used your original password to save you any confusion in the future.'
    />    
  )
}

function Welcome(){

  return (
    <Fragment>

      <p>
        Glad you and your team have decided to use DM Natural as your trust source for data management and automation. Take the time to enter your companies, pipelines and utilities which you trade and send gas to or from. This data will be available as options throughout the use of the application.
      </p>

      <Image source={ LogoImage }/> 

    </Fragment>
  )
}