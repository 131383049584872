import React, { useContext, useState, useEffect } from 'react';
import { Button, Card, Grid, TextInput, ViewContext } from 'components/lib';
import Axios from 'axios';

import Style from '../mission-control.module.scss';

export function Companies({ className, accountId }) {

    const context = useContext(ViewContext);

    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const [companyInput, setCompanyInput ] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [currentCompanies, setCurrentCompanies] = useState([]);

    useEffect(() => {
        const getCompanies = async () => {
            try {
                const res = await Axios.get('/api/company', {  params: { 'account_id': accountId } });

                if (res.status === 200) {
                    const resData = res.data.data;

                    if (resData.length) {
                        setCurrentCompanies(resData);                        
                    }

                } 
            } catch (err) {
                context.handleError(err);
            }

        };

        if(!companies.length) getCompanies();
    }, [context, accountId, companies, setCurrentCompanies]);

    const submit = async () => {
        try {
            setLoading(true);
            const res = await Axios.post('/api/company', {  
                account_id: accountId,
                companies 
            });

            setLoading(false);

            if (res.status === 200) {
                const resData = res.data.data;

                if (resData.length) {
                    setCurrentCompanies(resData);
                    setEditMode(false);                        
                }

            } 
        } catch (err) {
            setLoading(false);
            context.handleError(err);
        }
    }

    const listCompanies = () => {
        const jsxEls = companies.map((company, index) => {
            return (
                <li className={Style.item}>
                    <div>{ company }</div>
                    <div>
                        <Button
                            icon='x-circle'
                            size={ 32 }
                            className={ Style.close }
                            action={ e => removeCompany(index) }
                        />
                    </div>
                </li>
            );
        });

        return (
            <ul className={Style.companyList}>
                { jsxEls }
            </ul>
        );
    }

    const addCompany = () => {
        if(!companyInput) return;
        
        setCompanies([...companies, companyInput]);
        setCompanyInput(null);
    }

    const removeCompany = (index) => {
        companies.splice(index, 1);
        setCompanies([...companies]);
    } 

    const deleteCurrentCompany = (id) => {

    }

    return (
        <Card className={ className }>
            {!editMode &&
                <Grid>
                    <ul className={Style.companyList}>
                        {
                            currentCompanies.map(currentCompany => {
                                return (
                                    <li className={Style.item}>
                                        <div>{currentCompany.name}</div>
                                        <div>
                                            <Button
                                                icon='x-circle'
                                                size={ 32 }
                                                className={ Style.close }
                                                action={ e => deleteCurrentCompany(currentCompany.id) }
                                            />
                                        </div>
                                    </li>
                                );
                            })
                        }
                    </ul>
                    <Button small text='Edit Company Data' action={ () => { setEditMode(true) } }/>
                </Grid>
            }
            {editMode && 
                <Grid cols="2">
                    <div>
                        <TextInput 
                            label="Company Name"
                            name="company_name"
                            id="company_name"
                            onChange={(id, value) => setCompanyInput(value)}
                            value={companyInput}
                        />
                        <Button
                            text="Add Company"
                            action={ addCompany }
                        />
                        <Button outline text='Cancel' action={ () => setEditMode(false) } />
                    </div>
                    <div>
                        { listCompanies() }
                    </div>
                </Grid>  
            }
             {editMode && companies.length > 0 &&
                    <Button
                        loading={ loading }
                        text="Submit"
                        action={ submit }
                    />
                }       
        </Card>
    );
}