/***
*
*   LOGO
*   Replace the image in /images with your own logo
*
**********/

import React, { useContext } from 'react';
import ClassNames from 'classnames';
import { AuthContext, Link } from 'components/lib';
import LogoImage from './images/dmnatural-font-logo.svg';
import LogoMark from './images/dm-flame-logo-white.svg';
import Style from './logo.module.scss';

export function Logo(props){

  //const context = useContext(AuthContext);

  const css = ClassNames([

    Style.logo,
    props.className

  ]);

  const getImage = () => {
    /*const userObj = localStorage.getItem('user');
    
    if(context.permission && !context.permission.master && userObj) {
      const userJSON = JSON.parse(userObj);
      const themeObj = userJSON.theme;

      if(themeObj && Object.keys(themeObj).length) return themeObj.logo_filename;
    }*/

    return props.mark ? LogoMark : LogoImage;
  }

  return(props.noLink ?
    <img src={ getImage() } alt='Logo' />
    :
    <Link url='/' className={ css }>
      <img src={ getImage() } alt='Logo' />
    </Link>
  )
}
