import React, { Fragment } from 'react';
import { MissionControlAccountNav, TabView } from 'components/lib';
import { Companies } from './companies';

export function MasterAccountDetail(props){
    const { computedMatch } = props;
    const accountId = computedMatch.params.id;
    const labels = ['Companies', 'Utilities', 'Pipelines', 'Utility Pipelines'];   

    return (
        <Fragment>
            <MissionControlAccountNav />
            <TabView name="Custom Data" labels={ labels }>
                <Companies accountId={accountId} />
            </TabView>
        </Fragment>
    );
}