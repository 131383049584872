import React, { useContext, useEffect, useState } from 'react';
import { ViewContext, Button, Grid } from 'components/lib';
import moment from 'moment';
import Style from './deal-logs.module.scss';

import { getDateGapPairs } from 'utilities/date';

import { List } from 'antd';

export function LongTermDealGaps({ deal, fixSlots }) {
    const context = useContext(ViewContext);

    const [gapPairs, setGapPairs] = useState(undefined);

    useEffect(() => {

        if (!gapPairs) {
            setGapPairs(getDateGapPairs(deal.start_date, deal.end_date, fixSlots));
        }

    }, []);

    const prepareListData = () => {
        if (!gapPairs) return [];

        return gapPairs.map(pair => {
        
            return {
                title: `${moment(pair[0]).format('MMM, DD YYYY')} - ${moment(pair[1]).format('MMM, DD YYYY')}`,
            }
        });
    }


    return (
        <>
            {gapPairs && gapPairs.length === 0 &&
                <p>There are no empty timeframes available on this deal.</p>
            }
            
            {gapPairs && gapPairs.length > 0 &&
                <List
                    loading={!gapPairs}
                    itemLayout="horizontal"
                    dataSource={prepareListData()}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={item.title}
                            />
                        </List.Item>
                    )}
                />
            }
            
            <Grid cols="1">
                <Button fullWidth outline text='Cancel' action={ () => context.modal.hide(true) } />
            </Grid>
        </>
    );
}