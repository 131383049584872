import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';

import { AccountContext, ViewContext, Button, Grid, Label, TextInput } from 'components/lib';

import { Select } from 'antd';
const { Option } = Select;

export function CreateUtility({ utilityData }) {
    const viewContext = useContext(ViewContext);
    const { accountState } = useContext(AccountContext);

    const getPipelineOptEls = (data) => {
        const els = [];
        data.forEach(d => {
            els.push(
                <Option key={d.id}>{d.name}</Option>
            );
        });

        return els;
    }

    const [loading, setLoading] = useState(false);
    const [pipelineOptEls] = useState(getPipelineOptEls(accountState.pipelines));
    const [name, setName] = useState(utilityData?.name);
    const [selectedPipelines, setSelectedPipelines] = useState(utilityData?.pipelines ? utilityData?.pipelines.map(p => p.id) : []);

    const isValid = () => selectedPipelines.length > 0 && name;

    const submit = async () => {
        if (utilityData) {
            await updateUtility();
        } else {
            await createUtility();
        }
    }

    const updateUtility = async () => {
        try {
            setLoading(true);
            
            const res = await Axios.patch('/api/company-utility', { 
                utility_id: utilityData.id, 
                name,
                pipeline_list: selectedPipelines
            });

            setLoading(false);

            if (res.status === 200) {
                viewContext.modal.hide(false, res.data.data);
            } 
        } catch (err) {
            setLoading(false);
            viewContext.handleError(err);
        }
    }

    const createUtility = async () => {
        try {
            setLoading(true);
            
            const res = await Axios.post('/api/company-utility', {  
                name,
                pipeline_list: selectedPipelines
            });

            setLoading(false);

            if (res.status === 200) {
                viewContext.modal.hide(false, res.data.data);
            } 
        } catch (err) {
            setLoading(false);
            viewContext.handleError(err);
        }
    }

    return (
        <div>
            <Grid>
                <div>
                    <TextInput 
                        label="Utility Name"
                        name="name"
                        id="name"
                        onChange={(id, value) => setName(value)}
                        value={name}
                    />
                </div>
                <div>
                    <Label
                        text="Add Pipelines"
                        required={ true }
                    />
                            <Select
                                mode="multiple"
                                allowClear
                                size="large"
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                defaultValue={selectedPipelines}
                                onChange={(value) => setSelectedPipelines(value) }
                            >
                                {pipelineOptEls}
                            </Select>
                </div>
            </Grid>
            <Grid cols="2">
                {isValid() &&
                    <Button
                        fullWidth
                        loading={ loading }
                        text="Submit"
                        action={ submit }
                    />
                }
                <Button fullWidth outline text='Cancel' action={ () => viewContext.modal.hide(true) } />
            </Grid>
        </div>
    );
}