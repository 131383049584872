import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";

import Axios from 'axios';
import Style from './contracts.module.scss';

import { AccountContext, Animate, ViewContext, Button, Card, Checkbox, Grid, Label, NumberInput, TextInput, Select, useAPI } from 'components/lib';
import moment from 'moment';

import { IDeliveryLocation, ILinkedContract } from 'ts/interfaces/contract';
import { KeyPair } from 'ts/interfaces/general';

import { Alert, Button as AntButton, DatePicker, List, Typography, Space, Row, Col } from 'antd';
import { DeleteFilled } from '@ant-design/icons';

const { Text } = Typography;
const { RangePicker } = DatePicker;

interface IContractForm {
    contract_num?: string;
    pipeline_id?: string;
    start_date?: string;
    end_date?: string;
    receipt_location_id?: string;
    receipt_location_max_quantity: number;
    is_flex?: boolean;
    is_gate: boolean;
}

interface IDelLocForm {
    location_id?: string;
    max_quantity?: number;
    activity_code?: string;
    package_id?: string;
    utility_id?: string;
}

export function CreateContract() {

    const context = useContext(ViewContext);
    const { accountState } = useContext(AccountContext);

    const history = useHistory();
    const location = useLocation<{ [updateContract: string]: any }>();

    const getOptionData = (data: Array<{name: string, id: string}> | undefined): Array<KeyPair<string, string>> | undefined => data?.map(obj => ({
        label: obj.name,
        value: obj.id
    })) || [];

    const locationData: {
        data: Array<{name: string, id: string}> | null;
        loading: boolean;
    } = useAPI('/api/location');

    const [locations, setLocations] = useState<Array<KeyPair<string, string>> | undefined>([]);
    const [recipLocationOpts, setRecipLocationOpts] = useState<Array<KeyPair<string, string>> | undefined>([]);
    const [delLocationOpts, setDelLocationOpts] = useState<Array<KeyPair<string, string>> | undefined>([]);
    const [pipelines] = useState(accountState.pipelines);
    const [utilityOptions, setUtilityOptions] = useState(getOptionData(accountState.utilities));
    const [points] = useState(accountState.points);

    const [deliveryLocations, setDeliveryLocations] = useState<Array<IDeliveryLocation>>([]);
    const [loading, setLoading] = useState(false);
    const [delLocMaxOverReceiptMax, setDelLocMaxOverReceiptMax] = useState(false);
    const dateFormat = 'YYYY-MM-DD';

    const formObj = {
        contract_num: undefined,
        pipeline_id: undefined,
        start_date: undefined,
        end_date: undefined,
        receipt_location_id: undefined,
        receipt_location_max_quantity: 0,
        is_flex: false,
        is_gate: false
    };

    const delLocFormObj = {
        location_id: undefined,
        max_quantity: undefined,
        activity_code: undefined,
        package_id: undefined,
        utility_id: undefined
    }

    const getUpdateContractObj = () => {
        const formObjKeys = Object.keys(formObj);
        const newFormObj: { [x: string]: any} = {};
        
        formObjKeys.forEach(key => {
            newFormObj[key] = location.state.updateContract[key];
        });
        
        return newFormObj;
    }

    const getUpdateDelLocObjs = () => {
        const formObjKeys = Object.keys(delLocFormObj);

        const newDelObjs = location.state.updateContract.delivery_locations.map((dl: {[x: string]: any}) => {
                let dlObj: {[x: string]: any} = {};

                formObjKeys.forEach(key => {
                    dlObj[key] = dl[key];
                });

                return dlObj;
            });

        return newDelObjs;
    }

    const [formState, setFormState] = useState<IContractForm>(formObj);
    const [delLocFormState, setDelLocFormState] = useState<IDelLocForm>(delLocFormObj);

    useEffect(() => {
        if(location && location.state && location.state?.updateContract) {
            setFormState({...formObj, ...getUpdateContractObj()});
            setDeliveryLocations(getUpdateDelLocObjs());
        }
    }, [location]);

    useEffect(() => {      
            setRecipLocationOpts(
                locations?.filter(loc => 
                    !deliveryLocations.some(delLoc => delLoc.location_id === loc.value)
                )
            );
    
            setDelLocationOpts(
                locations?.filter(loc => 
                    loc.value !== formState.receipt_location_id && !deliveryLocations.some(delLoc => delLoc.location_id === loc.value)
                )
            );

        setUtilityOptions(getOptionData(accountState.utilities));

    }, [locations, deliveryLocations, formState]);

    useEffect(() => {
        if (locationData && locationData.data && locationData.data.length) {
            setLocations(getOptionData(locationData.data));
            setRecipLocationOpts(getOptionData(locationData.data));
            setDelLocationOpts(getOptionData(locationData.data));
        }
    }, [locationData]);

    useEffect(() => {

        if (formState.receipt_location_max_quantity && deliveryLocations) {
            const delLocMaxTotal = deliveryLocations.reduce((sum, dl) => sum + dl.max_quantity , 0);

            if (formState.receipt_location_max_quantity < delLocMaxTotal && !delLocMaxOverReceiptMax) {
                setDelLocMaxOverReceiptMax(true);
            } else if (formState.receipt_location_max_quantity >= delLocMaxTotal && delLocMaxOverReceiptMax) {
                setDelLocMaxOverReceiptMax(false);
            }
        }

    }, [formState, deliveryLocations, delLocMaxOverReceiptMax, setDelLocMaxOverReceiptMax]);

    const onDateRangeChange = (dateObjs: any, dates: Array<string>) => {
        if(dates.length === 2) {
            setFormState({
                ...formState,
                start_date: dates[0],
                end_date: dates[1]
            });
        }
    }

    const setValue = (id: string, value: string | boolean) => {
        if(id in formState) {
            const valObj = { [id]: value };

            setFormState({...formState, ...valObj });
        }
    }

    const setDelLocValue = (id: string, value: number | string | undefined) => {
        if(id in delLocFormState) {
            const valObj = { 
                [id]: id === 'max_quantity' ? 
                    (value as unknown) as number : 
                    value 
            };

            setDelLocFormState({...delLocFormState, ...valObj });
        }
    }

    const submitDelLocation = () => {
        const delLocKeys = Object.keys(delLocFormObj);
        let missingVal = false;
        const delLocFormStateObj: {[x: string]: any} = {...delLocFormState};

        delLocKeys.forEach(key => {
            missingVal = (delLocFormStateObj[key] === undefined) ? true : false;
        });

        const duplicate = deliveryLocations.filter(delLoc => delLoc.location_id === delLocFormState.location_id);

        if(!missingVal && !duplicate.length) {
            setUtilityOptions([]);
            setDeliveryLocations([...deliveryLocations, delLocFormState as IDeliveryLocation]);
            setDelLocFormState({...delLocFormObj});
        }
    }

    const removeDelLocation = (delLocationObj: IDelLocForm) => {
        const deleteString = JSON.stringify(delLocationObj);
        const newDelLoctions = deliveryLocations.filter(deliveryLocation => deleteString !== JSON.stringify(deliveryLocation));
        
        setDeliveryLocations(newDelLoctions);
    }

    const contractIsInUse = (): boolean => {
        const updateContract = location?.state ? location.state.updateContract : undefined;
        if (!updateContract) return false;

        return  Boolean(updateContract.linked_contracts.length) || 
                Boolean(updateContract.delivery_locations.find((dl: IDeliveryLocation) => dl.current_splits.length || dl.last_daily_nom_date))
    }

    const delLocIsLinked = (dl: IDeliveryLocation): boolean => {
        const updateContract = location?.state ? location.state.updateContract : undefined;
        if (!updateContract) return false;

        let match = false;

        updateContract.linked_contracts.forEach((lk: ILinkedContract) => {
            if (lk.sub_linked_locations.length) {
                match = Boolean(lk.sub_linked_locations.find(obj => obj.location_id === dl.location_id));
            }

            if (lk.primary_linked_locations.length) {
                match = Boolean(lk.primary_linked_locations.find(obj => obj.location_id === dl.location_id));
            }
        });

        return match;
    }

    const contractIsLinkedOrSplit = (): boolean => {
        const updateContract = location.state.updateContract;
        if (!updateContract) return false;

        return updateContract.linked_contracts.length || updateContract.delivery_locations.find((dl: IDeliveryLocation) => dl.current_splits.length);
    }

    const renderDelLocations = (delLocation: IDeliveryLocation): JSX.Element => {
        const locationObj = locationData?.data?.find(loc => loc.id === delLocation.location_id);
        const delLocDataObj = location?.state?.updateContract ? location.state.updateContract.delivery_locations.find((dl: IDeliveryLocation) => dl.location_id === delLocation.location_id) : undefined;
        const actionArr = [
            (<AntButton icon={<DeleteFilled />} onClick={ () => removeDelLocation(delLocation) } />)
        ];

        const delLocInUse = () => {
            return delLocDataObj.last_daily_nom_date || delLocDataObj.current_splits.length > 0 || delLocIsLinked(delLocation)
        }

        return (
            <List.Item
                actions={delLocDataObj && delLocDataObj.last_daily_nom_date ? undefined : actionArr}
            >
                <ul>
                    <li>
                        <Space direction="horizontal">
                            <Text>{ locationObj?.name }</Text> / 
                            <Text>{ delLocation.max_quantity }</Text>
                            {delLocDataObj && delLocInUse() && <Text type="warning">Delivery location in uses. Is linked, has split or has been used in a daily nomination.</Text>}
                        </Space>
                    </li>
                </ul>
            </List.Item>
        )
    }

    const isValid = (): boolean => {
        const formStateObj: {[x: string]: any} = {...formState};

        const keys = Object.keys(formStateObj);
        const missingVals = keys.filter(key => formStateObj[key] === undefined);

        return !Boolean(missingVals.length && deliveryLocations.length > 0 && deliveryLocations.length && !delLocMaxOverReceiptMax);
    }

    const submit = async () => {
        if(location?.state && location.state?.updateContract) {
            await submitUpdateContract();
            return;
        }

        await submitNewContract();
    }

    const prepareDatePayloadData = (startDate: string | undefined, endDate: string | undefined): { startDate: string, endDate: string} | void => {
        if (!startDate || !endDate) return;
        
        let startDateObj = moment(startDate);
        let endDateObj = moment(endDate);

        startDateObj = startDateObj.startOf('month').startOf('day');
        endDateObj = endDateObj.endOf('month').startOf('day');

        return {
            startDate: startDateObj.toISOString(),
            endDate: endDateObj.toISOString()
        }
    }

    const submitUpdateContract = async () => {
        setLoading(true);

        const dateData = prepareDatePayloadData(formState.start_date, formState.end_date);

        if (!dateData) {
            setLoading(false);
            return;
        }
        
        try {
            const res = await Axios.patch('/api/contract', { 
                contract: {
                    id: location.state.updateContract.id,
                    ...formState,
                    start_date: dateData.startDate,
                    end_date: dateData.endDate
                },
                delivery_locations: formState.is_flex ? deliveryLocations.map(dl => {
                    dl.max_quantity = 0;
                    return dl;
                }) : deliveryLocations
            });

            if (res.status === 200) {
                setLoading(false);
                goToContracts();            
            }
        } catch (err) {
            setLoading(false);
            context.handleError(err);
        }
    }

    const submitNewContract = async () => {
        setLoading(true);

        const dateData = prepareDatePayloadData(formState.start_date, formState.end_date);

        if (!dateData) {
            setLoading(false);
            return;
        }

        try {
            const res = await Axios.post('/api/contract', { 
                contract: {
                    ...formState,
                    start_date: dateData.startDate,
                    end_date: dateData.endDate
                },
                delivery_locations: formState.is_flex ? deliveryLocations.map(dl => {
                    dl.max_quantity = 0;
                    return dl;
                }) : deliveryLocations
            });

            if (res.status === 200) {
                setLoading(false);
                goToContracts();            
            }
        } catch (err) {
            setLoading(false);
            context.handleError(err);
        }
    }

    const goToContracts = () => {
        history.push({ pathname: '/contracts' });
    }
    
    if (!locationData?.loading && locationData?.data && !locationData?.data.length) {
        return (
            <Animate type="pop">
                <Card>
                        
                        <Alert
                            message={(
                                <>
                                    <p>You must add some delivery locations to you account before creating a contract.</p>
                                    <p>Go to you settings and select the "Location" tab to begin adding location entries.</p>
                                </>
                            )}
                                type="warning"
                        />
                        
                        <Grid cols="2">
                            <div  style={{ marginTop: '20px' }}>
                                <Button small outline text='Cancel' action={ () => {
                                    goToContracts();
                                } } />
                            </div>
                        </Grid>
                </Card>
            </Animate>
        );
    }
    
    return (
        <Animate type="pop">
            <Card>
                {delLocMaxOverReceiptMax &&
                    <Alert
                        message="The total maximum quantity of the delivery location(s) is greater than the receipt maximum quantity."
                        type="warning"
                        showIcon
                    />
                }
                {contractIsInUse() &&
                    <Space style={{marginBottom: '20px'}}>
                        <Text type="warning">Contract attributes cannot be changed because, it is in use.</Text>
                    </Space>
                }
            <Grid cols="4">
                <div>
                    <TextInput 
                        label="Contract Number"
                        required
                        name="contract_num"
                        id="contract_num"
                        disabled={contractIsInUse()}
                        onChange={setValue}
                        value={formState.contract_num}
                    />
                </div>
                <div>
                    <Select
                        customLabel="Pipeline"
                        required
                        name="pipeline_id"
                        id="pipeline_id"
                        onChange={setValue}
                        disabled={contractIsInUse()}
                        default={formState.pipeline_id}
                        value={formState.pipeline_id}
                        options={getOptionData(pipelines)}
                    />
                </div>
                <div>
                    <Select
                        customLabel="Receipt Location"
                        required
                        name="receipt_location_id"
                        id="receipt_location_id"
                        onChange={setValue}
                        disabled={contractIsLinkedOrSplit()}
                        default={formState.receipt_location_id}
                        value={formState.receipt_location_id}
                        options={getOptionData(points)}
                    />
                </div>
                <div>
                    <NumberInput 
                        label="Maximum Quantity"
                        required
                        placeholder="0"
                        name="receipt_location_max_quantity"
                        id="receipt_location_max_quantity"
                        onChange={setValue}
                        disabled={contractIsInUse()}
                        value={formState.receipt_location_max_quantity}
                    />
                </div>
            </Grid>
            <Grid cols="2">
                <div>
                    <Label text="Select Date Range" />
                    <RangePicker 
                        style={{ width: '100%' }}
                        onChange={onDateRangeChange} 
                        defaultValue={location.state.updateContract ? [
                            moment(location.state.updateContract.start_date, dateFormat), 
                            moment(location.state.updateContract.end_date, dateFormat)
                        ] : undefined}
                        disabled={contractIsInUse()}
                        direction="ltr"
                    />
                </div>
                <div style={{ paddingTop: '14px' }}>
                        <Checkbox
                            name="is_flex"
                            option="Flex Contract (The delivery locations to this contract will not require a maximum quantity limit.)"
                            index={1}
                            callback={(id: string, value: boolean) => setValue("is_flex", !value)}
                            checked={formState.is_flex}
                            value={formState.is_flex}
                        />
                        <Checkbox
                            name="is_gate"
                            option="Is Gate"
                            index={1}
                            callback={(id: string, value: boolean) => setValue("is_gate", !value)}
                            checked={formState.is_gate}
                            value={formState.is_gate}
                        />
                    </div>
            </Grid> 

            <Grid cols="1">
                {locationData && locationData.data && deliveryLocations.length > 0 &&
                    <div
                        id="scrollableDiv"
                        className={Style.delLocSection}
                    >
                    <List
                        size="small"
                        bordered
                        dataSource={deliveryLocations}
                        renderItem={renderDelLocations}
                    />
                    </div>
                }
            </Grid>            
            </Card>

            <Card
                title="Add Delivery Locations"
            >
            {!formState.receipt_location_id && 
                <Alert
                    message="Must select a receipt location before adding delivery locations to contract"
                    type="warning"
                />
            }
            {formState.receipt_location_id && delLocationOpts && delLocationOpts.length > 0 &&
                <>
                <Grid cols="3">
                    <div>
                        <Select
                            customLabel="Delivery Location"
                            required
                            name="location_id"
                            id="location_id"
                            onChange={setDelLocValue}
                            value={delLocFormState.location_id}
                            options={delLocationOpts}
                        />
                    </div>

                    {!formState.is_flex && 
                        <div>
                            <NumberInput 
                                label="Maximum Quantity"
                                required
                                placeholder="0"
                                name="max_quantity"
                                id="max_quantity"
                                onChange={setDelLocValue}
                                value={delLocFormState.max_quantity}
                            />
                        </div>
                    }
                    
                    <div>
                        <TextInput 
                            label="Activity Code"
                            required
                            name="activity_code"
                            id="activity_code"
                            onChange={setDelLocValue}
                            value={delLocFormState.activity_code}
                        />
                    </div>
                </Grid>
                <Grid cols="2">
                    <div>
                        <TextInput 
                            label="Package Id"
                            required
                            name="package_id"
                            id="package_id"
                            onChange={setDelLocValue}
                            value={delLocFormState.package_id}
                        />
                    </div>
                    <div>
                        <Select
                            customLabel="Utility Destination"
                            required
                            id="utility_id"
                            name="utility_id"
                            value={delLocFormState.utility_id}
                            onChange={setDelLocValue}
                            options={utilityOptions}
                        >
                        </Select>
                    </div>
                </Grid>
                </>
            }
            <Grid cols="1">
                <div>
                    {!locationData?.loading && locationData?.data && locationData.data.length > 0 && !delLocationOpts?.length &&
                        <Alert
                            message="Cannot add more delevery locations. All location options have been selected."
                            type="warning"
                        />
                    }
                    {formState.receipt_location_id && delLocationOpts && delLocationOpts.length > 0 &&
                        <Button
                            fullWidth
                            className={Style.submitDelLocBtn}
                            text="Add Delivery Location"
                            action={ submitDelLocation }
                        />
                    }
                </div>
            </Grid>
            </Card>
            

            
            <Grid cols="1">
                <Row gutter={4}>
                    <Col span={12} className={Style.btnMenu}>
                        {isValid() &&
                            <Button
                                loading={ loading }
                                text="Save Contract"
                                action={ submit }
                            />
                        }
                    </Col>
                    <Col span={12} className={Style.btnMenu}>
                        <Button outline text='Cancel' action={ () => {
                            goToContracts();
                        } } />
                    </Col>
                </Row>
            </Grid>
        </Animate>
    );
}