/***
*
*   MASTER ACCOUNTS
*   Manage all accounts signed up to your application
*
**********/

import React, { useContext } from 'react';
import { ViewContext, Button, Card, Grid, Table, Animate, useAPI, usePlans } from 'components/lib';

export function MasterSignupInvites(props){

    const context = useContext(ViewContext);
    const plans = usePlans();
    const invites = useAPI('/api/master/signup-invite');

    function editSignupInvite(data, callback){
        context.modal.show({
    
          title: 'Edit Account',
          form: {
            id: {
              type: 'hidden',
              value: data.id
            },
            company_name: {
                label: 'Company Name',
                value: data.company_name,
                type: 'text',
                required: true,
                errorMessage: 'Please enter the company name'
            },
            email: {
                label: 'Email',
                value: data.email,
                type: 'email',
                required: true,
            },
            plan: {
                label: 'Plan',
                type: 'select',
                options: plans.data.list,
                default: data.plan,
                required: true,
            }
          },
          buttonText: 'Email Signup Invite',
          url: '/api/master/signup-invite',
          method: 'POST'
    
        }, (res) => {
    
          context.notification.show(data.name + ' was updated', 'success', true);
          callback(res);
    
        });
      }

    const openCreateInviteModal = () => {
        context.modal.show({
            title: 'Create Signup Invite',
            form: {
                company_name: {
                    label: 'Company Name',
                    value: '',
                    type: 'text',
                    required: true,
                    errorMessage: 'Please enter the company name'
                },
                email: {
                    label: 'Email',
                    value: '',
                    type: 'email',
                    required: true,
                },
                plan: {
                    label: 'Plan',
                    type: 'select',
                    options: plans.data.list,
                    required: true,
                  }
            },
            buttonText: 'Create Account',
            url: '/api/master/signup-invite',
            method: 'POST'
        }, (form, res) => {});
    }

    function deleteSignupInvite(data, callback){

        context.modal.show({
          title: 'Delete Account',
          form: {
            id: {
              type: 'hidden',
              value: data.id
            }
          },
          buttonText: 'Delete Signup Invite',
          text: `Are you sure you want to close ${data.owner}'s account?`,
          url: `/api/master/signup-invite`,
          method: 'DELETE'
    
        }, () => {
    
          context.notification.show(`${data.name} was deleted`, 'success', true);
          callback();
    
        });
    }

      return(
        <Animate>
          <Card loading={ false }>
            <Grid cols="2">
                <div>
                    <Button
                        small
                        text="Create Signup Invite"
                        action={ openCreateInviteModal }
                    />
                </div>
            </Grid>
            <Table
              search
              hide={['id', 'light_theme', 'primary_color', 'secondary_color']}
              data={ invites?.data }
              loading={ invites?.loading }
              badge={{ col: 'plan', color: 'blue' }}
              actions={{ 
                edit: editSignupInvite, 
                delete: deleteSignupInvite, 
                email: true 
              }}
            />
    
          </Card>
        </Animate>
      );
}