import React, { useState } from 'react';
import ClassNames from 'classnames';
import { Label, Error, Icon } from 'components/lib';
import Style from './input.module.scss';

export function NumberInput(props){

  const [negativeVal, setNegativeVal] = useState(undefined);
  const [partFloatVal, setPartFloatVal] = useState(undefined);

  const [error, setError] = useState(props.errorMessage || 'Please enter a number');

  function validate(e){

    const min = props.min;
    const max = props.max;
    let value = e ? e.target.value : '';
    let valid = undefined;

    // input is required and value is blank
    if (props.required && value === '')
      valid = false;

    // input isn't required and value is blank
    if (!props.required && value)
      valid = true;

    // now test for a valid number
    if (isNaN(value)){

      valid = false;
      setError('Please enter a valid number');

    }

    // // check for min max
    if (min && max){
      if (value >= min && value <= max){
        valid = true;
      }
      else {
        valid = false;
        setError('Please enter a number between ' + min + ' and ' + max);
      }
    }
    else if (min){
      if (value > min){
        valid = true;
      }
      else {
        valid = false;
        setError('Please enter a number above ' + min);
      }
    }
    else if (max){
      if (value < max){
        valid = true;
      }
      else {
        valid = false;
        setError('Please enter a number below ' + max);
      }
    }
    else {

      valid = true;

    }

    // update the parent form
    //props.onChange(props.name, value, valid);

  }

  // style
  const css = ClassNames([

    Style.textbox,
    props.className,
    props.valid === false && Style.error,
    props.valid === true && Style.success

  ]);

  function isOnlyNegativeSign(x) {
    const strVal = String(x);

    return props.isNegative && strVal.length === 1 && strVal[0] === '-';
  }

  function isPartialFloatVal(x) {
    const strVal = String(x);
    const decimalIdx = strVal.indexOf('.');
    const hasTrailingDecimal = (decimalIdx + 1) === strVal.length;
    const hasTrailingZero = strVal[strVal.length - 1] === '0';

    return props.isFloat && decimalIdx > -1 && (hasTrailingDecimal || hasTrailingZero);
  }

  function hasMoreThanOneDecimal(x) {
    const strVal = String(x);

    return strVal.split('.').length > 2;
  }

  function setToPositiveNum(x) {
    if (!x) return undefined;
    
    let strVal = String(x);
    let newVal;
    const isNegative = props.isNegative && strVal[0] === '-';

    const prepareFinalValue = (theVal) => {
      if (theVal === '') return undefined;

      const isFloat = theVal.indexOf('.') > -1;
      const returnVal = isNegative ? `-${theVal}` : theVal;

      return isFloat ? parseFloat(returnVal) : parseInt(returnVal, 10);
    }

    if (isNegative) {
      strVal.substring(0);
    }

    if (props.isFloat && strVal.indexOf('.') > -1) {
      const valArr = strVal.split('.');
      const decPart = valArr[1];
      const decimalLimit = props.decimalLimit || 2;

      if (decPart.length && decPart.length > decimalLimit) {
        const newDecParts = decPart.split('');
        newDecParts.pop();

        newVal = `${valArr[0]}.${newDecParts.join('')}`.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');

        return prepareFinalValue(newVal);
      }

      newVal = strVal.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');

      return prepareFinalValue(newVal);
    } 

    newVal = strVal.replace(/[^0-9]/g, '');

    return prepareFinalValue(newVal);
  }

  function setValue() {
    if (negativeVal) {
      return negativeVal;
    }

    if (partFloatVal) {
      return partFloatVal;
    }

    return props.value !== undefined ? props.value : '';
  }

  return(
    <div className={ Style.input }>

      <Label
        text={ props.label }
        required={ props.required }
        for={ props.name }
        filled={ setValue() !== '' }
      />

      <input
        type='text'
        id={ props.name }
        name={ props.name }
        className={ css }
        value={ setValue() }
        min={ props.min }
        max={ props.max }
        disabled={ props.disabled }
        placeholder={ props.placeholder }
        onChange={ e => {
          const onlyNegativeSign = isOnlyNegativeSign(e.target.value);
          const isPartialFloat = isPartialFloatVal(e.target.value);
          const hasMultipleDecimals = hasMoreThanOneDecimal(e.target.value);

          if (!hasMultipleDecimals) {
            if (!onlyNegativeSign && !isPartialFloat) {
              setNegativeVal(undefined);
              setPartFloatVal(undefined);
              props.onChange(props.name, setToPositiveNum(e.target.value), undefined);
            } else if (onlyNegativeSign) {
              setNegativeVal('-');
            } else if (isPartialFloat) {
              setPartFloatVal(e.target.value);
            }
          }
        }}
        onBlur={ e => validate(e) }
      />

      { props.valid === false &&
        <Error message={ error }/> }

      { props.valid === true &&
        <Icon
          image='check'
          color='#8CC57D'
          className={ Style.successIcon }
          size={ 16 }
        />}

    </div>
  );
}
