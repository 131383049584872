/***
*
*   APP LAYOUT
*   Main application layout containing the navigation
*   and header (title, secondary nav and signed in user)
*
*   PROPS
*   title: title of the view
*
**********/

import React, { Fragment, useContext, useEffect } from 'react';
import { AccountContext, AuthContext, AppNav, Header, getAllAccountData, Loader, User, SocketContext, ViewContext } from 'components/lib';
import ClassNames from 'classnames';
import Style from './app.module.scss';
import '../layout.scss'; // globals

import Axios from 'axios';
import moment from 'moment';

export function AppLayout(props){

  // context & style
  const context = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const socket = useContext(SocketContext);
  
  const { accountState, accountDispatch } = useContext(AccountContext);

  useEffect(() => {

    if (!accountState.loading) {
      if (!accountState.points && !accountState.pipelines && !accountState.positions && !accountState.companies && !accountState.utilities) {
        getAllAccountData(accountDispatch);
      } else if (!accountState.hasOnboardData) {
        window.location = '/welcome';
      }
    }

    if (accountState.error) {
      viewContext.handleError(accountState.error);
    }

  }, [accountState, accountDispatch]);

  useEffect(() => {
    if (!socket.connected) {
      socket.on('MAILGUN_DELIVERY_SUCCESS', (obj) => {
        const messageObj = {
          'daily-nomination': 'The daily nomination email was successfully sent.',
          'company-deal_receipt': 'The company deal receipt email was successfully sent.'
        }

        if (obj?.type) viewContext.notification.show(messageObj[obj.type], 'success', true);

      });
    }
  }, []);

  useEffect(() => {

    const updateStorageForMonth = async (lastMonthDate) => {
      // call endpoint to update interruptible storage for account
      try {
              
        const startDate = lastMonthDate.startOf('month').format('YYYY-MM-DD');
        const endDate = lastMonthDate.endOf('month').format('YYYY-MM-DD');

        const res = await Axios.post('/api/interruptible/update-monthly', {
            start_date: startDate,
            end_date: endDate
        });

        if (res.status === 200) {
          localStorage.setItem('interruptible-monthly-update', JSON.stringify({ date: lastMonthDate.format('YYYY-MM') }));
        }          
      }
      catch (err) {}
    }

      const updateObj = localStorage.getItem('interruptible-monthly-update');
      const lastMonthDate = moment().subtract(1,'months');

      if (updateObj === null) {
          updateStorageForMonth(lastMonthDate);
      } else if (typeof updateObj === 'string') {
          const jsonData = JSON.parse(updateObj);
          const recentStorageDate = moment(jsonData.date);

          if(recentStorageDate.isBefore(lastMonthDate, 'month')) {
              updateStorageForMonth(lastMonthDate);
          }
      }

  }, []);

  const css = ClassNames([ Style.app, 'with-sidebar' ]);

  // call account actions to get data and store it to state tree

  const hasData = () => accountState.points && accountState.pipelines && accountState.positions && accountState.companies && accountState.utilities;

  const Styles = {
    'button': {
      backgroundColor: 'black !important'
    } 
  }

  return (
    <Fragment>
      <AppNav
        type='popup'
        items={[
          { label: 'Dashboard', icon: 'activity', link: '/dashboard' },
          { label: 'Utility Reqs', icon: 'sliders', link: '/utility-reqs' },
          { label: 'Daily Nom.', icon: 'archive', link: '/daily-nomination' },
          {
            group: [
              { label: 'Spot Deals', icon: 'briefcase', link: '/spot-deals' },
              { label: 'LT Deals', icon: 'briefcase', link: '/long-term-deals' },
              { label: 'Deal Logs', icon: 'file-text', link: '/deal-logs' },
            ]
          },
          { label: 'Contracts', icon: 'file-text', link: '/contracts' },
          { label: 'Settings', icon: 'settings', link: '/settings', },
          { label: 'Reports', icon: 'folder', link: '/reports' },
          { label: 'Interruptibles', icon: 'bar-chart-2', link: '/interruptibles' },
          { label: 'Email Jobs', icon: 'mail', link: '/email-jobs' },
          { label: 'Account', icon: 'user', link: '/account', },
          { label: 'Help', icon: 'help-circle', link: '/help' },
          { label: 'Sign Out', icon: 'log-out', action: context.signout }
        ]}
      />

      <main className={ css } style={Styles}>
        <Header title={ props.title }>
          <User/>
        </Header>

        {hasData() ? 
          <props.children {...props.data }/> : 
          <Loader fullScreen />   
        }

      </main>
    </Fragment>
  );
}
