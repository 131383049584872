import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Axios from 'axios';
import { AccountContext, Animate, Card, Chart, ViewContext } from 'components/lib';
import { UpdateUtilityReq } from './update-utility-req';
import moment from 'moment';

import { Alert, Badge, Button as AntButton, DatePicker, Divider, List, Row, Col, Space, Typography } from 'antd';

import Style from './utility-reqs.module.scss';

const { Text } = Typography;

export function UtilityReqs() {
    const context = useContext(ViewContext);
    const { accountState } = useContext(AccountContext);
    const history = useHistory();

    const [selectedDate, setSelectedDate] = useState(moment());
    const [utilities] = useState(accountState.utilities);
    const [utilityReqs, setUtilityReqs] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        const getUtilityReqs = async () => {
            try {
                setLoading(true);
                const utilityIds = utilities.map(util => util.id);

                const res = await Axios.get('/api/company-utility-requirements', { params: { 
                    date: selectedDate.format('YYYY-MM-DD'),
                    latest: 1,
                    utility_ids: utilityIds
                }});

                if (res.status === 200) {
                    setUtilityReqs(res.data.data);
                    setLoading(false);
                    
                } 
            }
            catch(err) {
                context.handleError(err);
                setLoading(false);
            }
        }

        if(utilities?.length) {
            getUtilityReqs();
        }

    }, [utilities, selectedDate]);

    const prepareListData = () => utilities.map(util => {
            const utilReq = utilityReqs.find(utilReq => utilReq.utility_id === util.id);
            util.req = utilReq ? utilReq : undefined;
            return util;
        });
    
    const openUpdateReqsModal = (utility) => {
        context.modal.show({
            title: 'Add Utility Requirements',
            customForm: UpdateUtilityReq,
            formProps: { 
                utility, 
                values: {
                    ...utilityReqs.find(utilReqs => utilReqs.utility_id === utility.id),
                    date: selectedDate
                }
            },
        }, (form, res) => {
            const hasEntry = utilityReqs.find(utilReq => utilReq.utility_id === res.utility_id);
            
            if(hasEntry) {
                const newReqBatch = utilityReqs.map(utilReq => {
                    if(utilReq.utility_id === res.utility_id) return res;
                    return utilReq;
                });
                setUtilityReqs(newReqBatch);
            } else {
                utilityReqs.push(res);
                setUtilityReqs(utilityReqs);
            }            
        });
    }

    const prepareTotalPieChartData = (utilData) => {
        let pieData = [];
    
        const interruptiblesTotal = utilData?.req ? utilData.req.interruptible_requirements.reduce((sum, curr) => sum + curr.requirement, 0) : undefined;

          pieData = [
            interruptiblesTotal, 
            utilData?.req ? utilData.req.cc_requirement : undefined
          ];
        
          const emptyData = pieData.filter(pd => !pd);
          const isEmpty = emptyData.length === pieData.length;

        const data = {
          labels: [
            'Interruptibles',
            'Customer Choice'
          ],
          datasets: isEmpty ? [] : [{
            label: 'My First Dataset',
            data: pieData,
            hoverOffset: 4
          }]
        };
    
        return data;
      }

    const renderUtilReqs = () => {

        const items = prepareListData();

        if (!items.length) return [];

        const renderRequirements = (itemObj) => {
            const hasInterruptibleReqs = itemObj.req.interruptible_requirements || itemObj.req.interruptible_requirements.length;
            const listItems = [...itemObj.req.interruptible_requirements, {name: 'Choice Customer:', requirement: itemObj.req.cc_requirement || 0}]
            
            return (
                    <List
                        header={
                            <Space direction='horizontal'>
                                <Text strong>Requirement:</Text>
                                <Badge
                                    className="site-badge-count-109"
                                    count={itemObj.req.requirement}
                                    overflowCount={1000000}
                                    color='#faad14'
                                />
                            </Space>
                        }
                        bordered
                        dataSource={hasInterruptibleReqs ? listItems : []}
                        renderItem={(item) => (
                            <List.Item>
                                <Space direction='horizontal'>
                                    <Text>{item.name}:</Text> 
                                    <Text strong>{item.requirement}</Text>
                                </Space>
                            </List.Item>
                        )}
                     />
            );
        }

        return items.map(item => {

            return (
                <Card
                    title={item.name}
                >

                        <Row>
                            <Col xl={{ span: 12}} sm={{ span: 24 }} xs={{ span: 24 }}>
                                {!loading && !item.req &&
                                    <Alert
                                        message="This utilities requirements need to be updated for today."
                                        type="warning"
                                    />
                                }
                                {item.req &&
                                    <>
                                        { renderRequirements(item) }
                                        <Divider />
                                        <List
                                            bordered
                                            dataSource={[
                                                {
                                                    name: 'Max Withdrawal:',
                                                    requirement: item.req.max_withdrawal
                                                },
                                                {
                                                    name: 'Max Injection:',
                                                    requirement: item.req.max_injection
                                                }
                                            ]}
                                            renderItem={(item) => (
                                                <List.Item
                                                    bordered={false}
                                                    dataSource={[

                                                    ]}
                                                >
                                                    <Space direction='horizontal'>
                                                        <Text>{item.name}</Text> 
                                                        <Text strong>{item.requirement}</Text>
                                                    </Space>
                                                </List.Item>
                                            )}
                                        />
                                    </>
                                }
                            </Col>
                            <Col xl={{ span: 12}} sm={{ span: 24 }} xs={{ span: 24 }}>
                                <div className={Style.utilChartWrapper}>
                                <Chart
                                    type='pie'
                                    legend={ true }
                                    loading={ !item }
                                    data={ prepareTotalPieChartData(item) }
                                    color={['purple', 'green']}
                                />
                                </div>
                                
                            </Col>
                        </Row>

                    <Divider />

                    <Space direction="horizontal">

                        <AntButton onClick={() => openUpdateReqsModal(item)}>
                            Update Utility Requirement
                        </AntButton>
                        <AntButton 
                            onClick={() => {
                                history.push({
                                    pathname: `/utility/${item.id}`
                                });
                            }} 
                        >
                            View Utility
                        </AntButton>

                    </Space>
                </Card>
            );
        });
    }
    
    return (
        <Animate type='pop'>
            <Card 
                loading={!utilities || !utilities?.length}
            >
                <Row>
                    <Col xs={24} md={14}>
                        <p className={Style.selectDatePrompt}>Select a date to display the utility requirements for that day.</p>
                    </Col>

                    <Col xs={24} md={{ span: 6, offset: 4}}>
                        <DatePicker 
                            style={{ width: '100%' }}
                            onChange={(dateObj) => setSelectedDate(dateObj)}
                            direction="ltr"
                            value={selectedDate}
                        />
                    </Col>
                </Row>
                
            </Card>

            { renderUtilReqs() }

        </Animate>
    );
}