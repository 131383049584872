import { useCallback, useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { ViewContext, Button, Grid, Select, usePlans } from 'components/lib';
import { EditableCell, EditableRow } from '../../components/editableTableSections/editable-table-sections';
import { Table } from 'antd';

import moment, { Moment } from 'moment';

import { KeyPair } from "ts/interfaces/general";
import { IContract, IDLData, ICycle, IDelCycleData, IReceiptCycleData } from 'ts/interfaces/contract';

import { Alert, Badge, Col, DatePicker, Row, Space, Typography } from 'antd';
const { Text } = Typography;

type TableInputCbType = (x: boolean) => void;

export function CycleQuantity({ contractData, preSelectDelLoc }: { contractData: IContract, preSelectDelLoc: IDLData}) {
    const context = useContext(ViewContext);
    const plans: { data: any, loading: boolean } = usePlans();

    const [contract, setContract] = useState<IContract>(contractData);

    const getDelLocOptions = useCallback(():KeyPair<string, string>[] => {
        if (preSelectDelLoc) {
            return [{
                label: preSelectDelLoc.location_num,
                value: preSelectDelLoc.location_id
            }];
        }

        return contract.delivery_locations
            .map(dl => ({
                label: dl.location_num,
                value: dl.location_id
            }));
    }, [contract, preSelectDelLoc]);

    const [dataLoading, setDataLoading] = useState<boolean>(false);

    const [cycles, setCycles] = useState<ICycle[]>([]);
    const [delLocOptions, setDelLocOptions] = useState<KeyPair<string, string>[] | undefined>(undefined);

    const [selectDelLoc, setSelectDelLoc] = useState<KeyPair<string, string> | undefined>(!preSelectDelLoc ? undefined : {
        label: preSelectDelLoc.location_num,
        value: preSelectDelLoc.location_id
    });

    const [currentDateDeliveryAmountData, setCurrentDateDeliveryAmountData] = useState<{[x: string]: any} | undefined>(undefined);
    const [currentDateReceiptAmountData, setCurrentDateReceiptAmountData] = useState<{[x: string]: any} | undefined>(undefined);

    const [selectedDate, setSelectedDate] = useState<Moment | undefined>(undefined);
    const [underDediAmount, setUnderDediAmount] = useState(false);

    const [delCycleData, setDelCycleData] = useState<Array<IDelCycleData> | undefined>(undefined);
    const [receiptCycleData, setReceiptCycleData] = useState<Array<IReceiptCycleData> | undefined>(undefined);

    const [isPremiumPlan, setIsPremiumPlan] = useState<boolean | undefined>(undefined);

    useEffect(() => {    
        if (plans?.data?.raw) {
          setIsPremiumPlan(plans?.data?.isPremium);
        }
    
      }, [plans]);

    useEffect(() => {
        if (contract) setDelLocOptions(getDelLocOptions());
    }, [contract, getDelLocOptions]);

    useEffect(() => {

        const getDeliveryAmountOnDate = async (delLocObj: KeyPair<string, string>, chosenDate: Moment) => {
            setDataLoading(true);
            try {
                const deliveryAmountRes = await Axios.get('/api/contract/delivery-amount', {
                    params: { 
                        contract_id: contract.id,
                        location_id: delLocObj?.value,
                        date: chosenDate.format('YYYY-MM-DD')
                    }
                });
          
                if (deliveryAmountRes.status === 200) {
                    const deliveryLocData = deliveryAmountRes.data.data;
                    setCurrentDateDeliveryAmountData(deliveryLocData);
                    setCurrentDateReceiptAmountData(deliveryLocData);
                    setDelCycleData(deliveryLocData.del_cycles);
                    setReceiptCycleData(deliveryLocData.receipt_cycles);
                    setDataLoading(false);
                }          
            } catch (err){
                setDataLoading(false);
                context.handleError(err);
            }
        }

        const getContract = async (chosenDate: Moment) => {
            try {
                setDataLoading(true);
    
                const res = await Axios.get('/api/contract', {  
                    params: {
                        id: contract.id,
                        current_date: chosenDate.format('YYYY-MM-DD')
                    }
                });
              
                if (res.status === 200) {     
    
                    setContract(res.data.data[0]);    
                    setDataLoading(false);
                } 
            } catch (err) {
                setDataLoading(false);
                context.handleError(err);
            }
        }
        
        if (cycles.length && selectedDate) {
            getContract(selectedDate);

            if (selectDelLoc) {
                setCurrentDateReceiptAmountData(undefined);
                getDeliveryAmountOnDate(selectDelLoc, selectedDate);
            }
        }

    }, [selectedDate, selectDelLoc, setDataLoading]);

    useEffect(() => {

        const getCycles = async () => {
            try {
                setDataLoading(true);
    
                const res = await Axios.get('/api/contract/cycles');

                if (res.status === 200) {
                    setCycles(res.data.data);
                }
            } catch(err) {
                setDataLoading(false);
                context.handleError(err);
            } 
        }

        if (!cycles.length) {
            getCycles();
        }
        
    }, []);

    const getSelectedDelLoc = () => selectDelLoc?.value ? contract.delivery_locations.find(dl => dl.location_id === selectDelLoc.value) : undefined;

    const onDateChange = (value: Moment) => {
        setSelectedDate(value);
    }

    const renderTable = (type: string) => {

        if (!cycles.length && !receiptCycleData && !delCycleData) return null;

        const defaultColumns = [
            {
                title: 'Cycle',
                dataIndex: 'id',
                render: (id: string, obj: any) => {
                    const cObj = cycles.find(c => (c.id === id) || (c.id === obj.cycle_id));

                    return cObj?.name;
                }
            },
            {
                title: 'Amount',
                dataIndex: 'quantity',
                width: '50%',
                editable: true
            }
        ];

        const columns = defaultColumns.map((col) => {
            if (!col.editable) {
              return col;
            }
            return {
              ...col,
              onCell: (record: any) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: (row: any, cb: TableInputCbType) => handleSave(row, type, cb),
              }),
            };
          });
        
        const components = {
            body: {
              cell: EditableCell,
              row: EditableRow
            },
        };

        const tableData = cycles.map(c => {
            if (!receiptCycleData && !delCycleData) return {};

            const cycleData = type === 'receipt' ? receiptCycleData as IReceiptCycleData[] | IDelCycleData[] : delCycleData as IReceiptCycleData[] | IDelCycleData[];
           
            if (!Array.isArray(cycleData)) return {};
            
            const cycleObj = [...cycleData].find(cd => cd.cycle_id === c.id);

            return {
                ...c,
                ...cycleObj,
                quantity: cycleObj ? cycleObj.quantity : 0,
            }
        });

        return (
            <Table
                loading={dataLoading}
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={delCycleData && receiptCycleData ? tableData : []}
                pagination={false}
                columns={columns as Array<any>}
                title={() => getHeader(type)}
            />
        );
    }

    const getHeader = (type: string) => (
        type === 'receipt' ?
        (
        <Space direction="horizontal" style={{ marginBottom: '10px' }}>
            <Text><strong>Reciept Location:</strong> {contract.receipt_location_name}</Text>
            <Text>
                <Badge
                    className="site-badge-count-109"
                    count={`Amount: ${currentDateReceiptAmountData?.current_receipt_amount}`}
                    overflowCount={1000000}
                    color='#faad14'
                />
            </Text>
        </Space>
        ) :
        (
            <Space direction="horizontal" style={{ marginBottom: '10px' }}>
                <Text><strong>Delivery Location:</strong> {selectDelLoc?.label}</Text>
                <Badge
                    className="site-badge-count-109"
                    count={`Amount: ${currentDateDeliveryAmountData?.quantity || getSelectedDelLoc()?.current_delivery_amount}`}
                    overflowCount={1000000}
                    color='#faad14'
                />
            </Space>
            )
    );

    const handleSave = (row: any, type: string, cb: (x: boolean) => void) => {
        if (!receiptCycleData && !delCycleData) return;

        const newData = type === 'receipt' ? [...receiptCycleData ? receiptCycleData : []] as IReceiptCycleData[] : [...delCycleData ? delCycleData : []] as IDelCycleData[];
        const index = newData.findIndex((item: any) => row.id === item.id);
        const item: any = newData[index];

        if (newData.length && row && item && (row.quantity === item.quantity)) {
            cb(false);
            return;
        }

        if (index > -1) {
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
        } else {
            newData.push(row);
        }
            
        if (type === 'receipt') {
            setReceiptCycleData(newData as IReceiptCycleData[]);
        } else {
            setDelCycleData(newData as IDelCycleData[])
        }
        submit(row, type, cb);
        
    }

    const submit = async (cycleAmountData: IReceiptCycleData | IDelCycleData, type: string, cb: TableInputCbType) => {
        if (!selectedDate) return false;

        try {

            const res = type === 'receipt' ? await Axios.post('/api/contract/receipt-cycle', { 
                    contract_id: contract.id,
                    ...(cycleAmountData.cycle_id && { id: cycleAmountData.id }),
                    delivery_location_id: currentDateDeliveryAmountData?.location_id,
                    receipt_location_amount_date: moment(currentDateDeliveryAmountData?.date).format('YYYY-MM-DD'),
                    cycle_id: cycleAmountData.cycle_id ? cycleAmountData.cycle_id : cycleAmountData.id,
                    quantity: cycleAmountData.quantity,
                    date: selectedDate.toISOString(),
            }) :
            await Axios.post('/api/contract/delivery-cycle', { 
                contract_id: contract.id,
                ...(cycleAmountData.cycle_id && { id: cycleAmountData.id }),
                delivery_location_id: currentDateDeliveryAmountData?.location_id,
                delivery_location_amount_id: currentDateDeliveryAmountData?.id,
                delivery_location_amount_date: moment(currentDateDeliveryAmountData?.date).format('YYYY-MM-DD'),
                cycle_id: cycleAmountData.cycle_id ? cycleAmountData.cycle_id : cycleAmountData.id,
                quantity: cycleAmountData.quantity,
                date: selectedDate.toISOString(),
            });
      
            if (res.status === 200) {
                cb(true);
            }         
        }
        catch (err){
            context.handleError(err);
        }
    }

    return (
        <div>
            {underDediAmount &&
                    <Row style={{ margin: '20px 0' }}>
                        <Alert
                            message="The delivery amount is under the dedicated amount needed for the linked contract / split that is on this delivery location."
                            type="error"
                        />
                    </Row>
            }
            <Row gutter={{ sm: 16 }}>
                <Col md={12} xs={24}>
                <DatePicker 
                    style={{ width: '100%', marginBottom: '20px' }}
                    onChange={(dateObj) => { if (dateObj) onDateChange(dateObj) }}
                    disabledDate={d => {
                        const start = moment(contract.start_date).startOf('day');
                        const end = moment(contract.end_date).add(1,'day').startOf('day');
                        return !d.isBetween(start, end, null, "[]");
                    }}
                    direction="ltr"
                    value={selectedDate ? moment(selectedDate) : null}
                    placeholder="Select Date"
                />
                </Col>
                <Col md={12} xs={24}>
                    <Select
                        customLabel="Select Delivery Location"
                        required
                        name="delivery_location"
                        value={selectDelLoc?.value}
                        default={selectDelLoc?.value}
                        onChange={(id: string, value: string) => {
                            if(underDediAmount) setUnderDediAmount(false);
                            setSelectDelLoc(value === 'unselected' ? undefined : delLocOptions?.find(dl => dl.value === value))
                        }}
                        options={delLocOptions}
                    />
                </Col>
            </Row>
            {selectedDate &&
                <>
                    {selectDelLoc &&
                        <Row style={{ margin: '10px 0' }}>
                            <Col xs={24}> 
                                    <Row justify="start" gutter={{ sm: 16 }}>
                                        <Col md={12} xs={24}>
                                            
                                            { renderTable('delivery') }
                                        </Col>
                                        {!contract.is_gate &&
                                        <Col md={12} xs={24}>
                                            
                                            { renderTable('receipt') }
                                        </Col>
                                        }
                                    </Row>
                                </Col>
                            <Col xs={24}>

                            </Col>
                        </Row>       
                    }
                </>
            }
            
            <Grid col="2">
                <Row style={{ margin: '20px 0' }}>
                    <Button fullWidth outline text='Close' action={ () => context.modal.hide(true) } />
                </Row>
            </Grid>
        </div>
    );
}