import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { ViewContext, Button, Grid, NumberInput } from 'components/lib';
import moment from 'moment';
import Style from './daily-nomination.module.scss';

export function InterruptibleFlow({
    flowDate,
    currentInterruptibles,
    save
}) {

    const context = useContext(ViewContext);

    const setCurrentFlows = () => {
        return currentInterruptibles.reduce((batch, obj) => {
            if (obj.current_utility_requirement) batch[obj.id] = obj.current_utility_requirement;
            return batch; 
        }, {});
    }

    const [flows, setFlows] = useState(setCurrentFlows());
    const [interruptEmpty, setInterruptEmpty] = useState([]);
    const [interruptMax, setInterruptMax] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getInterruptibleCurrentFlowData = async (id) => {
            let flowBatch = {};
            const prepareFlows = (flowData) => {
                const batch = {};

                flowData.forEach(fd => {
                    batch[fd.interruptible_id] = fd.dths_flow;
                });

                return batch;
            }


            for (let interruptibleObj of currentInterruptibles) {
                
                const res = await Axios.get('/api/interruptible/daily-flow-per-day', {  
                    params: { 
                        interruptible_id: interruptibleObj.id,
                        start_date: moment(flowDate).format('YYYY-MM-DD hh:mm'),
                        end_date: moment(flowDate).format('YYYY-MM-DD hh:mm')
                    } 
                });

                if (res.status === 200) {
                    const results = res.data.data;
                    flowBatch = {...flowBatch, ...prepareFlows(results)};
                }

            }

            setFlows(flowBatch);

        };

        if (!Object.keys(flows).length) {
            setLoading(true);
            getInterruptibleCurrentFlowData();
            setLoading(false);
        }

        
    }, []);

    const setValue = (interruptibleId, value) => {
        setFlows({...flows, [interruptibleId]: value });
    }

    const renderForm = () => {
        return currentInterruptibles.map(interruptible => {
            return (
                <Grid cols="2" key={interruptible.id}>
                    <div className={Style.interruptibleInfoSection}>
                        <p>{`${ interruptible.name } > ${ interruptible.utility_name } > ${ interruptible.pipeline_name }`}</p>
                        <p>{`${moment(interruptible.start_date).format('MMM, DD YYYY')} - ${moment(interruptible.end_date).format('MMM, DD YYYY')}`}</p>
                        <p className={interruptMax.indexOf(interruptible.id) > -1 ? Style.maxError : ''}>
                            Max Daily Flow: { interruptible.maximum_daily_volume}
                        </p>
                    </div>
                    <div>
                        <NumberInput 
                            label="Flow DTHS"
                            required
                            placeholder="0"
                            name="flow_dths"
                            id="flow_dths"
                            onChange={(id, value) => setValue(interruptible.id, value)}
                            value={flows[interruptible.id]}
                            valid={flows[interruptible.id] === undefined || interruptEmpty.indexOf(interruptible.id) > -1 ? false : true}
                        />
                        <hr />
                    </div>
                </Grid>
            );
        });
    }

    const isValid = () => {
        const interrupIds = Object.keys(flows);
        const interruptMaxIds = [];
        const interruptEmptyIds = [];

        interrupIds.forEach(interrupId => {
            if (flows[interrupId] === undefined) interruptEmptyIds.push(interrupId);
            
            currentInterruptibles
                .filter(currentInterrupt => currentInterrupt.id === interrupId)
                .forEach(currentInterrupt => {
                    if (currentInterrupt.maximum_daily_volume < parseInt(flows[interrupId])) {
                        interruptMaxIds.push(interrupId);
                    }
                });
        });
        
        setInterruptEmpty(interruptEmptyIds);
        setInterruptMax(interruptMaxIds);

        return interrupIds.length === currentInterruptibles.length && !interruptMaxIds.length && !interruptEmptyIds.length
    }

    const submit = async () => {
        setInterruptEmpty([]);
        setInterruptMax([]);

        if(isValid()) {
            setLoading(true);
            context.modal.hide(false, flows);
        }
    }

    return (
        <div className={Style.interruptibleFlowForm}>
            <p>
                You currently have {currentInterruptibles.length > 1 ? 'interruptibles ' : 'an interruptible '} attached to the chosen utility on this daily nomination.
                Add dths flow to {currentInterruptibles.length > 1 ? 'these' : 'this'}.
            </p>
            {renderForm()}
            <Grid col="2">
                <Button
                    loading={ loading }
                    text={save ? "Save" : "Submit Daily Nomination & Finalize Deals"}
                    action={ submit }
                />
                <Button outline text='Cancel' action={ () => context.modal.hide(true) } />
            </Grid>
        </div>
    );
}