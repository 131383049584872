/***
*
*   MISSIONCONTROL LAYOUT
*   The master dashboard layout containing the navigation
*   and header (title, secondary nav)
*
*   PROPS
*   title: title of the view
*
**********/

import React, { Fragment, useContext } from 'react';
import ClassNames from 'classnames';
import { AuthContext, AppNav, Header, User } from 'components/lib';
import Style from '../app/app.module.scss';

export function MissionControlLayout(props){

  // context
  const context = useContext(AuthContext);

  // style
  const css = ClassNames([ Style.app, 'with-sidebar' ]);

  return (
    <Fragment>
      <AppNav
        type='popup'
        items={[
          { label: 'Dashboard', icon: 'activity', link: '/missioncontrol/dashboard' },
          { label: 'Feedback', icon: 'heart', link: '/missioncontrol/feedback' },
          { label: 'Logs', icon: 'clipboard', link: '/missioncontrol/logs' },
          { label: 'Features', icon: 'clipboard', link: '/missioncontrol/features' },
          { label: 'Accounts', icon: 'credit-card', link: '/missioncontrol/accounts' },
          { label: 'DE Accounts', icon: 'users', link: '/missioncontrol/data-entry-accounts' },
          { label: 'Users', icon: 'users', link: '/missioncontrol/users' },
          { label: 'Signup Invite', icon: 'star', link: '/missioncontrol/signup-invites' },
          { label: 'Sign Out', icon: 'log-out', action: context.signout }
        ]}
      />

      <main className={ css }>

        <Header title={ props.title }>
          <User/>
        </Header>

        { <props.children {...props.data }/> }

      </main>
    </Fragment>
  );
}
