import { MasterDashboard } from 'views/missioncontrol/dashboard';
import { MasterAccounts } from 'views/missioncontrol/accounts';
import { MasterDataEntryAccounts } from 'views/missioncontrol/data-entry-accounts';
import { MasterSignupInvites } from 'views/missioncontrol/signup-invites';
import { MasterAccountDetail } from 'views/missioncontrol/accounts/detail';
import { MasterAccountProfileSettings } from 'views/missioncontrol/accounts/profile-settings';
import { MasterAccountFeatureSettings } from 'views/missioncontrol/accounts/feature-settings';
import { MasterUsers } from 'views/missioncontrol/users';
import { MasterFeedback } from 'views/missioncontrol/feedback';
import { MasterLogs } from 'views/missioncontrol/log/list';
import { MasterLogDetail } from 'views/missioncontrol/log/detail';
import { MasterFeatureSettings } from 'views/missioncontrol/features';

const Routes = [
  {
    path: '/missioncontrol/dashboard',
    view: MasterDashboard,
    layout: 'missioncontrol',
    permission: 'master',
    title: 'Mission Control'
  },
  {
    path: '/missioncontrol/accounts',
    view: MasterAccounts,
    layout: 'missioncontrol',
    permission: 'master',
    title: 'Accounts'
  },
  {
    path: '/missioncontrol/data-entry-accounts',
    view: MasterDataEntryAccounts,
    layout: 'missioncontrol',
    permission: 'master',
    title: 'Data Entry Accounts'
  },
  {
    path: '/missioncontrol/accounts/:id',
    view: MasterAccountDetail,
    layout: 'missioncontrol',
    permission: 'master',
    title: 'Account Detail'
  },
  {
    path: '/missioncontrol/accounts/:id/profile-settings',
    view: MasterAccountProfileSettings,
    layout: 'missioncontrol',
    permission: 'master',
    title: 'Account Profile Settings'
  },
  {
    path: '/missioncontrol/accounts/:id/feature-settings',
    view: MasterAccountFeatureSettings,
    layout: 'missioncontrol',
    permission: 'master',
    title: 'Account Feature Settings'
  },
  {
    path: '/missioncontrol/users',
    view: MasterUsers,
    layout: 'missioncontrol',
    permission: 'master',
    title: 'Users'
  },
  {
    path: '/missioncontrol/signup-invites',
    view: MasterSignupInvites,
    layout: 'missioncontrol',
    permission: 'master',
    title: 'Signup Invites'
  },
  {
    path: '/missioncontrol/feedback',
    view: MasterFeedback,
    layout: 'missioncontrol',
    permission: 'master',
    title: 'User Feedback'
  },
  {
    path: '/missioncontrol/logs',
    view: MasterLogs,
    layout: 'missioncontrol',
    permission: 'master',
    title: 'App Logs'
  },
  {
    path: '/missioncontrol/logs/:id',
    view: MasterLogDetail,
    layout: 'missioncontrol',
    permission: 'master',
    title: 'Log Detail'
  },
  {
    path: '/missioncontrol/features',
    view: MasterFeatureSettings,
    layout: 'missioncontrol',
    permission: 'master',
    title: 'Feature Settings'
  },
]

export default Routes;