import React from 'react';
import { Animate } from 'components/lib';
import { ClosedLongTermDeals } from './closed-long-term-deals';
import { OpenLongTermDeals } from './open-long-term-deals';

export function LongTermDeals() {
    return (
        <Animate type='pop'>
            <OpenLongTermDeals />
            <ClosedLongTermDeals />
        </Animate>
    );
}