import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { Animate, ViewContext, Badge, Button, Card, Grid, Table, useAPI } from 'components/lib';
import { AddEmailJob } from './add-email-job';
import Style from './email-jobs.module.scss';

export function EmailJobs() {
    const context = useContext(ViewContext);

    const emailJobData = useAPI('/api/email-job');

    const [emailJobs, setEmailJobs] = useState([]);
    

    useEffect(() => {
        if(emailJobData && emailJobData.data && emailJobData.data.length) {
            setEmailJobs(emailJobData.data);
        }
    }, [emailJobData]);

    const deleteEmailJob = async (emailJob) => { 
        try {
            const res = await Axios.delete('/api/email-job', { data: { id: emailJob.id } });
      
            if (res.status === 200) {
                const updatedEmailJobs = emailJobs.filter(emailJobsObj => emailJobsObj.id !== emailJob.id);
                setEmailJobs(updatedEmailJobs);
                context.notification.show('Email job was removed', 'success', true);
            }          
          }
          catch (err){
            context.handleError(err);
          }
    }

    const showPendingEmails = async (emailJob) => {
        if(!emailJob.email_count) {
            context.notification.show("This email job doesn't have any pending emails to remove.", 'success', false);
            return;
        }

        try {
            const res = await Axios.get('/api/email-queue', { params: { email_job_id: emailJob.id } });
      
            if (res.status === 200) {
                const emails = res.data.data;

                if(emails.length) {
                    const options = emails.map(email => ({ label: email.subject, value: email.id }));

                    context.modal.show({
                        title: 'Select Emails to Delete',
                        form: {
                            id: {
                                label: 'Email',
                                type: 'select',
                                required: true,
                                options
                            }
                        },
                        buttonText: 'Delete Scheduled Email',
                        url: '/api/email-queue',
                        method: 'DELETE'
                    }, (res) => {
                        if(res.status === 200) {
                            const modifiedEmailJobs = emailJobs.map(ejObj => {
                                if(emailJob.id === ejObj.id) {
                                    ejObj.email_count = !ejObj.email_count ? 0 : ejObj.email_count - 1;
                                }
                                
                                return ejObj;
                            });
    
                            setEmailJobs(modifiedEmailJobs);
                            context.notification.show('Email was removed from queue and deleted.', 'success', false);
                        }
                    });
                }
                
            }          
          }
          catch (err){
            context.handleError(err);
          }
    }

    const getEmailJobs = () => {
        if(emailJobs.length) {
            const data = emailJobs.map(emailJob => {
                let meridiemType = 'AM';
                let recurringHour = emailJob.recurring_time_hour;
                const recurringMin = emailJob.recurring_time_minute;
                const recipData = String(emailJob.recipients).split(',');

                if(parseInt(recurringHour) > 12) {
                    recurringHour = recurringHour - 12;
                    meridiemType = 'PM';
                }

                emailJob.recurring_time = `${recurringHour}:${recurringMin} ${meridiemType}`;
                emailJob.recipient_emails = recipData.map(recip => (<Badge color='blue' text={recip} />));
                emailJob.pending_emails = emailJob.email_count ? (<Badge color='orange' text={emailJob.email_count} />) : undefined;

                return emailJob;
            });

            return (
                <div className={Style.dealListWrap}>
                    <Table
                        hide={['id', 'account_id', 'recipients', 'recurring_time_hour', 'recurring_time_minute', 'email_count']}
                        data={ data }
                        actions={{ 
                            edit: showPendingEmails,
                            delete: deleteEmailJob
                        }}
                    >
                    </Table>
                </div>
            );
        }
    }

    const createEmailJob = () => {
        context.modal.show({
            title: 'Add Email Job',
            customForm: AddEmailJob
        }, (form, res) => {
            emailJobs.push(res.data);
            setEmailJobs(emailJobs);
            context.notification.show('Email job was created', 'success', true);
        });
    }
    
    return (
        <Animate type='pop'>
            <Card>
                <Grid cols="2">
                    <div>
                        <h1>Email Jobs</h1>
                    </div>
                    <div>
                        <Button 
                            small 
                            text='Add Email Job' 
                            className={Style.addEmailJobBtn}
                            action={ createEmailJob }
                        />
                    </div>
                </Grid>
                {!emailJobs.length &&
                    <p>
                        Create an email job to routinely schedule your emails at a recurring time everyday.
                    </p>
                }
                { getEmailJobs() }
            </Card>
        </Animate>
    );
}
