import React, { Fragment, useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { Button, Card,
    SettingNav, Animate, Grid, Message, ViewContext, useAPI } from 'components/lib';

import { Button as AntButton, List, Popconfirm, Typography } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import Style from './settings.module.scss';

const { Text } = Typography;

export function Locations() {

    const context = useContext(ViewContext);

    const locationData = useAPI('/api/location');

    const [locations, setLocations] = useState(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (locationData && locationData.data && locationData.data.length) {
            setLocations(locationData.data);
        }
    }, [locationData]);

    const createLocation = () => {
        context.modal.show({
    
            title: 'Create Location',
            form: {
                location_num: {
                    label: "Location ID",
                    type: "text",
                    name: "location_num",
                    id: "location_num",
                    required: true
                },
                name: {
                    label: "Name",
                    type: "text",
                    required: true,
                    name: "name",
                    id: "name"
                }
            },
            buttonText: 'Submit',
            url: '/api/location',
            method: 'POST'
      
          }, (form, res) => {
            if(res) {
                const updatedLocations = [...locations, res];
                setLocations(updatedLocations);
            }
          });
      }

      const deleteLocation = async (location) => { 
        try {
            setLoading(true);
            const res = await Axios.delete('/api/location', { data: { id: location.id } });

            if (res.status === 200) {
                const updatedLocations = locations.filter(locationObj => locationObj.id !== location.id);

                setLocations(updatedLocations);
                setLoading(false);
                context.notification.show('Location was removed', 'success', true);
            }          
          }
          catch (err){
            context.handleError(err);
            setLoading(false);
          }
    }

    const renderLocations = (item) => {
        const confirmDelete = async (dealObj, idx) => {
            await deleteLocation(dealObj);
        }

        const actionArr = [];
        const deleteBtn = (
            <Popconfirm
                title="Are you sure you want to delete this location?"
                onConfirm={async () => await confirmDelete(item)}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
            >
                <AntButton icon={<DeleteFilled />} />
            </Popconfirm>
        );

        const inUseText = (<Text italic text="success">Currently assigned to a contract.</Text>);

        actionArr.push((!item.assignedReceiptContracts.length && !item.assignedDeliveryContracts.length) ? deleteBtn : inUseText);

        return (
            <List.Item
                actions={actionArr}
            >
                <ul>
                    <li>
                        <Text>{item.name}</Text><br/>
                        <Text italic>{item.location_num}</Text>
                    </li>
                </ul>
            </List.Item>
        );
      }

    return (
        <Fragment>
          <SettingNav />
          <Animate>

            <Message
                closable
                title='Locations'
                text='These are the locations (points) that are listed for your account. They will be available for selection when creating contracts.'
                type='info'
            />
            <Card
                loading={loading}
            >

                <Grid cols="2">
                    <div></div>
                    <div>
                        <Button 
                            small 
                            className={Style.createLocationBtn} 
                            text='Create Location'
                            action={ createLocation }
                        />
                    </div>
                </Grid>

                {!locationData.loading && !locations &&
                    <p>
                        You currently do not have any locations.
                    </p>
                }

<List
                        loading={locationData.loading}
                        size="small"
                        bordered
                        dataSource={locations || []}
                        renderItem={renderLocations}
                    />
            
            </Card>

            
            
            
          </Animate>
        </Fragment>
      );
}