import React, { useCallback, useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { ViewContext, Label, NumberInput, Select } from 'components/lib';
import Style from './contracts.module.scss';

import moment from 'moment';

import { Alert, Button as AntButton, Col, DatePicker, Divider, Row, Steps, Space, Spin, Typography } from 'antd';

import { IContract, IDeliveryLocation } from 'ts/interfaces/contract';

import 'ts/types/ant-design';

const { Text } = Typography;

const { Step } = Steps;
const { RangePicker } = DatePicker;

export function CreateLinkContract({ primaryContract }: { primaryContract: IContract }) {
    const context = useContext(ViewContext);

    const [loading, setLoading] = useState(false);
    const [current, setCurrent] = useState<number>(0);
    const [currentDate] = useState(moment().format('YYYY-MM-DD'));
    const [subContractList, setSubContractList] = useState<Array<IContract> | undefined>(undefined);
    const [subContractOptions, setSubContractOptions] = useState<Array<{ value: string; label: string; }>>([]);
    const [linkedDateRanges, setLinkedDateRanges] = useState<Array<{[x: string]: string}>>([]);

    const [subContract, setSubContract] = useState<IContract | undefined>(undefined);
    const [startDate, setStartDate] = useState<string | undefined>(undefined);
    const [endDate, setEndDate] = useState<string | undefined>(undefined);

    const [exportQuantities, setExportQuantities] = useState<Array<{[x: string]: number }>>([]);
    const [importQuantities, setImportQuantities] = useState<Array<{[x: string]: number }>>([]);

    const getExportTotal = useCallback(() => {
        return exportQuantities.reduce((sum: number, obj: {[x: string]: number }) => {
            const quantityNum = Object.values(obj)[0];
            return sum + quantityNum;
        }, 0);
    }, [exportQuantities]);

    const getExportQuantity = useCallback((locationId: string) => {
        const eqObj: { [x: string]: number } | undefined = exportQuantities.find((eq) => Object.keys(eq)[0] === locationId);

        return eqObj ? eqObj[locationId] : undefined;
    }, [exportQuantities]);


    useEffect(() => {
        const filterSubContractsWithEmptyDelLocQuantity = (subContracts: Array<IContract>) => {
            return subContracts.filter(sub => {
                const dlBatch = sub.delivery_locations.filter(dl => dl.current_delivery_amount > 0);

                return dlBatch.length > 0;
            })
        }
        const getContractListData = async () => {
            setLoading(true);
            try {
                const res = await Axios.get('/api/contract/none-linked', {
                    params: {
                        contract_id: primaryContract.id,
                        current_date: currentDate
                    }
                });

                if (res.status === 200) {
                    const resData = res.data.data;

                    const validSubs = filterSubContractsWithEmptyDelLocQuantity(resData);

                    setSubContractList(validSubs);
                    setSubContractOptions(prepareSubContractListOptions(validSubs));
                    setLoading(false);
                }
            } catch (err) {
                setLoading(false);
                context.handleError(err);
            }
        }

        if (!subContractOptions.length) {
            getContractListData();
        }
    }, [setSubContractList, setSubContractOptions]);

    useEffect(() => {

        const getLinkedDateRanges = async () => {
            setLoading(true);
            try {
                const res = await Axios.get('/api/contract/link/paired', {
                    params: {
                        primary_contract_id: primaryContract.id,
                        sub_contract_id: subContract?.id,
                        current_date: currentDate
                    }
                });

                if (res.status === 200) {
                    const resData = res.data.data;
                    setLinkedDateRanges(resData);
                    setLoading(false);
                }
            } catch (err) {
                setLoading(false);
                context.handleError(err);
            }
        }

        if(current === 1) {
            // get date ranges
            getLinkedDateRanges();
        }

        if(current === 3) {
            if(subContract?.delivery_locations && subContract.delivery_locations.length === 1) {
                const delLoc = subContract.delivery_locations[0];
                const availableSpace = getImportDelLocAvailableSpace(delLoc);
                const totalExportQuantity = getExportTotal();

                if (totalExportQuantity > availableSpace) {
                    setImportQuantities([]);
                } else {
                    setImportQuantities([{ [delLoc.location_id]: totalExportQuantity }]);
                }
            }
        }
    }, [current, currentDate, getExportTotal, getExportQuantity, primaryContract, subContract, context]);

    useEffect(() => {
        setStartDate(undefined);
        setEndDate(undefined);
        setExportQuantities([]);
        setImportQuantities([]);
    }, [subContract]);

    const validateStep = (current: number) => {
        let result;

        switch (current) {
            case 0:
                result = !subContract ? current : current + 1;
                break;
            case 1:
                result = !startDate || !endDate ? current : current + 1;
                break;
            case 2:
                result = !exportQuantities.length || overExportMax() ? current : current + 1;
                break;
            case 3:
                result = Boolean(!importQuantities.length);
                break;
            default:
                result = current;
                break;
        }

        return (result as unknown) as number;
    }

    const next = () => {
        setCurrent(validateStep(current));
      };
    
    const prev = () => {
        setCurrent(current - 1);
    };

    const prepareSubContractListOptions = (subContractOptions: Array<IContract>) => {
        return subContractOptions.map(scobj => ({ value: scobj.id, label: scobj.contract_num }));
    }

    const getDefaultRange = () => [
        startDate ? moment(startDate) : undefined,
        endDate ? moment(endDate) : undefined
    ];

    const onDateRangeChange = (dateObjs: any, dates: Array<string>) => {
        if(dates.length === 2) {
            setStartDate(dates[0]);
            setEndDate(dates[1]);
        }
    }

    const getImportQuantity = (locationId: string) => {
        const eqObj = importQuantities.find(eq => Object.keys(eq)[0] === locationId);
        return eqObj ? eqObj[locationId] : undefined;
    }

    const getImportTotal = () => {
        return importQuantities.reduce((sum, obj) => {
            const quantityVal = Object.values(obj)[0];
            const amount = quantityVal || 0;
            return sum + amount;
        }, 0);
    }

    const totalImportEqualsExportTotal = () => getImportTotal() === getExportTotal();

    const overExportMax = () => {
        let overMax = false;

        primaryContract.delivery_locations.forEach(dl => {
            exportQuantities.forEach(eq => {
                const eqId = Object.keys(eq)[0];
                const eqQuantity = Object.values(eq)[0];

                if (eqId === dl.location_id && dl.final_quantity < eqQuantity) {
                    overMax = true;
                } 
            });
        });

        return overMax;
    }

    const overImportMax = () => getExportTotal() < getImportTotal();

    const getImportDelLocAvailableSpace = (dl: IDeliveryLocation) => dl.max_quantity - dl.final_quantity;
    const getTotalImportAvailableSpace = () => {
        if (subContract?.is_flex) {
            const totalQuantity = subContract?.delivery_locations.reduce((sum, dl) => dl.final_quantity + sum, 0) || 0;
            return subContract.receipt_location_max_quantity - totalQuantity;
        }

        return subContract?.delivery_locations.reduce((sum, dl) => (dl.max_quantity - dl.final_quantity) + sum, 0) || 0;
    }

    const noLinkableContracts = () => subContractList !== undefined && !subContractList.length;

    const prepareDatePayloadData = (startDate: string | undefined, endDate: string | undefined): { startDate: string, endDate: string} | void => {
        if (!startDate || !endDate) return;
        
        let startDateObj = moment(startDate);
        let endDateObj = moment(endDate);

        startDateObj = startDateObj.startOf('month');
        endDateObj = endDateObj.endOf('month');

        return {
            startDate: startDateObj.toISOString(),
            endDate: endDateObj.toISOString()
        }
    }

    const submit = async () => {

        setLoading(true);

        const dateData = prepareDatePayloadData(startDate, endDate);

        if (!dateData) {
            setLoading(false);
            return;
        }
        
        try {
            const res = await Axios.post('/api/contract/link', { 
                primary_contract_id: primaryContract.id,
                sub_contract_id: subContract?.id,
                start_date: dateData.startDate, 
                end_date: dateData.endDate,
                export_quantity: exportQuantities,
                import_quantity: importQuantities
            });
      
            if (res.status === 200) {
                setLoading(false);
                
                // send the user some where else
                context.modal.hide(false, res.data);
            }          
        } catch (err){
            setLoading(false);
            context.handleError(err);
            context.modal.hide(true);
        }
    }

    const renderDelLocImport = (dl: IDeliveryLocation) => {
        const totalExportQuantity = getExportTotal();

        if (!subContract?.is_flex) {
            const availableSpace = getImportDelLocAvailableSpace(dl);

            return (
                <Row justify="start">
                    <Col span={12}>
                        <Space direction="vertical">
                            <Text>Delivery Location Package Id: {dl.package_id}</Text>
                            <Text>Available Quantity Space: <strong>{availableSpace}</strong></Text>     
                            {totalExportQuantity <= availableSpace && <Text>Incoming Quantity Amount: <strong>{totalExportQuantity}</strong></Text>}                     
                        </Space>
                        {totalExportQuantity > availableSpace && 
                            <Alert
                                message={`There is not enough space on this delivery location to except incoming quantity of ${totalExportQuantity}`}
                                type="error"
                                showIcon
                            />
                        }
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row justify="start">
                    <Col span={12}>
                        <Space direction="vertical">
                            <Text>Delivery Location Package Id: {dl.package_id}</Text>
                            <Text>Incoming Quantity Amount: <strong>{totalExportQuantity}</strong></Text>                     
                        </Space>
                    </Col>
                </Row>
            );
        }

    }

    const renderDelLocImportBatch = (dls: Array<IDeliveryLocation>) => {
        const renderDelLocImportForms = () => {
            return dls.map((dl, index) => {

                if (!subContract?.is_flex) {
                    const availableSpace = getImportDelLocAvailableSpace(dl);

                    return (
                        <Row justify="start">
                            <Col span={12}>
                                <Space direction="vertical">
                                    <Text>Delivery Location Package Id: {dl.package_id}</Text>
                                    <Text>Available Quantity Space: <strong>{availableSpace}</strong></Text>                              
                                </Space>
                                <NumberInput 
                                    placeholder="0"
                                    name="import_quantity"
                                    id="import_quantity"
                                    min={0}
                                    disabled={!availableSpace}
                                    onChange={(id: string, value: string) => {
                                        const newValue = value !== undefined ? parseInt(value) : 0;
                                        const newImports = importQuantities.filter(iq => Object.keys(iq)[0] !== dl.location_id);

                                        setImportQuantities([...newImports, { [dl.location_id]: (availableSpace >= newValue) ? newValue : availableSpace }]);
                                    }}
                                    value={getImportQuantity(dl.location_id)}
                                />
                            </Col>
                        </Row>
                    )
                } else {
                    return (
                        <Row justify="start">
                            <Col span={12}>
                                <Space direction="vertical">
                                    <Text>Delivery Location Package Id: {dl.package_id}</Text>
                                </Space>
                                <NumberInput 
                                    placeholder="0"
                                    name="import_quantity"
                                    id="import_quantity"
                                    min={0}
                                    onChange={(id: string, value: string) => {
                                        const newImportVal = (value as unknown) as number;
                                        const importsBatch = importQuantities.filter(iq => Object.keys(iq)[0] !== dl.location_id);
                                        const newImport = { [dl.location_id]: newImportVal };
                                        setImportQuantities([...importsBatch, newImport]);
                                    }}
                                    value={getImportQuantity(dl.location_id)}
                                />
                            </Col>
                        </Row>
                    )
                }
                
            })
        };

        return (
            <>
                {subContract && getExportTotal() > getTotalImportAvailableSpace() &&
                        <Alert
                            message="There is not enough space left on the delivery location(s) for this contract to except incoming quantity."
                            type="error"
                        />
                }

                {overImportMax() && 
                        <Alert
                            message="The specified quantity amount is over the total quantity available."
                            type="error"
                        />
                }

                {subContract && getTotalImportAvailableSpace() > 0 &&
                        <Row style={{ margin: '20px 0' }}>
                            <Alert
                                message={`The total quantity of ${getExportTotal()} must be transfered.`}
                                type="warning"
                                showIcon
                            />
                        </Row>
                }

                <Text strong>How much quantity do you want to store into the delivery location(s) of linked contract  {subContract?.contract_num}?</Text>

                { renderDelLocImportForms() }

                <Row>
                        <Col span={12}>
                            <Divider />
                            <Text>Current total supplied to delivery locations: {getImportTotal()}</Text>
                        </Col>
                </Row>
            </>
        );
    }

    const steps = [
        {
          title: 'Select Contract To Link',
          content: (
            <div>
                <Spin spinning={loading} delay={500}>
                    <Select
                        customLabel="Select Contract to Link"
                        required
                        name="sub_contract_id"
                        value={subContract?.id}
                        default={subContract?.id}
                        onChange={(id: string, value: string) => {
                            if (subContractList) setSubContract(subContractList.find(sc => sc.id === value));
                        }}
                        options={subContractOptions}
                    >
                    </Select>
                </Spin>
            </div>
          ),
        },
        {
          title: 'Pick Date Range',
          content: (
              <div>
                    <Label text="Select Date Range for the duration of link between the contracts." />
                    <RangePicker 
                        onChange={onDateRangeChange} 
                        defaultValue={startDate && endDate ? [
                            moment(startDate),
                            moment(endDate)
                        ] : undefined}
                        disabledDate={(d): boolean => {
                            if (!subContract) return true;

                            const occupiedDate = linkedDateRanges.find(ldr => {
                                const ldrStart = moment(ldr.start_date).startOf('day');
                                const ldrEnd = moment(ldr.end_date).endOf('day');

                                return Boolean(d.isBetween(ldrStart, ldrEnd));
                            });

                            const validStartDate = moment(primaryContract.start_date).isBefore(subContract.start_date) ? subContract.start_date : primaryContract.start_date; 
                            const validEndDate = moment(primaryContract.end_date).isBefore(subContract.end_date) ? primaryContract.end_date : subContract.end_date; 
                            const outsideValidTimeFrame = d.isBefore(validStartDate) || d.isAfter(validEndDate);

                            return Boolean(occupiedDate || d.isBefore(currentDate) || outsideValidTimeFrame);
                        }}
                        direction="ltr"
                    />
              </div>
          ),
        },
        {
          title: 'Send Amount',
          content: (
              <div>
                <Text strong>How much quantity do you want to transfer from the delivery location(s) of contract {primaryContract.contract_num}?</Text>
                {overExportMax() && 
                    <Alert
                        message="One of the transfer amounts is over it's delivery locations available quantity."
                        type="error"
                    />
                }
                <div className={Style.exportSection}>
                    {
                        primaryContract.delivery_locations.map((dl, index) => (
                            <Row justify="start">
                                <Col span={4}>
                                    <Space direction="vertical">
                                        <Text>{dl.package_id}</Text>
                                        <Text italic>Available Quantity: {dl.final_quantity}</Text>
                                    </Space>
                                </Col>
                                <Col span={4}>
                                <NumberInput 
                                    placeholder="0"
                                    name="export_quantity"
                                    id="export_quantity"
                                    max={dl.final_quantity}
                                    min={0}
                                    onChange={(id: string, value: string) => {
                                        const newExportVal = (value as unknown) as number;
                                        const exportBatch = exportQuantities.filter(eq => Object.keys(eq)[0] !== dl.location_id);
                                        const newExport = { [dl.location_id]: newExportVal };

                                        setExportQuantities([...exportBatch, newExport]);
                                    }}
                                    value={getExportQuantity(dl.location_id)}
                                />
                                </Col>
                            </Row>
                        ))
                    }
                </div>
              </div>
          ),
        },
        {
            title: 'Receive Amount',
            content: (
                <div>
                    {subContract && !getTotalImportAvailableSpace() &&
                        <Alert
                            message="There is no space left on the delivery location(s) for this contract."
                            type="error"
                        />
                    }
                    
                <div className={Style.exportSection}>
                    {subContract && !!getTotalImportAvailableSpace() && subContract.delivery_locations.length === 1 && renderDelLocImport(subContract.delivery_locations[0])}
                    {subContract && !!getTotalImportAvailableSpace() && subContract.delivery_locations.length > 1 && renderDelLocImportBatch(subContract.delivery_locations)}
                </div>
              </div>
            )
        }
      ];

    return (
          <div className={Style.linkContractModal}>
            {noLinkableContracts() &&
                <Alert
                    message="Warning"
                    description="The chosen contract does not have any availabe contracts to link."
                    type="warning"
                    showIcon
                />
            }
            {!noLinkableContracts() &&
                <>
                <Steps progressDot current={current}>
                    {steps.map(item => (
                        <Step key={item.title} title={item.title} />
                    ))}
                </Steps>

                <div className={Style.stepsContent}>{steps[current].content}</div>
                </>
            }
            


            <div className="steps-action">
                {!noLinkableContracts() && current < steps.length - 1 && (
                    <AntButton loading={loading} type="primary" onClick={() => next()}>
                        Next
                    </AntButton>
                )}
                {!noLinkableContracts() && totalImportEqualsExportTotal() && current === steps.length - 1 && (
                    <AntButton 
                        loading={loading} 
                        type="primary"
                        disabled={overImportMax() || !getTotalImportAvailableSpace()}
                        onClick={() => submit()}
                    >
                        Done
                    </AntButton>
                )}
                {!noLinkableContracts() && current > 0 && (
                <AntButton loading={loading} style={{ margin: '0 8px' }} onClick={() => prev()}>
                    Previous
                </AntButton>
                )}

                <AntButton loading={loading} style={{ margin: '0 8px' }} onClick={() => context.modal.hide(false)}>
                    Cancel
                </AntButton>
            </div>
          </div>
      );
}