import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Card, Grid, Select } from 'components/lib';
import Style from './deal-logs.module.scss';

import { DatePicker, Spin } from 'antd';

const { RangePicker } = DatePicker;

export function DealLogFilter({ companies, submit, loading }) {
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const [dealType, setDealType] = useState(undefined);
    const [companyOptions, setCompanyOptions] = useState(undefined);
    const [selectedCompany, setSelectedCompany] = useState(undefined);
    const [isGated, setIsGated] = useState(false);

    useEffect(() => {
        companies.unshift({ label: "All Companies", value: 'unselected' });
        setCompanyOptions(companies);
    }, []);

    const [dealTypeOptions] = useState([
        {
            value: 2,
            label: "Long Term Deals w/ Fixed Price Slots"
        },
        {
            value: 1,
            label: "Long Term Deals w/ No Fixed Price Slots"
        },
        {
            value: 0,
            label: "Spot Deals"
        }
    ]);

    const getDateInput = () => {
        if(dealType === 0) {
            return (
                <div>
                    <RangePicker 
                        onChange={onDateRangeChange} 
                        direction="ltr"
                    />
                </div>
            );
        }

        if(dealType) {
            return (
                <div>
                    <DatePicker 
                        style={{ width: '100%' }}
                        onChange={onDateChange} 
                        direction="ltr"
                    />
                </div>
            );
        }
    }

    const onChangeDealType = (id, value) => {
        if(value) setDealType(parseInt(value));
    }

    const onChangeCompany = (id, value) => {
        setSelectedCompany(value === 'unselected' ? null : value);
    }

    const onDateRangeChange = (dateObjs, dates) => {
        if(dates.length === 2) {
            setStartDate(dates[0]);
            setEndDate(dates[1]);
        }
    }

    const onDateChange = (dateObj, date) => {
        setStartDate(date);
        setEndDate(null);
    }

    const isValid = () => {
        if (!isNaN(parseInt(dealType))) {
            return dealType > 0 ? startDate : startDate && endDate;
        }

        return false;
    }

    return (
        <Card title="Search Filters">
            <Spin spinning={loading}>
                <Grid cols="5">
                    <div>
                        <Select
                            customLabel="Deal Type"
                            name="deal_type"
                            id="deal_type"
                            onChange={onChangeDealType}
                            value={dealType}
                            default={false}
                            options={dealTypeOptions}
                        /> 
                    </div>
                    <div style={{ padding: '14px 0' }}>
                        <Checkbox
                            label="Gated"
                            name="gated"
                            option="Is Gated"
                            index={1}
                            callback={(id, value) => setIsGated(!value) }
                            checked={isGated}
                            value={isGated}
                        />
                    </div>
                    <div>
                        {companyOptions &&
                            <Select
                                customLabel="Company"
                                name="company"
                                id="company"
                                default={companyOptions[0]}
                                onChange={onChangeCompany}
                                value={selectedCompany}
                                options={companyOptions}
                            />
                        }
                    </div>
                    { getDateInput() }
                    <div className={Style.filterBtnWrap}>
                        {isValid() && !loading && 
                            <Button
                                fullWidth
                                small
                                text="Filter"
                                action={ () => {
                                    submit({
                                        startDate,
                                        endDate,
                                        dealType,
                                        selectedCompany,
                                        isGated
                                    });
                                } }
                            />
                        }
                    </div>
                </Grid>
            </Spin>
        </Card>
    );
}