const accountReducer = (state, action) => {
    switch (action.type) {
        case "SET_POINTS":
            return {
                ...state,
                points: action.payload
            }
        case "SET_PIPELINES":
            return {
                ...state,
                pipelines: action.payload,
                hasOnboardData: state.pipelines?.length && state.utilities?.length && state.companies?.length
            }
        case "SET_POSITIONS":
            return {
                ...state,
                postions: action.payload
            }
        case "SET_COMPANIES":
            return {
                ...state,
                companies: action.payload
            }
        case "SET_UTILITIES":
            return {
                ...state,
                utilities: action.payload,
                hasOnboardData: state.pipelines?.length && state.utilities?.length && state.companies?.length
            }
        case "SET_ACCOUNT_DATA":
            return {
                ...state,
                ...action.payload
            }
        case "SET_LOADING":
            return {
                ...state,
                loading: action.payload
            }
        case "SET_ERROR":
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
}

export default accountReducer;