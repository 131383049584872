import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { EditableCell, EditableRow } from '../../components/editableTableSections/editable-table-sections';
import { ViewContext, Button, Grid } from 'components/lib';
import { Table } from 'antd';

import './interruptibles.module.scss';

import moment from 'moment';

export function AddActualFlow({ interruptible }) {
    const context = useContext(ViewContext);
    const [dateOptions, setDateOptions] = useState([]);

    useEffect(() => {

        const getUnfulfilledDates = async () => {
            try {
                const res = await Axios.get('/api/interruptible/daily-flow-unfulfills', {
                    params: {
                        interruptible_id: interruptible.id
                    }   
                });

                if (res.status === 200) {
                    const unfulfills = res.data.data;

                    if (unfulfills.length) {
                        setDateOptions(unfulfills);                        
                    }

                }

            } catch (err) {
                context.handleError(err);
            }
        }

        if (!dateOptions.length) {
            getUnfulfilledDates();
        }

    }, [context, dateOptions, interruptible, setDateOptions]);

    const handleSave = (row, cb) => {

        const newData = [...dateOptions];
        const index = newData.findIndex((item) => row.id === item.id);
        const item = newData[index];

        if (row.actual_flow === item.actual_flow) {
            cb(false);
            return;
        }

        newData.splice(index, 1, {
            ...item,
            ...row,
        });
    
        setDateOptions(newData);
        submit(row, cb);
        
    }
    const submit = async (flowData, cb) => {

        try {
            const res = await Axios.post('/api/interruptible/daily-flow', {
                actual_flows: { [flowData.id]: flowData.actual_flow }
            });
      
            if (res.status === 200) {
                cb(true);
            }          
          }
          catch (err){
            context.handleError(err);
          }
    }

    const renderTable = () => {

        const defaultColumns = [
            {
                title: 'Flow Date',
                dataIndex: 'flow_date',
                sorter: (a, b) => moment(a.flow_date) - moment(b.flow_date)
            },
            {
                title: 'DTHS Flow',
                dataIndex: 'dths_flow',
            },
            {
                title: 'Actual Flow',
                dataIndex: 'actual_flow',
                width: '50%',
                editable: true,
            }
        ];

        const columns = defaultColumns.map((col) => {
            if (!col.editable) {
              return col;
            }
            return {
              ...col,
              onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
              }),
            };
          });

        const components = {
            body: {
              cell: EditableCell,
              row: EditableRow
            },
          };


        return (
            <Table
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dateOptions}
                pagination={{
                    pageSize: 5
                }}
                columns={columns}
            />
        );
    }

    return (
        <div>
            {!dateOptions.length  && 
                <p>This interruptible's flow data is currently up to date.</p>
            }

            <Grid>
                { renderTable() }
                <Button outline text='Close' action={ () => context.modal.hide(true) } />
            </Grid>
        </div>
    );

}