import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import { Animate, ViewContext, Button, Card, Chart, Grid, StatTwo } from 'components/lib';
import { InterruptibleExport } from './interruptible-export';
import { getDayListFromMonth, getLatestDayFromDateList } from 'utilities/date';

import Style from './interruptibles.module.scss';
import moment from 'moment';

export function Interruptible() {
    const context = useContext(ViewContext);
    const { id } = useParams();

    const [interruptible, setInterruptible] = useState(undefined);
    const [currentMonthFlowTotal, setCurrentMonthFlowTotal] = useState(undefined);
    const [wholeFlowTotal, setWholeFlowTotal] = useState(undefined);
    const [monthlyFlowData, setMonthlyFlowData] = useState([]);
    const [monthDailyFlowData, setMonthDailyFlowData] = useState([]);
    const [chartMonthlyFlowData, setChartMonthlyFlowData] = useState(undefined);

    useEffect(() => {
        const getInterruptible = async () => {
            try {
                const res = await Axios.get('/api/interruptible', {  params: { id } });

                if (res.status === 200) {
                    const resData = res.data.data;

                    if (resData.length) {
                        const interruptibleObj = resData[0];
                        interruptibleObj.start_date = moment(interruptibleObj.start_date);
                        interruptibleObj.end_date = moment(interruptibleObj.end_date);

                        setInterruptible(interruptibleObj);                        
                    }

                } 
            } catch (err) {
                context.handleError(err);
            }

        };

        if(!interruptible) getInterruptible();
    }, [context, id, interruptible, setInterruptible]);

    useEffect(() => {
        const getThisMonthDailyFlowData = async () => {
            const res = await Axios.get('/api/interruptible/daily-flow-per-day', {  
                params: { 
                    interruptible_id: interruptible.id,
                    start_date: moment().startOf('month').format('YYYY-MM-DD hh:mm'),
                    end_date: moment().endOf('month').format('YYYY-MM-DD hh:mm')
                } 
            });

            if (res.status === 200) {
                setMonthDailyFlowData(res.data.data);
            }

        };

        const getThisMonthFlowData = async () => {
            try {
                const res = await Axios.get('/api/interruptible/total-flow', {  
                    params: { 
                        interruptible_id: id,
                        start_date: moment().startOf('month').format('YYYY-MM-DD hh:mm'),
                        end_date: moment().endOf('month').format('YYYY-MM-DD hh:mm')
                    } 
                });

                if (res.status === 200) {
                    const resData = res.data.data;

                    if (resData.length) {
                        console.log('the month res - ', resData);
                        setCurrentMonthFlowTotal(resData[0]);                        
                    }

                } 
            } catch (err) {
                context.handleError(err);
            }
        };

        const getAllFlowData = async () => {
            try {
                const res = await Axios.get('/api/interruptible/total-flow', {  
                    params: { 
                        interruptible_id: id,
                        start_date: moment(interruptible.start_date).format('YYYY-MM-DD hh:mm'),
                        end_date: moment(interruptible.end_date).format('YYYY-MM-DD hh:mm')
                    } 
                });

                if (res.status === 200) {
                    const resData = res.data.data;

                    if (resData.length) {
                        console.log('the all res - ', resData);

                        setWholeFlowTotal(resData[0]);                        
                    }

                } 
            } catch (err) {
                context.handleError(err);
            }
        }

        const prepareMonthlyFlowChartData = (mFlowData) => {
            const currentMonthYears = mFlowData
                .sort((a, b) =>  moment(`${b.year}-${b.month}`) - moment(`${a.year}-${a.month}`))
                .map(monthlyFlow => `${monthlyFlow.year}-${String(monthlyFlow.month).length === 1 ? `0${monthlyFlow.month}` : monthlyFlow.month}`);

            const latestCurrentMonthYear = currentMonthYears[currentMonthYears.length - 1];
            const dateList = getDateList();

            for (let dList of dateList) {
                if(dList === latestCurrentMonthYear) break;
                if(currentMonthYears.indexOf(dList) === -1) {
                    const datePair = dList.split('-');

                    mFlowData.push({
                        month: parseInt(datePair[1]),
                        year: parseInt(datePair[0]),
                        total_dths_flow: 0,
                        total_actual_flow: 0
                    });
                }
            }

            return mFlowData.sort((a, b) => (a.year <= b.year) && (a.month < b.month)).reverse();
        }

        const getMonthlyFlowData = async () => {
            try {
                const res = await Axios.get('/api/interruptible/monthly-total-flow', {  
                    params: { 
                        interruptible_id: id,
                        start_date: moment(interruptible.start_date).format('YYYY-MM-DD hh:mm'),
                        end_date: moment(interruptible.end_date).format('YYYY-MM-DD hh:mm')
                    } 
                });

                if (res.status === 200) {
                    const resData = res.data.data;

                    if (resData.length) {
                        setChartMonthlyFlowData(prepareMonthlyFlowChartData(resData));
                        setMonthlyFlowData(resData);                        
                    }

                } 
            } catch (err) {
                context.handleError(err);
            }
        }

        if(interruptible) {
            getThisMonthFlowData();
            getAllFlowData();
            getMonthlyFlowData();
            getThisMonthDailyFlowData();
        }
    }, [id, interruptible, setCurrentMonthFlowTotal, setWholeFlowTotal, setMonthlyFlowData, setMonthDailyFlowData]);

    const getWholeFlowDifference = () => {
        if (!currentMonthFlowTotal) return { num: 0, percent: '0%' };

        const getPercent = (a, b, isBelow) => {
            return `${isBelow ? '-' : ''}${parseInt(((a - b) / a) * 100)}%`;
        }

        const totalDthsFlow = parseInt(currentMonthFlowTotal.total_dths_flow);
        const totalActualFlow = parseInt(currentMonthFlowTotal.total_actual_flow);

        if (totalDthsFlow > totalActualFlow) {
            return { num: totalDthsFlow - totalActualFlow, percent: getPercent(totalDthsFlow, totalActualFlow) };
        }

        if (totalDthsFlow < totalActualFlow) {
            return { num: totalActualFlow - totalDthsFlow, percent: getPercent(totalActualFlow, totalDthsFlow, true) };
        }

        return { num: 0, percent: '0%' };
    }

    const getWholeFlowStatus = () => {
        if (!currentMonthFlowTotal) return '';

        const totalDthsFlow = parseInt(currentMonthFlowTotal.total_dths_flow);
        const totalActualFlow = parseInt(currentMonthFlowTotal.total_actual_flow);

        if (totalDthsFlow > totalActualFlow) {
            return 'long';
        }

        if (totalDthsFlow < totalActualFlow) {
            return 'short';
        }

        return 'equal';
    }

    const getDateList = () => {
        if(interruptible) {
            const dateStart = interruptible.start_date;
            const dateEnd = interruptible.end_date;
            const interim = dateStart.clone();
            const timeValues = [];

            while (dateEnd > interim || interim.format('M') === dateEnd.format('M')) {
                timeValues.push(interim.format('YYYY-MM'));
                interim.add(1,'month');
            }

            return timeValues;
        }

        return [];
    }

    const getMonthDateList = () => {
        if(interruptible) {
            const dateStart = moment().startOf('month');
            const dateEnd = moment().endOf('month');
            const interim = dateStart.clone();
            const timeValues = [];

            while (dateEnd > interim || interim.date() === dateEnd.date()) {
                timeValues.push(interim.format('MM-DD'));
                interim.add(1,'day');
            }

            return timeValues;
        }

        return [];
    }

    const prepareMonthDailyFlowData = (flows) => {
        const dayArr = getDayListFromMonth(flows[0].date);
        const greatestDay = getLatestDayFromDateList(flows);

        const dthsFlowBatch = [];
        const actualFlowBatch = [];

        for (const day of dayArr) {
            const dayNum = day + 1;

            if(dayNum > greatestDay) {
                dthsFlowBatch.push(0);
                actualFlowBatch.push(0);
            } else {
                const matchFlow = flows.filter(flow => moment(flow.date).date() === dayNum);
        
                dthsFlowBatch.push(matchFlow.length ? matchFlow[0].dths_flow : 0);
                actualFlowBatch.push(matchFlow.length ? matchFlow[0].actual_flow : 0);
            }
        }

        return {
            dthsFlows: dthsFlowBatch,
            actualFlows: actualFlowBatch
        };
    }

    const prepareDailyFlowData = () => {
        if (!monthDailyFlowData) return [];
        
        const data = prepareMonthDailyFlowData(monthDailyFlowData);

        return {
            datasets: [
                {
                    data: [...data.actualFlows],
                    label: 'Actual DTHS Flow'
                },
                {
                   data: [...data.dthsFlows],
                   label: 'DTHS Flow'
                }
            ],
            labels: getMonthDateList()
        };
    }

    const prepareChartData = () => {
        if (!monthlyFlowData) return [];
        
        const monthlyDTHSFlows = chartMonthlyFlowData.map(monthlyObj => monthlyObj.total_dths_flow);
        const monthlyActualFlows = chartMonthlyFlowData.map(monthlyObj => monthlyObj.total_actual_flow);

        return {
            datasets: [
                {
                    data: [...monthlyActualFlows],
                    label: 'Actual DTHS Flow'
                },
                {
                   data: [...monthlyDTHSFlows],
                   label: 'DTHS Flow'
                }
            ],
            labels: getDateList()
        };
    }

    const openInterruptibleExportModal = () => {
        context.modal.show({
            title: 'Export Interruptible Monthly Flow',
            customForm: InterruptibleExport,
            formProps: { interruptible }
        }, (form, res) => {

        });
    }

    return (
        <Animate type='pop'>
            {interruptible &&
                <>
                <Card 
                    title={`${interruptible?.utility_name} - ${interruptible?.pipeline_name}`}
                    className={Style.interruptibleInfoSection} 
                    loading={!interruptible}
                >
                    <Grid cols="2">
                        <div>
                            <p><strong>Timeframe:</strong> {moment(interruptible?.start_date).format('LL')} - {moment(interruptible?.end_date).format('LL')}</p>
                            <p><strong>Max Storage:</strong> {interruptible?.maximum_storage}</p>
                            <p><strong>Max Daily Vol:</strong> {interruptible?.maximum_daily_volume}</p>
                        </div>
                        <div>
                            <Button 
                                small 
                                text='Export Interruptible Monthly Flow'
                                className={Style.createInterruptibleBtn} 
                                action={ openInterruptibleExportModal }
                            />
                        </div>
                    </Grid>
                </Card>
                <Grid cols="4">
                    <StatTwo
                        loading={ !currentMonthFlowTotal }
                        value={ currentMonthFlowTotal?.total_dths_flow || 0 }
                        label='this month dths flow'
                        icon='calendar'
                        color='rosybrown'
                    />
                    <StatTwo
                        loading={ !currentMonthFlowTotal }
                        value={ currentMonthFlowTotal?.total_actual_flow || 0 }
                        label='this month actual dths flow'
                        icon='calendar'
                        color='rosybrown'
                    />
                    <StatTwo
                        loading={ !interruptible }
                        value={ interruptible?.current_storage }
                        label='current storage'
                        color='rosybrown'
                    />
                    <StatTwo
                        loading={ !wholeFlowTotal }
                        value={ getWholeFlowDifference().num }
                        label={ getWholeFlowStatus() }
                        change={ getWholeFlowDifference().percent }
                        color='rosybrown'
                    />
                </Grid>
                </>
            }
            {monthDailyFlowData.length > 0 &&
                <Card name='month-daily-flow' title='Interruptible Daily Flow'>
                    <Chart
                    type='line'
                    legend
                    data={ prepareDailyFlowData() }
                    color={['red', 'blue']}
                    />
                </Card>
            }
            {monthlyFlowData.length > 0 &&
                <Card name='monthly-flow' title='Interruptible Monthly Flow'>
                    <Chart
                    type='line'
                    legend
                    data={ prepareChartData() }
                    color={['red', 'blue']}
                    />
                </Card>
            }
        </Animate>
    );
}