import React, { useContext, useState } from 'react';
import Axios from 'axios';
import { ViewContext, Button, Grid, TextInput } from 'components/lib';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import Style from './email-jobs.module.scss';

import { TimePicker } from 'antd';

export function AddEmailJob() {
    const context = useContext(ViewContext);

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(undefined);
    const [hour, setHour] = useState(undefined);
    const [minute, setMinute] = useState(undefined);
    const [recipients, setRecipients] = useState([]);

    const isValid = () => name && hour && minute && recipients.length > 0;

    const submit = async () => {
        setLoading(true);
            try {
                const res = await Axios.post('/api/email-job', { 
                    recipients: recipients.length > 1 ? recipients.join(',') : recipients[0],
                    name: name,
                    recurring_time_hour: hour,
                    recurring_time_minute: minute
                });
          
                if (res.status === 200) {
                    setLoading(false);
                    
                    // send the user some where else
                    context.modal.hide(false, res.data);
                }          
            } catch (err){
                setLoading(false);
                context.handleError(err);
                context.modal.hide(true);
            }
    }

    const onChange = (time, timeString) => {
        setHour(String(time.hour()));
        setMinute(time.minutes() < 10 ? `0${time.minutes()}` : String(time.minutes()));
    } 

    return (
        <div>
            <Grid col="3">
                <div>
                    <TextInput 
                        label="Name"
                        name="email-job-name"
                        required
                        id="email-job-name"
                        onChange={(id, value) => setName(value)}
                        value={name}
                    />
                </div>
                <div>
                    <p>Recurring time that emails will be sent by job everyday.</p>
                    <TimePicker placeholder="Select Time" style={{width: '100%' }} use12Hours format="h:mm a" onChange={onChange} />
                </div>
                <div>
                    <ReactMultiEmail
                        placeholder="Add Receipient Emails"
                        emails={recipients}
                        onChange={(emails) => setRecipients(emails)}
                        validateEmail={email => {
                            return isEmail(email); // return boolean
                        }}
                        getLabel={(email, index, removeEmail) => {
                            return (
                                <div data-tag key={index}>
                                    {email}
                                    <span data-tag-handle onClick={() => removeEmail(index)}>
                                        ×
                                    </span>
                                </div>
                            );
                        }}
                    /> 
                </div>
            </Grid>
            <Grid cols="2">
                {isValid() &&
                    <Button
                        fullWidth
                        loading={ loading }
                        text="Create Email Job"
                        action={ submit }
                    />
                }
                <Button fullWidth outline text='Cancel' action={ () => context.modal.hide(true) } />
            </Grid>
        </div>
    );
}