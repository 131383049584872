/***
*
*   FEATURES
*   Toggle account features
*
**********/

import React, { Fragment, useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { Button, Card, Grid, SettingNav, Animate, Message, Switch, ViewContext, useAPI } from 'components/lib';
import { EmailList } from './email-list';
import { Divider, List, Typography } from 'antd';
import { UpdateEmailList } from './update-email-list';

const { Text, Title } = Typography;

export function Features(){

  // context
  const context = useContext(ViewContext);
  const defaultSettings = useAPI('/api/features');
  const accountDefaultSettings = useAPI('/api/features/account-settings');
  const primaryEmailList = useAPI('/api/features/primary-email-list');

  const [settingsList, setSettingsList] = useState(undefined);

  const [loading, setLoading] = useState(false);
  const [primaryEmailListCount, setPrimaryEmailListCount] = useState(undefined);

  useEffect(() => {

    if (primaryEmailList?.data && primaryEmailListCount === undefined) {
        setPrimaryEmailListCount(primaryEmailList?.data?.length);
    }

  }, [primaryEmailList, primaryEmailListCount]);

  useEffect(() => {
    const triageSettings = () => {
        return defaultSettings.data.map(settingsObj => {
            const accountSettingsData = accountDefaultSettings.data.filter(adSettings => adSettings.default_settings_id === settingsObj.id);
  
            if(accountSettingsData.length) {
                settingsObj.status = accountSettingsData[0].status;
            }
  
            return settingsObj;
        });
    }

    if(!settingsList && defaultSettings && defaultSettings.data && accountDefaultSettings && accountDefaultSettings.data) {
        setSettingsList(triageSettings());
    }
    
  }, [defaultSettings, accountDefaultSettings, setSettingsList, settingsList]);

  const onChangeFeature = async (feature, status) => {

    setLoading(true);
    try {
        const res = await Axios.post('/api/features/account-settings', { 
            status,
            id: feature.id
        });
  
        if (res.status === 200) {
            setLoading(false);
        }          
    } catch (err){
        setLoading(false);
        context.handleError(err);
    }
  }

  const renderFeatureOpts = (item) => {
    
    return (
        <List.Item
            actions={[
                <Switch 
                    default={item.status}
                    name="settings-toggle"
                    onChange={(id, status) => onChangeFeature(item, status)} 
                />
            ]}
        >
            <ul>
                <li>
                    <Title level={5}>{item.name}</Title>
                    <Divider />
                    {item.description &&
                        <Text type="secondary">{item.description}</Text>
                    }
                </li>
            </ul>
        </List.Item>
    );
  }

  const showEmailList = () => {
    context.modal.show({
        title: 'Current Email List',
        customForm: EmailList
    });
  }

  const showUpdateEmailList = () => {
    context.modal.show({
        title: `${primaryEmailListCount ? 'Update' : 'Create'} Email List`,
        customForm: UpdateEmailList
    }, (form, updatedList) => {
        setPrimaryEmailListCount(updatedList.length);
    });
  }

  return (
    <Fragment>
      <SettingNav />
      <Animate type='pop'>        
      
        <Message
            closable
            text='These are current features that are included in the application which you can turn on or off.'
            type='info'
        />

        <Card
          title='Processes'
          restrictWidth>

            <List
                loading={!settingsList || loading}
                size="small"
                bordered
                dataSource={settingsList}
                renderItem={renderFeatureOpts}
            />
          
        </Card>
        
        <Card
            title='Default Email Recipients'
            restrictWidth
        >
            <Grid>
                <p>Create a defualt email list for your company. These receipients will receive all emails that are sent from application.</p>
                <p>An example would be the reports email for nominations.</p>

                <Grid cols="2">
                    <Button text={`${primaryEmailListCount ? 'Update' : 'Create'} Email List`} action={ () => showUpdateEmailList() } />
                    {primaryEmailListCount > 0 && 
                        <Button text="View Email List" action={ () => showEmailList() } />
                    }
                </Grid>
            </Grid>
        </Card>
      </Animate>
    </Fragment>
  );
}
