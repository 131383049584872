import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Axios from 'axios';

import { CreateLinkContract } from './create-link-contract';
import { CreateSplit } from './create-split';
import { DeliveryLocationQuantity } from './delivery-location-quantity';
import { CycleQuantity } from './cycle-quantity';
import { CycleQuantityHistory } from './cycle-quantity-history';
import { getDayListFromMonth, getLatestDayFromDateList } from 'utilities/date';

import { Animate, ViewContext, Card, Chart, Grid, Select, useWindowSize } from 'components/lib';

import { IContract, ICycle, IContractTableData, IDailyAmount, IDeliveryLocation, IDLData, ILinkData, ISplitData } from 'ts/interfaces/contract';
import { KeyPair } from 'ts/interfaces/general';

import Style from './contracts.module.scss';
import moment, { Moment } from 'moment';

import { Button as AntButton, Alert, AutoComplete, Col, DatePicker, Dropdown, Input, Popconfirm, Row, Table, Tag, Typography, Space, Divider } from 'antd';

import 'ts/types/ant-design';

const { Text, Title } = Typography;

export function Contract() {
    const context = useContext(ViewContext);
    const history = useHistory();
    const { id } = useParams<{ id?: string }>();
    const { width } = useWindowSize();
    const dateFormat = 'YYYY-MM-DD';

    const [contract, setContract] = useState<IContract | undefined>(undefined);
    const [currentContractId, setCurrentContractId] = useState<string | undefined>(undefined);
    const [amountLoading, setAmountLoading] = useState<boolean>(false);
    const [delLocOptions, setDelLocOptions] = useState<Array<KeyPair<string, string>> | undefined>(undefined);
    const [selectDelLocAmountToView, setSelectDelLocAmountToView] = useState<KeyPair<string, string> | undefined>(undefined);
    const [dailyAmountData, setDailyAmountData] = useState<IDailyAmount | undefined>(undefined);
    const [cycles, setCycles] = useState<ICycle[]>([]);

    const [options, setOptions] = useState<{ id: string, contract_num: string }[]>([]);
    const [chosenSearchOption, setChosenSearchOption] = useState<{ value: string, label: string} | undefined>(undefined);
    const [searchValue, setSearchValue] = useState('');
    const [autoSearchOpenStatus, setAutoSearchOpenStatus] = useState<boolean | undefined>(undefined);
    const [contractDate, setContractDate] = useState<string>(moment().format(dateFormat));
    const [throttle] = useState(500);


    const getContract = useCallback( async () => {

        const getDelLocOptions = (obj: IContract): Array<KeyPair<string, string>> => {
            return obj.delivery_locations
                .map(dl => ({
                    label: dl.location_num,
                    value: dl.location_id
                }));
        }

        try {
            const res = await Axios.get('/api/contract', {  params: { id, current_date: contractDate } });

            if (res.status === 200) {
                const resData = res.data.data;

                if(resData.length) {
                    setContract(resData[0]);
                    setDelLocOptions(getDelLocOptions(resData[0]));
                } else {
                    history.push({
                        pathname: '/contracts'
                    });
                }

            } 
        } catch (err) {
            context.handleError(err);
        }

    }, [contractDate, setContract, history, id, context]);

    const getContractIds = useCallback( async () => {

        try {
            const res = await Axios.get('/api/contract/contract-num', {  
                params: { 
                    contract_num: searchValue,
                } 
            });

            if (res.status === 200) {
                setOptions(res.data.data);
            }
        } catch (err) {
            context.handleError(err);
        }

        // setOptions()

    }, [searchValue]);

    useEffect(() => {

        if (searchValue) {
            setTimeout(() => { getContractIds() }, throttle);
        }

    }, [searchValue, throttle, getContractIds]);

    useEffect(() => {
        if(currentContractId !== id) {
            setCurrentContractId(id);
            getContract();
        }
    }, [context, currentContractId, history, id, contract, setContract, setCurrentContractId, getContract]);

    useEffect(() => {
        getContract();
    }, [contractDate, getContract]);

    useEffect(() => {
        const getThisMonthDailyLocationAmountData = async () => {

            try {
                setAmountLoading(true);
                const res = await Axios.get('/api/contract/amount', {  
                    params: { 
                        contract_id: contract?.id,
                        receipt_location_id: contract?.receipt_location_id,
                        delivery_location_id: selectDelLocAmountToView?.value,
                        start_date: moment().startOf('month').format('YYYY-MM-DD hh:mm'),
                        end_date: moment().endOf('month').format('YYYY-MM-DD hh:mm')
                    } 
                });
    
                if (res.status === 200) {
                    setDailyAmountData(res.data.data);
                    setAmountLoading(false);
                }
            } catch (err) {
                setAmountLoading(false);
                context.handleError(err);
            }
            

        };

        if (selectDelLocAmountToView) {
            getThisMonthDailyLocationAmountData();
        }
    }, [selectDelLocAmountToView, setDailyAmountData, contract]);

    useEffect(() => {

        const getCycles = async () => {
            try {    
                const res = await Axios.get('/api/contract/cycles');

                if (res.status === 200) {
                    setCycles(res.data.data);
                }
            } catch(err) {
                context.handleError(err);
            } 
        }

        if (!cycles.length) {
            getCycles();
        }
        
    }, []);

    const usedInDailyNom: IDeliveryLocation | undefined = contract ? contract.delivery_locations.find(c => c.last_daily_nom_date) : undefined;
    
    const hasSplits: IDeliveryLocation | undefined = contract ? contract.delivery_locations.find(dl => dl?.current_splits?.length) : undefined;

    const dateIsInBetween = (date: Date, start: Date, end: Date): boolean => moment(date).isBetween(moment(start).startOf('day'), moment(end).endOf('day'))

    const confirmDeleteContract = async (contractObj: IContract) => {
        try {
            const res = await Axios.delete('/api/contract', { data: { id: contractObj?.id } });
      
            if (res.status === 200) {
                history.push({
                    pathname: '/contracts'
                });
            }          
          }
          catch (err){
            context.handleError(err);
          }
    }

    const confirmDeleteLink = async (record: ILinkData) => {
        try {
            const res = await Axios.delete('/api/contract/link', { 
                data: { 
                    id: record.lk_id,
                    primary_contract_id: record.primary_contract_id
                } 
            });
      
            if (res.status === 200) {
                getContract();
            }          
          }
          catch (err){
            context.handleError(err);
          }
    }

    const confirmDeleteSplit = async (record: ISplitData) => {
        try {
            const res = await Axios.delete('/api/contract/split', { 
                data: { 
                    id: record.id,
                    contract_id: record.contract_id 
                } 
            });
      
            if (res.status === 200) {
                getContract();
            }          
          }
          catch (err){
            context.handleError(err);
          }
    }
    
    const menu = (contractObj: IContract) => {
        const emptyQuantity = contractObj.delivery_locations.filter(dl => !dl?.current_delivery_amount);
        const hasEmptyQuantity = contractObj.delivery_locations.length === emptyQuantity?.length;

        return {
            items: [
                { label: 'Update Cycle Amount', key: '1' },
                ...(!hasEmptyQuantity ? [{ label: 'Link Contract', key: '2' }, { label: 'Split Contract', key: '3' }] : []),
            ],
            onClick: (e: any) => {
                const menuEvents: {[x: string]: () => any} = {
                    1: () => openCycleQuantityModal(contractObj),
                    2: () => openCreateContractLinkModal(contractObj),
                    3: () => openCreateSplitModal(contractObj)
                }

                if (menuEvents[e.key]) menuEvents[e.key]();
            }
        };
    };

    const renderActions = (contractObj: IContract) => {
        const emptyQuantity = contractObj.delivery_locations.filter(dl => !dl?.current_delivery_amount);
        const hasEmptyQuantity = contractObj.delivery_locations.length === emptyQuantity?.length;

        return (
        <Space wrap className={Style.createContractBtn}>
            <AntButton.Group>
            <Popconfirm 
                    title="Are you sure？"
                    onConfirm={async () => await confirmDeleteContract(contractObj)}
                    okText="Yes"
                    cancelText="No"
                >
                    <AntButton>Delete Contract</AntButton>
                </Popconfirm>
            {(!usedInDailyNom || !hasEmptyQuantity) && 
                <Dropdown.Button 
                    onClick={() => openDelLocationQuantityModal(contractObj)} 
                    menu={menu(contractObj)}>
                    Update Receipt / Delivery Amount
                </Dropdown.Button>
            }
            </AntButton.Group>
                

            {(usedInDailyNom && hasEmptyQuantity) &&
                <>
                    <AntButton.Group>
                        <AntButton
                            onClick={() => openDelLocationQuantityModal(contractObj)} 
                        >
                            Update Receipt / Delivery Amount
                        </AntButton>
                        <AntButton
                        onClick={() => openCycleQuantityModal(contractObj)} 
                        >
                            Update Cycle Amount
                        </AntButton>
                    </AntButton.Group>
                    
                </>
                
            }
        </Space>
        );
    };

    const openCycleQuantityModal = (contractObj: IContract) => {
        context.modal.show({
            title: `Update Cycle Amount for Contract ${contractObj.contract_num}`,
            customForm: CycleQuantity,
            formProps: {
                contractData: contract
            },
            lgModal: true
        }, () => {
            getContract();
            context.notification.show('Delivery / Reciept cycle quantity has been updated.', 'success', true);
        }); 
    }

    const openCreateContractLinkModal = (contractObj: IContract) => {
        context.modal.show({
            title: `Link Contract - ${contractObj.contract_num}`,
            customForm: CreateLinkContract,
            formProps: {
                primaryContract: contractObj
            },
            lgModal: true
        }, (form: any, res: any) => {
            if (res) {
                getContract();
                context.notification.show('Contract has been linked.', 'success', true);
            }
        });
    } 

    const openCreateSplitModal = (contractObj: IContract) => {
        context.modal.show({
            title: `Split Contract - ${contractObj.contract_num}`,
            customForm: CreateSplit,
            formProps: {
                contract: contractObj
            },
            lgModal: true
        }, (form: any, res: any) => {
            if (res) {
                getContract();
                context.notification.show('Contract has been split.', 'success', true);
            }
        });
    } 

    const openDelLocationQuantityModal = (contractObj: IContract) => {
        context.modal.show({
            title: `Update Amount for Contract ${contractObj.contract_num}`,
            customForm: DeliveryLocationQuantity,
            formProps: {
                contractData: contractObj
            },
            lgModal: true
        }, () => {
            getContract();
            context.notification.show(`${contractObj.contract_num} receipt and delivery amount has been updated.`, 'success', true);
        });
    }

    const renderSplitTable = () => {

        const prepareSplitData = () => {
            const splits: Array<ISplitData> = [];

            contract?.delivery_locations.forEach(dl => {
                if (dl.current_splits.length) {
                    
                    dl.current_splits.forEach(sl => {
                        splits.push({...sl, ...{
                            name: dl.name,
                            last_daily_nom_date: dl.last_daily_nom_date
                        }});
                    });

                }
            });

            return splits;
        }

        const renderAction = (record: ISplitData) => {
            return (
                <Space size="middle">
                    {!dateIsInBetween(record.last_daily_nom_date, record.start_date, record.end_date) &&
                         <Popconfirm 
                         title="Are you sure？"
                         onConfirm={async () => await confirmDeleteSplit(record)}
                         okText="Yes"
                         cancelText="No"
                     >
                         <AntButton>Remove Split</AntButton>
                     </Popconfirm>
                    }  
                </Space>
            );
        }

        const columns = [
            {
                title: 'Splits on Delivery Locations',
                render: (record: ISplitData) => {
                    return (
                        <Fragment>
                            Name: {record.name}
                            <br />
                            Split Amount: {record.split_quantity}
                            <br />
                            Note: {record.note}
                            <br />
                            Start Date: {moment(record.start_date).format('MMM, DD YYYY')}
                            <br />
                            End Date: {moment(record.end_date).format('MMM, DD YYYY')}
                            <Divider />
                            { renderAction(record) }
                        </Fragment>
                    );
                },
                responsive: ["xs"]
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: 200,
                responsive: ["sm"]
            },
            {
                title: 'Split Amount',
                dataIndex: 'split_quantity',
                key: 'split_quantity',
                width: 200,
                responsive: ["sm"]
            },
            {
                title: 'Note',
                dataIndex: 'note',
                key: 'note',
                responsive: ["sm"]
            },
            {
                title: 'Start Date',
                dataIndex: 'start_date',
                key: 'start_date',
                responsive: ["sm"],
                width: 150,
                render: (data: Date) => moment(data).format('MMM, DD YYYY')
            },
            {
                title: 'End Date',
                dataIndex: 'end_date',
                key: 'end_date',
                responsive: ["sm"],
                width: 150,
                render: (data: Date) => moment(data).format('MMM, DD YYYY')
            },
            {
                title: 'Action',
                key: 'action',
                width: 150,
                render: renderAction,
                responsive: ["sm"]
            }
        ];

        const mediumScreenWidth = width && (width < 1000 && width > 574);

        return (
            <Table
                columns={columns as Array<any>}
                dataSource={prepareSplitData()}
                pagination={{ pageSize: 5 }}
                bordered
                scroll={{ x: mediumScreenWidth ? 1300 : undefined }}
            >
            </Table>
        );
    }

    const renderLinkedContractTable = () => {

        let lkData: Array<ILinkData> = [];

        const renderAction = (record: ILinkData) => {
            return (
                <Space size="middle">
                    {!dateIsInBetween(record.last_daily_nom_date, record.lk_start_date, record.lk_end_date) &&
                         <Popconfirm 
                         title="Are you sure？"
                         onConfirm={async () => await confirmDeleteLink(record)}
                         okText="Yes"
                         cancelText="No"
                     >
                         <AntButton>Remove Link</AntButton>
                     </Popconfirm>
                    }  
                </Space>
            );
        }
        
        const columns = [
            {
                title: 'Linked Contract',
                render: (record: ILinkData) => {
                    return (
                        <Fragment>
                            Status: {
                                <>
                                {record.send_quantity && 
                                    <Tag color="green">
                                        SENDING: {record.send_quantity}    
                                    </Tag>
                                }
                                {record.receive_quantity && 
                                    <Tag color="green">
                                        RECEIVING: {record.receive_quantity}    
                                    </Tag>
                                }
                                </>
                            }
                            <br />
                            Linked To: {record.contract_num}
                            <br />
                            Start Date: {moment(record.lk_start_date).format('MMM, DD YYYY')}
                            <br />
                            End Date: {moment(record.lk_end_date).format('MMM, DD YYYY')}
                            <Divider />
                            { renderAction(record) }
                        </Fragment>
                    );
                },
                responsive: ["xs"]
            },
            {
                title: 'Status',
                key: 'status',
                responsive: ["sm"],
                render: (record: ILinkData) => (
                        <>
                        {record.send_quantity && 
                            <Tag color="green">
                                SENDING: {record.send_quantity}    
                            </Tag>
                        }
                        {record.receive_quantity && 
                            <Tag color="green">
                                RECEIVING: {record.receive_quantity}    
                            </Tag>
                        }
                        </>
                    )
            },
            {
                title: 'Linked To',
                dataIndex: 'contract_num',
                width: 200,
                responsive: ["sm"]
            },
            {
                title: 'Start Date',
                dataIndex: 'lk_start_date',
                key: 'lk_start_date',
                responsive: ["sm"],
                width: 150,
                render: (data: Date) => moment(data).format('MMM, DD YYYY')
            },
            {
                title: 'End Date',
                dataIndex: 'lk_start_date',
                key: 'lk_start_date',
                responsive: ["sm"],
                width: 150,
                render: (data: Date) => moment(data).format('MMM, DD YYYY')
            },
            {
                title: 'Action',
                key: 'action',
                width: 150,
                render: renderAction,
                responsive: ["sm"]
            }
        ];

        contract?.linked_contracts.forEach(linkedContract => {
            contract.delivery_locations.forEach(dlObj => {
                if (linkedContract.primary_linked_locations.length) {
                    const primaryTotal = linkedContract.primary_linked_locations.reduce((total, pll) => {
                        const quantity = pll?.quantity || 0;
                        return (pll.location_id === dlObj.location_id) ? total + quantity : total
                    }, 0);

                    if (primaryTotal) {
                        const primaryProps = {
                            send_quantity: primaryTotal,
                            contract_num: linkedContract.sub_contract_num,
                            sub_contract_id: linkedContract.sub_contract_id,
                            primary_contract_id: linkedContract.primary_contract_id,
                            lk_start_date: linkedContract.start_date,
                            lk_end_date: linkedContract.end_date,
                            lk_id: linkedContract.id
                        };

                        lkData.push({...dlObj, ...primaryProps });
                    }
                }

                if (linkedContract.sub_linked_locations.length) {
                    const subTotal = linkedContract.sub_linked_locations.reduce((total, sll) => {
                        const quantity = sll?.additional_quantity || 0;
                        return (sll.location_id === dlObj.location_id) ? total + quantity : total;
                    }, 0);  

                    if (subTotal) {
                        const subProps = {
                            receive_quantity: subTotal,
                            contract_num: linkedContract.sub_contract_num,
                            sub_contract_id: linkedContract.sub_contract_id,
                            primary_contract_id: linkedContract.primary_contract_id,
                            lk_start_date: linkedContract.start_date,
                            lk_end_date: linkedContract.end_date,
                            lk_id: linkedContract.id
                        };
                        

                        lkData.push({...dlObj, ...subProps});
                    }
                }

                return dlObj;
            });

        });

        const mediumScreenWidth = width && (width < 1000 && width > 574);

        return (
            <Table
                columns={columns as Array<any>}
                dataSource={lkData}
                bordered
                scroll={{ x: mediumScreenWidth ? 1300 : undefined }}
            >
            </Table>
        );
    }

    const openCycleModal = (delLocation: IDLData) => {
        context.modal.show({
            title: `Cycles history for ${delLocation.name}`,
            customForm: CycleQuantityHistory,
            formProps: {
                delLocation: delLocation,
                currentDate: contractDate
            },
            lgModal: true
        });  
    }

    const renderDelLocTable = () => {

        const renderAction = (delLocation: IDLData) => {
            return (
                <Space size="middle">
                    <AntButton onClick={() => openCycleModal(delLocation)}>View Cycles</AntButton>
                </Space>
            );
        }

        const columns: Array<any> = [
            {
                title: 'Delivery Locations',
                render: (record: IDLData) => {
                    return (
                        <Fragment>
                            Name: {record.name}
                            <br />
                            Location Num: {record.location_num}
                            <br />
                            Receipt Amount: {record.current_receipt_amount}
                            <br />
                            Max Capacity: {record.max_capacity}
                            <br />
                            Original Quantity: {record.current_delivery_amount}
                            <br />
                            Avialable Quantity: {record.final_quantity}
                            <br />
                            <div>
                                Cycles
                                <Divider />
                                Timely: {(record as any).timely || ''}
                                <br />
                                Evening: {(record as any).evening || ''}
                                <br />
                                ID1: {(record as any).id1 || ''}
                                <br />
                                ID2: {(record as any).id2 || ''}
                                <br />
                                ID3: {(record as any).id3 || ''}
                            </div>
                            <br />
                            Current Link: {
                                <>
                                {record.send_quantity && 
                                    <Tag color="green">
                                    SENDING: {record.send_quantity}    
                                    </Tag>
                                }
                                {record.receive_quantity && 
                                    <Tag color="green">
                                    RECEIVING: {record.receive_quantity}    
                                    </Tag>
                                }
                                </>
                            }
                            <br />
                        </Fragment>
                    );
                },
                responsive: ["xs"]
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                responsive: ["sm"]
            },
            {
                title: 'Location Num',
                dataIndex: 'location_num',
                key: 'location_num',
                responsive: ["sm"]
            },
            {
                title: 'Receipt Amount',
                dataIndex: 'current_receipt_amount',
                key: 'current_receipt_amount',
                responsive: ["sm"]
            },
            {
                title: 'Max Capacity',
                dataIndex: 'max_quantity',
                key: 'max_quantity',
                responsive: ["sm"]
            },
            {
                title: 'Original Quantity',
                dataIndex: 'current_delivery_amount',
                key: 'current_delivery_amount',
                responsive: ["sm"]
            },
            {
                title: 'Avialable Quantity',
                dataIndex: 'final_quantity',
                key: 'final_quantity',
                responsive: ["sm"]
            },
            {
                title: 'Cycles',
                responsive: ["sm"],
                children: [
                    {
                        title: 'Timely',
                        dataIndex: 'timely',
                        key: 'timely',
                        responsive: ["sm"] 
                    },
                    {
                        title: 'Evening',
                        dataIndex: 'evening',
                        key: 'evening',
                        responsive: ["sm"] 
                    },
                    {
                        title: 'ID1',
                        dataIndex: 'id1',
                        key: 'id1',
                        responsive: ["sm"] 
                    },
                    {
                        title: 'ID2',
                        dataIndex: 'id2',
                        key: 'id2',
                        responsive: ["sm"] 
                    },
                    {
                        title: 'ID3',
                        dataIndex: 'id3',
                        key: 'id3',
                        responsive: ["sm"] 
                    }
                ]
            },
            {
                title: 'Current Link',
                key: 'linked',
                responsive: ["sm"],
                render: (record: IDLData) => (
                        <>
                        {record.send_quantity && 
                            <Tag color="green">
                            SENDING: {record.send_quantity}    
                            </Tag>
                        }
                        {record.receive_quantity && 
                            <Tag color="green">
                            RECEIVING: {record.receive_quantity}    
                            </Tag>
                        }
                        </>
                    )
            },
            {
                title: 'Current Split',
                key: 'split',
                responsive: ["sm"],
                render: (record: IDLData) => (
                    <>
                        {record.current_splits && record.current_splits.length > 0 &&
                            <Tag color="yellow">
                            SPLIT: {record.current_splits.reduce((sum, obj) => sum + obj.split_quantity, 0)}    
                            </Tag>
                        }
                    </>
                )
            },
            {
                title: 'Action',
                key: 'action',
                responsive: ["sm"],
                render: renderAction
            }
        ];

        if (contract?.linked_contracts.length) {
            contract.linked_contracts.forEach(linkedContract => {
                contract.delivery_locations = contract.delivery_locations.map((dlObj: IDLData) => {
                    if (linkedContract.primary_linked_locations.length) {
                        const primaryTotal = linkedContract.primary_linked_locations.reduce((total, pll) => {
                            const quantity = pll?.quantity || 0;
                            return (pll.location_id === dlObj.location_id) ? total + quantity : total;
                        }, 0);

                        if (primaryTotal) dlObj.send_quantity = primaryTotal;
                    }

                    if (linkedContract.sub_linked_locations.length) {
                        const subTotal = linkedContract.sub_linked_locations.reduce((total, sll) => {
                            const quantity = sll?.additional_quantity || 0;
                            return (sll.location_id === dlObj.location_id) ? total + quantity : total;
                        }, 0);

                        if (subTotal) dlObj.receive_quantity = subTotal;
                    }

                    return dlObj;
                });

            });
        }

        const mediumScreenWidth = width && (width < 1000 && width > 574);

        const getTableData = () => {
        
            if (contract?.delivery_locations) {
                contract.delivery_locations = contract.delivery_locations.map(del => {
                    let cycleObj: {[x: string]: number} = {};
    
                    del.cycles.forEach(delC => {
                        const indexName = delC.cycle_name.toLowerCase();
                        cycleObj[indexName] = delC.quantity;
                    });
    
                    return { ...del, ...cycleObj };
                });
            }
            
            return contract?.delivery_locations;
        }
        
        return (
            <Table
                columns={columns}
                dataSource={getTableData()}
                bordered
                scroll={{ x: mediumScreenWidth ? 1300 : undefined }}
                summary={() => {
                    let totalSpace = contract?.delivery_locations ? contract.delivery_locations.reduce((sum, dlObj) => dlObj.final_quantity + sum, 0) : 0;

                    if (width && width < 769) {
                        return (
                            <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} colSpan={4}>
                                        Total Available Quantity:
                                        <Text strong>
                                            {totalSpace}
                                        </Text>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </>
                        );
                    }

                    return (
                        <>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={5}>Total Available Quantity</Table.Summary.Cell>
                                <Table.Summary.Cell index={1} colSpan={1}>
                                    <Text>{totalSpace}</Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );

                }}
            >
            </Table>
        );
    }

    const prepareDailyAmountData = () => {
        
        const receiptAmounts = dailyAmountData?.receipt_amounts;
        const deliveryAmounts = dailyAmountData?.delivery_amounts;

        const dayArr = getDayListFromMonth(moment().format('YYYY-MM-DD'));
        const delGreatestDay = getLatestDayFromDateList(deliveryAmounts);
        const receiptGreatestDay = getLatestDayFromDateList(receiptAmounts);
        const greatestDay = delGreatestDay >= receiptGreatestDay ? delGreatestDay : receiptGreatestDay; 

        const receiptAmountBatch = [];
        const deliveryAmountBatch = [];

        for (const day of dayArr) {
            const dayNum = day + 1;

            if(dayNum > greatestDay) {
                receiptAmountBatch.push(0);
                deliveryAmountBatch.push(0);
            } else {
                const matchReceipt = receiptAmounts?.find(r => moment(r.date).date() === dayNum);
                const matchDel = deliveryAmounts?.find(d => moment(d.date).date() === dayNum);

                receiptAmountBatch.push(matchReceipt?.quantity || 0);
                deliveryAmountBatch.push(matchDel?.quantity || 0);
            }
        }

        return {
            receiptAmountData: receiptAmountBatch,
            deliveryAmountData: deliveryAmountBatch
        };

    }

    const getMonthDateList = () => {
        if(dailyAmountData) {
            const dateStart = moment().startOf('month');
            const dateEnd = moment().endOf('month');
            const interim = dateStart.clone();
            const timeValues = [];

            while (dateEnd > interim || interim.date() === dateEnd.date()) {
                timeValues.push(interim.format('MM-DD'));
                interim.add(1,'day');
            }

            return timeValues;
        }

        return [];
    }

    const prepareDailyAmountChartData = () => {
        if (!dailyAmountData) return [];

        const data = prepareDailyAmountData();

        return {
            datasets: [
                {
                    data: [...data.receiptAmountData],
                    label: 'Receipt Location Amount'
                },
                {
                   data: [...data.deliveryAmountData],
                   label: 'Delivery Location Amount'
                }
            ],
            labels: getMonthDateList()
        };
    }

    const renderContractStatusAlerts = () => {
        const renderInvalidSpaceTag = (status: boolean) => {
            const renderMessage = !status ? (
                <>
                   <p>
                       Currently this contract does not have enough space to receive the volume its scheduled to receive.
                       <br />
                       You probably have this contract set to receive volume from another linked contract and / or a delivery amount on one of its delivery locations.
                       <br />
                       Change the delivery amount on of its delivery locations or the linked contract details.
                       <br />
                       Until this is fixed, this contract cannot be used in a daily nomination
                   </p>
                </>
            ) : (
                <>
                    <p>
                        Currently this contract does not have enough volume to send to either a linked contract or split on one of its delivery locations.
                        <br />
                        You may need to increase the volume on one of the delivery locations or change the linked contract / split details.
                        <br />
                        Until this is fixed, this contract cannot be used in a daily nomination.
                    </p>
                </>
            );

            return (
                <Alert
                    message={renderMessage}
                    type="error"
                />
            );
        }

        if (contract) {
            if (contract.available_space < 0) {
                return renderInvalidSpaceTag(false);
            } else if (!contract.is_flex && contract.available_space > contract.total_delivery_max_quantity) {
                return renderInvalidSpaceTag(true);
            }
        }

        return null;
    }

    const renderDailyAmountChart = () => {        
        return (
            <Chart
                type='bar'
                legend
                data={ prepareDailyAmountChartData() }
                color={['red', 'blue']}
            />
        );
    }

    const onChange = (data: string, x: any) => {
        setChosenSearchOption(x);
      };

    const goToContract = () => {
        setAutoSearchOpenStatus(false);
        
        if (chosenSearchOption && chosenSearchOption.value && (currentContractId !== chosenSearchOption.value)) {
            history.push({
                pathname: `/contracts/${chosenSearchOption.value}`
            });
        }
        
    };

    const onContractDateChange =  (date: Moment | null) => {
        if (date) setContractDate(date?.format(dateFormat));
    }

    const prepareOptions = () => options.map((obj: {contract_num: string, id: string}) => ({value: obj.id, label: obj.contract_num}));

    return (
        <Animate type="pop">
            <Card>
                <Row gutter={[16, 16]}>

                    <Col xl={{ span: 8 }} md={{ span: 12 }} xs={24}>
                        <AutoComplete
                            options={prepareOptions()}
                            onChange={onChange}
                            value={chosenSearchOption?.label}
                            onSearch={(text) => {
                                setSearchValue(text);
                            }}
                            open={autoSearchOpenStatus}
                        >
                            <Input.Search 
                                size="large" 
                                style={{ height: '100%' }}
                                placeholder="Search Contract Num"
                                onSearch={goToContract}
                                onBlur={() => setAutoSearchOpenStatus(false)}
                                onClick={(e) => {
                                    setTimeout(() => setAutoSearchOpenStatus(undefined), 500);
                                }}
                            />
                        </AutoComplete>
                    </Col>

                    <Col xl={{ span: 8 }} md={{ span: 12 }} xs={24}>
                        <DatePicker 
                            style={{ width: '100%' }}
                            onChange={onContractDateChange}
                            direction="ltr"
                            value={moment(contractDate)}
                        />
                    </Col>
                </Row>
                
            </Card>
            <Card
                    loading={!contract}
                    className={Style.contractInfoSection}
                >
                    <Grid cols="2">
                            <div>
                                <strong>Contract Num:</strong> {contract?.contract_num}
                            </div>
                            <div>
                                {contract && renderActions(contract) }
                            </div>
                    </Grid>
                    <Row>
                        <Col xl={6} xs={24}>
                            <p><strong>Pipeline:</strong> {contract?.pipeline}</p>
                            <p><strong>Receipt Name:</strong> {contract?.receipt_location_name}</p>
                            <p><strong>Max Delivery Quantity:</strong> {contract?.receipt_location_max_quantity}</p>
                            <p><strong>Timeframe:</strong> {moment(contract?.start_date).format('LL')} - {moment(contract?.end_date).format('LL')}</p>
                            {Boolean(contract?.is_flex) && 
                                <Tag color="green">
                                    FLEX   
                                </Tag>
                            }
                        </Col>
                        <Col xl={18} xs={24}>
                            {contract && renderContractStatusAlerts() }
                        </Col>
                    </Row>
            </Card>

            <Card
                title="Delivery Locations"
                loading={!contract || !cycles.length}
            >
                {contract && renderDelLocTable()}
            </Card>

            {delLocOptions &&
                <Card name='month-daily-flow' loading={amountLoading}>
                    <Row>
                            <Col span={20}>
                                <Title level={4}>Receipt / Delivery Location Amount for the Month</Title>
                            </Col>
                            <Col span={4}>
                                <Select
                                        name="delivery_location"
                                        value={selectDelLocAmountToView}
                                        default={selectDelLocAmountToView?.value}
                                        onChange={(id: string, value: string) => {
                                            setDailyAmountData(undefined);
                                            setSelectDelLocAmountToView(value === 'unselected' ? undefined : delLocOptions.find(dl => dl.value === value))
                                        }}
                                        options={delLocOptions}
                                >
                                </Select>  
                            </Col>
                    </Row>
                    {dailyAmountData &&
                        renderDailyAmountChart()
                    }
                </Card>
            }

            {hasSplits &&
                <Card
                    title="Splits on Delivery Locations"
                >
                    {renderSplitTable()}
                </Card>
            }
            {contract && contract.linked_contracts.length > 0 && 
                <Card
                    title="Linked Contracts"
                    >
                    {renderLinkedContractTable()}
                </Card>
            }

        </Animate>
    );
}
