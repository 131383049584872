import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Axios from 'axios';

import { Animate, ViewContext, Card, Grid } from 'components/lib';
import { Button as AntButton, Dropdown, Menu, Popconfirm, Table, Tag, Space } from 'antd';

import { CreateLongTermDeal } from './create-long-term-deal';
import { AddFixPriceSlot } from './add-fix-price-slot';
import { LongTermDealGaps } from './long-term-deal-gaps';

import { currency } from 'utilities/currency';
import moment from 'moment';

import Style from './new-deal.module.scss';

export function LongTermDeal() {
    const context = useContext(ViewContext);
    const history = useHistory();
    const { id } = useParams();

    const [deal, setDeal] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [fixSlots, setFixSlots] = useState(undefined);

    const getDeal = useCallback(async () => {

        try {
            setLoading(true);
            const res = await Axios.get('/api/deal', {  
                params: { 
                    id,
                    is_long_term: 1,
                    from: moment().toISOString()
                } 
            });

            if (res.status === 200) {
                const resData = res.data.data;

                if(resData.length) {
                    const dealObj = resData.pop()
                    setDeal(dealObj);
                    setFixSlots(dealObj.fix_price_slot_data);
                    setLoading(false);

                } else {
                    history.push({
                        pathname: '/long-term-deals'
                    });
                }

            } 
        } catch (err) {
            context.handleError(err);
        }
    }, [setDeal, history, id, context]);

    useEffect(() => {
        if (!deal && id) getDeal();
    }, [context, history, id, setDeal]);

    const getCurrentFixPriceSlot = () => deal.fix_price_slot_data.filter((fpObj) => fpObj.is_current);

    const getVolumeLeft = () => {
        const currentFixPriceSlots = getCurrentFixPriceSlot();
        const currentVol = currentFixPriceSlots.reduce((sum, obj) => sum + obj.volume, 0);

        return deal?.volume - currentVol;
    }

    const deleteLongTermDeal = async () => {
        try {
            const res = await Axios.delete('/api/deal', { data: { id: deal.id } });
      
            if (res.status === 200) {
                history.push({
                    pathname: '/long-term-deals'
                });
            }          
          }
          catch (err){
            context.handleError(err);
          }
    }

    const updateLongTermDeal = () => {
        context.modal.show({
            title: 'Update Long Term Deal',
            customForm: CreateLongTermDeal,
            formProps: {
                updateDeal: deal
            },
            lgModal: true
        }, (form, res) => {
            if(res.data) {
                getDeal();
            }
        });
    }

    const openAddFixPriceSlotModal = (updateFixPriceSlot) => {
        context.modal.show({
            title: 'Add Fix Price Slot',
            customForm: AddFixPriceSlot,
            formProps: { 
                deal, 
                fixSlots: fixSlots || [],
                updateFixPriceSlot
            },
        }, (form, res) => {
            if(res.data) {
                getDeal();
            }
        });
    }

    const openLongTermDealGapsModal = () => {
        context.modal.show({
            title: 'Available Gaps Within Deal',
            customForm: LongTermDealGaps,
            formProps: { 
                deal, 
                fixSlots: fixSlots || [],
            },
        }, (form, res) => {});
    }

    const cancel = () => {}

    const renderActions = () => {
        const notInUse = moment().isBefore(moment(deal.start_date));
        const expired = moment().isAfter(moment(deal.end_date));

        if (!notInUse && !expired) {
            return (
                <Space wrap className={Style.createContractBtn}>
                    <AntButton 
                        onClick={() => openAddFixPriceSlotModal()}
                    >
                        Add Fix Price Slot
                    </AntButton>
                </Space> 
            );
        }

        if (notInUse && !expired) {
            return (notInUse &&
                <Space wrap className={Style.createContractBtn}>
                    <Dropdown.Button 
                        onClick={() => openAddFixPriceSlotModal()}
                        overlay={menu()}
                    >
                        Add Fix Price Slot
                    </Dropdown.Button>    
                </Space>
            );
        }

        if (!notInUse && expired) {
            return null;
        }
        
        return (
            <Space wrap className={Style.createContractBtn}>
                <Popconfirm
                        title="Are you sure to delete this long term deal?"
                        onConfirm={async () => await confirmDelete()}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
            >
                <AntButton>
                    Delete Long Term Deal
                </AntButton>
            </Popconfirm>
                
            </Space> 
        );

    };

    const confirmDelete = async (dealObj, idx) => {
        await deleteLongTermDeal(dealObj, idx);
    }

    const menu = () => {
        return (
        <Menu>
            <Menu.Item
                onClick={() => updateLongTermDeal()}
            >
                Edit
            </Menu.Item>
            <Popconfirm
                        title="Are you sure to delete this long term deal?"
                        onConfirm={async () => await confirmDelete()}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
            >
                <Menu.Item>
                    Delete
                </Menu.Item>
            </Popconfirm>
        </Menu>
        );
    };

    const renderDealDateRange = () => {
        return `${moment(deal.start_date).format('MMM, DD YYYY')} - ${moment(deal.end_date).format('MMM, DD YYYY')}`;
    }

    const deleteFixPriceSlot = async (fixPriceSlotEntry) => {
        try {
            const res = await Axios.delete('/api/fix-price-slot', { data: { id: fixPriceSlotEntry.id } });
      
            if (res.status === 200) {
                if(res.data) {
                    context.notification.show('Fixed price slot was removed', 'success', true);
                    getDeal();
                }
            }          
          }
          catch (err){
            context.handleError(err);
          }
    }

    const renderFixSlotsTable = () => {

        const renderAction = (record) => {
            return (
                <Space>
                    {record.daily_nom_use === 0 &&
                        <>
                            <Popconfirm 
                                title="Are you sure？"
                                onConfirm={async () => await deleteFixPriceSlot(record)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <AntButton>Remove</AntButton>
                            </Popconfirm>
                            {/*<AntButton onClick={() => openAddFixPriceSlotModal(record)}>Edit</AntButton>*/}
                        </>
                    } 
                    <AntButton onClick={() => openAddFixPriceSlotModal(record)}>Edit</AntButton>
                    {record.daily_nom_use > 0 &&
                        <Tag color="blue">Used in daily nomination.</Tag>
                    } 
                </Space>
            );
        }

        const columns = [
            {
                title: 'Fix Price Slot',
                render: (record, index) => {
                    return (
                        <Fragment>
                            Date Range: {moment(record.start_date).format('MMM, DD YYYY')} - {moment(record.end_date).format('MMM, DD YYYY')}
                            <br />
                            Fixed Price: {currency(record.fixed_price, 4)}
                            <br />
                            Volume: {record.volume}
                        </Fragment>
                    );
                },
                responsive: ["xs"]
            },
            {
                title: 'Date Range',
                key: 'date_range',
                responsive: ["sm"],
                render: (record) => {
                    return(
                    <>
                        {moment(record.start_date).format('MMM, DD YYYY')} - {moment(record.end_date).format('MMM, DD YYYY')}
                    </>
                )}
            },
            {
                title: 'Fixed Price',
                key: 'fixed_price',
                responsive: ["sm"],
                render: (record) => (
                        <>
                            { currency(record.fixed_price, 4) }
                        </>
                    )
            },
            {
                title: 'Volume',
                key: 'volume',
                responsive: ["sm"],
                render: (record) => (
                        <>
                            { record.volume }
                        </>
                    )
            },
            {
                title: 'Action',
                key: 'action',
                width: 150,
                render: renderAction
            }
        ];

        return (
            <Table
                columns={columns}
                dataSource={fixSlots}
                hideOnSinglePage
                bordered
            >
            </Table>
        );
    }

    return (
        <Animate type="pop">
            <Card
                loading={!deal}
                className={Style.dealInfoSection}
            >
                <Grid cols="2">
                    <div>
                        { Boolean(deal?.is_gate) && <Tag color="green">IS GATED</Tag> }
                        <p><strong>Pipeline:</strong> {deal?.pipeline}</p>
                        <p><strong>Point:</strong> {deal?.point}</p>
                        <p><strong>Company:</strong> {deal?.company}</p>
                        { deal?.deal_num && <p><strong>Dean #:</strong> {deal?.deal_num}</p> }
                        <p><strong>Position:</strong> {deal?.position}</p>
                        <p><strong>Total Volume:</strong> {deal?.volume}</p>
                        { deal && <p><strong>Date Range:</strong> { renderDealDateRange() }</p> }
                        {deal?.fix_price_slot_data && deal?.fix_price_slot_data.length > 0 &&  
                            <Space direction='horizontal'>
                            <p><strong>Current Volume Opened:</strong> {getVolumeLeft()}</p>
                            <AntButton 
                                onClick={() => openLongTermDealGapsModal()}
                            >
                                View Date Gaps within Deal
                            </AntButton>
                            </Space>
                        }
                    </div>
                    <div>
                        {deal && renderActions() }
                    </div>
                </Grid>
            </Card>
            <Card
                title="Fix Price Slots"
                loading={loading}
            >
                {fixSlots?.length > 0 ? renderFixSlotsTable() : <p>No fixed price slots exist for this long term deal.</p>}
            </Card>
        </Animate>
    );
}