import React, { Fragment, useContext, useEffect, useState } from 'react';
import Axios from 'axios';

import { useHistory } from "react-router-dom";
import { Animate, ViewContext, Button, Card, Grid, useAPI, useWindowSize } from 'components/lib';
import { CreateInterruptible } from './create-interruptible';
import { AddActualFlow } from './add-actual-flow';
import { ConfirmDeleteInterruptible } from './confirm-delete-interruptible';

import Style from './interruptibles.module.scss';
import moment from 'moment';

import { Dropdown, Menu, Popconfirm, Table, Space, Divider, Typography } from 'antd';

const { Text } = Typography;

export function Interruptibles() {
    const context = useContext(ViewContext);
    const history = useHistory();
    const { width } = useWindowSize();

    const interruptibleData = useAPI('/api/interruptible');

    const [interruptibles, setInterruptibles] = useState([]);

    useEffect(() => {
        if (interruptibleData && interruptibleData.data && interruptibleData.data.length) {
            setInterruptibles(interruptibleData.data);
        }
    }, [interruptibleData]);

    const confirmDelete = async (item) => {
        context.modal.show({
            title: 'Confirm Interruptible Removal',
            customForm: ConfirmDeleteInterruptible,
            formProps: { interruptible: item },
        }, (form, newBatch) => {
            setInterruptibles(newBatch);
            context.notification.show('Interruptible has been removed.', 'success', true);
        });
    }

    const menu = (record) => {
        const expired = moment().isAfter(moment(record.end_date));

        return (
        <Menu>
            {!expired &&
                <>
                    <Menu.Item
                        onClick={() => openFlowModal(record)}
                    >
                        Update Actual Flow
                    </Menu.Item>
                
                    <Menu.Item
                        onClick={() => createInterruptible(record)}
                    >
                        Edit
                    </Menu.Item>
                </>   
            }
            <Menu.Item onClick={() => confirmDelete(record)}>
                Delete
            </Menu.Item>
        </Menu>
        );
    };

    const getInterruptibles = () => {
        if (interruptibles.length) {
            const data = interruptibles.map(interruptible => {
                const sameDates = moment(interruptible.start_date).isSame(interruptible.end_date);

                interruptible.current = moment().isBefore(interruptible.end_date) && moment().isAfter(interruptible.start_date);
                interruptible.date_range = sameDates ? '---' : `${moment.utc(interruptible.start_date).format('MMM, DD YYYY')} - ${moment.utc(interruptible.end_date).format('MMM, DD YYYY')}`

                return interruptible;
            })
            .sort((a, b) => moment(a.start_date) - moment(b.start_date));

            const renderActions = (record, index) => ((
                <Space wrap>
                    <Dropdown.Button 
                        onClick={() => {
                            console.log('record ', record.id);
                            history.push({
                                pathname: `/interruptibles/${record.id}`
                            });
                        }}
                        overlay={menu(record)}
                        >
                            View Interruptible
                    </Dropdown.Button >
                </Space> 
            ));

            const columns = [
                {
                    title: 'Interruptible',
                    render: (record, index) => {
    
                        return (
                            <Fragment>
                                Utility: <Text strong>{record.utility_name}</Text>
                                <br />
                                Pipeline: <Text strong ellipsis>{record.pipeline_name}</Text>
                                <br />
                                Name: <Text strong ellipsis>{record.name}</Text>
                                <br />
                                Max Daily Vol.:<Text strong>{record.maximum_daily_volume}</Text>
                                <br />
                                Max Storage: <Text strong>{record.maximum_storage}</Text>
                                <br />
                                Current Storage: <Text strong>{record.current_storage}</Text>
                                <br />
                                Flow Entry Count: <Text strong>{record.flow_entry_count}</Text>
                                <br />
                                Current: <Text strong>{record.current}</Text>
                                <br />
                                Date Range: <Text strong>{record.date_range}</Text>
                                <Divider />
                                {renderActions(record)} 
                            </Fragment>
                        );
                    },
                    responsive: ["xs"]
                },
                {
                    title: 'Utility',
                    dataIndex: 'utility_name',
                    key: 'utility_name',
                    sorter: (a, b) => a.utility_name.toLowerCase().localeCompare(b.utility_name.toLowerCase()),
                    responsive: ["sm"]
                },
                {
                    title: 'Pipeline',
                    dataIndex: 'pipeline_name',
                    key: 'pipeline_name',
                    sorter: (a, b) => a.pipeline_name.toLowerCase().localeCompare(b.pipeline_name.toLowerCase()),
                    responsive: ["sm"]
                },
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
                    responsive: ["sm"]
                },
                {
                    title: 'Max Daily Vol.',
                    dataIndex: 'maximum_daily_volume',
                    key: 'maximum_daily_volume',
                    sorter: (a, b) => a.maximum_daily_volume - b.maximum_daily_volume,
                    responsive: ["sm"]
                },
                {
                    title: 'Max Storage',
                    dataIndex: 'maximum_storage',
                    key: 'maximum_storage',
                    sorter: (a, b) => a.maximum_storage - b.maximum_storage,
                    responsive: ["sm"]
                },
                {
                    title: 'Current Storage',
                    dataIndex: 'current_storage',
                    key: 'current_storage',
                    sorter: (a, b) => a.current_storage - b.current_storage,
                    responsive: ["sm"]
                },
                {
                    title: 'Flow Entry Count',
                    dataIndex: 'flow_entry_count',
                    key: 'flow_entry_count',
                    sorter: (a, b) => a.flow_entry_count - b.flow_entry_count,
                    responsive: ["sm"]
                },
                {
                    title: 'Current',
                    dataIndex: 'current',
                    key: 'current',
                    sorter: (a, b) => a.current - b.current,
                    responsive: ["sm"]
                },
                {
                    title: 'Date Range',
                    dataIndex: 'date_range',
                    key: 'date_range',
                    sorter: (a, b) => moment(a.start_date) - moment(b.start_date),
                    responsive: ["sm"]
                },
                {
                    title: 'Action',
                    key: 'action',
                    render: renderActions,
                    responsive: ["sm"],
                    width: 200
                  },
              ];

              const mediumScreenWidth = width && (width < 1150 && width > 574);
        
            if (mediumScreenWidth) {
                columns[columns.length - 1]['fixed'] = 'right';
            } else {
                delete columns[columns.length - 1]['fixed'];
            }

            return (
                <Table
                    columns={columns} 
                    dataSource={data} 
                    bordered
                    scroll={{ x: mediumScreenWidth ? 1150 : undefined }}
                >
                </Table>
            );
        }
    }

    const openFlowModal = (interruptible) => {
        context.modal.show({
            title: 'Add Actual Flow to Interruptible',
            customForm: AddActualFlow,
            lgModal: true,
            formProps: { interruptible },
        }, (form, res) => {

        });
    }

    const createInterruptible = (interruptibleObj) => {
        context.modal.show({
            title: 'Create Interruptible',
            customForm: CreateInterruptible,
            formProps: {
                updateInterruptible: interruptibleObj
            },
            lgModal: true
        }, (form, res) => {
            setInterruptibles(res.data);
            context.notification.show('Interruptible was created', 'success', true);
        });
    }

    return (
        <Animate type='pop'>
            <Card>
                <Grid cols="2">
                    <div></div>
                    <div>
                        <Button 
                            small 
                            text='Create Interruptible'
                            className={Style.createInterruptibleBtn} 
                            action={ createInterruptible }
                        />
                    </div>
                </Grid>
                {!interruptibles.length &&
                    <p>
                        You currently do not have any interruptibles.
                    </p>
                }
                { getInterruptibles() }
            </Card>
        </Animate>
    );

}
