import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { ViewContext, Button, Grid, useAPI } from 'components/lib';

import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';

import Style from './settings.module.scss';

export function UpdateEmailList() {
    const context = useContext(ViewContext);
    const primaryEmailList = useAPI('/api/features/primary-email-list');


    const [recipients, setRecipients] = useState(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (primaryEmailList && primaryEmailList.data && primaryEmailList.data.length > 0) {
            setRecipients(primaryEmailList.data);
        }
        
      }, [primaryEmailList]);

    const submitEmailList = async () => {
        try {
            setLoading(true);
            const res = await Axios.post('/api/features/primary-email-list', { 
                emails: recipients
            });
      
            if (res.status === 200) {
                setLoading(false);
                const emailListStr = res.data.data.email_list;
                const emailListArr = emailListStr.split(',');
                context.modal.hide(false, emailListArr)
            }          
        } catch (err){
            setLoading(false);
            context.handleError(err);
            context.modal.hide(true);
        }
    }

    return (
        <>
                        <ReactMultiEmail
                            className={Style.multiEmail}
                            placeholder="Enter Recipient Emails"
                            emails={recipients}
                            onChange={(emails) => setRecipients(emails)}
                            validateEmail={email => {
                                return isEmail(email); // return boolean
                            }}
                            getLabel={(email, index, removeEmail) => {
                                return (
                                <div data-tag key={index}>
                                    {email}
                                    <span data-tag-handle onClick={() => removeEmail(index)}>
                                    ×
                                    </span>
                                </div>
                                );
                            }}
                        />
                        <Grid cols="2">
                            <Button
                                fullWidth
                                loading={ loading }
                                text="Save Email List"
                                action={ submitEmailList }
                            />
                            <Button fullWidth outline text='Close' action={ () => context.modal.hide(true) } />
                        </Grid>
                    </>
    );
}