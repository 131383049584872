/***
*
*   PROFILE
*   Update the user profile or close the account
*
**********/

import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import Axios from 'axios';

import { AccountContext, getPipelines, Animate, ViewContext, usePlans } from 'components/lib';

import { Button as AntButton, List, Typography } from 'antd';
import { EyeOutlined } from '@ant-design/icons';


const { Text } = Typography;


export function Pipelines({ isPremium }){

    const viewContext = useContext(ViewContext);
    const { accountState, accountDispatch } = useContext(AccountContext);
    const [reveals, setReveals] = useState({});

    const prepareData = useCallback(async () => {
        try {
            await getPipelines(accountDispatch, {
                params: {
                    with_settings: 1
                }
            });

        } catch (err) {
            viewContext.handleError(err);
        }
    }, [viewContext, accountDispatch]);



    useEffect(() => {

        if (accountState.pipelines) {
            const revealOpts = accountState.pipelines.reduce((opts, obj) => {
                if (obj.settings) {
                    if (!opts[obj.settings.id]) opts[obj.settings.id] = false;
                }

                return opts;
            }, {});

            setReveals(revealOpts);
        }

    }, [accountState.pipelines]);

    const removePipeline = async (item) => {
        try {
            const res = await Axios.delete('/api/pipeline', { data: { id: item.id } });
      
            if (res.status === 200) {
                viewContext.notification.show('Pipeline was removed', 'success', true);
                await prepareData();
            }          
          }
          catch (err){
            viewContext.handleError(err);
          }
    }

    const openEditPipelineSettings = (item) => {
        const isUpdate = item?.settings?.id;
        const formObj = {
            website_url: {
                label: "Website URL",
                type: "text",
                required: isPremium,
                name: "website_url",
                id: "website_url",
                value: item?.settings?.website_url
            },
            username: {
                label: "User Name",
                type: "text",
                required: isPremium,
                name: "username",
                id: "username",
                value: item?.settings?.username
            },
            password: {
                label: "Password",
                type: "text",
                required: isPremium,
                name: "password",
                id: "password",
                value: item?.settings?.password
            },
            pipeline_id: {
                type: "hidden",
                name: "pipeline_id",
                id: "pipeline_id",
                value: item?.id
            }
        };

        if (isUpdate) {
            formObj['id'] = {
                type: "hidden",
                name: "id",
                id: "id",
                value: item?.settings?.id
            };
        }

        viewContext.modal.show({

            title: 'Edit Pipeline Information',
            form: formObj,
            buttonText: 'Submit',
            url: '/api/pipeline/account-setting',
            method: isUpdate ? 'PATCH' : 'POST'
      
          }, () => {
            prepareData();
          });
    }

    const openCreatPipelineModal = () => {
        viewContext.modal.show({

            title: 'Create Pipeline',
            form: {
                name: {
                    label: "Name",
                    type: "text",
                    required: true,
                    name: "name",
                    id: "name"
                },
                website_url: {
                    label: "Website URL",
                    type: "text",
                    required: isPremium,
                    name: "website_url",
                    id: "website_url"
                },
                username: {
                    label: "User Name",
                    type: "text",
                    required: isPremium,
                    name: "username",
                    id: "username"
                },
                password: {
                    label: "Password",
                    type: "text",
                    required: isPremium,
                    name: "password",
                    id: "password"
                }
            },
            buttonText: 'Submit',
            url: '/api/pipeline',
            method: 'POST'
      
          }, () => {
            prepareData();
          });
    }

    const renderPipelines = (item) => {

        return (
            <List.Item
                actions={[
                    <AntButton 
                        key="pipeline-setting-edit" 
                        onClick={() => openEditPipelineSettings(item)}>
                            Edit Details
                    </AntButton>,
                    <AntButton 
                        key="pipeline-editt" 
                        onClick={async() => await removePipeline(item)}>
                            Delete
                    </AntButton>
                ]}
            >
                <ul>
                    <li>
                        <Text>{item.name}</Text>
                    </li>
                </ul>
            </List.Item>
        );
    }

    return (
        <Fragment>
          <Animate>

            <>
                <AntButton
                    style={{ margin: '10px 0' }}
                    size="middle"
                    onClick={() => openCreatPipelineModal() }
                >
                    Create Pipeline
                </AntButton>

                {accountState.pipelines?.length > 0 && 
                    <List
                        size="small"
                        bordered
                        dataSource={accountState.pipelines}
                        renderItem={renderPipelines}
                    />
                }
                
            </>
          </Animate>
        </Fragment>
      );
}