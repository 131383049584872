import React, { useContext, useState } from 'react';
import Axios from 'axios';
import { AccountContext, Button, Grid, Label, NumberInput, Select, Switch, TextInput, ViewContext } from 'components/lib';
import moment from 'moment';

import { Alert, Typography } from 'antd';
const { Text } = Typography;

export function UpdateLostUnaccounted({ pipeline }) {
    const context = useContext(ViewContext);

    const [unaccountedAmount, setUnaccountedAmount] = useState(pipeline?.unaccounted_amount);
    const [loading, setLoading] = useState(false);

    const submit = async () => {
        setLoading(true);

        try {
            const res = await Axios.post('/api/pipeline/unaccounted-amount', {
                unaccounted_amount: unaccountedAmount,
                pipeline_id: pipeline.id
            });

            if (res.status === 200) {
                setLoading(false);
                context.modal.hide(false, res.data);
            }

        }
        catch (err) {
            setLoading(false);
            context.handleError(err);
            context.modal.hide(true);
        }
    }

    return (
        <div>
            <Grid>
                <Alert
                    message={
                        <>
                        <p>
                            This number is used with the pipeline lost / accounted formula to determine the final volume amount delivered to a delivery location on this pipeline."
                        </p>
                        <p>
                            When setting the delivery / receipt amount for a contract this formula will generate the delivery amount.
                        </p>
                        </>
                    }
                    type="info"
                />
            </Grid>
            <Grid>
                <Text strong>{pipeline.name}</Text>
                <NumberInput 
                    label="Unaccounted Amount"
                    required
                    isFloat
                    placeholder="0.00"
                    name="unaccounted_amount"
                    id="unaccounted_amount"
                    decimalLimit={4}
                    onChange={(id, value) => {                        
                        setUnaccountedAmount(value);
                    }}                
                    value={unaccountedAmount}
                />
            </Grid>
            
            <Grid>
                {unaccountedAmount &&
                    <Button
                        fullWidth
                        loading={ loading }
                        text="Submit"
                        action={ submit }
                    />
                }
                <Button fullWidth outline text='Cancel' action={ () => context.modal.hide(true) } />
            </Grid>
        </div>
    );
}
