/***
*
*   STAT
*   Statistic value with optional icon and -/+ change value
*
*   PROPS
*   value:  numeric value
*   label: string
*   loading: boolean to toggle loading animation (optional)
*   icon: icon to use (optional)
*   change: positive/negative number indicating change amount (optional)
*
**********/

import React from 'react'
import ClassNames from 'classnames';
import { Card, Icon, Loader } from 'components/lib';
import Style from './stattwo.module.scss';

export function StatTwo(props){

  const colorMap = {
    'rosybrown': {
      borderColor: 'rosyBrownBorder',
      iconWrapBgColor: 'rosyBrownBg',
      iconColor: 'rosyBrownIcon'
    },
    'shellblue': {
      borderColor: 'shellBlueBorder',
      iconWrapBgColor: 'shellBlueBg',
      iconColor: 'shellBlueIcon'
    },
    'darkblue': {
      borderColor: 'darkBlueBorder',
      iconWrapBgColor: 'darkBlueBg',
      iconColor: 'darkBlueIcon'
    },
    'orange': {
      borderColor: 'orangeBorder',
      iconWrapBgColor: 'orangeBg',
      iconColor: 'orangeIcon'
    }
  }

  // style
  const changeCss = ClassNames([

    Style.change,
    props.change?.toString().includes('-') ? Style.down : Style.up,

  ])

  const borderStyles = ClassNames([
    Style.wrapper,
    Style[colorMap[props.color]['borderColor']]
  ]);

  const iconWrapStyles = ClassNames([
    Style.iconWrap,
    Style[colorMap[props.color]['iconWrapBgColor']]
  ]);

  const iconStyles = ClassNames([
    Style.icon,
    //Style[colorMap[props.color]['iconColor']]
  ]);

  // is loading
  if (props.loading || props.value === undefined){
    return (
      <Card>
        <div className={ Style.stat }>
          <Loader />
        </div>
      </Card>
    );
  }

  return(
    <Card className={ borderStyles }>
      <div className={ Style.statTwo }>

        { props.icon &&
          <div className={ iconWrapStyles }>
            <Icon
              color='#000'
              image={ props.icon }
              size={ 30 }
              className={ iconStyles }
            />
          </div>
        }

        <div className={Style.content}>
          <div className={ Style.value }>{ props.value }</div>
          <div className={ Style.label }>{ props.label }</div>
        </div>

        { props.change &&
          <div className={ changeCss }>
          { props.change }
          </div>
        }

      </div>
    </Card>
  );
}
