import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Axios from 'axios';
import { Animate, ViewContext, Card, Message, Switch } from 'components/lib';

import { List, Typography } from 'antd';

const { Text, Title } = Typography;

export function MasterAccountFeatureSettings(props){
    const context = useContext(ViewContext);

    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [features, setFeatures] = useState(undefined);

    useEffect(() => {
        const getFeatures = async () => {
            try {
                const res = await Axios.get('/api/master/account-features', { 
                    params: { id }
                });
          
                if (res.status === 200) {
                    setFeatures(res.data.data);
                    setLoading(false);
                }          
            } catch (err){
                setLoading(false);
                context.handleError(err);
            }
        };

        if(!features) {
            getFeatures();
        }

    }, [features, setFeatures]);

    const onChangeFeature = async (feature, status) => {

        setLoading(true);
        try {
            const res = await Axios.post('/api/master/account-features', { 
                status,
                feature_id: feature.id,
                account_id: id
            });
      
            if (res.status === 200) {
                setLoading(false);
            }          
        } catch (err){
            setLoading(false);
            context.handleError(err);
        }
    }

    const renderFeatureOpts = (item) => {
        return (
            <List.Item
                actions={[
                    <Switch 
                        default={item.status}
                        name="settings-toggle"
                        onChange={(id, status) => onChangeFeature(item, status)} 
                    />
                ]}
            >
                <ul>
                    <li>
                        <Title level={5}>{item.name}</Title>
                        {item.description &&
                            <Text type="secondary">{item.description}</Text>
                        }
                    </li>
                </ul>
            </List.Item>
        );
    }

    return (
        <Fragment>
            <Animate>
            <Message
                text='Feature flag settings for account'
                type='info'
            />            
            
            <Card
                title='Feature Flags'
                restrictWidth>

                <List
                    loading={!features || loading}
                    size="small"
                    bordered
                    dataSource={features}
                    renderItem={renderFeatureOpts}
                />
            
            </Card>
            </Animate>
        </Fragment>
    );
}