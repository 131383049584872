import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { ViewContext, Button, Grid, Label, NumberInput } from 'components/lib';
import moment from 'moment';
import Style from './deal-logs.module.scss';

import { currency } from 'utilities/currency';
import { getDateGapPairs } from 'utilities/date';


import { Alert, DatePicker, List, Space, Typography } from 'antd';

const { Text } = Typography;
const { RangePicker } = DatePicker;

export function AddFixPriceSlot({ deal, fixSlots, updateFixPriceSlot }) {
    const context = useContext(ViewContext);

    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(updateFixPriceSlot?.start_date);
    const [endDate, setEndDate] = useState(updateFixPriceSlot?.end_date);
    const [fixPrice, setFixPrice] = useState(updateFixPriceSlot?.fixed_price);
    const [midPoint, setMidPoint] = useState(updateFixPriceSlot?.midpoint);
    const [fixVolume, setFixVolume] = useState(updateFixPriceSlot?.volume || deal.volume);
    const [gapPairs, setGapPairs] = useState([]);
    const [fixedVolumeInvalid, setFixedVolumeInvalid] = useState(false);

    const prepareListData = () => {
        return gapPairs.map(pair => {
        
            return {
                title: `${moment(pair[0]).format('MMM, DD YYYY')} - ${moment(pair[1]).format('MMM, DD YYYY')}`,
            }
        });
    }

    useEffect(() => {

        setGapPairs(getDateGapPairs(deal.start_date, deal.end_date, fixSlots));

    }, [fixSlots, deal.start_date, deal.end_date]);

    useEffect(() => {

        if (fixVolume > deal.volume) {
            setFixedVolumeInvalid(true);
        } else if (fixedVolumeInvalid) {
            setFixedVolumeInvalid(false);
        }

    }, [fixVolume, fixedVolumeInvalid, setFixedVolumeInvalid, deal]);

    const isValid = () => startDate && endDate && fixPrice > 0 && !fixedVolumeInvalid;

    const prepareDatePayloadData = (startDate, endDate) => {
        if (!startDate || !endDate) return;
        
        let startDateObj = moment(startDate);
        let endDateObj = moment(endDate);

        startDateObj = startDateObj.startOf('day');
        endDateObj = endDateObj.startOf('day');

        return {
            startDate: startDateObj.toISOString(),
            endDate: endDateObj.toISOString()
        }
    }

    const submitNew = async () => {
        setLoading(true);

        const dateData = prepareDatePayloadData(startDate, endDate);

        try {
            const res = await Axios.post('/api/fix-price-slot', { 
                deal_id: deal.id,
                start_date: dateData.startDate, 
                end_date: dateData.endDate,
                midpoint: midPoint,
                volume: fixVolume
            });
      
            if (res.status === 200) {
                setLoading(false);
                
                // send the user some where else
                context.modal.hide(false, res.data);
            }          
        } catch (err){
            setLoading(false);
            context.handleError(err);
            context.modal.hide(true);
        }
    }

    const submitUpdate = async () => {
        setLoading(true);

        const dateData = prepareDatePayloadData(startDate, endDate);

        try {
            const res = await Axios.patch('/api/fix-price-slot', { 
                id: updateFixPriceSlot.id,
                start_date: dateData.startDate, 
                end_date: dateData.endDate,
                midpoint: midPoint,
                volume: fixVolume
            });
      
            if (res.status === 200) {
                setLoading(false);
                
                // send the user some where else
                context.modal.hide(false, res.data);
            }          
        } catch (err){
            setLoading(false);
            context.handleError(err);
            context.modal.hide(true);
        }
    }

    const submit = async () => {
        if (updateFixPriceSlot) {
            submitUpdate();
        } else {
            submitNew();
        }
    }

    const onDateRangeChange = (dateObjs, dates) => {
        if(dates.length === 2) {
            setStartDate(dates[0]);
            setEndDate(dates[1]);
        }
    }
    const getDefaultRange = () => updateFixPriceSlot ? [moment(updateFixPriceSlot.start_date), moment(updateFixPriceSlot.end_date)] : undefined;

    
    return (
        <div className={Style.addFixPriceSlotModal}>
            {!updateFixPriceSlot && fixSlots.length > 0 && gapPairs.length > 0 &&
                <List
                    header={<div>Available Gaps Within Deal</div>}
                    itemLayout="horizontal"
                    dataSource={prepareListData()}
                    renderItem={item => (
                    <List.Item>
                        <List.Item.Meta
                        title={item.title}
                        />
                    </List.Item>
                    )}
                />
            }
            <Grid>
                <div>
                    <Label text="Select Date Range" />
                    <RangePicker 
                        style={{ width: '100%' }}
                        onChange={onDateRangeChange} 
                        disabledDate={d => !d || !d.isBetween(moment(deal.start_date).startOf('day'), moment(deal.end_date).endOf('day')) /*|| fixSlots.filter(f => d.isBetween(moment(f.start_date).startOf('day'), moment(f.end_date).endOf('day'))).length*/ } 
                        direction="ltr"
                        defaultValue={ getDefaultRange()}
                    />
                </div>
                <div>
                    <NumberInput 
                        label="Midpoint"
                        required
                        isFloat
                        placeholder="0.00"
                        name="midpoint"
                        id="midpoint"
                        decimalLimit={4}
                        onChange={(id, value) => {
                            const getFixedPrice = (value) => {
                                const newPrice = value + deal.base_price;
                                return newPrice < 0 ? 0 : newPrice;
                            }

                            setMidPoint(value);
                            setFixPrice(value ? getFixedPrice(value) : undefined);
                        }}
                        value={midPoint}
                    />
                </div>
                <div>
                    <NumberInput 
                        label="Fixed Volume"
                        required
                        placeholder="0"
                        name="fixVolume"
                        id="fixVolume"
                        onChange={(id, value) => setFixVolume(value)}
                        value={fixVolume}
                    />
                </div>

                <div className={Style.totalWrap}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Alert
                            message={(<Text>Fix Price: {fixPrice > -1 && <strong>{ currency(fixPrice, 4) }</strong> }</Text>)}
                            type="info"
                        />

                        <Alert
                            message={(<Text>Total Deal Volume: {deal.volume}</Text>)}
                            type="info"
                        />
                    </Space>
                </div>
                
                {fixedVolumeInvalid &&
                    <Alert
                        message="The fixed volume cannot be greater than the total volume on deal."
                        type="error"
                    />
                }
            </Grid>
            <Grid cols="2">
                {isValid() &&
                    <Button
                        fullWidth
                        loading={ loading }
                        text="Add Fix Price Slot"
                        action={ submit }
                    />
                }
                <Button fullWidth outline text='Cancel' action={ () => context.modal.hide(true) } />
            </Grid>
        </div>
    );
}