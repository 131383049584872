import moment from 'moment';

const getDayListFromMonth = date => {
    const daysInMonth = moment.utc(date).daysInMonth();
    return [...Array(daysInMonth).keys()];
}

const getLatestDayFromDateList = batch => batch.reduce((day, obj) => {
    const currDate = moment.utc(obj.date).date();
    return currDate > day ? currDate : day; 
}, 0);

const isLastDayOfMonth = date => {
    const dateObj = moment.isMoment(date) ? date.clone() : moment(date).clone();
    const newDateObj = dateObj.clone().add(1, 'day');

    return dateObj.month() !== newDateObj.month();
}

const isFirstDayOfMonth = date => {
    const dateObj = moment.isMoment(date) ? date.clone() : moment(date).clone();
    const newDateObj = dateObj.clone().subtract(1, 'day');

    return dateObj.month() !== newDateObj.month();
}

const previousEndOfDay = date => {
    const dateObj = moment.isMoment(date) ? date.clone() : moment(date).clone();

    return dateObj.clone().subtract(1, 'day');
}

const setToLastDayOfMonth = date => {
    const dateObj = moment.isMoment(date) ? date.clone() : moment(date).clone();

    const isLastDay = isLastDayOfMonth(date);
    return isLastDay ? dateObj : dateObj.subtract(1, 'day');
}

const setToFirstDayOfMonth = date => {
    const dateObj = moment.isMoment(date) ? date.clone() : moment(date).clone();

    const isLastDay = isFirstDayOfMonth(date);
    return isLastDay ? dateObj : dateObj.add(1, 'day');
}

const getDateBatch = (dateData, startDate, endDate) => {
    const arr = dateData
        .sort((a, b) => moment(a.start_date) - moment(b.start_date))
        .reduce((batch, curr) => {

            batch.push(curr.start_date);
            batch.push(curr.end_date);

        return batch;
    }, []);

    arr.unshift(startDate);
    arr.push(endDate);

    return arr;
}

const getDateGapPairs = (startDate, endDate, fixPriceSlots) => {
    let datePair = [];
    let datePairs = [];
    let dateBatch = getDateBatch(fixPriceSlots, startDate, endDate);


    const pairUp = (obj, index) => {

        datePair.push(obj);

        if(datePair.length === 2) {
            datePairs.push(datePair);
            datePair = [];
        } else if(datePairs.length === (index + 1)) {
            datePairs.push(datePair);
        }

    }
    
    dateBatch.forEach(pairUp);

    const gapBatch = datePairs.reduce((batch, pair, index) => {
        const startD = pair[0];
        const endD = pair[1];

        const startM = moment(startD).format('YYYY-MM-DD');
        const endM = moment(endD).format('YYYY-MM-DD');
        const isNextDay = moment(startM).add(1, 'day').isSame(endM, 'day');

        console.log('date pair - ', startD, endD);
        console.log('moment pair - ', startM, endM);

        const isEndGap = moment(startD).isAfter(endD, 'day');
        console.log('isEndGap - ', isEndGap);

        if(!moment(startM).isSame(endM, 'day') && !isNextDay && !isEndGap) {
            batch.push(index ? setToFirstDayOfMonth(startD).toISOString() : startD);
            batch.push(setToLastDayOfMonth(endD).toISOString());
        }

        return batch;
    }, []);

    datePair = [];
    datePairs = [];

    gapBatch.forEach(pairUp);

    return datePairs;
} 

export {
    getDateGapPairs,
    getDayListFromMonth,
    getLatestDayFromDateList,
    isLastDayOfMonth,
    previousEndOfDay,
    setToLastDayOfMonth
}