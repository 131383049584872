import React, { useCallback, useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { ViewContext, Button, Grid, Fieldset, Label, Select } from 'components/lib';
import Style from './daily-nomination.module.scss';

import { Spin, Alert, Table } from 'antd';
import moment from 'moment';

export function CreatePipelineRecord({ pipelines, utilityId, chosenDate }) {
    const context = useContext(ViewContext);

    const [loading, setLoading] = useState(false);
    const [currentDate] = useState(moment().format('YYYY-MM-DD'));

    const [selectedPipeline, setSelectedPipeline] = useState(undefined);
    const [contracts, setContracts] = useState([]);
    const [delLocs, setDelLocs] = useState([]);

    const [noContractsAvail, setNoContractsAvail] = useState(false);

    const [selectedContract, setSelectedContract] = useState(undefined);
    const [selectedDelLocs, setSelectedDelLocs] = useState([]);

    const [selectAllContracts, setSelectAllContracts] = useState(false);

    const getContracts = useCallback(async () => {
        setLoading(true);

        try {
            const res = await Axios.get('/api/contract', {
                params: {
                    pipeline_id: selectedPipeline.value,
                    utility_id: utilityId,
                    omit_invalid_space_contracts: true,
                    time_filter_type: 1,
                    current_date: chosenDate
                }
            });

            if (res.status === 200) {

                setNoContractsAvail(!res.data.data.length);
                setContracts(!res.data.data.length ? undefined : res.data.data);

                return res.data.data;
            }
        } catch (err) {
            setLoading(false);
            context.handleError(err);
        }
    }, [selectedPipeline, context]);

    useEffect(() => {

        if (selectAllContracts && selectedPipeline && contracts?.length) {
            const delLocsBatch = contracts.reduce((batch, con) => { 
                batch = [...batch, ...con.delivery_locations];
                return batch;
            }, []);

            setDelLocs(delLocsBatch);
        } else if (!selectAllContracts && selectedContract && selectedPipeline) {
            setDelLocs(selectedContract.delivery_locations);
        }


    }, [contracts, selectedContract, selectAllContracts, selectedPipeline]);

    useEffect(() => {

        const prepareContracts = async () => {
            await getContracts();
            setLoading(false);
        };

        if (!contracts) {
            setSelectedContract(undefined);
            setSelectedDelLocs([]);
            prepareContracts();
        }

    }, [selectedPipeline, contracts, getContracts]);

    const getContractOptions = (batch) => {
        return batch.map(b => ({
            label: b.contract_num,
            value: b.id
        }));
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {

          const delLocsBatch = selectedRows.map(obj => {
              const keyArr = obj.key.split('~');
              const contractId = keyArr[0];
              const locationId = keyArr[1];              
              const match = delLocs.find(delLocObj => delLocObj.contract_id === contractId && delLocObj.location_id === locationId);

              return match;
          });

          setSelectedDelLocs([...delLocsBatch]);
        },
        getCheckboxProps: (record) => ({
            disabled: !record.final_quantity
          })
    };
    
    const renderDelLocationOptions = () => {

        const columns = [
            {
              title: 'Location Num',
              dataIndex: 'location_num'
            },
            {
              title: 'Available Volume',
              dataIndex: 'final_quantity',
              render: (data) => {
                  return (!data) ? 'No volume on this delivery location' : data
              }
            }
          ];

        const data = delLocs.map(dl => ({
            key: `${dl.contract_id}~${dl.location_id}`,
            location_num: dl.location_num,
            final_quantity: dl.final_quantity
          }));

        return (
            <Table
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                columns={columns}
                dataSource={data}
                pagination={false}
            />
        );
    }

    const isValid = () => {
        return (selectedContract || selectAllContracts) && selectedDelLocs.length > 0;
    }

    const submit = () => {

        const pipelineRecords = selectedDelLocs.map(dl => {

            return {
                utility_id: utilityId,
                pipeline_id: selectedPipeline.value,
                pipeline_name: selectedPipeline.label,
                contract_id: dl.contract_id,
                contract_num: contracts.find(c => c.id === dl.contract_id)["contract_num"],
                quantity: dl.final_quantity,
                location_id: dl.location_id,
                location: dl.location_num
            }
        });

        context.modal.hide(false, { pipelineRecords });

    }

    const allSelectOptions = ["Manually select a contract assigned to chosen pipeline.", "Select all contracts assigned to chosen pipeline."];

    return (
        <div>
            <div>
                    <Fieldset
                        type="radio"
                        default={selectAllContracts ? allSelectOptions[1] : allSelectOptions[0]}
                        options={allSelectOptions}
                        onChange={(name, option, valid) => {
                            setContracts(undefined);

                            setDelLocs([]);
                            setSelectedDelLocs([]);
                            setSelectAllContracts(Boolean(option === allSelectOptions[1]))
                        }}
                    />
            </div>
            <div>
            <Select
                    customLabel="Select Pipeline"
                    required
                    name="contract"
                    value={selectedPipeline?.value}
                    default={selectedPipeline?.value}
                    onChange={(id, value) => {
                        setContracts(undefined);
                        setDelLocs([]);
                        setSelectedDelLocs([]);
                        setSelectedPipeline(value === 'unselected' ? undefined : pipelines.find(c => c.value === value))
                    }}
                    options={pipelines}
                >
                </Select>
            </div>
            {selectedPipeline &&
                <div>
                <Spin spinning={loading} tip="Loading...">
                    {noContractsAvail &&
                        <Alert
                            style={{ marginBottom: '20px' }}
                            description={"There are no contracts assigned to this pipeline."}
                            type="warning"
                            showIcon
                        />
                    }
                    {!noContractsAvail && !selectAllContracts && contracts && 
                        <Select
                            customLabel="Select Contract"
                            required
                            name="contract"
                            value={selectedContract?.id}
                            default={selectedContract?.id}
                            onChange={(id, value) => {
                                setSelectedDelLocs([]);
                                setSelectedContract(value === 'unselected' ? undefined : contracts.find(c => c.id === value))
                            }}
                            options={getContractOptions(contracts)}
                        >
                        </Select>   
                    }
                </Spin>
            </div>
            }
            {(selectedContract || selectAllContracts) && delLocs.length > 0 &&
                <Grid col="1">
                    <div>
                        <Label text={"Delevery Location"} />
                    </div>
                    <div className={Style.tableScrollWrap}>
                        { renderDelLocationOptions() }
                    </div>
                </Grid>
            }
            <Grid col="2">
                    {isValid() && 
                        <Button
                            loading={ loading }
                            text="Submit"
                            action={ submit }
                        />
                    }
                    <Button 
                        loading={ loading } 
                        outline 
                        text='Cancel' 
                        action={ () => context.modal.hide(true) } 
                    />
                </Grid>
        </div>
    );
}