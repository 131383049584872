import { useCallback, useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { ViewContext, Button, Grid, Label, NumberInput, Select, usePlans } from 'components/lib';

import moment, { Moment } from 'moment';

import { KeyPair } from "ts/interfaces/general";
import { IContract, IDLData, ILinkedLocation } from 'ts/interfaces/contract';

import { Alert, Badge, Col, DatePicker, Divider, Row, Space, Typography } from 'antd';
const { Text } = Typography;


export function DeliveryLocationQuantity({ contractData, preSelectDelLoc }: { contractData: IContract, preSelectDelLoc: IDLData}) {
    const context = useContext(ViewContext);
    const plans: { data: any, loading: boolean } = usePlans();

    const [contract, setContract] = useState<IContract>(contractData);

    const getDelLocOptions = useCallback(():KeyPair<string, string>[] => {
        if (preSelectDelLoc) {
            return [{
                label: preSelectDelLoc.location_num,
                value: preSelectDelLoc.location_id
            }];
        }

        return contract.delivery_locations
            .map(dl => ({
                label: dl.location_num,
                value: dl.location_id
            }));
    }, [contract, preSelectDelLoc]);

    const [loading, setLoading] = useState<boolean>(false);

    const [delLocOptions, setDelLocOptions] = useState<KeyPair<string, string>[] | undefined>(undefined);

    const [selectDelLoc, setSelectDelLoc] = useState<KeyPair<string, string> | undefined>(!preSelectDelLoc ? undefined : {
        label: preSelectDelLoc.location_num,
        value: preSelectDelLoc.location_id
    });

    const [deliveryAmount, setdeliveryAmount] = useState<number | undefined>(undefined);
    const [receiptAmount, setReceiptAmount] = useState<number | undefined>(contractData.is_gate ? 0 : undefined);

    const [currentDateDeliveryAmount, setCurrentDateDeliveryAmount] = useState<number | undefined>(undefined);
    const [currentDateReceiptAmount, setCurrentDateReceiptAmount] = useState<number | undefined>(undefined);

    const [selectedDate, setSelectedDate] = useState<Moment | undefined>(undefined);
    const [underDediAmount, setUnderDediAmount] = useState(false);

    const [isPremiumPlan, setIsPremiumPlan] = useState<boolean | undefined>(undefined);

    useEffect(() => {    
        if (plans?.data?.raw) {
          setIsPremiumPlan(plans?.data?.isPremium);
        }
    
      }, [plans]);

    useEffect(() => {
        if (contract) setDelLocOptions(getDelLocOptions());
    }, [contract, getDelLocOptions]);

    useEffect(() => {

        const getDeliveryAmountOnDate = async (delLocObj: KeyPair<string, string>, chosenDate: Moment) => {
            setLoading(true);
            try {
                const deliveryAmountRes = await Axios.get('/api/contract/delivery-amount', {
                    params: { 
                        contract_id: contract.id,
                        location_id: delLocObj?.value,
                        date: chosenDate.format('YYYY-MM-DD')
                    }
                });
          
                if (deliveryAmountRes.status === 200) {
                    const deliveryLocData = deliveryAmountRes.data.data;
                    setLoading(false);
                    setCurrentDateDeliveryAmount(deliveryLocData.quantity);
                    setCurrentDateReceiptAmount(deliveryLocData.current_receipt_amount);
                }          
            } catch (err){
                setLoading(false);
                context.handleError(err);
            }
        }

        const getContract = async (chosenDate: Moment) => {
            try {
                setLoading(true);
    
                const res = await Axios.get('/api/contract', {  
                    params: {
                        id: contract.id,
                        current_date: chosenDate.format('YYYY-MM-DD')
                    }
                });
              
                if (res.status === 200) {     
    
                    setContract(res.data.data[0]);    
                    setLoading(false);
                } 
            } catch (err) {
                setLoading(false);
                context.handleError(err);
            }
        }
        
        if (selectedDate) {
            getContract(selectedDate);

            if (selectDelLoc) {
                setCurrentDateReceiptAmount(undefined);
                getDeliveryAmountOnDate(selectDelLoc, selectedDate);
            }
        }

    }, [selectedDate, selectDelLoc, setLoading]);

    const getLinkedExportAmount = (): number => {
        if (!selectDelLoc) return 0;

        const delLocObj = getSelectedDelLoc();
        let primaryLinks: ILinkedLocation<string, number>[] = [];
        
        contract.linked_contracts.forEach(lk => {
            const linkBatch = lk.primary_linked_locations.filter(plk => plk.location_id === delLocObj?.location_id);
            if(linkBatch.length) {
                primaryLinks = [...primaryLinks, ...linkBatch];
            }
        });

        return primaryLinks.length ? primaryLinks.reduce((sum, obj) => {
            if (obj.quantity) sum = sum + obj.quantity;

            return sum;
        }, 0) : 0;
    }

    const getLinkedImportAmount = (): number => {
        if (!selectDelLoc) return 0;

        const delLocObj = getSelectedDelLoc();
        let subLinks: ILinkedLocation<string, number>[]  = [];
        
        contract.linked_contracts.forEach(lk => {
            const linkBatch = lk.sub_linked_locations.filter(plk => plk.location_id === delLocObj?.location_id);
            if(linkBatch.length) {
                subLinks = [...subLinks, ...linkBatch];
            }
        });

        return subLinks.length ? subLinks.reduce((sum, obj) => {
            if (obj.additional_quantity) sum = sum + obj.additional_quantity;

            return sum;
        }, 0) : 0;
    }

    const getDeliveryAmount = (): number => deliveryAmount === undefined ? 0 : deliveryAmount;

    const getSplitAmmount = (): number => {
        if (!selectDelLoc) return 0;

        const delLocObj = getSelectedDelLoc();

        return Array.isArray(delLocObj?.current_splits) && delLocObj?.current_splits.length ? delLocObj.current_splits.reduce((sum, obj) => sum + obj.split_quantity, 0) : 0;
    }

    const getDelLocMaxQuantity = (): number => {
        const maxQuantity = getSelectedDelLoc()?.max_quantity || 0;
        const linkedImportAmount = getLinkedImportAmount();

        return maxQuantity - linkedImportAmount;
    }

    const overMax = (): boolean => !contract.is_flex && selectDelLoc ? getDelLocMaxQuantity() < getDeliveryAmount() : false;

    // for flex contracts
    const totalQuantityOverReceiptMax = (): boolean => {
        if (!contract.is_flex) return false;
        
        const totalDLQuantity = contract.delivery_locations.reduce((sum, dl) => {
            if (dl.location_id === selectDelLoc?.value) return sum + parseInt((getDeliveryAmount() as unknown) as string);
            return sum + dl.final_quantity;
        }, 0);

        return contract.receipt_location_max_quantity < totalDLQuantity;
    }


    const underDedicatedAmount = (delAmount: number): boolean => delAmount < (getLinkedExportAmount() + getSplitAmmount());

    const getSelectedDelLoc = () => selectDelLoc?.value ? contract.delivery_locations.find(dl => dl.location_id === selectDelLoc.value) : undefined;

    const renderExportAmountMessage = () => {
        const textType = underDediAmount ? "danger": undefined;

        if (getLinkedExportAmount() > 0 && !getSplitAmmount()) {
            return (
                <Text type={textType}>
                    This delivery location is sending {getLinkedExportAmount()} to a contract it is linked to.<br /> 
                    Your quantity cannot be less than that amount.
                </Text>
            );
        } else if (getSplitAmmount() > 0 && !getLinkedExportAmount()) {
            return (
                <Text type={textType}>
                    This delivery location has a split of {getSplitAmmount()}. <br />
                    Your quantity cannot be less than that amount.
                </Text>
            );
        } else if (getLinkedExportAmount() > 0 && getSplitAmmount() > 0) {
            return (
                <Text type={textType}>
                    This delivery location has a total of {getSplitAmmount() + getLinkedExportAmount()} dedicated to a split and another contract its linked to.<br />
                    Your quantity cannot be less than that amount.
                </Text>
            );
        }
        
        return null;

    }

    const onDateChange = (value: Moment) => {
        setSelectedDate(value);
    }

    const submit = async () => {
        if(underDedicatedAmount(getDeliveryAmount())) {
            setUnderDediAmount(true);
            return;
        }

        setLoading(true);
        try {
            const deliveryAmountRes = await Axios.post('/api/contract/delivery-amount', { 
                contract_id: contract.id,
                location_id: selectDelLoc?.value,
                date: selectedDate ? selectedDate.format('YYYY-MM-DD') : undefined,
                quantity: getDeliveryAmount()
            });

            const receiptAmountRes = await Axios.post('/api/contract/receipt-amount', { 
                contract_id: contract.id,
                location_id: contract.receipt_location_id,
                delivery_location_id: selectDelLoc?.value,
                date: selectedDate ? selectedDate.format('YYYY-MM-DD') : undefined,
                quantity: receiptAmount
            });
      
            if (deliveryAmountRes.status === 200 && receiptAmountRes.status === 200) {
                setLoading(false);
                
                // send the user some where else
                context.modal.hide(false, {
                    deliveryAmount: deliveryAmountRes.data,
                    receiptAmount: receiptAmountRes.data
                });
            }          
        } catch (err){
            setLoading(false);
            context.handleError(err);
            context.modal.hide(true);
        }
    }

    const hasPipelineUnaccountedAmount = () => contract.pipeline_unaccounted_amount !== undefined;

    const prepareVolumeAmount = (amount: number, isRecieved?: boolean): number => {
        const setUnaccountedAmount = () => {

            if (isRecieved) {
                const receiptFormula = (r: number) => Math.round((r * 100) / (contract.pipeline_unaccounted_amount + 100));
                const newVal = receiptFormula(amount);

                setdeliveryAmount(newVal !== undefined && newVal > -1 ? newVal : undefined);
            } else {
                const deliveryFormula = (x: number) => ((contract.pipeline_unaccounted_amount + 100) * x) / 100;
                const newVal = Math.round(Number(deliveryFormula(amount).toFixed(3)));
                
                setReceiptAmount(newVal !== undefined && newVal > -1 ? newVal : undefined);
            }    
        };

        if (isPremiumPlan && hasPipelineUnaccountedAmount()) setUnaccountedAmount();
        
        return amount;
    }
    
    const onDeliveryChange = (id: string, value: any) => {
        if(underDediAmount) setUnderDediAmount(false);
        const newVal = !isNaN(value) ? prepareVolumeAmount(value) : undefined;
        setdeliveryAmount(newVal);
    }

    const onReceiptChange = (id: string, value: any) => {
        const newVal = !isNaN(value) ? prepareVolumeAmount(value, true) : undefined;
        setReceiptAmount(newVal);
    }

    return (
        <div>
            {isPremiumPlan && !hasPipelineUnaccountedAmount() &&
                <Row style={{ margin: '20px 0' }}>
                    <Alert
                        message={
                            <>
                                <p>
                                    The pipeline on this contract does not have the "lost and accounted amount" set so, you will have to go the the pipeline site yourself to get the true delivery amount that the delivery location will receive.
                                </p>
                                <p>
                                    If you want the application to automatically calculate the true delivery amount, go to the settings and set the "lost and accounted amount" for this pipeline.
                                </p>
                            </>
                        }
                        type="warning"
                    />
                </Row>
            }
            {overMax() &&
                    <Row style={{ margin: '20px 0' }}>
                        <Alert
                            message="The delivery amount is over the maximum amount this delivery location can hold."
                            type="error"
                        />
                    </Row>
            }
            {selectDelLoc && totalQuantityOverReceiptMax() &&
                <Row style={{ margin: '20px 0' }}>
                    <Alert
                        message="The total delivery amount is above the contract's receipt location maximum quantity."
                        type="error"
                    />
                </Row>
            }
            {underDediAmount &&
                    <Row style={{ margin: '20px 0' }}>
                        <Alert
                            message="The delivery amount is under the dedicated amount needed for the linked contract / split that is on this delivery location."
                            type="error"
                        />
                    </Row>
            }

            <Row style={{ margin: '20px 0' }}>
                    <Alert
                        message={(
                            <>
                                <Text>
                                    The latest amount entered for a receipt / delivery location will persist for the next day. This way you will not have to update the amount everyday. The amount will update when you change it.
                                </Text>
                            </>    
                        )}
                        type="info"
                        closable
                    />
            </Row>
            <div>
                <Label text="Select Date" />
                <DatePicker 
                    style={{ width: '100%', marginBottom: '20px' }}
                    onChange={(dateObj) => { if (dateObj) onDateChange(dateObj) }}
                    disabledDate={d => {
                        const start = moment(contract.start_date).startOf('day');
                        const end = moment(contract.end_date).add(1,'day').startOf('day');
                        return !d.isBetween(start, end, null, "[]");
                    }}
                    direction="ltr"
                    value={selectedDate ? moment(selectedDate) : null}
                />
            </div>
            {selectedDate &&
                <>
                    {selectDelLoc &&
                        <Row style={{ margin: '20px 0' }}>
                            <Space direction="vertical">
                                {getSelectedDelLoc()?.latest_quantity_date &&
                                <Alert
                                    message={(
                                        <Space direction="horizontal">
                                            <Text>
                                                Current amount saved for delivery location on chosen date:
                                            </Text> 
                                            <Text>
                                                <Badge
                                                    className="site-badge-count-109"
                                                    count={currentDateDeliveryAmount || getSelectedDelLoc()?.current_delivery_amount}
                                                    overflowCount={1000000}
                                                    color='#faad14'
                                                />
                                            </Text>
                                        </Space>    
                                    )}
                                    type="info"
                                />
                                }
                                {!getSelectedDelLoc()?.latest_quantity_date &&
                                    <Text>This will be your first entry for the selected delivery location.</Text>
                                }
                                {!contract.is_flex && <Text>Max Quantity: <strong>{getSelectedDelLoc()?.max_quantity}</strong></Text>}
                                {renderExportAmountMessage()}
                            </Space>
                            
                        </Row>        
                    }

                    <Row>
                        <Col md={12} xs={24}>
                            <Row justify="start">
                                <Col md={8} xs={24}>
                                    <Space direction="vertical" style={{ margin: '20px 0' }}>
                                        <Text strong>Delivery Location:</Text>
                                    </Space>
                                </Col>
                                <Col md={12} xs={24}>
                                    <Select
                                        customLabel="Select Delivery Location"
                                        required
                                        name="delivery_location"
                                        value={selectDelLoc?.value}
                                        default={selectDelLoc?.value}
                                        onChange={(id: string, value: string) => {
                                            if(underDediAmount) setUnderDediAmount(false);
                                            setSelectDelLoc(value === 'unselected' ? undefined : delLocOptions?.find(dl => dl.value === value))
                                        }}
                                        options={delLocOptions}
                                    >
                                    </Select>
                                </Col>
                            </Row>
                        </Col>

                        <Col md={12} xs={24}>
                                <NumberInput 
                                    label="Delivery Amount"
                                    placeholder="0.00"
                                    name="quantity_amount"
                                    id="quantity_amount"
                                    isFloat
                                    decimalLimit={3}
                                    onChange={onDeliveryChange}
                                    value={deliveryAmount}
                                />
                        </Col>
                    </Row>
                    
                    {!contract.is_gate &&
                        <>
                            <Divider />

                            <Row style={{ margin: '20px 0' }}>
                                <Space direction="vertical">
                                    {selectDelLoc && currentDateReceiptAmount !== undefined &&
                                    <Alert
                                        message={(
                                            <>
                                            <Space direction="vertical">
                                            <Space direction="horizontal">
                                                <Text>
                                                    Current amount saved for receipt location on chosen date: 
                                                </Text> 
                                                <Text>
                                                    <Badge
                                                        className="site-badge-count-109"
                                                        count={currentDateReceiptAmount}
                                                        overflowCount={1000000}
                                                        color='#faad14'
                                                    />
                                                </Text>
                                            </Space>  
                                            <Space direction="horizontal">
                                                <Text>Max Delivery Quantity:</Text> 
                                                <Text>
                                                    <Badge
                                                        className="site-badge-count-109"
                                                        count={contract?.receipt_location_max_quantity}
                                                        overflowCount={1000000}
                                                        color='#faad14'
                                                    />
                                                </Text>
                                            </Space>
                                            </Space>
                                            </>  
                                        )}
                                        type="info"
                                    />    
                                    }   

                                </Space>
                            </Row>

                            <Row>
                                <Col md={12} xs={24}> 
                                    <Row justify="start">
                                        <Col md={8} xs={12}>
                                            <Space direction="vertical" style={{ margin: '20px 0' }}>
                                                <Text strong>Reciept Location:</Text>
                                            </Space>
                                        </Col>
                                        <Col md={12} xs={12}>
                                            <Space direction="vertical" style={{ margin: '20px 0' }}>
                                                <Text>{contract.receipt_location_name}</Text>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col md={12} xs={24}>
                                    <NumberInput 
                                        label="Receipt Amount"
                                        placeholder="0.00"
                                        name="receipt_amount"
                                        id="receipt_amount"
                                        isFloat
                                        decimalLimit={3}
                                        onChange={onReceiptChange}
                                        value={receiptAmount}
                                    />
                                </Col>
        
                            </Row>
                        </>
                    }
                </>
            }
            
            <Grid col="2">
                    {selectDelLoc && 
                     deliveryAmount !== undefined && 
                     !overMax() && 
                     receiptAmount !== undefined && 
                     selectedDate &&
                     !totalQuantityOverReceiptMax() &&
                        <Button
                            loading={ loading }
                            text="Submit"
                            action={ submit }
                        />
                    }
                    <Button outline text='Cancel' action={ () => context.modal.hide(true) } />
                </Grid>
        </div>
    );
}