/***
*
*   SIGN IN
*   Sign in form for all account types (including master).
*
**********/

import React, { useContext, useState, useEffect } from 'react';
import { Animate, AuthContext, ViewContext, Form, Card, Logo, Row } from 'components/lib';
import { Row as AntRow, Carousel, Col } from 'antd';
import LogoImage from './images/dm-flame-dark-logo.svg';

import Style from '../auth.module.scss';

const contentStyle = {
  height: '100%',
  color: '#333440',
  fontSize: '26px',
  lineHeight: '36px',
  margin: '5em 0 0 1em',
  textAlign: 'left',
  background: 'transparent',
  fontWeight: 200
};


export function Signin(props){

  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  
  // state
  const [form] = useState({

    email: {
      label: 'Email',
      type: 'email',
      required: true,
    },
    password: {
      label: 'Password',
      type: 'password',
      required: true,
    },
    forgotpassword: {
      type: 'link',
      url: '/forgotpassword',
      text: 'Forgot your password?'
    }
  });

  useEffect(() => {

    // was an error message passed from the server router?
    const qs = props.location.search;

    if (qs.includes('error')){
  
      const msg = decodeURIComponent(qs.substring(qs.indexOf('?error=')+7));
      viewContext.notification.show(msg, 'error');
  
    }
  }, [props.location.search, viewContext.notification]);

  return(
    <Animate type='pop' className={Style.authMain}>
      <AntRow className={Style.authWrap}>
        <Col span={12} className={Style.authSection}>

          <div className={Style.slideWrap}>
            
            <div className={Style.dmImage}>
              
              <div style={{
                float: 'left',
                width: '84px'
              }}>
                <img src={ LogoImage } alt='Logo' />
              </div>
              <div style={{
                float: 'left',
                width: '180px',
                margin: '28px 0'
              }}>
                <Logo noLink />
              </div>
              
            </div>
            <div className={Style.carouselWrap}>
              <Carousel 
                autoplay
                effect="fade"
                style={{ 
                  height: '290px'
                }}>
                <div>
                  <h3 style={contentStyle}>
                    Data management <br />
                    for natural resources.
                  </h3>
                </div>
                <div>
                  <h3 style={contentStyle}>
                    Track your trades to know your bottom line.
                  </h3>
                </div>
                <div>
                  <h3 style={contentStyle}>
                    Visual overview of your companies monthly spending.
                  </h3>
                </div>
              </Carousel>
            </div>
            
          </div>
          

        </Col>
        <Col span={12} className={Style.authSection}>

          <Row title='Sign In'>

            <Card restrictWidth center>

              <Form
                data={ form }
                method='POST'
                url='/api/auth'
                buttonText='Sign In'
                callback={ (res) => {

                  authContext.signin(res);

                }}
              />

            </Card>

          </Row>
          
        </Col>
      </AntRow>

      {/*
        <Row title='Sign in to DM Natural'>

        <Card restrictWidth center>

          <Form
            data={ form }
            method='POST'
            url='/api/auth'
            buttonText='Sign In'
            callback={ (res) => {

              authContext.signin(res);

            }}
          />

        </Card>

      </Row>
          */}
    </Animate>
  );
}
