/***
*
*   usePlans hook
*   fetch, format and return the price plans
*
**********/

import { useState, useEffect } from 'react';
import { useAPI } from 'components/lib';

export function usePlans(){

  const [state, setState] = useState({ data: null, loading: false });
  const plans = useAPI('/api/account/plans');

  useEffect(() => {

    setState({ loading: true });

    // format plans
    if (plans?.data?.plans){

      let formatted = [];
      plans.data.plans.map(plan => {

        let label = `${plan.name} (${plan.currency.symbol}${plan.price}/${plan.interval || "no charge"})`
        return formatted.push({ value: plan.id, label: label });

      });

      let activePlan = plans.data.plans.find(obj => obj.id === plans.data.active);
      activePlan.type = 'premium';

      setState({ 
        data: { 
          list: formatted, 
          active: plans.data.active, 
          isPremium: activePlan.type === 'premium', 
          raw: plans.data 
        }, 
        loading: false 
      });

    }
  }, [plans]);

  return state;

}
