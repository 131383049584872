/***
*
*   PROFILE
*   Update the user profile or close the account
*
**********/

import React, { Fragment, useContext } from 'react';
import Axios from 'axios';
import { AccountContext, Card, getCompanies,
    SettingNav, Animate, Message, ViewContext } from 'components/lib';

import states from './state-json';
import { Button as AntButton, Col, List, Row, Typography } from 'antd';

const { Text } = Typography;

export function Companies(){

  // context

  const viewContext = useContext(ViewContext);
  const { accountState, accountDispatch } = useContext(AccountContext);

  const openEditAddressModal = (item) => {
    viewContext.modal.show({

        title: 'Edit Company Information',
        form: {
            address_one: {
                label: "Address",
                type: "text",
                required: true,
                name: "address_one",
                id: "address_one",
                value: item?.address_one
            },
            address_two: {
                label: "Address Two",
                type: "text",
                required: true,
                name: "address_two",
                id: "address_two",
                value: item?.address_two
            },
            city: {
                label: "City",
                type: "text",
                required: true,
                name: "city",
                id: "city",
                value: item?.city
            },
            state_region: {
                label: "State / Region",
                type: "select",
                required: true,
                name: "state_region",
                id: "state_region",
                value: item?.state_region,
                options: states.map(state => ({ label: state.name, value: state.code })) 
            },
            zip_code: {
                label: "Zip Code",
                type: "text",
                required: true,
                name: "zip_code",
                id: "zip_code",
                value: item?.zip_code
            },
            id: {
                type: "hidden",
                name: "id",
                id: "id",
                value: item?.id
            }
        },
        buttonText: 'Submit',
        url: '/api/company/address',
        method: 'POST'
  
      }, () => {
        getCompanies(accountDispatch);
      });
  }

  const openEditContactModal = (item) => {
    const formObj = {
        name: {
            label: "Name",
            type: "text",
            required: true,
            name: "name",
            id: "name",
            value: item?.name
        },
        phone_number: {
            label: "Phone Number",
            type: "phone",
            name: "phone_number",
            id: "phone_number",
            value: item?.phone_number
        },
        email: {
            label: "Email",
            type: "text",
            required: true,
            name: "email",
            id: "email",
            value: item?.email
        },
        id: {
            type: "hidden",
            name: "id",
            id: "id",
            value: item?.id
        }
    };

    viewContext.modal.show({

        title: 'Edit Company Information',
        form: formObj,
        buttonText: 'Submit',
        url: '/api/company/contact',
        method: 'POST'
  
      }, () => {
        getCompanies(accountDispatch);
      });
  }

  const removeCompany = async (item) => {
    try {
        const res = await Axios.delete('/api/company', { data: { id: item.id } });
  
        if (res.status === 200) {
            viewContext.notification.show('Company was removed', 'success', true);
            getCompanies(accountDispatch);
        }          
      }
      catch (err){
        viewContext.handleError(err);
      }
}

  const renderCompanies = (item) => {
    return (
        <List.Item
            actions={[
                <AntButton 
                    key="address-edit" 
                    onClick={() => openEditAddressModal(item)}>
                        Edit Address
                </AntButton>, 
                <AntButton 
                    key="contact-edit" 
                    onClick={() => openEditContactModal(item)}>
                        Edit Contacts
                </AntButton>,
                <AntButton 
                    key="pipeline-editt" 
                    onClick={async() => await removeCompany(item)}>
                        Delete
                </AntButton>
            ]}
        >
            <ul>
                <li>
                    <Text>{item.name}</Text>
                </li>
                {item.address_one ?
                    <li>
                        <address>
                            {item.address_one} <br />
                            {item.address_two} {item.address_two && <br />}
                            {item.city}, {item.state_region} {item.zip_code}
                        </address>
                    </li> :
                    <li>
                        <Text type="secondary" italic>No address assigned.</Text>
                    </li>
                }
                {item.email ?
                    <li>
                        <Text>{item.email}</Text> 
                    </li>
                    :
                    <li>
                        <Text type="secondary" italic>No email assigned.</Text>
                    </li>
                }
                {item.phone_number &&
                    <li>
                        <Text>{item.phone_number}</Text>
                    </li> 
                }
            </ul>
        </List.Item>
    );
  }

  return (
    <Fragment>
      <SettingNav />
      <Animate>
        <Message
            closable
            title='Company Options List'
            text='These are the company options that are assigned to your account.'
            type='info'
        />
        <Card
            loading={accountState.loading}
        >
                <Row gutter={[16, 16]}>
                    <Col offset={18} span={6}>
                        <AntButton
                            style={{ float: 'right', margin: '10px 0' }}
                            size="middle"
                            onClick={() => openEditContactModal() }
                        >
                            Create Company
                        </AntButton>
                    </Col>
                </Row>
            <List
                size="small"
                bordered
                dataSource={accountState.companies}
                renderItem={renderCompanies}
            />

        </Card>
      </Animate>
    </Fragment>
  );
}
