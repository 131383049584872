import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import { ViewContext, Card, Grid, IconCard, useWindowSize } from 'components/lib';
import { CreateLongTermDeal } from './create-long-term-deal';
import { currency } from 'utilities/currency';

import { Dropdown, Menu, Popconfirm, Table, Space, Divider } from 'antd';

import moment from 'moment';

import { IDeal, ILTDealForm } from 'ts/interfaces/deal';

export function ClosedLongTermDeals() {
    const context = useContext(ViewContext);
    const history = useHistory();
    const { width } = useWindowSize();

    const defaultPageData = {
        current: 1,
        pageSize: 10,
        total: 100
    };

    const [deals, setDeals] = useState<Array<IDeal> | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const [offset, setOffSet] = useState<number>(0);
    const [pageSizeCount] = useState<number>(10);
    const [pageData, setPageData] = useState<{[x: string]: number}>(defaultPageData);

    const limit = 100;
    
    useEffect(() => {
        if(!deals) {
            getLongTermDeals();
        }
    }, [deals]);

    const getLongTermDeals = useCallback(async () => {
        try {
            setLoading(true);
            const res = await Axios.get('/api/deal', {  
                params: {
                    long_term_status: 0,
                    //limit,
                    offset
                } 
            });
          
            if (res.status === 200) {
                const currentDeals = !deals ? [] : deals;

                setDeals([ ...currentDeals, ...res.data.data]);
                if(res.data.count !== pageData.total) setPageData({...pageData, total: res.data.count });
                setLoading(false);
            } 
        } catch (err) {
            setLoading(false);
            context.handleError(err);
        }
    }, [setLoading, setDeals]);

    const createLongTermDeal = (deal: IDeal) => {
        context.modal.show({
            title: 'Create Long Term Deal',
            customForm: CreateLongTermDeal,
            formProps: {
                updateDeal: deal
            },
            lgModal: true
        }, (form: ILTDealForm, res: { data: IDeal }) => {
            if(deal) {
                const updatedDeals = deals?.map(dealObj => {
                    if(dealObj.id === res.data.id) return res.data;
                    return dealObj;
                });
                setDeals(updatedDeals);
                context.notification.show('Long Term Deal was updated', 'success', true);
            } else {
                deals?.push(res.data);
                setDeals(deals);
                context.notification.show('Long Term Deal was created', 'success', true);
            }
        });
    }

    const handleTableChange = (pagination: {[x: string]: any}) => {
        const { current, pageSize } = pagination;
        const totalPages = Math.floor(current * pageSize);

        if (deals && totalPages === deals.length) {
            setOffSet(totalPages - 1);
            getLongTermDeals();
        }

        setPageData({ ...pageData, current });
        
    }


    const deleteLongTermDeal = async (deal: IDeal, index: number) => {
        try {
            const res = await Axios.delete('/api/deal', { data: { id: deal.id } });
      
            if (res.status === 200 && deals) {
                deals.splice(index, 1);
                setDeals([...deals]);
            }          
          }
          catch (err){
            context.handleError(err);
        }
    }

    const renderDealsList = () => {   
        const data = deals ? deals.map(deal => {
            deal.date_range = `${moment(deal.start_date).format('MMM, DD YYYY')} - ${moment(deal.end_date).format('MMM, DD YYYY')}`
            deal['fix_slots'] = deal.fix_price_slot_data ? deal.fix_price_slot_data.length : 0;

            return deal;
        }): undefined;

        const renderLongTermDealActionBtns = (deal: IDeal, index: number): JSX.Element => {

            //const notInUse = !deal.fix_slots && moment().isBefore(moment(deal.start_date));
            const sendToLongTermView = () => {
                history.push({
                    pathname: `/long-term-deals/${deal.id}`
                });
            };

            /*const actionEl = notInUse ? (
                <AntButton 
                    onClick={() => sendToLongTermView()}
                >
                    View Long Term Deal
                </AntButton>
            ) :
            (
                <Space wrap>
                    <Dropdown.Button 
                        onClick={() => sendToLongTermView()}
                        overlay={menu(deal, index)}
                    >
                        View Long Term Deal
                    </Dropdown.Button>    
                </Space>
            )*/

            const actionEl = (
                <Space wrap>
                    <Dropdown.Button 
                        onClick={() => sendToLongTermView()}
                        overlay={menu(deal, index)}
                    >
                        View Long Term Deal
                    </Dropdown.Button>    
                </Space>
            );

            return actionEl;
        };

        const confirmDelete = async (dealObj: IDeal, idx: number) => {
            await deleteLongTermDeal(dealObj, idx);
        }

        const menu = (deal: IDeal, index: number) => {
            const cancel = () => {}

            return (
            <Menu>
                <Menu.Item
                    onClick={() => createLongTermDeal(deal)}
                >
                    Edit
                </Menu.Item>
                <Popconfirm
                            title="Are you sure to delete this long term deal?"
                            onConfirm={async () => await confirmDelete(deal, index)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                >
                    <Menu.Item>
                        Delete
                    </Menu.Item>
                </Popconfirm>
            </Menu>
            );
        };

        const columns: Array<any> = [
            {
                title: 'Long Term Deal',
                render: (record: IDeal, index: number) => {

                    return (
                        <Fragment>
                        Volume Per Day: {record.volume}
                        <br />
                        Base Price: {currency(record.base_price, 4)}
                        <br />
                        Pipeline: {record.pipeline}
                        <br />
                        Position: {record.position}
                        <br />
                        Point: {record.point}
                        <br />
                        Company: {record.company}
                        <br />
                        Date Range: {record.date_range}
                        <br />
                        Fixed Price Slots {record.fix_slots}
                        <Divider />
                        {renderLongTermDealActionBtns(record, index)}
                    </Fragment>
                    );
                },
                responsive: ["xs"]
            },
            {
              title: 'Volume Per Day',
              dataIndex: 'volume',
              key: 'volume',
              sorter: (a: IDeal, b: IDeal) => a.volume - b.volume,
              responsive: ["sm"]
            },
            {
              title: 'Base Price',
              dataIndex: 'base_price',
              key: 'base_price',
              render: (base_price: number) => currency(base_price, 4),
              sorter: (a: IDeal, b: IDeal) => a.start_date && b.start_date ? moment(a.start_date).diff(moment(b.start_date)) : 'N/A',
              responsive: ["sm"]
            },
            {
                title: 'Pipeline',
                dataIndex: 'pipeline',
                key: 'pipeline',
                sorter: (a: IDeal, b: IDeal) => a.pipeline.toLowerCase().localeCompare(b.pipeline.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Position',
                dataIndex: 'position',
                key: 'position',
                sorter: (a: IDeal, b: IDeal) => a.position.toLowerCase().localeCompare(b.position.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Point',
                dataIndex: 'point',
                key: 'point',
                sorter: (a: IDeal, b: IDeal) => a.point.toLowerCase().localeCompare(b.point.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Company',
                dataIndex: 'company',
                key: 'company',
                sorter: (a: IDeal, b: IDeal) => a.company.toLowerCase().localeCompare(b.company.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Date Range',
                dataIndex: 'date_range',
                key: 'date_range',
                sorter: (a: IDeal, b: IDeal) => a.start_date && b.start_date ? moment(a.start_date).diff(moment(b.start_date)) : 'N/A',
                responsive: ["sm"]
            },
            {
                title: 'Fixed Price Slots',
                dataIndex: 'fix_slots',
                key: 'fix_slots',
                sorter: (a: IDeal, b: IDeal) => a.fix_slots && b.fix_slots ? a.fix_slots - b.fix_slots : 'N/A',
                responsive: ["sm"]
            },
            {
                title: 'Action',
                key: 'action',
                render: renderLongTermDealActionBtns,
                responsive: ["sm"],
                width: 240
            }
          ];

          const mediumScreenWidth = width && (width < 1240 && width > 574);

          if (mediumScreenWidth) {
            columns[columns.length - 1]['fixed'] = 'right';
          } else {
            delete columns[columns.length - 1]['fixed'];
          }

          return (
            <Table 
                columns={columns} 
                dataSource={data} 
                onChange={handleTableChange} 
                //pagination={pageData}
                loading={loading}
                scroll={{ x: mediumScreenWidth ? 1300 : undefined }}
                bordered
            />
        );
    }

    return(
        <Fragment>
            <Card
                    title="Long Term Deals (Closed)"
                    loading={!deals}
                >
                {deals && deals.length === 0 &&
                    <p>No long term deals without fixed price slots exist.</p>
                }
                {deals && deals.length > 0 &&
                    renderDealsList()
                }
            </Card>
        </Fragment>
    );
}