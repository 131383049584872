/***
*
*   STAT
*   Statistic value with optional icon and -/+ change value
*
*   PROPS
*   value:  numeric value
*   label: string
*   loading: boolean to toggle loading animation (optional)
*   icon: icon to use (optional)
*   change: positive/negative number indicating change amount (optional)
*
**********/

import React from 'react'
import ClassNames from 'classnames';
import { Card } from 'components/lib';
import Style from './iconcard.module.scss';

export function IconCard(props){

  const colorMap = {
    'rosybrown': {
      borderColor: 'rosyBrownBorder',
      iconWrapBgColor: 'rosyBrownBg',
      iconColor: 'rosyBrownIcon'
    },
    'shellblue': {
      borderColor: 'shellBlueBorder',
      iconWrapBgColor: 'shellBlueBg',
      iconColor: 'shellBlueIcon'
    },
    'darkblue': {
      borderColor: 'darkBlueBorder',
      iconWrapBgColor: 'darkBlueBg',
      iconColor: 'darkBlueIcon'
    },
    'orange': {
      borderColor: 'orangeBorder',
      iconWrapBgColor: 'orangeBg',
      iconColor: 'orangeIcon'
    }
  }

  // style
  const borderStyleArr = [
    Style.wrapper,
    //Style[colorMap[props.color]['borderColor']]
  ]

  if(props.onClick) borderStyleArr.push(Style.pointer);
  if(props.hover) borderStyleArr.push(Style.hover);
  const borderStyles = ClassNames(borderStyleArr);

  const iconWrapStyles = ClassNames([
    Style.iconWrap,
    Style[colorMap[props.color]['iconWrapBgColor']]
  ]);

  const iconStyles = ClassNames([
    Style.icon,
    //Style[colorMap[props.color]['iconColor']]
  ]);

  const click = (e) => {
      if(props.onClick) props.onClick();
  }

  return(
    <div onClick={click}>

        <Card className={ borderStyles }>
            <div 
                className={ Style.statTwo }
            >
                { props.icon &&
                <div className={ iconWrapStyles }>
                    <props.icon
                    className={ iconStyles }
                    />
                </div>
                }

                <div className={Style.content}>
                <div className={ Style.text }>{ props.text }</div>
                </div>

            </div>
        </Card>

    </div>
  );
}