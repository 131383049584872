import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { useHistory } from "react-router-dom";
import { Animate, ViewContext, Card, Grid, useWindowSize } from 'components/lib';
import { CreateSpotDeal } from './create-spot-deal';

import { DealService } from 'services';
import { currency, currencyNoFloat } from 'utilities/currency';

import { IFlowDate } from 'ts/interfaces/flow_date';
import { IDeal, IDealBase, IMultiMidPoint } from 'ts/interfaces/deal';


import { Button as AntButton, DatePicker, List, Popconfirm, Result, Space, Table, Tag, Typography } from 'antd';

import {
    DeleteFilled,
    FileTextOutlined
  } from '@ant-design/icons';

import moment, { Moment } from 'moment';

import Style from './new-deal.module.scss';

const { Text } = Typography;

export function FlowDatesOverview() {
    const context = useContext(ViewContext);
    const history = useHistory();
    const { width } = useWindowSize();

    const [selectedFlowDate, setSelectedFlowDate] = useState<Moment | undefined>(undefined);
    const [flowDateObj, setFlowDateObj] = useState<IFlowDate | undefined>(undefined);
    const [pendingFlowDates, setPendingFlowDates] = useState<Array<IFlowDate> | undefined>(undefined);
    const [dailyNomDateSelected, setDailyNomDateSelected] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [dealsLoading, setDealsLoading] = useState<boolean>(false);

    const [hasDailyGasData, setHasDailyGasData] = useState<boolean>(false);
    const [dataCountLoad, setDataCountLoad] = useState<boolean>(false);
    const [multiMidpointSpotDeals, setMultiMidpointSpotDeals] = useState<Array<IDeal> | undefined>(undefined);

    useEffect(() => {
        const getPendingFlowDates = async () => {
            try {
                const res = await Axios.get('/api/flow-date', {
                    params: { pending: true, is_long_term: 0, has_deals: true }
                });

                const sortedFlowDates = res.data.data.sort((a: IFlowDate, b: IFlowDate) => b.date.toLowerCase().localeCompare(a.date.toLowerCase()));
    
                if (res.status === 200) {
                    setPendingFlowDates(sortedFlowDates);
                }
            } catch (err) {
                context.handleError(err);
            }
        }

        if(!pendingFlowDates) getPendingFlowDates();

    }, [pendingFlowDates]);

    useEffect(() => {
        const getFlowDateData = async (selectedDate: Moment) => {
            try {
                const res = await Axios.get('/api/flow-date', {
                    params: { date: selectedDate.format('YYYY-MM-DD') }
                });
    
                if (res.status === 200) {
                    return res.data.data;
                }
            } catch (err) {
                context.handleError(err);
            }
        }

        const prepareFlowDateData = async (selectedDate: Moment) => {
            const flowDateObjRes = await getFlowDateData(selectedDate);

                setFlowDateObj(flowDateObjRes);
                getDataRecordsCount();

                if(flowDateObjRes && !flowDateObjRes.pending) {
                    setDailyNomDateSelected(true);
                }
        }

        if(selectedFlowDate) {

            // check if pending
            const matchingDate = pendingFlowDates ? pendingFlowDates.find(pdDate => moment(pdDate.date).format('YYYY-MM-DD') === selectedFlowDate.format('YYYY-MM-DD')) : undefined;
            
            if(!matchingDate) {
                prepareFlowDateData(selectedFlowDate);
            } else {
                history.push({
                    pathname: `/spot-deals/${matchingDate.id}`
                });
            }
        }

    }, [selectedFlowDate]);

    useEffect(() => {

        const getMultiMidpointSpotDeals = async () => {

            setDealsLoading(true);
            const res = await DealService.getDeals({ from: moment().format('YYYY-MM-DD'), deal_type: 0, multi_day_spot_deal: true });
          
            if (res.status === 200) {                
                setMultiMidpointSpotDeals(res.data.data);

                setDealsLoading(false);
            } else {
                context.handleError(res);
                setDealsLoading(false);
            }
        }

        if (!multiMidpointSpotDeals) getMultiMidpointSpotDeals();
    }, [context, selectedFlowDate, multiMidpointSpotDeals, setDealsLoading, setMultiMidpointSpotDeals]);

    const createSpotDeal = () => {

        const formObj: {[x: string]: any} = { flowDate: selectedFlowDate };
        
        if (flowDateObj) {
            formObj['flowDateId'] = flowDateObj.id;
        }

        context.modal.show({
            title: 'Create Spot Deal',
            customForm: CreateSpotDeal,
            formProps: formObj,
            lgModal: true
        }, (form: IDealBase, res: { data: IDeal}) => {
            if(res) {
                history.push({
                    pathname: `/spot-deals/${res.data.flow_date_id}`
                });
            }
        });
    }

    const renderPendingFlowDates = (item: IFlowDate) => {
        const actionBtns = [
            <AntButton 
                onClick={() => {
                    history.push({
                        pathname: `/spot-deals/${item.id}`
                    });
                }} 
            >
                View Unfinished Deals
            </AntButton>,
            <Popconfirm
                    title="Are you sure you want to remove all unfinished deals on this date?"
                    onConfirm={async () => await confirmFlowDateAndDelete((item.id as unknown) as keyof IFlowDate) }
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                >
                <AntButton 
                    icon={<DeleteFilled />} 
                />
            </Popconfirm>
            

        ];

        return (
            <List.Item
                actions={actionBtns}
            >
                <List.Item.Meta 
                    title={moment(item.date).format('YYYY-MM-DD')}
                    description={<Text italic>{item.spot_deal_count} pending deal{item.spot_deal_count > 1 ? 's' : ''}</Text>}
                />
            </List.Item>
        );
    }

    const selectDailyNomDate = async (selectedDate: Moment) => {
        setLoading(true);

        try {
            const res = await Axios.post('/api/flow-date/clear-daily-nom', {
                date: selectedDate.format('YYYY-MM-DD')
            });

            if (res.status === 200) {
                history.push({
                    pathname: `/spot-deals/${res.data.data.id}`
                });
            }
        } catch (err) {
            setLoading(false);
            context.handleError(err);
        }
    }

    const deleteFlowDate = async (pendingDateId: keyof IFlowDate) => {
        try {
            const res = await Axios.delete('/api/flow-date', { data: { id: pendingDateId } });
      
            if (res.status === 200) {                
                const newPendingDateBatch = pendingFlowDates ? pendingFlowDates.filter(pd => pd.id !== pendingDateId) : undefined;
                setPendingFlowDates(newPendingDateBatch);
            }          
          }
          catch (err){
            context.handleError(err);
          }
    }

    const confirmFlowDateAndDelete = async (pendingDateId: keyof IFlowDate) => {
        await deleteFlowDate(pendingDateId);
    };

    const getDataRecordsCount = useCallback(async () => {
        if (!selectedFlowDate) return;

        try {
            const res = await Axios.get('/api/document-chart/data-count', { params: { flow_date: selectedFlowDate?.format('YYYY-MM-DD') } });

            if(res.status === 200) {
                setHasDailyGasData(Boolean(res.data.data));
                setDataCountLoad(false);
            }
        }
        catch (err) {
            context.handleError(err);
        }
    }, [setHasDailyGasData, selectedFlowDate]);

    const uploadDailyGasDocument = () => {

        if (!selectedFlowDate) return;

        context.modal.show({
            title: 'Upload Gas Daily Document',
            form: {
                file: {
                    label: 'Upload File',
                    max: 1,
                    type: 'file',
                    name: 'file',
                    required: true
                },
                flow_date: {
                    type: 'hidden',
                    value: selectedFlowDate.format('YYYY-MM-DD')
                }
            },
            buttonText: 'Upload',
            url: '/api/document-chart',
            method: 'POST',
            }, (res: any) => {
                if(res.status === 200) {
                    setDataCountLoad(true);
                    setTimeout(() => getDataRecordsCount(), 4000);
                    context.notification.show('Gas Daily midpoints has been gathered successfully.', 'success', true);
                }
            });
    }

    const prepareDateRange = (deal: IDeal) => {
        const sameDates = moment(deal.start_date).isSame(deal.end_date);
        deal.date_range = sameDates ? '---' : `${moment(deal.start_date).format('MMM, DD YYYY')} - ${moment(deal.end_date).format('MMM, DD YYYY')}`;

        return deal;
    }

    const renderMultiMidpointDeals = () => {
        const data = multiMidpointSpotDeals?.map(prepareDateRange);
        const mediumScreenWidth = width && (width < 940 && width > 574);

        const renderAction = (deal: IDeal, index: number) => {
            const confirmDelete = async (dealObj: IDeal) => {
                //await deleteDeal(dealObj);
            }

            return (
                <Space size="middle">
                    <Popconfirm
                            title="Are you sure you want to delete this spot deal?"
                            onConfirm={async () => await confirmDelete(deal)}
                            onCancel={() => {}}
                            okText="Yes"
                            cancelText="No"
                    >
                        <AntButton icon={<DeleteFilled />} />
                    </Popconfirm>
                </Space>
              );
        };

        
        const columns: Array<any> = [
            {
                title: 'Spot Deal',
                render: (record: IDeal, index: number) => {

                    return (
                        <Fragment>
                            Volume: <Text strong>{record.volume}</Text>
                            <br />
                            Base Price: <Text strong>{record.base_price}</Text>
                            <br />
                            Pipeline: <Text strong ellipsis>{record.pipeline}</Text>
                            <br />
                            Position: <Text strong>{record.position}</Text>
                            <br />
                            Point: <Text strong>{record.point}</Text>
                            <br />
                            Company: <Text strong>{record.company}</Text>
                            <br />
                            Date Range: <Text strong>{record.date_range}</Text>
                            {renderAction(record, index)}

                        </Fragment>
                    );
                },
                responsive: ["xs"]
            },
            {
              title: 'Volume',
              dataIndex: 'volume',
              key: 'volume',
              sorter: (a: IDeal, b: IDeal) => a.volume - b.volume,
              responsive: ["sm"]
            },
            {
              title: 'Base Price',
              dataIndex: 'base_price',
              key: 'base_price',
              render: (base_price: number) => currency(base_price),
              sorter: (a: IDeal, b: IDeal) => a.base_price - b.base_price,
              responsive: ["sm"]
            },
            {
                title: 'Pipeline',
                dataIndex: 'pipeline',
                key: 'pipeline',
                sorter: (a: IDeal, b: IDeal) => a.pipeline.toLowerCase().localeCompare(b.pipeline.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Position',
                dataIndex: 'position',
                key: 'position',
                sorter: (a: IDeal, b: IDeal) => a.position.toLowerCase().localeCompare(b.position.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Point',
                dataIndex: 'point',
                key: 'point',
                sorter: (a: IDeal, b: IDeal) => a.point.toLowerCase().localeCompare(b.point.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Company',
                dataIndex: 'company',
                key: 'company',
                sorter: (a: IDeal, b: IDeal) => a.company.toLowerCase().localeCompare(b.company.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Date Range',
                dataIndex: 'date_range',
                key: 'date_range',
                sorter: (a: IDeal, b: IDeal) => a.start_date && b.start_date ? moment(a.start_date).diff(moment(b.start_date)) : 'N/A',
                responsive: ["sm"]
            },
            {
                title: '',
                key: 'static_midpoint',
                dataIndex: 'static_midpoint',
                responsive: ["sm"],
                render: (static_midpoint: number) => (
                    <>
                        {!static_midpoint &&
                            <Tag color="yellow">
                              Requires Daily Midpoint Price
                            </Tag>
                        }
                    </>
                )
            },
            {
                title: 'Action',
                key: 'action',
                render: renderAction,
                responsive: ["sm"]
              }
          ];


          function expandedRowRender(row: IDeal) {

            if (!row.midpoints.length) return null;

            const midpointColumns = [
                {
                    title: 'Midpoints',
                    render: (record: IMultiMidPoint) => {
    
                        return (
                            <Fragment>
                                Date: <Text strong>{moment(record.flow_date).format('YYYY-MM-DD')}</Text>
                                <br />
                                Midpoint Price: <Text strong>{currency(record.midpoint, 4)}</Text>
                                <br />
                                Final Price: <Text strong>{record.final_price ? currencyNoFloat(record.final_price) : ''}</Text>
                                <br />
                            </Fragment>
                        );
                    },
                    responsive: ["xs"]
                },
                {
                    title: 'Date',
                    dataIndex: 'flow_date',
                    key: 'flow_date',
                    responsive: ["sm"],
                    render: (flow_date: string) => moment(flow_date).format('YYYY-MM-DD')
                },
                {
                    title: 'Midpoint Price',
                    dataIndex: 'midpoint',
                    key: 'midpoint',
                    render: (midpoint: number) => currency(midpoint, 4),
                    sorter: (a: IDeal, b: IDeal) => a.midpoint - b.midpoint,
                    responsive: ["sm"]
                },
                {
                    title: 'Final Price',
                    dataIndex: 'final_price',
                    key: 'total_price',
                    render: (final_price: string) => final_price ? currency(final_price) : '',
                    sorter: (a: IMultiMidPoint, b: IMultiMidPoint) => parseFloat(a.final_price) - parseFloat(b.final_price),
                    responsive: ["sm"]
                },
            ];

            const data = row.midpoints;

            return (
                <div className={Style.subTableWrap}>
                    <p>Midpoint Prices</p>
                    <Table columns={midpointColumns as Array<any>} dataSource={data} pagination={false} bordered />
                </div>
            )
          }

          return (
            <Card title="Current spot deals that span across multiple days.">
                <Table 
                    columns={columns}
                    dataSource={data}
                    expandable={ 
                        {
                            expandedRowRender,
                            rowExpandable: (record) => !record.static_midpoint && Boolean(record.midpoints.length)
                        } 
                    }
                    bordered
                    scroll={{ x: mediumScreenWidth ? 1300 : undefined }}
                />
            </Card>
        );
    }

    return (
        <Animate type='pop'>
            <Card>
            {!dailyNomDateSelected &&
                <>
                    <p>Select a flow date to create a spot deal.</p>
                    <Grid cols="2">
                        <div>
                            {!dailyNomDateSelected &&
                                <DatePicker
                                    value={selectedFlowDate}
                                    style={{ width: '100%' }}
                                    onChange={(dateObj: Moment | null) => {
                                        if (dateObj) setSelectedFlowDate(dateObj);
                                    }}
                                    direction="ltr"
                                />
                            }
                            
                        </div>
                        {selectedFlowDate && 
                            <Space direction="horizontal">
                                <AntButton 
                                    size="large"
                                    onClick={() => createSpotDeal()} 
                                >
                                    Creat Spot Deal
                                </AntButton>
                                <AntButton 
                                    size="large"
                                    icon={<FileTextOutlined />}
                                    onClick={() => uploadDailyGasDocument()} 
                                >
                                    {hasDailyGasData ? 'Reupload' : 'Upload'} Gas Daily
                                </AntButton>
                            </Space>
                        }
                        
                    </Grid>
                </>  
            }   
            {dailyNomDateSelected && selectedFlowDate &&
                <Result
                    status="error"
                    title="Date Already Has Daily Nomination"
                    subTitle="If you choose to proceed with making changes on this date, the daily nomination will need to be resubmitted."
                    extra={[
                        <AntButton loading={loading} type="primary" key="console" onClick={async() => {
                            await selectDailyNomDate(selectedFlowDate);
                        }}>
                            Continue
                        </AntButton>,
                        <AntButton key="buy" onClick={() => {
                            setDailyNomDateSelected(false);
                        }}>Pick Another Date</AntButton>,
                    ]}
                />
            }           
            </Card>

            { multiMidpointSpotDeals && multiMidpointSpotDeals.length > 0 && renderMultiMidpointDeals() }

            <Card
                title="Flow Dates w/ Unfinished Spot Deals"
                loading={!pendingFlowDates}
            >
                {pendingFlowDates?.length === 0 &&
                    <p>Currently no pending spot deals.</p>
                }
                {pendingFlowDates && pendingFlowDates?.length > 0 &&
                    <List
                        size="small"
                        bordered
                        dataSource={pendingFlowDates}
                        renderItem={renderPendingFlowDates}
                    />
                }
            </Card>
        </Animate>
    );
}