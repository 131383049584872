/***
*
*   HOME NAV
*   Navigation used on the main external website. Renders a dashboard link
*   if the user is signed in, or a sign up link if they are not
*
**********/

import React, { Fragment, useContext } from 'react';
import { useLocation } from "react-router-dom";
import ClassNames from 'classnames';
import { AuthContext, Logo, Link, Content, useWindowSize } from 'components/lib';
import Style from './home.module.scss';

export function HomeNav(props){
  const location = useLocation();
  const { width } = useWindowSize();

  // context
  const context = useContext(AuthContext);

  // style
  const css = ClassNames([

    Style.nav,
    props.transparent && Style.transparent

  ]);

  return(
    <section className={ css }>
      <Content>

        { (location.pathname !== "/" || width <= 600) && <Logo className={ Style.logo }/> }

        <nav>

          { context.user?.token ?
            <Link btn small url='/dashboard' className={ Style.cta } text='Dashboard' /> :
            <Fragment>
              <Link url='/' text='Sign In' />
            </Fragment>
          }
        </nav>
      </Content>
    </section>
  );
}
