import { Companies } from 'views/settings/companies';
import { Features } from 'views/settings/features';
import { Locations } from 'views/settings/locations';
import { Utilities } from 'views/settings/utilities';
import { Pipelines } from 'views/settings/pipelines';

const Routes = [
    {
        path: '/settings',
        view: Features,
        layout: 'app',
        permission: 'user',
        title: 'Settings'
    }, 
    {
        path: '/settings/companies',
        view: Companies,
        layout: 'app',
        permission: 'user',
        title: 'Settings'
    },
    {
        path: '/settings/utilities',
        view: Utilities,
        layout: 'app',
        permission: 'user',
        title: 'Settings'
    },
    {
        path: '/settings/pipelines',
        view: Pipelines,
        layout: 'app',
        permission: 'user',
        title: 'Settings'
    },
    {
        path: '/settings/locations',
        view: Locations,
        layout: 'app',
        permission: 'user',
        title: 'Settings'
    }
];

export default Routes;