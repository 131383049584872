import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { AccountContext, Animate, Card, Grid, Loader, ViewContext } from 'components/lib';
import { InterruptibleFlow } from './interruptible-flow';
import { CreatePipelineRecord } from './create-pipeline-record';
import { OutOfBalanceList } from './out-of-balance-list';
import { DeleteDailyNominationConfirm } from './delete-daily-nomination-confirm';
import { useHistory, useLocation } from "react-router-dom";
import Axios from 'axios';
import moment from 'moment';
import Style from './daily-nomination.module.scss';

import { message, Alert, Badge, Button as AntButton, Collapse, Col, DatePicker, Divider, Result, Row, Table, Tooltip, Typography, Space } from 'antd';
import {
    DeleteFilled,
  } from '@ant-design/icons';

const { Text } = Typography;
const { Panel } = Collapse;

export function CreateDailyNomination() {
    const context = useContext(ViewContext);
    const { accountState } = useContext(AccountContext);

    const history = useHistory();
    const location = useLocation();
        
    const [loading, setLoading] = useState(false);

    const [flowDateData, setFlowDateData] = useState(undefined);
    const [emptyUtilityReqs, setHasEmptyUtilityReqs] = useState(false);
    const [hasCompletedDailyNom, setHasCompletedDailyNom] = useState(false);
    const [hasUncompleteSpotDeals, setHasUncompleteSpotDeals] = useState(false);
    const [chosenDate, setChosenDate] = useState(location && location.state && location.state.flowDate ? moment(location.state.flowDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'));
    const [volumeTotal, setVolumeTotal] = useState(undefined);

    const [outOfBalanceContracts, setOutOfBalanceContracts] = useState([]);

    const [utilities, setUtilities] = useState(accountState.utilities);
    const [pipelineRecords, setPipelineRecords] = useState([]);

    const [volMinimumInvalid, setVolMinimumInvalid] = useState(false);

    const [currentInterruptibles] = useState([]);
    const [successResult, setSuccessResult] = useState(false);
    const [showDeleteNomConfirm, setShowDeleteNomConfirm] = useState(false);
    const [resetDailyNom, setResetDailyNom] = useState(false);

    useEffect(() => {
        const getDailyVolume = async () => {
            try {
                const res = await Axios.get('/api/deal/avail-flow', { params: { date: chosenDate }});

                if (res.status === 200) {
                    const volume = res.data.data;

                    setVolumeTotal(volume ? volume : undefined);
                } 
            }
            catch(err) {
                context.handleError(err);
            }
        };

        const getUtilityReqs = async () => {
            try {
                const utilityIds = utilities.map(util => util.id);
                const res = await Axios.get('/api/company-utility-requirements', { params: { 
                    date: chosenDate,
                    latest: 1,
                    utility_ids: utilityIds
                }});

                if (res.status === 200) {
                    return res.data.data;
                } 
            }
            catch(err) {
                context.handleError(err);
            }
        }

        const getOutOfBalanceContracts = async () => {
            try {

                const res = await Axios.get('/api/contract/out-of-balance', { 
                    params: {
                        date: chosenDate
                    }
                });

                if (res.status === 200) {
                    const outOfBalanceData = res.data.data;

                    if(outOfBalanceData.length) {
                        
                        const batch = outOfBalanceData.reduce((arr, outOfBObj) => {
                            if (!arr.find(obj => obj.receipt_location_id === outOfBObj.receipt_location_id)) arr.push(outOfBObj);

                            return arr;
                        }, []);

                        setOutOfBalanceContracts(batch);
                    }
                }

            } catch(err) {
                context.handleError(err);
            }
        }

        const verifyUtilitiesHasReqs = async () => {
            const utilityReqData = await getUtilityReqs();
            
            const matchesUtilCount = () => {
                if(!utilityReqData.length) return false;

                const foundUtils = utilities.filter(util => {
                    return utilityReqData.find(utilReq => utilReq.utility_id === util.id);
                });

                return foundUtils.length === utilities.length;
            }

            if(matchesUtilCount()) {
                setUtilities(pairUtilitiesWithReqs(utilityReqData));
                setHasEmptyUtilityReqs(false);
                return true;
            } else {
                setHasEmptyUtilityReqs(true);
                return false;
            }

        }

        const pairUtilitiesWithReqs = (utilReqs) => utilities.map(util => {
            const utilReq = utilReqs.find(utilReq => utilReq.utility_id === util.id);
            if (utilReq) util.req = utilReq;
            return util;
        });

        const getDailyNomData = async (flowDateData, getRecentNom = false) => {

            const params = getRecentNom ? { date: flowDateData, get_recent_nom: getRecentNom } : { flow_date_id: flowDateData.id };

            try {
                const res = await Axios.get('/api/daily-nomination', {  params });

                if (res.status === 200) return res.data.data;          
              }
              catch (err){
                context.handleError(err);
              }
        }

        const getRecentDailyNom = async () => {
            const preparePrePopulatePLDate = (utilData) => {
                let PLBatch = [];

                utilData.forEach(utilObj => {

                    if (utilObj.pipelineRecords) {
                        const pLUtilBatch = utilObj.pipelineRecords.map(plObj => {
                            return {
                                utility_id: utilObj.utility_id,
                                pipeline_id: plObj.pipeline_id,
                                pipeline_name: plObj.name,
                                location: plObj.location,
                                quantity: plObj.quantity,
                                location_id: plObj.location_id,
                                contract_id: plObj.contract_id,
                                contract_num: plObj.contract_num
                            }
                        });
    
                        PLBatch = [...PLBatch, ...pLUtilBatch];
                    }
                    
                });

                return PLBatch;
            }
            
            const recentDailyNomData = await getDailyNomData(chosenDate, true);

            if(recentDailyNomData && recentDailyNomData.length) {
                const recentPLRecords = preparePrePopulatePLDate(recentDailyNomData);
                setPipelineRecords(recentPLRecords);
            } else {
                setPipelineRecords([]);
            }
        }

        const getFlowDataByDate = async () => {
            try {
                const res = await Axios.get('/api/flow-date', {
                    params: { date: chosenDate }
                });

                if(res.status === 200) {
                    return res.data.data;
                }
            } catch (err) {
                context.handleError(err);
            }
        };

        const getUncompleteSpotDeals = async (flowDateId) => {
            try {
                const res = await Axios.get('/api/deal/uncomplete-spot-deals', {
                    params: { flow_date_id: flowDateId }
                });

                if (res.status === 200) {
                    return res.data.data;
                }

            } catch (err) {
                context.handleError(err);
            }
        }

        const getDataRecordsCount = async (chosenflowDate) => {
            try {
                const res = await Axios.get('/api/document-chart/data-count', { params: { flow_date: moment(chosenflowDate).format('YYYY-MM-DD') } });
    
                if(res.status === 200) {
                    return res.data.data;
                }
            }
            catch (err) {
                context.handleError(err);
            }
        }

        const prepareUtilReqsAndVolume = async () => {
            const hasUtilReqsForDate = await verifyUtilitiesHasReqs();
            if (hasUtilReqsForDate) await getDailyVolume();
        }

        const resetState = () => {
            setHasUncompleteSpotDeals(false);
            setHasCompletedDailyNom(false);
            setResetDailyNom(false);
            setSuccessResult(false);
            setShowDeleteNomConfirm(false);
        }

        const prepareDailyNominationStates = async () => {
            resetState();
            setLoading(true);

            const flowDateData = await getFlowDataByDate();
            setFlowDateData(flowDateData);

            if (flowDateData && flowDateData.id) {
                const uncompleteSpotDeals = await getUncompleteSpotDeals(flowDateData.id);

                if (uncompleteSpotDeals.length) {
                    const dataRecordsCount = await getDataRecordsCount(flowDateData.date);
                    
                    // no gas daily or fixed prices for spot deals on this flow date
                    setHasUncompleteSpotDeals(!dataRecordsCount);
                }

                const dailyNomData = await getDailyNomData(flowDateData);
                setHasCompletedDailyNom(Boolean(!flowDateData.pending && dailyNomData.length));
            } 

            await prepareUtilReqsAndVolume();
            await getOutOfBalanceContracts();

            setLoading(false);
        }

        if(chosenDate || resetDailyNom) {
            prepareDailyNominationStates();
        }

        if(volumeTotal) {
            getRecentDailyNom();
        }

    }, [chosenDate, volumeTotal, resetDailyNom]);

    useEffect(() => {
        
        if (volMinimumInvalid) {
            message.error((<Text>The pipeline records volume total is greater than the available total volume of all deals which is <strong>{volumeTotal}</strong></Text>));
        }

    }, [volMinimumInvalid]);

    const getOptionData = useCallback((data) => data.map(obj => ({
        label: obj.name,
        value: obj.id
    })), []);

    const removePipelineRecord = (pipelineRecordObj) => {
        const deletePRString = JSON.stringify(pipelineRecordObj);
        const newRecords = pipelineRecords.filter(pipelineRecord => deletePRString !== JSON.stringify(pipelineRecord) );
        const util = utilities.find(ut => ut.id === pipelineRecordObj.utility_id);
        
        updateUtilityStorageAmount(util, 0);
        setPipelineRecords(newRecords);
    }

    const addPipeLineRecord = (plrBatch) => {
        const cleanBatch = plrBatch.filter(plr => {
            const duplicate = pipelineRecords.filter(obj => obj.contract_id === plr.contract_id && obj.location_id === plr.location_id && obj.pipeline_id === plr.pipeline_id);
            
            return !duplicate.length;
        });

        setPipelineRecords([...pipelineRecords, ...cleanBatch]);
    }

    const getPLRecordsQuantityTotal = () => pipelineRecords.reduce((sum, pl) => parseInt(sum) + parseInt(pl.quantity), 0);

    const validVolumeAmount = () => {
        const pLRTotalQuanitity = getPLRecordsQuantityTotal();
        return pLRTotalQuanitity && pLRTotalQuanitity <= volumeTotal;
    }

    const listPipelineRecords = (pipelineRecordsListData) => {

        if (!pipelineRecordsListData.length) {
            return (
                <p>Add some pipeline records.</p>
            );
        }

        const renderActions = (record, index) => ((
            <Space wrap>
                <AntButton icon={<DeleteFilled />} onClick={() => removePipelineRecord(record)} />
            </Space> 
        ));

        const sendToContract = async (contractObj) => {
            await saveWithoutInterruptibles();

            history.push({
                pathname: `/contracts/${contractObj.contract_id}`
            });
        }
        
        const columns = [
            {
                title: 'Pipeline Records',
                render: (record, index) => {
                    return (
                        <Fragment>
                            Quantity: <Text strong>{record.quantity}</Text>
                            <br />
                            Contract: <AntButton type="link" onClick={() => sendToContract(record)}>{record.contract_num}</AntButton>             
                            <br />
                            Location: <Text strong>{record.location}</Text>
                            <br />
                            Pipeline: <Text strong ellipsis>{record.pipeline_name}</Text>
                            <Divider />
                            {renderActions(record)}
                        </Fragment>
                    );
                },
                responsive: ["xs"]
            },
            {
                title: 'Quantity',
                dataIndex: 'quantity',
                key: 'quantity',
                sorter: (a, b) => a.quantity - b.quantity,
                responsive: ["sm"]
            },
            {
                title: 'Contract',
                dataIndex: 'contract_num',
                key: 'contract_num',
                render: (contract_num, contactObj) => {
                    return (
                        <Tooltip title="View Contract">
                            <AntButton type="link" onClick={() => sendToContract(contactObj)}>{contract_num}</AntButton>             
                        </Tooltip>
                    );
                },
                sorter: (a, b) => a.contract_num.toLowerCase().localeCompare(b.contract_num.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Location',
                dataIndex: 'location',
                key: 'location',
                sorter: (a, b) => a.location.toLowerCase().localeCompare(b.location.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Pipeline',
                dataIndex: 'pipeline_name',
                key: 'pipeline_name',
                sorter: (a, b) => a.pipeline_name.toLowerCase().localeCompare(b.pipeline_name.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Action',
                key: 'action',
                render: renderActions,
                responsive: ["sm"]
            },
        ]
        

        return (
            <Table
                columns={columns} 
                dataSource={pipelineRecordsListData} 
                bordered
                pagination={{
                    pageSize: 5
                }}
            >
            </Table>
        );    
    }

    const getQuantityStatus = (currentTotal, requiredAmount) => {
        if(requiredAmount > currentTotal) {
            return 'short';
        } else if(requiredAmount < currentTotal) {
            return 'long';
        }
    }

    const getUtilQuantityStatus = (util) => {
        const currentTotal = getUtilTotalVolume(util);
        const requirement = util.req.requirement;

        return getQuantityStatus(currentTotal, requirement);
    }

    const getPLRQuantityStatus = (util) => {
        const currentTotal = getUtilTotalPLRVolume(util.id);
        const requirement = util.req.requirement;

        return getQuantityStatus(currentTotal, requirement);
    }

    const getUtilTotalPLRVolume = (utilId) => pipelineRecords
        .filter(pl => pl.utility_id === utilId)
        .reduce((sum, pl) => parseInt(pl.quantity) + parseInt(sum), 0);

    const getUtilTotalVolume = (util) => {
        const storageAmt = parseInt(util.storage_amount) || 0;
        const pLRVolTotal = getUtilTotalPLRVolume(util.id);

        if(pLRVolTotal > util.req.requirement) {
            return parseInt(getUtilTotalPLRVolume(util.id)) - storageAmt;
        }

        return parseInt(getUtilTotalPLRVolume(util.id)) + storageAmt;        
    }

    const isWithdrawal = status => status === 'short';

    const showUtilPipelineRecords = () => {
        return utilities.map((util, idx) => {
            const utilQuantityTotalStatus = getUtilQuantityStatus(util);
            const plrQuantityTotalStatus = getPLRQuantityStatus(util);
            const pipelineRecordsListData = pipelineRecords.filter(plrecord => plrecord.utility_id === util.id);

            if (pipelineRecordsListData.length) pipelineRecordsListData.sort((a, b) => a.pipeline_name.toLowerCase().localeCompare(b.pipeline_name.toLowerCase()));
            
            const renderUtilStorageAmount = () => {
                const storageAmt = parseInt(util.storage_amount);

                return (plrQuantityTotalStatus && storageAmt > 0 && 
                    <Space direction="horizontal">
                        <Text>{isWithdrawal(plrQuantityTotalStatus) ? 'Withdrawal' : 'Injection'}:</Text>
                        <Text>{ storageAmt }</Text>
                    </Space>
                )
            }

            const renderPipelineRecordCount = () => (
                <Space direction="horizontal">
                    <Text className={Style.pipelineRecordCount}>Pipeline Records:</Text>
                    <Text className={Style.pipelineRecordCount}>{ pipelineRecordsListData.length }</Text>
                </Space>
            );

            return (
                <div className={Style.utilPipelineSection}>
                    <Badge.Ribbon text={renderPipelineRecordCount()} color='#383838d9'>
                    <Collapse>
                        <Panel 
                            header={(
                                <span className={Style.utilHeaderSection}>
                                    <Space direction="vertical">
                                        <Text strong>{ util.name }</Text>
                                        

                                        <Space direction="vertical">

                                            { renderUtilStorageAmount() }

                                            <Space direction="horizontal">
                                                <Text>Required Volume: </Text>
                                                <Text>{util.req.requirement}</Text>
                                            </Space>

                                            <Space direction="horizontal">
                                                <Text>Current Total:</Text>
                                                <Badge 
                                                    count={`${getUtilTotalVolume(util)} ${utilQuantityTotalStatus ? `- ${utilQuantityTotalStatus.toUpperCase()}` : ''}`} 
                                                    showZero 
                                                    overflowCount={1000000}
                                                    color={utilQuantityTotalStatus ? '#ff4d4f' : '#2e9f00' } />
                                            </Space>
                                        </Space>

                                        
                                    </Space>
                                </span>
                            )} 
                            key={idx} 
                            extra={(
                                <>
                                    <Space direction='vertical' className={Style.utilityMenu}>
                                        <AntButton 
                                            block
                                            size='small'
                                            onClick={async (e) => {
                                                e.stopPropagation();
                                                await openPipelineRecordForm(util);
                                            } }>
                                                Add Record
                                        </AntButton>
                                        <AntButton 
                                            block
                                            size='small'
                                            onClick={ (e) => {
                                                e.stopPropagation();
                                                openUtilStorageModal(util);
                                            } }>
                                            {isWithdrawal(utilQuantityTotalStatus) ? 'Make Withdrawal' : 'Make Injection'}     
                                        </AntButton>
                                    </Space>
                                </>
                            )}
                        >
                            { listPipelineRecords(pipelineRecordsListData) }
                        </Panel>
                    </Collapse>
                    </Badge.Ribbon>
                </div>
            );
        });
    }

    const showOutOfBalanceAlert = () => {
        return (
            <Alert
                    showIcon
                    description="Some of your receipt locations are out of balanace."
                    type="error"
                    action={
                    <AntButton size="small" danger onClick={() => openOutOfBalanceModal()}>
                        Details
                    </AntButton>
                    }
              />
        );
    }

    const getPipeLines = async (util) => {
        try {
            const res = await Axios.get('/api/pipeline', {  params: { utility_id: util.id } });
      
            if (res.status === 200) {
                const pipelineRes = res.data.data;
                if (pipelineRes.length) return getOptionData(pipelineRes);
            }          
          }
          catch (err){
            context.handleError(err);
          }
    }

    const openOutOfBalanceModal = async () => {

        context.modal.show({
            title: 'Out Of Balance at Receipt Locations',
            customForm: OutOfBalanceList,
            formProps: {
                outOfBalanceContracts
            },
            lgModal: true
        });

    }

    const openPipelineRecordForm = async (util) => {
        const pipelineOptions = await getPipeLines(util);

        context.modal.show({
            title: `Create Pipeline Record - Utility: ${util.name}`,
            customForm: CreatePipelineRecord,
            formProps: {
                utilityId: util.id,
                pipelines: pipelineOptions,
                chosenDate
            },
            lgModal: true
        }, (form, res) => {
            addPipeLineRecord(res.pipelineRecords);
            updateUtilityStorageAmount(util, 0);
            context.modal.hide(true);
        });
        
    }

    const openUtilStorageModal = (util) => {
        const status = getUtilQuantityStatus(util);
        const isWithdrawal = status === 'short'; 
        const utilPRTotalVol = getUtilTotalVolume(util);

        const difference = isWithdrawal ? util.req.requirement - utilPRTotalVol : utilPRTotalVol - util.req.requirement; 

        context.modal.show({
            title: `${isWithdrawal ? 'Withdrawal from' : 'Make Injection into'} ${util.name}`,
            form: {
                header: {
                    type: 'header',
                    id: 'header',
                    label: (
                        <Space direction='vertical'>
                            { isWithdrawal && <Text italic>Max Withdrawal is: {util.req.max_withdrawal}</Text> }
                            { !isWithdrawal && <Text italic>Max Injection is: {util.req.max_injection}</Text> }
                            <Text italic>Amount to {isWithdrawal ? 'withdraw' : 'inject'} to meet requirement: {difference}</Text>
                        </Space>
                    )
                },
                amount: {
                    label: `Utility Storage ${isWithdrawal ? 'Withdrawal' : 'Injection'}`,
                    type: "number",
                    required: true,
                    name: "amount",
                    id: "amount",
                    value: util.storage_amount
                }
            },
            buttonText: 'Save'
        }, (form) => {
            updateUtilityStorageAmount(util, form.amount)
            context.modal.hide(true);
        });
    }

    const updateUtilityStorageAmount = (util, amount) => {
        const updatedUtils = utilities.map(ut => {
            if (ut.id === util.id) util.storage_amount = amount;
            return ut;
        });

        setUtilities(updatedUtils);
    }

    const preparePayloadData = () => {
        const data = [];

        utilities.forEach(util => {
            const obj = { utility_id: util.id, pipeline_records: [] };

            pipelineRecords.forEach(pl => {
                if(pl.utility_id === util.id) {
                    obj.pipeline_records.push(pl);
                }
            });

            if (util.storage_amount) {
                const status = getPLRQuantityStatus(util);
                const isWithdrawal = status === 'short';

                obj.storage = {
                    amount: util.storage_amount,
                    is_withdrawal: isWithdrawal 
                };
            }

            data.push(obj);
        });

        return data;
    }

    const getInterruptibles = async () => {
        try {
            const utilityIds = utilities.map(util => util.id);
            const res = await Axios.get('/api/interruptible', {  params: { utility_ids: utilityIds, current: chosenDate } });
            
            if (res.status === 200) {
                return res.data.data;
            }
        } catch(err) {
            context.handleError(err);
        }
    }

    const isValid = () => {
        let emptyUtils = false;

        utilities.forEach(util => {
            const plBatch = pipelineRecords.filter(pl => pl.utility_id === util.id);
            if(!plBatch.length) emptyUtils = true;
        });

        return !emptyUtils;
    };

    const storeDailyNomination = async (flowDateId, flows) => {
        return await Axios.post('/api/daily-nomination', { 
            flow_date_id: flowDateId,
            pipeline_record_data: preparePayloadData(),
            dths_flows: flows
        });
    }

    const openInterruptibleFlow = async (currentInterruptibles, save, cb) => {
        context.modal.show({
            title: `Interruptibles`,
            customForm: InterruptibleFlow,
            formProps: {
                save,
                flowDate: chosenDate,
                currentInterruptibles
            },
            lgModal: true
        }, cb);
        
    }

    const submitDailyNomination = async (flows, save) => {
        setLoading(true);

        try {
            const flowDateDataObj = flowDateData ? flowDateData : await createFlowDate();
            const dailyNominationRes = await storeDailyNomination(flowDateDataObj.id, flows);

            if (dailyNominationRes.status === 200) {
                if (!save) {
                    // deals are completed
                    const completeDealsRes = await Axios.post('/api/deal/complete', { 
                        flow_date_id: flowDateDataObj.id
                    });

                    if (completeDealsRes.status === 200) {
                        setLoading(false);
                        setSuccessResult(true);
                    }
                } else {
                    setLoading(false);
                    context.notification.show(`Daily nomination data has been saved for ${chosenDate}.`, 'success', true);
                }

            }       
        } catch (err){
            setLoading(false);
            context.handleError(err);
        }
    }

    const saveWithoutInterruptibles = async () => {
        setLoading(true);
        await submitDailyNomination(null, true);
        setLoading(false);
    }

    const submit = async () => {
        /*if(!validVolumeAmount()) {
            setVolMinimumInvalid(true);
            return;
        }*/

        if(volMinimumInvalid) setVolMinimumInvalid(false);

        if(isValid()) {
            setLoading(true);

            // check if utility has a current interruptible
            const interruptibleBatch = await getInterruptibles();
            setLoading(false);

            if(interruptibleBatch?.length) {
                openInterruptibleFlow(interruptibleBatch, false, (form, flows) => {
                    if (Object.keys(flows)?.length) {
                        submitDailyNomination(flows, false);
                    }
                });

                setLoading(false);   
            } else {
                await submitDailyNomination(null, false);
            }
        }             
    }

    const createFlowDate = async () => {
        try {
            const res = await Axios.post('/api/flow-date', {
                date: moment(chosenDate).format('YYYY-MM-DD')
            });

            if (res.status === 200) {
                setFlowDateData(res.data.data);
                return res.data.data;
            }
        } catch (err) {
            context.handleError(err);
        }
    }

    const goToReports = () => {
        history.push({
            pathname: '/reports',
            state: { flowDate: flowDateData.id }
        });
    }

    const onChangeDate = (date, dateString) => {
        setChosenDate(dateString);
    }

    const closeConfirm = (status) => {
        if (!status) {
            setShowDeleteNomConfirm(false);
        } else {
            setResetDailyNom(true);
        }
    }

    return (
        <Animate type='pop'>
            {loading && <Loader fullScreen />}
            {successResult &&
                <Card>
                    <Result
                        status="success"
                        title="Daily nomination has been submitted"
                        extra={[
                        <AntButton 
                            type="primary" 
                            key="console"
                            onClick={() => goToReports()}>
                            View Report
                        </AntButton>,
                        <AntButton 
                            key="console"
                            onClick={() => {
                                setResetDailyNom(true);
                            }}>
                            Create Another Daily Nomination
                        </AntButton>
                        ]}
                    />
                </Card>
            }
            {!successResult && 
                <Card>
                    <Row>
                        <Col xs={24} md={14}>
                            <p className={Style.selectDatePrompt}>Select a date to submit a daily nomination for.</p>
                        </Col>

                        <Col xs={24} md={{ span: 6, offset: 4}}>
                            <DatePicker 
                                style={{ width: '100%' }}
                                value={moment(chosenDate, 'YYYY-MM-DD')}
                                onChange={onChangeDate} 
                                allowClear={false}
                            />
                        </Col>
                    </Row>
                </Card>
            }
            
            
            {!successResult && !loading && hasCompletedDailyNom &&
                <Card>
                    {showDeleteNomConfirm &&
                        <DeleteDailyNominationConfirm
                            selectedDate={moment(chosenDate)}
                            closeConfirm={closeConfirm}

                        />
                    }

                    {!showDeleteNomConfirm &&
                        <Space direction='horizontal'>
                            <Text>This chosen date already has a completed daily nomination.</Text>
                            <AntButton onClick={() => {
                                setShowDeleteNomConfirm(true);
                            }}>Delete Daily Nomination</AntButton>
                        </Space>
                    }
                </Card>
            }

            {!emptyUtilityReqs && 
             !successResult && 
             !loading && 
             !hasCompletedDailyNom && 
             !volumeTotal &&
                <Card>
                    There is no volume available to supply for the chosen date.
                </Card>
            }

            {!loading && emptyUtilityReqs && !hasCompletedDailyNom &&
                <Card>
                    <Alert
                        message="Make sure you enter the requirements for each utility before submitting a daily nomination."
                        type="error"
                    />
                </Card>
            }

            {!emptyUtilityReqs && !successResult && !hasCompletedDailyNom && volumeTotal &&
                <Card
                    title="Add Pipeline Records For Each Utility"
                >
                    <div className={Style.dailyNominationFormWrap}>
                        {!currentInterruptibles.length && 
                        <>
                            <div>
                                <Grid cols="2">
                                    <Space direction='vertical'>
                                        <Alert
                                            message="Each utility must have at least one pipeline record assigned to it."
                                            type="info"
                                            showIcon
                                        />
 
                                        <Alert
                                            message={(<Text>Total volume (quantity) of all deals on <strong>{moment(chosenDate).format('LL')}</strong>: &nbsp;&nbsp;<strong>{volumeTotal}</strong></Text>)}
                                            type="info"
                                            showIcon
                                        />
                                        {volMinimumInvalid && 
                                            <Alert
                                                message={`The pipeline records volume total is greater than the available total volume of all deals which is ${volumeTotal}`}
                                                type="error"
                                            />
                                        }
                                    </Space>
                                </Grid>

                                {outOfBalanceContracts.length > 0 &&
                                    <Grid cols="2">
                                         { showOutOfBalanceAlert() }
                                    </Grid> 
                                }

                                { showUtilPipelineRecords() }

                                <Space 
                                    direction='horizontal' 
                                    className={(getPLRecordsQuantityTotal() <= volumeTotal) ? Style.quantityError : Style.quantitySuccess }>
                                        <Text strong>Current Total Quantity: </Text>
                                        <Text strong>{getPLRecordsQuantityTotal()}</Text>
                                        
                                </Space>
                                <Divider />
                            </div>
                            <div className={Style.btnMenu}>
                                <Space direction='vertical'>
                                    {hasUncompleteSpotDeals &&
                                    <div>
                                        <Alert
                                            message={(
                                                <p>
                                                    This chosen date has spot deals without a "Fixed Price".
                                                    <br />
                                                    Upload a Gas Daily document so the midpoint price can be retrieved for each of those deals to calculate their final price.
                                                    <br />
                                                    Please go back to this date within the "Spot Deals" page and upload the Gas Daily or provide a "Fixed Price".
                                                    <br /> 
                                                    Until this is done you will only be able to save your daily nomination entries.                                                    
                                                </p>
                                            )}
                                            type="error"
                                            />
                                            </div>
                                    }
                                    <div>
                                    
                                    <Space direction='horizontal'>
                                        <AntButton
                                            disabled={!isValid() || hasUncompleteSpotDeals}
                                            loading={ loading }
                                            onClick={ () => submit() }
                                            size="large"
                                        >
                                            Submit Daily Nomination & Finalize Deals
                                        </AntButton>
                                                
                                        <AntButton
                                            disabled={!pipelineRecords.length}
                                            loading={ loading }
                                            onClick={ () => saveWithoutInterruptibles() }
                                            size="large"
                                        >
                                            Save
                                        </AntButton>
                                    </Space>
                                    
                                    </div>
                                </Space>
                            </div>
                        </>
                        }
                    </div>
                </Card>
            }
        </Animate>
    );
}