import React, { useContext, useState } from 'react';
import Axios from 'axios';
import Style from './new-deal.module.scss';
import { AccountContext, Button, Grid, Label, NumberInput, Select, Switch, TextInput, ViewContext } from 'components/lib';
import moment from 'moment';

import { IDeal, ILTDealForm } from 'ts/interfaces/deal';
import { KeyPair } from 'ts/interfaces/general';

import { Alert, DatePicker } from 'antd';
const { RangePicker } = DatePicker;

export function CreateLongTermDeal({ updateDeal }: { updateDeal: IDeal | undefined }) {

    const formObj: ILTDealForm = {
        pipeline_id: undefined,
        point_id: undefined,
        position_id: undefined,
        company_id: undefined,
        volume: undefined,
        base_price: undefined,
        final_price: undefined,
        deal_num: undefined,
        start_date: undefined,
        end_date: undefined
    };

    const getUpdateDealObj = (): ILTDealForm => {
        const updateDealObj: {[x: string]: any} = {...updateDeal};
        const formObjKeys = Object.keys(formObj);
        const newFormObj: {[x: string]: any} = {};

        formObjKeys.forEach(key => {
            newFormObj[key] = updateDealObj[key];
        });
        
        return (newFormObj as unknown) as ILTDealForm;
    }

    const getOptionData = (data: Array<{ name: string, id: string }> | undefined): Array<KeyPair<string, string>> => data ? data.map((obj: { name: string, id: string }) => ({
        label: obj.name,
        value: obj.id
    })) : [];

    const context = useContext(ViewContext);
    const { accountState } = useContext(AccountContext);

    const monthFormat = 'YYYY-MM';
    const [loading, setLoading] = useState<boolean>(false);
    const [formState, setFormState] = useState<ILTDealForm>(updateDeal ? getUpdateDealObj() : formObj);

    const [points] = useState(getOptionData(accountState.points));
    const [pipelines] = useState(getOptionData(accountState.pipelines));
    const [positions] = useState(getOptionData(accountState.positions));
    const [companies] = useState(getOptionData(accountState.companies));

    const [isGated, setIsGated] = useState<boolean>(Boolean(updateDeal?.is_gate) || false);

    const isValid = () => {
        return (
            formState.pipeline_id && 
            formState.point_id && 
            formState.position_id && 
            formState.company_id && 
            formState.volume !== undefined && !isNaN(formState.volume) && 
            formState.base_price !== undefined && !isNaN(formState.base_price) && 
            formState.start_date &&
            formState.end_date
        ) ? true : false;
    }

    const submit = async () => {
        if(updateDeal) {
            await submitUpdateDeal();
            return;
        }

        await submitNewDeal();
    }

    const prepareDatePayloadData = (startDate: string | undefined, endDate: string | undefined): { startDate: string, endDate: string} | void => {
        if (!startDate || !endDate) return;
        
        let startDateObj = moment(startDate);
        let endDateObj = moment(endDate);

        startDateObj = startDateObj.startOf('month').startOf('day');
        endDateObj = endDateObj.endOf('month').startOf('day');

        return {
            startDate: startDateObj.toISOString(),
            endDate: endDateObj.toISOString()
        }
    }

    const submitNewDeal = async () => {
        setLoading(true);
        
        const dateData = prepareDatePayloadData(formState.start_date, formState.end_date);

        if (!dateData) {
            setLoading(false);
            return;
        }

        try {
            const res = await Axios.post('/api/deal', { 
                ...formState, 
                start_date: dateData.startDate, 
                end_date: dateData.endDate,
                current_date: moment().format('YYYY/MM/DD'),
                is_long_term: true,
                is_gate: isGated
            });

            if (res.status === 200) {
                setLoading(false);
                context.modal.hide(false, res.data);
            }
        } catch (err) {
            setLoading(false);
            context.handleError(err);
        }
    }

    const submitUpdateDeal = async () => {
        if (!updateDeal) return;

        setLoading(true);

        const dateData = prepareDatePayloadData(formState.start_date, formState.end_date);

        if (!dateData) {
            setLoading(false);
            return;
        }

        try {
            const res = await Axios.patch('/api/deal', {
                ...formState,
                start_date: dateData.startDate, 
                end_date: dateData.endDate,
                id: updateDeal.id,
                is_gate: isGated
            });

            if (res.status === 200) {
                setLoading(false);
                context.modal.hide(false, res.data);
            }
        } catch (err) {
            setLoading(false);
            context.handleError(err);
        }
    }

    const setValue = (id: string, value: any) => {
        if(id in formState) {
            setFormState({...formState, [id]: value });
        }
    }

    const onDateRangeChange = (dateObjs: any, dates: [string, string]) => {
        if(dates.length === 2) {

            console.log('selected date objs', dateObjs);
            console.log('selected dates -- ', dates);

            setFormState({
                ...formState,
                start_date: dates[0],
                end_date: dates[1]
            });
        }
    }

    const getMinimumDate = () => {
        // moment obj day / month are zero based
        const currentDate = moment();
        const year = currentDate.year();
        const month = currentDate.month();
        const day = currentDate.day();

        let minMonth = (!day) ? month : month + 1;
        const minMonthStr = String(minMonth).length === 1 ? `0${minMonth + 1}` : minMonth + 1;
        const minYear = (month < 0) ? year - 1 : year; 
        const minDay = '01';

        return `${minYear}-${minMonthStr}-${minDay}`;
    }

    return (
        <div className={Style.createDeal}>
            <Grid cols="1">
                <div>      
                    <Alert
                        message='Once a long term deal is saved, it can be viewed in the "Deal Logs" upon creation and only go into affect on its start date.'
                        type="info"
                        showIcon
                    />              
                </div>
            </Grid>
            <Grid cols="4">
                <div>
                    <Select
                            customLabel="Pipeline"
                            required
                            name="pipeline_id"
                            id="pipeline_id"
                            onChange={setValue}
                            default={formState.pipeline_id}
                            value={formState.pipeline_id}
                            options={pipelines}
                        />
                </div>
                <div>
                    <Select
                            customLabel="Point"
                            required
                            name="point_id"
                            id="point_id"
                            onChange={setValue}
                            default={formState.point_id}
                            value={formState.point_id}
                            options={points}
                        /> 
                </div>
                <div>
                    <Select
                            customLabel="Position"
                            required
                            name="position_id"
                            id="position_id"
                            onChange={setValue}
                            default={formState.position_id}
                            value={formState.position_id}
                            options={positions}
                        /> 
                </div>
                <div>
                    <Select
                            customLabel="Company"
                            required
                            name="company_id"
                            id="company_id"
                            onChange={setValue}
                            default={formState.company_id}
                            value={formState.company_id}
                            options={companies}
                        /> 
                </div>
            </Grid>
            <Grid cols="2">
                <div>
                    <NumberInput 
                            label="Volume Per Day"
                            required
                            placeholder="0"
                            name="volume"
                            id="volume"
                            onChange={setValue}
                            value={formState.volume}
                    />
                </div>
                <div>
                    <NumberInput 
                            label="Base Price"
                            required
                            isFloat
                            isNegative
                            decimalLimit={4}
                            placeholder="0.00"
                            name="base_price"
                            id="base_price"
                            onChange={setValue}
                            value={formState.base_price}
                    />
                </div>
            </Grid>
            <Grid cols="2">
                <div>
                    <TextInput 
                        label="Deal #"
                        name="deal_num"
                        id="deal_num"
                        onChange={setValue}
                        value={formState.deal_num}
                    />
                </div>
                <div style={{ paddingTop: '16px' }}>   
                    <Switch 
                        label="Is Gated"
                        default={isGated}
                        name="is_gated"
                        onChange={(id: string, status: boolean) => setIsGated(status)} 
                    />
                    
                </div>
            </Grid>
            <div className={Style.dateOptionsSection}>
                <Label text="Select Date Range of Term (Term will end on 11:59 PM on the last day of chosen end month.)" />
                <div>
                    <RangePicker 
                        onChange={onDateRangeChange} 
                        picker="month"
                        //disabledDate={d => !d || d.isBefore(getMinimumDate())} 
                        defaultValue={formState.start_date && formState.end_date
                            ? [
                                moment(formState.start_date, monthFormat), 
                                moment(formState.end_date, monthFormat)
                            ] : undefined
                        }
                        direction="ltr"
                    />
                </div>
            </div>
            <div className={Style.btnMenu}>
                {isValid() &&
                    <Button
                        fullWidth
                        loading={ loading }
                        text="Create Deal"
                        action={ submit }
                    />
                }
                <Button fullWidth outline text='Cancel' action={ () => context.modal.hide(true) } />
            </div>
        </div>
    );
}