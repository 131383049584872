import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { AccountContext, Animate, ViewContext, Button, Card, Grid, useWindowSize } from 'components/lib';
import { DealLogFilter } from './deal-log-filter';
import { DealService } from 'services';
import { currency, currencyNoFloat } from 'utilities/currency';

import Style from './deal-logs.module.scss';
import moment from 'moment';
import * as FileSaver from 'file-saver';

import { Col, Collapse, Row, Table, Typography } from 'antd';

const { Panel } = Collapse;
const { Text } = Typography;

export function DealLogs() {
    const context = useContext(ViewContext);
    const { accountState } = useContext(AccountContext);
    const { width } = useWindowSize();

    const limit = 20;
    const [deals, setDeals] = useState([]);
    const [summaryData, setSummaryData] = useState([]);
    const [searched, setSearched] = useState(false);

    const filterObj = {
        startDate: undefined,
        endDate: undefined,
        dealType: undefined,
        selectedCompany: undefined,
        isGated: undefined
    };

    const [filters, setFilters] = useState(filterObj);

    const getOptionData = (data) => data.map(obj => ({
        label: obj.name,
        value: obj.id
    }));

    const [companies] = useState(getOptionData(accountState.companies));
    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState({
        current: 1,
        pageSize: 10,
        total: 100
    });

    const [pipelineAvails, setPipelineAvails] = useState(undefined);
    const [pipelineAvailLoading, setPipelineAvailLoading] = useState(false);


    const getPipelineAvailableSpace = async (data) => {
        try {
            setPipelineAvailLoading(true);

            const res = await DealService.getPipelineLTAvailableSpace({
                current_date: data.startDate,
                company_id: data.selectedCompany,
            });
          
            if (res.status === 200) {     
                setPipelineAvails(res.data.data);
                setPipelineAvailLoading(false);
            } 
        } catch (err) {
            setPipelineAvailLoading(false);
            context.handleError(err);
        }

    };


    const renderDealsList = () => {
        if(!deals.length) return;

        return filters.dealType ? getLongTermDealTable() : getSpotDealTable();
    }

    const renderSummary = () => {
        if(!deals.length) return;

        return filters.dealType ? getLongTermDealSummary() : getSpotDealSummary();
    }

    const handleTableChange = (pagination) => {
        const { current, pageSize } = pagination;
        const totalPages = Math.floor(current * pageSize);

        setPageData({ ...pageData, current });

        if (totalPages === deals.length && deals.length < pageData.total) {
            getDeals({ 
                startDate: filters.startDate, 
                endDate: filters.endDate, 
                dealType: filters.dealType, 
                selectedCompany: filters.selectedCompany, 
                offSet: totalPages, 
                current 
            });
        }
    }

    const getSpotDealTable = () => {
        const data = deals.map(deal => {
            const sameDates = moment(deal.start_date).isSame(deal.end_date);
            deal.date_range = sameDates ? moment(deal.start_date).format('MMM, DD YYYY') : `${moment(deal.start_date).format('MMM, DD YYYY')} - ${moment(deal.end_date).format('MMM, DD YYYY')}`;
            return deal;
        });

        const columns = [
            {
                title: 'Spot Deal',
                render: (record, index) => {

                    return (
                        <Fragment>
                            Volume: <Text strong>{record.volume}</Text>
                            <br />
                            Base Price: <Text strong>{currency(record.base_price, 4)}</Text>
                            <br />
                            Midpoint: <Text strong>{currency(record.midpoint)}</Text>
                            <br />
                            Final Price: <Text strong>{currency(record.final_price)}</Text>
                            <br />
                            Pipeline: <Text strong ellipsis>{record.pipeline}</Text>
                            <br />
                            Position: <Text strong>{record.position}</Text>
                            <br />
                            Point: <Text strong>{record.point}</Text>
                            <br />
                            Company: <Text strong>{record.company}</Text>
                            <br />
                            Date Range: <Text strong>{record.date_range}</Text>
                        </Fragment>
                    );
                },
                responsive: ["xs"]
            },
            {
              title: 'Volume',
              dataIndex: 'volume',
              key: 'volume',
              sorter: (a, b) => a.volume - b.volume,
              responsive: ["sm"]
            },
            {
              title: 'Base Price',
              dataIndex: 'base_price',
              key: 'base_price',
              render: (base_price) => currency(base_price, 4),
              sorter: (a, b) => a.base_price - b.base_price,
              responsive: ["sm"]
            },
            {
                title: 'Midpoint',
                dataIndex: 'midpoint',
                key: 'midpoint',
                render: (midpoint) => midpoint ? currency(midpoint) : '',
                sorter: (a, b) => a.midpoint - b.midpoint,
                responsive: ["sm"]
              },
            {
                title: 'Final Price',
                dataIndex: 'total_price',
                key: 'total_price',
                render: (total_price) => total_price ? currencyNoFloat(total_price) : '',
                sorter: (a, b) => a.total_price - b.total_price,
                responsive: ["sm"]
            },
            {
                title: 'Pipeline',
                dataIndex: 'pipeline',
                key: 'pipeline',
                sorter: (a, b) => a.pipeline.toLowerCase().localeCompare(b.pipeline.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Position',
                dataIndex: 'position',
                key: 'position',
                sorter: (a, b) => a.position.toLowerCase().localeCompare(b.position.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Point',
                dataIndex: 'point',
                key: 'point',
                sorter: (a, b) => a.point.toLowerCase().localeCompare(b.point.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Company',
                dataIndex: 'company',
                key: 'company',
                sorter: (a, b) => a.company.toLowerCase().localeCompare(b.company.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Date Range',
                dataIndex: 'date_range',
                key: 'date_range',
                sorter: (a, b) => a.date_range - b.date_range,
                responsive: ["sm"]
            },
          ];

          function expandedRowRender(row) {

            if (!row.midpoints.length) return null;

            const midpointColumns = [
                {
                    title: 'Midpoints',
                    render: (record) => {
    
                        return (
                            <Fragment>
                                Date: <Text strong>{moment(record.flow_date).format('YYYY-MM-DD')}</Text>
                                <br />
                                Midpoint Price: <Text strong>{currency(record.midpoint, 4)}</Text>
                                <br />
                                Final Price: <Text strong>{record.final_price ? currency(record.final_price) : ''}</Text>
                                <br />
                            </Fragment>
                        );
                    },
                    responsive: ["xs"]
                },
                {
                    title: 'Date',
                    dataIndex: 'flow_date',
                    key: 'flow_date',
                    responsive: ["sm"],
                    render: (flow_date) => moment(flow_date).format('YYYY-MM-DD')
                },
                {
                    title: 'Midpoint Price',
                    dataIndex: 'midpoint',
                    key: 'midpoint',
                    render: (midpoint) => currency(midpoint, 4),
                    sorter: (a, b) => a.midpoint - b.midpoint,
                    responsive: ["sm"]
                },
                {
                    title: 'Final Price',
                    dataIndex: 'final_price',
                    key: 'total_price',
                    render: (final_price) => final_price ? currency(final_price) : '',
                    sorter: (a, b) => parseFloat(a.final_price) - parseFloat(b.final_price),
                    responsive: ["sm"]
                },
            ];

            const data = row.midpoints;

            return (
                <div className={Style.subTableWrap}>
                    <p>Midpoint Prices</p>
                    <Table columns={midpointColumns} dataSource={data} pagination={false} bordered />
                </div>
            )
          }

          const mediumScreenWidth = width && (width < 1150 && width > 574);

        return (
            <Table 
                columns={columns} 
                dataSource={data} 
                expandable={ 
                    {
                        expandedRowRender,
                        rowExpandable: (record) => !record.static_midpoint && Boolean(record.midpoints.length)
                    } 
                }
                onChange={handleTableChange} 
                pagination={pageData}
                loading={loading}
                scroll={{ x: mediumScreenWidth ? 1300 : undefined }}
                bordered
            />
        );
    }

    const triageDealsByPosition = (data) => {
        const summaryObj = {
            buy: {},
            sell: {}    
        }

        data.totalVolume.forEach(volumeObj => {
            summaryObj[volumeObj.name]['volumeTotal'] = volumeObj.volume;
        });

        data.totalFinalPrice.forEach(finalPriceObj => {
            summaryObj[finalPriceObj.name]['totalPrice'] = finalPriceObj.total_price;
        });

        if (data.averageMidpoint) {
            data.averageMidpoint.forEach(averageMidpointObj => {
                summaryObj[averageMidpointObj.name]['avarageMidpoint'] = averageMidpointObj.midpoint;
            });
        }

        if (data.totalMonthlyCost) {
            data.totalMonthlyCost.forEach(tmcObj => {
                summaryObj[tmcObj.name]['totalMonthlyCost'] = tmcObj.total_monthly_cost;
            });
        }

        if (data.weightedAverageCost) {
            data.weightedAverageCost.forEach(wacObj => {
                summaryObj[wacObj.name]['weightedAverageCost'] = wacObj.weighted_average_cost;
            });
        }

        setSummaryData(summaryObj);
    }

    const getSpotDealSummary = () => {
        const data = [];
        const buyObj = {};
        const sellObj = {};

        if(summaryData?.buy && Object.keys(summaryData['buy']).length) {
            buyObj['total_volume'] = summaryData.buy?.volumeTotal || 'N/A';
            buyObj['total_final_price'] = summaryData.buy.totalPrice;
            buyObj['position'] = 'bought';
            data.push(buyObj);
        }

        if(summaryData?.sell && Object.keys(summaryData['sell']).length) {
            sellObj['total_volume'] = summaryData.sell?.volumeTotal || 'N/A';
            sellObj['total_final_price'] = summaryData.sell?.totalPrice;
            sellObj['position'] = 'sold';
            data.push(sellObj);
        }

        const dateRange = (filters.startDate && filters.endDate) ? `Within ${moment(filters.startDate).format('MM/DD/YYYY')} - ${moment(filters.endDate).format('MM/DD/YYYY')}` : '';

        const columns = [
            {
                title: `Total Summary ${dateRange}`,
                render: (record, index) => {

                    return (
                        <Fragment>
                            Position: <Text strong>{record.position}</Text>
                            <br />
                            Total Volume: <Text strong>{record.total_volume}</Text>
                            <br />
                            Total Final Price: <Text strong>{record.total_final_price ? currency(record.total_final_price) : 'N/A'}</Text>
                        </Fragment>
                    );
                },
                responsive: ["xs"]
            },
            {
                title: 'Position',
                dataIndex: 'position',
                key: 'position',
                responsive: ["sm"]
            },
            {
                title: 'Total Volume',
                dataIndex: 'total_volume',
                key: 'total_volume',
                responsive: ["sm"]
            },
            {
                title: 'Total Final Price',
                dataIndex: 'total_final_price',
                key: 'total_final_price',
                render: (total_final_price) => total_final_price ? currency(total_final_price) : 'N/A',
                responsive: ["sm"]
            }
        ];

        return (
            <Card title={`Total Summary ${dateRange}`} className={Style.summaryWrap}>
                <Table 
                    
                    columns={columns} 
                    dataSource={data} 
                    loading={loading}
                    pagination={false}
                    bordered
                />
            </Card>
        );
    }

    const getLongTermDealSummary = () => {
        const data = [];

        const buyObj = {};
        const sellObj = {};

        if(summaryData?.buy && Object.keys(summaryData['buy']).length) {
            buyObj['total_volume'] = summaryData.buy?.volumeTotal || 'N/A';
            buyObj['total_base_price'] = summaryData.buy?.totalPrice;
            buyObj['total_monthly_cost'] = summaryData.buy?.totalMonthlyCost;
            buyObj['weighted_average_cost'] = summaryData.buy?.weightedAverageCost ? summaryData.buy?.weightedAverageCost : undefined
            
            buyObj['position'] = 'bought';
            data.push(buyObj);
        }

        if(summaryData?.sell && Object.keys(summaryData['sell']).length) {
            sellObj['total_volume'] = summaryData.sell?.volume || 'N/A';
            sellObj['total_base_price'] = summaryData.sell?.totalPrice;
            sellObj['average_base_price'] = summaryData.sell?.volume ? (summaryData.sell?.totalPrice / summaryData.sell?.volume) : undefined
            sellObj['position'] = 'sold';
            data.push(sellObj);
        }

        const columns = [
            {
                title: "Total Summary",
                render: (record, index) => {

                    return (
                        <Fragment>
                            Position: <Text strong>{record.position}</Text>
                            <br />
                            Total Volume Per Da: <Text strong>{record.total_volume}</Text>
                            <br />
                            Total Base Price: <Text strong>{record.total_base_price ? currency(record.total_base_price) : 'N/A'}</Text>
                            <br />
                            Total Monthly Cost: <Text strong>{record.total_monthly_cost ? currency(record.total_monthly_cost) : 'N/A'}</Text>
                            <br />
                            Weighted Average Cost Per Dth: <Text strong>{record.weighted_average_cost ? currency(record.weighted_average_cost) : 'N/A'}</Text>
                        </Fragment>
                    );
                },
                responsive: ["xs"]
            },
            {
                title: 'Position',
                dataIndex: 'position',
                key: 'position',
                responsive: ["sm"]
            },
            {
                title: 'Total Volume Per Day',
                dataIndex: 'total_volume',
                key: 'total_volume',
                responsive: ["sm"]
            },
            {
                title: 'Total Base Price',
                dataIndex: 'total_base_price',
                key: 'total_base_price',
                render: (total_base_price) => total_base_price ? currency(total_base_price) : 'N/A',
                responsive: ["sm"]
            },
            {
                title: 'Total Monthly Cost',
                dataIndex: 'total_monthly_cost',
                key: 'total_monthly_cost',
                render: (total_monthly_cost) => total_monthly_cost ? currency(total_monthly_cost) : 'N/A',
                responsive: ["sm"]
            },
            {
                title: 'Weighted Average Cost Per Dth',
                dataIndex: 'weighted_average_cost',
                key: 'weighted_average_cost',
                render: (weighted_average_cost) => weighted_average_cost ? currency(weighted_average_cost) : 'N/A',
                responsive: ["sm"]
            }
        ];
        
        return (
            <Card title="Total Summary" className={Style.summaryWrap}>
                <Table 
                    columns={columns} 
                    dataSource={data} 
                    loading={loading}
                    pagination={false}
                    bordered
                />
            </Card>
        );
    }

    const getLongTermDealTable = () => {
        const data = deals.map(deal => {
            const sameDates = moment(deal.start_date).isSame(deal.end_date);
            
            deal.date_range = sameDates ? '---' : `${moment(deal.start_date).format('MMM, DD YYYY')} - ${moment(deal.end_date).format('MMM, DD YYYY')}`
            deal['fix_slots'] = deal.fix_price_slot_data ? deal.fix_price_slot_data.length : 0;

            return deal;
        });

        const columns = [
            {
                title: 'Long Term Deal',
                render: (record, index) => {

                    return (
                        <Fragment>
                            Volume: <Text strong>{record.volume}</Text>
                            <br />
                            Base Price: <Text strong>{currency(record.base_price, 4)}</Text>
                            <br />
                            Final Price: <Text strong>{record.final_price !== null && record.final_price ? currency(record.final_price, 4) : 'N/A'}</Text>
                            <br />
                            Pipeline: <Text strong ellipsis>{record.pipeline}</Text>
                            <br />
                            Position: <Text strong>{record.position}</Text>
                            <br />
                            Point: <Text strong>{record.point}</Text>
                            <br />
                            Company: <Text strong>{record.company}</Text>
                            <br />
                            Date Range: <Text strong>{record.date_range}</Text>
                            <br />
                            Fixed Price Slots <Text strong>{record.fix_slots}</Text>
                        </Fragment>
                    );
                },
                responsive: ["xs"]
            },
            {
              title: 'Volume',
              dataIndex: 'volume',
              key: 'volume',
              sorter: (a, b) => a.volume - b.volume,
              responsive: ["sm"]
            },
            {
              title: 'Base Price',
              dataIndex: 'base_price',
              key: 'base_price',
              render: (base_price) => currency(base_price, 4),
              sorter: (a, b) => a.base_price - b.base_price,
              responsive: ["sm"]
            },
            {
                title: 'Final Price',
                dataIndex: 'final_price',
                key: 'final_price',
                render: (final_price) => final_price !== null && final_price ? currency(final_price, 4) : 'N/A',
                sorter: (a, b) => a.final_price - b.final_price,
                responsive: ["sm"]
            },
            {
                title: 'Pipeline',
                dataIndex: 'pipeline',
                key: 'pipeline',
                sorter: (a, b) => a.pipeline.toLowerCase().localeCompare(b.pipeline.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Position',
                dataIndex: 'position',
                key: 'position',
                sorter: (a, b) => a.position.toLowerCase().localeCompare(b.position.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Point',
                dataIndex: 'point',
                key: 'point',
                sorter: (a, b) => a.point.toLowerCase().localeCompare(b.point.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Company',
                dataIndex: 'company',
                key: 'company',
                sorter: (a, b) => a.company.toLowerCase().localeCompare(b.company.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Date Range',
                dataIndex: 'date_range',
                key: 'date_range',
                sorter: (a, b) => a.date_range - b.date_range,
                responsive: ["sm"]
            },
            {
                title: 'Fixed Price Slots',
                dataIndex: 'fix_slots',
                key: 'fix_slots',
                sorter: (a, b) => a.fix_slots - b.fix_slots,
                responsive: ["sm"]
            }
          ];

          const mediumScreenWidth = width && (width < 1150 && width > 574);

        return (
            <Table 
                columns={columns} 
                dataSource={data} 
                onChange={handleTableChange} 
                pagination={pageData}
                loading={loading}
                bordered
                scroll={{ x: mediumScreenWidth ? 1300 : undefined }}
            />
        );
    }

    const renderPipelineAvails = () => {
        return (
            <Collapse defaultActiveKey={['1']}>
                <Panel 
                    key={1}
                    header="Open Volumes of Gas"
                >
                    <div style={{ maxWidth: '500px', width: '100%' }}>
                    { pipelineAvails.map(obj => (
                        <Row>
                            <Col span={14} className={Style.pipelineAvailItem}>
                                <p><strong>{obj.name}</strong>:</p>
                            </Col>
                            <Col span={10}>{obj.available_volume}</Col>
                        </Row>
                      )) 
                    }
                    </div>
                    
                </Panel>
            </Collapse>
        )
    }

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const exportToCSV = async () => {
        if(filters.dealType) {
            await exportLongTermDeals();
        } else {
            await exportSpotDeals();
        }
    }

    const exportSpotDeals = async () => {
        const { excelBuffer, fileName } = await exportDeals();

        if (excelBuffer) {
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, fileName);
        }
    }

    const exportLongTermDeals = async () => {
        const { excelBuffer, fileName } = await exportDeals();

        if (excelBuffer) {
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, fileName);
        }
    }

    const submit = async ({ startDate, endDate, dealType, selectedCompany, isGated }) => {
        setFilters({
            ...filters,
            startDate,
            endDate,
            dealType,
            selectedCompany,
            isGated
        });

        await getDeals({ startDate, endDate, dealType, selectedCompany, isGated, offSet: 0 }, true);
        
        if (dealType) await getPipelineAvailableSpace({ startDate, selectedCompany, isGated });
    }

    const getDeals = async (data, freshSearch) => {
        
        setLoading(true);

            const res = await DealService.getDeals({ 
                from: data.startDate, 
                to: data.endDate,
                deal_type: data.dealType,
                pending: false,
                company: data.selectedCompany,
                isGated: data.isGated,
                limit,
                offset: data.offSet
            });
          
            if (res.status === 200) {
                const currDeals = freshSearch ? [] : deals;
                
                setDeals([ ...currDeals, ...res.data.data]);

                if (res.data.count !== pageData.total) setPageData({...pageData, current: data.current, total: res.data.count });
                if (res.data.summaryData) triageDealsByPosition(res.data.summaryData);
                setSearched(true);
                setLoading(false);
            } else {
                context.handleError(res);
                setLoading(false);
            }

        

    }

    const exportDeals = async () => {

        setLoading(true);
        const res = await DealService.getExportDeals({ 
                from: filters.startDate, 
                to: filters.endDate,
                deal_type: filters.dealType,
                pending: false,
                company: filters.selectedCompany,
                isGated: filters.isGated,
                export: true
            });

        const fileName = res.headers['content-disposition'].split("=")[1].replace(/['"]+/g, '');

        if (res.status === 200 && fileName) {
            setLoading(false);
            return {
                excelBuffer: res.data,
                fileName
            }
        } else {
            context.handleError(res);
            setLoading(false);
        }

    }

    return (
        <Animate type='pop'>
            <DealLogFilter 
                companies={companies} 
                submit={ submit } 
                loading={ loading } 
            />
            {!loading && searched && !deals.length &&
                <Card>
                    No deals were found with your seach criteria.
                </Card>
            }
            {!loading && deals.length > 0 &&
                <>
                {filters.dealType > 0 && 
                    <Card loading={pipelineAvailLoading}>
                        <>
                            <Grid cols="1">
                                { pipelineAvails?.length > 0 && renderPipelineAvails() }
                            </Grid>
                        </>
                </Card>
                }
                { renderSummary() }
                <Card className={Style.dealLogsTableSection}>
                    <Grid cols="2">
                        <div>
                            <h1>Deals</h1>
                         </div>
                        <div>
                            <Button 
                                small 
                                text='Export Report' 
                                className={Style.exportBtn}
                                action={ async () => await exportToCSV() }
                            />
                        </div>
                    </Grid>
                    { renderDealsList() }
                </Card>
                </>
            }
        </Animate>
    );
}