import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Axios from 'axios';
import { ViewContext, Button, Card, Form, Grid, Label, MissionControlAccountNav, Select, useAPI } from 'components/lib';
import { BlockPicker } from 'react-color';


export function MasterAccountProfileSettings(props){
    const context = useContext(ViewContext);

    const { id } = useParams();
    const accounts = useAPI('/api/master/accounts');

    const [formState, setFormState] = useState({});
    const [loading, setLoading] = useState(false);
    const [account, setAccount] = useState(undefined);

    useEffect(() => {
        if(!account && accounts.data) {
            const accountData = accounts.data.filter(a => a.id === id);

            if(accountData.length) {
                setAccount(accountData[0]);
                setFormState({
                    ...formState,
                    id: accountData[0].id,
                    plan: accountData[0].plan,
                    active: accountData[0].active,
                    light_theme: accountData[0]?.light_theme,
                    primary_color: accountData[0]?.primary_color,
                    secondary_color: accountData[0]?.secondary_color
                });

            }
        }
    }, [accounts, account, setAccount, id, setFormState, formState]);

    const changePrimaryColor = (color) => {
        setFormState({ ...formState, primary_color: color.hex });
    }

    const changeSecondaryColor = (color) => {
        setFormState({ ...formState, secondary_color: color.hex });
    }

    const submit = async () => {
        setLoading(true);

        try {
            const res = await Axios.patch('/api/master/account', {
                id: formState.id,
                plan: formState.plan,
                active: formState.active,
                theme: {
                    primary_color: formState.primary_color,
                    secondary_color: formState.secondary_color,
                    light_theme: formState.light_theme
                }
            });
      
            if (res.status === 200) {
                setLoading(false);
            }          
          }
          catch (err){
            setLoading(false);
            context.handleError(err);
          }
    }

    return (
        <Fragment>
            <MissionControlAccountNav />
            <Grid cols="2">
                <Card
                    loading={ !account }
                    restrictWidth>
                    {account &&
                        <>
                            <Grid cols="2">
                                <div>
                                    <Label text="Primary Color" />
                                    <BlockPicker
                                        color={formState.primary_color || ''}
                                        onChangeComplete={ changePrimaryColor }
                                    />
                                </div>
                                <div>
                                    <Label text="Secondary Color" />
                                    <BlockPicker
                                        color={formState.secondary_color || ''}
                                        onChangeComplete={ changeSecondaryColor }
                                    />
                                </div>
                            </Grid>
                            <div>
                                <Select 
                                    customLabel='Theme Type'
                                    type='select'
                                    value={formState.light_theme}
                                    default={formState.light_theme}
                                    onChange={(id, value) => setFormState({ ...formState, light_theme: value })}
                                    options={[
                                        {
                                            label: 'dark',
                                            value: 0,
                                        },
                                        {
                                            label: 'light',
                                            value: 1
                                        }
                                    ]}
                                />
                            </div>
                            <Grid>
                                {(!formState.primary_color || !formState.secondary_color || formState.light_theme !== undefined) &&
                                    <Button
                                        loading={ loading }
                                        text="Submit"
                                        action={ submit }
                                    />
                                }
                            </Grid>
                        </>
                    }
                </Card>
                <Card 
                    loading={ !account }
                    restrictWidth>
                        {account &&

                            <Form 
                            data={{
                                    account_id: {
                                        type: 'hidden',
                                        value: account.id
                                    },
                                    file: {
                                        label: 'Upload Logo',
                                        type: 'file',
                                        name: 'file',
                                        required: true,
                                        accept: 'image/gif, image/jpeg, image/png'
                                    }
                            }}
                            method='POST'
                            url='/api/master/account/logo' 
                            buttonText='Upload Logo'
                            />
                        }

                </Card>
            </Grid>
        </Fragment>
    );
}