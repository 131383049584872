/***
*
*   MASTER ACCOUNTS
*   Manage all accounts signed up to your application
*
**********/

import React, { useContext } from 'react';
import { ViewContext, Button, Card, Grid, Table, Animate, useAPI } from 'components/lib';

export function MasterDataEntryAccounts(props){

  const context = useContext(ViewContext);
  const accounts = useAPI('/api/master/data-entry-accounts');

  function editAccount(data, callback){
    context.modal.show({

      title: 'Edit Account',
      form: {
        id: {
          type: 'hidden',
          value: data.id
        },
        active: {
          label: 'Status',
          type: 'select',
          default: data.active,
          options: [
            { value: 1, label: 'Active' },
            { value: 0, label: 'Cancelled' }
          ],
          required: true
        },
        name: {
          label: 'Company Name',
          type: 'text',
          value: data.company_name,
          required: true,
          errorMessage: 'Must enter a company name.'
        },
        address: {
          label: 'Address',
          type: 'text',
          value: data.address,
          required: true,
          errorMessage: 'Must enter an address.'
        },
        phone_number: {
          label: 'Phone Number',
          type: 'phone',
          value: data.phone_number,
          required: true,
          errorMessage: 'Must enter a phone number.'
        },
      },
      buttonText: 'Save',
      url: '/api/master/account',
      method: 'PATCH'

    }, (res) => {

      context.notification.show(data.name + ' was updated', 'success', true);
      callback(res);

    });
  }

  function deleteAccount(data, callback){

    context.modal.show({
      title: 'Delete Account',
      form: {},
      buttonText: 'Delete Account',
      text: `Are you sure you want to close ${data.owner}'s account?`,
      url: `/api/master/account/${data.id}`,
      method: 'DELETE'

    }, () => {

      context.notification.show(`${data.name} was deleted`, 'success', true);
      callback();

    });
  }

  const openCreateAccountModal = () => {
    context.modal.show({
        title: 'Add Date Entry Account',
        form: {
            name: {
                label: 'Company Name',
                value: '',
                type: 'text',
                required: true,
                errorMessage: 'Please enter your company name'
            },
            email: {
                label: 'Email',
                value: '',
                type: 'email',
                required: true,
            },
            password: {
                label: 'Password',
                type: 'password',
                required: true,
                complexPassword: true
            },
            confirm_password: {
                type: 'hidden',
                value: null,
            } 
        },
        buttonText: 'Create Account',
        url: '/api/master/data-entry-account',
        method: 'POST'
    }, (form, res) => {});
  }

  return(
    <Animate>
      <Card loading={ false }>
        <Grid cols="2">
            <div>
                <Button
                    small
                    text="Create Date Entry Account"
                    action={ openCreateAccountModal }
                />
            </div>
        </Grid>
        <Table
          search
          hide={['id', 'light_theme', 'primary_color', 'secondary_color']}
          data={ accounts?.data }
          loading={ accounts?.loading }
          badge={{ col: 'plan', color: 'blue' }}
          actions={{ 
            edit: editAccount, 
            delete: deleteAccount, 
            email: true 
          }}
        />

      </Card>
    </Animate>
  );
}
