import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Input, Row, Space } from 'antd';

import {
    CheckOutlined,
    CloseOutlined
} from '@ant-design/icons';

const EditableContext = React.createContext(null);

const EditableRow = ({ Context, index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    const [theValue, setTheValue] = useState(children[1]);
    const [saveSuccess, setSaveSuccess] = useState(false);
  
    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);
  
    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
  
        
        handleSave({ ...record, ...values }, (status) => {
            if (status) {

                setSaveSuccess(true);
                setTimeout(() => {
                    setSaveSuccess(false);
                    toggleEdit();
                }, 750);
                
            } else {
                toggleEdit();
            }
        });

        
      } catch (errInfo) {
        console.log('Save failed:', errInfo);
      }
    };
  
    let childNode = children;
  
    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
            <Row gutter={8}>
                <Col span={4}>
                    <Input 
                        ref={inputRef} 
                        onPressEnter={save} 
                        value={theValue} 
                        onChange={(e) => setTheValue(e.target.value)} 
                        onFocus={(e) => {
                            const currentValue = e.target.value;

                            if (currentValue === 0 || currentValue === '0') setTheValue(undefined);
                        }}
                        />
                </Col>
                <Col span={20}>
                    <Space direction='horizontal'>
                        <Button 
                            type="primary" 
                            icon={<CheckOutlined />} onClick={save}>
                                {saveSuccess ? 'Saved!' : ''}
                            </Button>
                        <Button 
                            type="primary" 
                            icon={<CloseOutlined />} 
                            onClick={() => {
                                setTheValue(children[1]);
                                toggleEdit();
                            }} 
                        />
                    </Space>
                    
                </Col>
            </Row>
        </Form.Item>
      ) : (
        <div className="editable-cell-value-wrap" style={{ 
            padding: '4px 11px', 
            border: '1px solid #d9d9d9',
            width: '80px'
            }} onClick={toggleEdit}>
          {children}
        </div>
      );
    }
  
    return <td {...restProps}>{childNode}</td>;
  };

  export {
    EditableRow,
    EditableCell
  }