import React, { Fragment, useContext, useEffect, useState } from 'react';
import { AccountContext, Animate, Card, Chart, ViewContext } from 'components/lib';
import { useHistory, useLocation, useParams } from "react-router-dom";
import Axios from 'axios';
import moment from 'moment';

import { Table } from 'antd';

const lastThirtyDays = [...new Array(30)].map((i, idx) => moment().startOf("day").subtract(idx, "days").format('YYYY-MM-DD')).reverse();

export function Utility() {
    const context = useContext(ViewContext);
    const { accountState } = useContext(AccountContext);
    const { id } = useParams();
    const history = useHistory();
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [utility, setUtility] = useState(undefined);
    const [utilityReqs, setUtilityReqs] = useState([]);
    const [storageHist, setStorageHist] = useState([]);

    useEffect(() => {
        const getUtilityReqs = async (utilityId) => {
            setLoading(true);

            try {
                // get req on current day

                const res = await Axios.get('/api/company-utility-requirements', { params: { 
                    utility_ids: [utilityId],
                    limit: 10
                }});

                if (res.status === 200) {
                    setUtilityReqs(res.data.data);
                    setLoading(false);
                } 
            }
            catch(err) {
                context.handleError(err);
            }
        }

        const getUtilityStorageHist = async (utilityId) => {
            try {
                // get req on current day

                const res = await Axios.get('/api/company-utility-storage-history', { params: { 
                    utility_id: utilityId,
                    start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
                    end_date:   moment().format('YYYY-MM-DD')
                }});

                if (res.status === 200) {
                    setStorageHist(res.data.data);
                } 
            }
            catch(err) {
                context.handleError(err);
            }
        }

        if(id) {
            const utilObj = accountState.utilities.find(util => util.id === id);

            if(!utilObj) {
                history.push({
                    pathname: '/utility-reqs'
                });
            } else {
                setUtility(utilObj);
                getUtilityReqs(id);
                getUtilityStorageHist(id);
            }
        }

    }, [id]);


    const renderUtilityReqs = () => {

        const columns = [
           {
                title: 'Recent requirements',
                render: (record, index) => {
                    return (
                        <Fragment>
                            Max Injection: {record.max_injection}
                            <br />
                            Max Withdrawal: {record.max_withdrawal}
                            <br />
                            Required Amount: {record.requirement}
                            <br />
                            Date: {record.date}
                        </Fragment>
                    )
                },
                responsive: ["xs"]
           },
           {
                title: 'Max Injection',
                dataIndex: 'max_injection',
                key: 'max_injection',
                sorter: (a, b) => a.max_injection - b.max_injection,
                responsive: ["sm"]
           },
           {
                title: 'Max Withdrawal',
                dataIndex: 'max_withdrawal',
                key: 'max_withdrawal',
                sorter: (a, b) => a.max_withdrawal - b.max_withdrawal,
                responsive: ["sm"]
           },
           {
                title: 'Requirement',
                dataIndex: 'requirement',
                key: 'requirement',
                sorter: (a, b) => a.requirement - b.requirement,
                responsive: ["sm"]
           },
           {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (date) => ((<>{moment(date).format('YYYY-MM-DD')}</>)),
            sorter: (a, b) => a.date.toLowerCase().localeCompare(b.date.toLowerCase()),
            responsive: ["sm"]
           },
        ];

        return (
            <Table
                columns={columns} 
                dataSource={utilityReqs} 
                bordered
                pagination={false}
            >
            </Table>
        );
    }

    const prepareStorageHistory = () => {
        const colors = {
            red: {
                bg: 'rgba(255, 99, 132, 0.5)',
                border: 'rgb(255, 99, 132)'
            },
            blue: {
                bg: 'rgba(53, 162, 235, 0.5)',
                border: 'rgb(53, 162, 235)'
            }
        };

        const triageHistData = storageHist.reduce((obj, histObj) => {
            const type = histObj.is_withdrawal ? 'withdrawals' : 'injections';

            if(!Array.isArray(obj[type])) obj[type] = [];
            obj[type].push(histObj);

            return obj;
        }, {}); 

        const prepareHistDataForChart = (type) => {
            if(!triageHistData[type]) return [];

            return lastThirtyDays.map(date => {
                const matchHist = triageHistData[type].find(histObj => moment(date).isSame(moment(histObj.date), 'day'));
                
                return matchHist?.amount || 0
            });
        }

        const data = {
            labels: lastThirtyDays,
            datasets: [
              {
                label: 'withdrawals',
                data: prepareHistDataForChart('withdrawals'),
                borderColor: colors.red.border,
                backgroundColor: colors.red.bg
              },
              {
                label: 'injections',
                data: prepareHistDataForChart('injections'),
                borderColor: colors.blue.border,
                backgroundColor: colors.blue.bg
              },
            ],
          };

        return data;
    }

    return (
        <Animate type='pop'>
            <Card 
                title={`${utility?.name} (ten most recent)`}
                loading={loading}
            >
                {renderUtilityReqs()}
            </Card>
            <Card
                title="Utility Storage History (Pass 30 Days)"
                loading={!storageHist}
            >
                <Chart
                        type='bar'
                        legend
                        data={ prepareStorageHistory() }
                        color={['red', 'blue']}
                />
            </Card>
        </Animate>
    );
}