import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Axios from 'axios';
import { Animate, ViewContext, Card, Grid, IconCard, useWindowSize } from 'components/lib';
import { CreateSpotDeal } from './create-spot-deal';
import { currency } from 'utilities/currency';

import { IFlowDate } from 'ts/interfaces/flow_date';
import { IDeal, IDealApiParams } from 'ts/interfaces/deal';

import { Alert, Button as AntButton, Popconfirm, Table, Typography, Tooltip, Space, Spin, Divider } from 'antd';

import {
    FileExcelOutlined,
    FileDoneOutlined,
    FileTextOutlined,
    ProjectOutlined,
    DeleteFilled,
    EditFilled
  } from '@ant-design/icons';

import moment from 'moment';

const { Text } = Typography;

export function FlowDateOverview() {
    const context = useContext(ViewContext);
    const history = useHistory();
    const { id } = useParams<{ id?: string }>();
    const { width } = useWindowSize();


    const [flowDateObj, setFlowDateObj] = useState<IFlowDate | undefined>(undefined);
    const [deals, setDeals] = useState<Array<IDeal> | undefined>(undefined);
    const [multiDaySpotDeals, setMultiDaySpotDeals] = useState<Array<IDeal> | undefined>(undefined);
    const [hasDailyGasData, setHasDailyGasData] = useState<boolean>(false);
    const [dataCountLoad, setDataCountLoad] = useState<boolean>(false);

    const getDataRecordsCount = useCallback(async () => {
        if (!flowDateObj) return;

        try {
            const res = await Axios.get('/api/document-chart/data-count', { params: { flow_date: moment(flowDateObj.date).format('YYYY-MM-DD') } });

            if(res.status === 200) {
                setHasDailyGasData(Boolean(res.data.data));
                setDataCountLoad(false);
            }
        }
        catch (err) {
            context.handleError(err);
        }
    }, [setHasDailyGasData, flowDateObj]);

    const getDeals = useCallback(async (params: IDealApiParams) => {
        try {
            const res = await Axios.get('/api/deal', {  params });
      
            if (res.status === 200) {
                return res.data.data;
            }          
          }
          catch (err){
            context.handleError(err);
          }
    }, []);

    useEffect(() => {
        const getFlowDateData = async () => {
            try {
                const res = await Axios.get('/api/flow-date', {
                    params: { id }
                });
    
                if (res.status === 200) {
                    if(!res.data.data) {
                        history.push({
                            pathname: '/spot-deals'
                        });
                    } else {
                        setFlowDateObj(res.data.data);
                    }
                }
            } catch (err) {
                context.handleError(err);
            }
        }

        if(id) {
            getFlowDateData();
        }

    }, [id]);

    useEffect(() => {
        const prepareDeals = async (flowDateObjData: IFlowDate) => {
            const deals = await getDeals({ flow_date_id: flowDateObjData.id, deal_type: 0 });
            
            setDeals(deals);
        }; 

        if(flowDateObj) {
            prepareDeals(flowDateObj);
            getDataRecordsCount();
        }
    }, [flowDateObj]);

    useEffect(() => {

        const getOverlapSpotDeals = async (flowDateObjData: IFlowDate) => {
            const deals = await getDeals({ from: moment(flowDateObjData.date).format('YYYY-MM-DD'), deal_type: 0, multi_day_spot_deal: true });
            setMultiDaySpotDeals(deals);
        }

        if (multiDaySpotDeals === undefined && flowDateObj) getOverlapSpotDeals(flowDateObj);

    }, [flowDateObj]);

    const deleteFlowDate = async () => {
        if (!flowDateObj) return;

        try {
            const res = await Axios.delete('/api/flow-date', { data: { id: flowDateObj.id } });
      
            if (res.status === 200) {                
                history.push({
                    pathname: '/spot-deals'
                });
            }          
          }
          catch (err){
            context.handleError(err);
          }
    }

    const allSpotDealsHaveFinalPrice = () => {
        const spotDeals = deals ? deals.filter(d => !d.is_long_term) : [];
        const finalPriceDeals = spotDeals.filter(d => d.final_price || d.fix_price);

        return spotDeals.length === finalPriceDeals.length;
    }

    const createSpotDeal = (deal: IDeal) => {
        context.modal.show({
            title: 'Create Spot Deal',
            customForm: CreateSpotDeal,
            formProps: {
                flowDateId: flowDateObj?.id,
                flowDate: moment(flowDateObj?.date),
                updateDeal: deal
            },
            lgModal: true
        }, (form: any, res: { data: IDeal}) => {
            if(res) {
                if(deal) {
                    const updatedDeals = deals?.map(dealObj => {
                        if(dealObj.id === res.data.id) return res.data;
                        return dealObj;
                    });
                    setDeals(updatedDeals);
                    context.notification.show('Deal was updated', 'success', true);
                } else {
                    deals?.push(res.data);
                    if (deals) setDeals([...deals]);
                    context.notification.show('Spot deal was created', 'success', true);
                }
            }
        });
    }

    const uploadDailyGasDocument = () => {
        context.modal.show({
            title: 'Upload Gas Daily Document',
            form: {
                file: {
                    label: 'Upload File',
                    max: 1,
                    type: 'file',
                    name: 'file',
                    required: true
                },
                flow_date: {
                    type: 'hidden',
                    value: moment(flowDateObj?.date).format('YYYY-MM-DD')
                }
            },
            buttonText: 'Upload',
            url: '/api/document-chart',
            method: 'POST',
            }, (res: any) => {
                if(res.status === 200) {
                    setDataCountLoad(true);
                    setTimeout(() => getDataRecordsCount(), 4000);
                    context.notification.show('Gas Daily midpoints has been gathered successfully.', 'success', true);
                }
            });
    }

    const sendToDailyNominationView = () => {        
        history.push({
            pathname: '/daily-nomination',
            state: { flowDate: flowDateObj?.date }
        });
    }

    const confirmFlowDateAndDelete = async () => {
        await deleteFlowDate();
    };

    const cancel = () => {}

    const deleteDeal = async (deal: IDeal) => { 
        try {
            const res = await Axios.delete('/api/deal', { data: { id: deal.id } });

            if (res.status === 200) {
                if (deals?.length === 1) {
                    await deleteFlowDate();
                    return;
                }

                const updatedDeals = deals?.filter(dealObj => dealObj.id !== deal.id);

                setDeals(updatedDeals);
                context.notification.show('Deal was removed', 'success', true);
            }          
        }
        catch (err){
            context.handleError(err);
        }
    }

    const getTableColumns = (isMultiDayDeals: boolean) => {
        const renderAction = (deal: IDeal, index: number) => {
            const confirmDelete = async (dealObj: IDeal) => {
                await deleteDeal(dealObj);
            }

            return (
                <Space size="middle">
                    <Tooltip title="Edit Spot Deal">
                        <AntButton icon={<EditFilled />} onClick={async () => createSpotDeal(deal)} />
                    </Tooltip>
                    <Popconfirm
                            title="Are you sure you want to delete this spot deal?"
                            onConfirm={async () => await confirmDelete(deal)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                    >
                        <AntButton icon={<DeleteFilled />} />
                    </Popconfirm>
                </Space>
              );
        };

        const columnBatch: Array<any> = [
            {
                title: 'Spot Deal',
                render: (record: IDeal, index: number) => {

                    return (
                        <Fragment>
                            Volume: <Text strong>{record.volume}</Text>
                            <br />
                            Base Price: <Text strong>{record.base_price}</Text>
                            <br />
                            Pipeline: <Text strong ellipsis>{record.pipeline}</Text>
                            <br />
                            Position: <Text strong>{record.position}</Text>
                            <br />
                            Point: <Text strong>{record.point}</Text>
                            <br />
                            Company: <Text strong>{record.company}</Text>
                            <br />
                            Date Range: <Text strong>{record.date_range}</Text>
                            {!isMultiDayDeals &&
                                <>
                                    <Divider />
                                    {renderAction(record, index)}
                                </>
                            }
                        </Fragment>
                    );
                },
                responsive: ["xs"]
            },
            {
              title: 'Volume',
              dataIndex: 'volume',
              key: 'volume',
              sorter: (a: IDeal, b: IDeal) => a.volume - b.volume,
              responsive: ["sm"]
            },
            {
              title: 'Base Price',
              dataIndex: 'base_price',
              key: 'base_price',
              render: (base_price: number) => currency(base_price),
              sorter: (a: IDeal, b: IDeal) => a.base_price - b.base_price,
              responsive: ["sm"]
            },
            {
                title: 'Pipeline',
                dataIndex: 'pipeline',
                key: 'pipeline',
                sorter: (a: IDeal, b: IDeal) => a.pipeline.toLowerCase().localeCompare(b.pipeline.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Position',
                dataIndex: 'position',
                key: 'position',
                sorter: (a: IDeal, b: IDeal) => a.position.toLowerCase().localeCompare(b.position.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Point',
                dataIndex: 'point',
                key: 'point',
                sorter: (a: IDeal, b: IDeal) => a.point.toLowerCase().localeCompare(b.point.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Company',
                dataIndex: 'company',
                key: 'company',
                sorter: (a: IDeal, b: IDeal) => a.company.toLowerCase().localeCompare(b.company.toLowerCase()),
                responsive: ["sm"]
            },
            {
                title: 'Date Range',
                dataIndex: 'date_range',
                key: 'date_range',
                sorter: (a: IDeal, b: IDeal) => a.start_date && b.start_date ? moment(a.start_date).diff(moment(b.start_date)) : 'N/A',
                responsive: ["sm"]
            }
          ];

          if (!isMultiDayDeals) {
            columnBatch.push({
                title: 'Action',
                key: 'action',
                render: renderAction,
                responsive: ["sm"]
              });
          }

          return columnBatch;
    }

    const prepareDateRange = (deal: IDeal) => {
        const sameDates = moment(deal.start_date).isSame(deal.end_date);
        deal.date_range = sameDates ? '---' : `${moment(deal.start_date).format('MMM, DD YYYY')} - ${moment(deal.end_date).format('MMM, DD YYYY')}`;

        return deal;
    }

    const getMultiDaySpotDeals = () => {
        const data = multiDaySpotDeals?.map(prepareDateRange);
        const columns = getTableColumns(true);
        const mediumScreenWidth = width && (width < 940 && width > 574);

        return (
            <Card title="Current Multi-Day Spot Deals (that overlap chosen date)">
                <Table 
                    columns={columns}
                    dataSource={data}
                    bordered
                    scroll={{ x: mediumScreenWidth ? 1300 : undefined }}
                />
            </Card>
        );
    }

    const getDealsList = () => {
        const data = deals?.map(prepareDateRange);
        const columns = getTableColumns(false);
        const mediumScreenWidth = width && (width < 940 && width > 574);


          if (mediumScreenWidth) {
            if (columns[columns.length - 1]['key'] === 'action') {
                columns[columns.length - 1]['fixed'] = 'right';
            } else {
                delete columns[columns.length - 1]['fixed'];
            } 
          } 
    
          const showSpotDeals = () => {
            return(
                <Card title={`Spot Deals for ${moment(flowDateObj?.date).format('MMMM Do YYYY')}`}>
                    <Grid>
                        <Alert
                            message='The spot deals will be finalized once the daily nomination for this flow date has been submitted.'
                            type="info"
                            showIcon
                        />
                    </Grid>
                    <Grid>
                        <Table 
                            columns={columns} 
                            dataSource={data} 
                            bordered
                            scroll={{ x: mediumScreenWidth ? 1300 : undefined }}
                        />
                    </Grid>
                </Card>
            );
        }

        return (
            <Fragment>
                { showSpotDeals() }
            </Fragment>
        );
    }

    return (
        <Animate type='pop'>
            <Grid cols='4'>
                <IconCard
                    onClick={ createSpotDeal }
                    icon={ProjectOutlined}
                    text="Create Deal"
                    hover
                    color='rosybrown'
                />
                
                <IconCard
                    onClick={ uploadDailyGasDocument }
                    icon={FileTextOutlined}
                    text={(
                        <>
                            <Spin spinning={dataCountLoad}>
                            <Text>{hasDailyGasData ? 'Reupload' : 'Upload'} Daily Gas</Text>
                            <br />
                            {hasDailyGasData && <Text type="success">Already Uploaded</Text>}
                            </Spin>
                            
                        </>
                    )}
                    hover
                    color='rosybrown'
                />

                {deals && (hasDailyGasData || allSpotDealsHaveFinalPrice()) &&
                    <IconCard
                        onClick={ sendToDailyNominationView }
                        icon={FileDoneOutlined}
                        text="Create Daily Nomination"
                        hover
                        color='rosybrown'
                    />
                }
                
                <Popconfirm
                    title="Are you sure you want to remove all unfinished deals on this date?"
                    onConfirm={async () => await confirmFlowDateAndDelete() }
                    onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconCard
                        icon={FileExcelOutlined}
                        text="Remove All Deals"
                        hover
                        color='rosybrown'
                    />
                </Popconfirm>
            </Grid>
            { multiDaySpotDeals && multiDaySpotDeals?.length > 0 && 
                getMultiDaySpotDeals()
            }
            { deals && getDealsList() }
        </Animate>
    );
}