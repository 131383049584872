/***
*
*   MISSION CONTROL ACCOUNT NAV
*   Acount sub nav that renders different options
*
**********/

import React from 'react';
import { NavLink, useParams } from "react-router-dom";

import './sub.scss';

export function MissionControlAccountNav(){
  const { id } = useParams();

  return(
    <nav className='subnav'>

      <NavLink exact to={`/missioncontrol/accounts/${id}`} activeClassName='active'>Custom Data</NavLink>
      <NavLink exact to={`/missioncontrol/accounts/${id}/profile-settings`} activeClassName='active'>Profile Settings</NavLink>
      <NavLink exact to={`/missioncontrol/accounts/${id}/feature-settings`} activeClassName='active'>Feature Settings</NavLink>

    </nav>
  );
}
