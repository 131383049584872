import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import Style from './new-deal.module.scss';
import { AccountContext, Button, Fieldset, Grid, Label, NumberInput, Select, Switch, TextInput, ViewContext } from 'components/lib';
import moment, { Moment } from 'moment';

import { IDeal, IDealForm } from 'ts/interfaces/deal';
import { KeyPair } from 'ts/interfaces/general';

import { Alert, DatePicker, Divider } from 'antd';
const { RangePicker } = DatePicker;

export function CreateSpotDeal({ flowDateId, flowDate, updateDeal }: { flowDateId: string, flowDate: Moment, updateDeal: IDeal | undefined }) {    

    const dateFormat = 'YYYY-MM-DD';

    const formObj: IDealForm = {
        pipeline_id: undefined,
        point_id: undefined,
        position_id: undefined,
        company_id: undefined,
        gate_id: undefined,
        static_midpoint: undefined,
        deal_num: undefined,
        volume: undefined,
        base_price: undefined,
        fix_price: undefined,
        start_date: undefined,
        end_date: undefined
    };

    const getUpdateDealObj = (): IDealForm => {
        const updateDealObj: {[x: string]: any} = {...updateDeal};
        const formObjKeys = Object.keys(formObj);
        const newFormObj: {[x: string]: any} = {};

        formObjKeys.forEach(key => {
            newFormObj[key] = updateDealObj[key];
        });
        
        return (newFormObj as unknown) as IDealForm;
    }

    const updateDealMultiDayStatus = (dealObj: IDeal) => {
        const startD = moment(dealObj.start_date).format('MM/DD/YYYY');
        const endD = moment(dealObj.end_date).format('MM/DD/YYYY');

        return startD !== endD;
    }

    const getOptionData = (data: Array<{ name: string, id: string }> | undefined): Array<KeyPair<string, string>> => data ? data.map((obj: { name: string, id: string }) => ({
        label: obj.name,
        value: obj.id
    })) : [];

    const context = useContext(ViewContext);
    const { accountState } = useContext(AccountContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [formState, setFormState] = useState<IDealForm>(updateDeal ? getUpdateDealObj() : formObj);
    const [forMultiDays, setForMultiDays] = useState(updateDeal ? updateDealMultiDayStatus(updateDeal) : false);
    const [dateRangeError, setDateRangeError] = useState<boolean>(false);
    const [availableVolume, setAvailableVolume] = useState<number | undefined>(undefined);
    const [lackVolume, setLackVolume] = useState<boolean>(false);
    const [noVolume, setNoVolume] = useState<boolean>(false);
    const [isGated, setIsGated] = useState<boolean>(Boolean(updateDeal?.is_gate) || false);
    const [isStaticMidpoint, setStaticMidpoint] = useState<boolean>(Boolean(updateDeal?.static_midpoint || true));

    const [points] = useState(getOptionData(accountState.points));
    const [pipelines] = useState(getOptionData(accountState.pipelines));
    const [positions] = useState(getOptionData(accountState.positions));
    const [companies] = useState(getOptionData(accountState.companies));

    useEffect(() => {

        if (!isStaticMidpoint) setFormState(prevFormState => ({...prevFormState, fix_price: undefined }));

    }, [isStaticMidpoint]);

    useEffect(() => {
        if(!forMultiDays) {
            formState.start_date = undefined;
            formState.end_date = undefined;
            setFormState(formState);
        }
    }, [formState, forMultiDays, setFormState]);

    useEffect(() => {
        // get total available volume by date
        const getAvailableVolume = async () => {
            setLoading(true);
            try {
                const res = await Axios.get('/api/deal/avail-flow', { params: {
                    date: flowDate.format('YYYY-MM-DD')
                }});

                if (res.status === 200) {
                    setAvailableVolume(res.data.data);
                    setLoading(false);
                }
            } catch (err) {
                setLoading(false);
                context.handleError(err);
            }
        }

        if(availableVolume === undefined) getAvailableVolume();

    }, [availableVolume, setAvailableVolume, setLoading, context, flowDate]);

    const isValid = () => {
        const multiDaysValid = () => {
            if(forMultiDays) {
                return (!dateRangeError && formState.start_date && formState.end_date) ? true : false;
            }

            return true;
        }

        return (
            formState.pipeline_id && 
            formState.point_id && 
            formState.position_id && 
            formState.company_id && 
            formState.volume !== undefined && !isNaN(formState.volume) && 
            (
                !!formState.base_price  || 
                !!formState.fix_price 
            ) && 
            multiDaysValid()
        ) ? true : false;
    }

    const hasVolumeAvailability = () => {
        const position = positions.filter(position => formState.position_id === position.value);

        if (availableVolume === undefined || formState.volume === undefined) {
            return false;
        }

        if (position[0].label === 'sell' && (availableVolume - formState.volume) < 0) {
            return false;
        }

        return true;
    }

    const submit = async () => { 
        setLackVolume(false);
        setNoVolume(false);

        if(!hasVolumeAvailability()) {
            setLackVolume(true);
            return;
        }

        if(formState.volume === 0) {
            setNoVolume(true);
            return;
        }

        if(updateDeal) {
            await submitUpdateDeal();
            return;
        }

        await submitNewDeal();
    }

    const prepareDateRangeData = () => {
        const noDateRange = !formState.start_date || !formState.end_date;

        return {
            startDate: moment(noDateRange ? flowDate : formState.start_date).startOf('day').toISOString(),
            endDate: moment(noDateRange ? flowDate : formState.end_date).startOf('day').toISOString()
        }
    }

    const submitNewDeal = async () => {
        setLoading(true);

        const dates = prepareDateRangeData();

        try {
            const payloadData: {[x: string]: any} = {
                ...formState,
                start_date: dates.startDate, 
                end_date: dates.endDate,
                is_long_term: false,
                is_gate: isGated,
                static_midpoint: isStaticMidpoint
            };

            if(flowDateId) {
                payloadData['flow_date_id'] = flowDateId;
            } else {
                payloadData['date'] = flowDate.format('YYYY-MM-DD');
            }

            const res = await Axios.post('/api/deal', payloadData);

            if (res.status === 200) {
                setLoading(false);
                context.modal.hide(false, res.data);
            }
        } catch (err) {
            setLoading(false);
            context.handleError(err);
        }
    }

    const submitUpdateDeal = async () => {
        if (!updateDeal) return;

        setLoading(true);

        const dates = prepareDateRangeData();

        try {
            const res = await Axios.patch('/api/deal', {
                ...formState,
                start_date: dates.startDate, 
                end_date: dates.endDate,
                id: updateDeal.id,
                is_gate: isGated
            });

            if (res.status === 200) {
                setLoading(false);
                context.modal.hide(false, res.data);
            }
        } catch (err) {
            setLoading(false);
            context.handleError(err);
        }
    }

    const setValue = (id: string, value: string | number | boolean) => {
        const val = !value ? undefined : value; 
        const formStateObj: {[x: string]: any } = formState;
        const valHasChanged = val !== formStateObj[id];

        if(id in formState && valHasChanged) {
            const valObj = { [id]: val };
            
            if(id === 'base_price') {
                valObj['fix_price'] = undefined;
            } else if (id === 'fix_price') {
                valObj['base_price'] = undefined;
            } else if (id === 'volume') {
                setLackVolume(false);
                setNoVolume(false);
            } else if (id === 'gate_id') {
                valObj['gate_id'] = (!val) ? undefined : val;
            }

            setFormState({...formState, ...valObj });
        }
    }

    const onDateRangeChange = (dateObjs: any, dates: [string, string]) => {

        if(dates.length === 2) {
            const formatFlowDate = flowDate.format('YYYY-MM-DD');
            const isInRange = moment(dates[0]).isSameOrBefore(formatFlowDate, 'day') && moment(dates[1]).isSameOrAfter(formatFlowDate, 'day');
            
            if(!isInRange) {
                setDateRangeError(true);
                setFormState({
                    ...formState,
                    start_date: undefined,
                    end_date: undefined
                });
                return;
            }

            setDateRangeError(false);
            setFormState({
                ...formState,
                start_date: dates[0],
                end_date: dates[1]
            });
        }
    }

    return (
        <div className={Style.createDeal}>
            <Grid cols="1">
                <div>
                    {availableVolume !== undefined &&      
                        <p>
                            <strong>Flow Date:</strong> {flowDate.format('MMMM Do YYYY')} 
                            <br/>
                            <strong>Total flow (volume) on this flow date:</strong> {availableVolume > 0 ? availableVolume : 'None'}    
                        </p>            
                    }  
                    
                    {lackVolume &&
                        <Alert
                            message={`You currently do not have enough total volume to sell the amount of ${formState.volume}. Create another buy deal to increase your volume amount.`}
                            type="error"
                        />
                    }

                    {noVolume &&
                        <Alert
                            message="You must have a volume amount greater than zero."
                            type="error"
                        />
                    }

                </div>
            </Grid>
            <Grid cols="4">
                <div>
                    <Select
                            customLabel="Select Pipeline"
                            required
                            name="pipeline_id"
                            id="pipeline_id"
                            onChange={setValue}
                            default={formState.pipeline_id}
                            value={formState.pipeline_id}
                            options={pipelines}
                        />
                </div>
                <div>
                    <Select
                            customLabel="Select Point"
                            required
                            name="point_id"
                            id="point_id"
                            onChange={setValue}
                            default={formState.point_id}
                            value={formState.point_id}
                            options={points}
                        /> 
                </div>
                <div>
                    <Select
                            customLabel="Select Position"
                            required
                            name="position_id"
                            id="position_id"
                            onChange={setValue}
                            default={formState.position_id}
                            value={formState.position_id}
                            options={positions}
                        /> 
                </div>
                <div>
                    <Select
                            customLabel="Select Company"
                            required
                            name="company_id"
                            id="company_id"
                            onChange={setValue}
                            default={formState.company_id}
                            value={formState.company_id}
                            options={companies}
                        /> 
                </div>
            </Grid>
            <Grid cols="3">
                <div>
                    <NumberInput 
                            label="Volume"
                            required
                            placeholder="0"
                            name="volume"
                            id="volume"
                            onChange={setValue}
                            value={formState.volume}
                    />
                </div>
                <div>
                    <NumberInput 
                            label="Base Price"
                            required
                            isFloat
                            isNegative
                            decimalLimit={4}
                            placeholder="0.00"
                            name="base_price"
                            id="base_price"
                            onChange={setValue}
                            value={formState.base_price}
                    />
                </div>
                <div>
                    <NumberInput 
                            label="Fixed Price (Optional)"
                            placeholder="0.00"
                            isNegative
                            name="fix_price"
                            id="fix_price"
                            isFloat
                            decimalLimit={4}
                            onChange={setValue}
                            value={formState.fix_price}
                            disabled={!isStaticMidpoint}
                    />
                </div>
            </Grid>
            <Grid cols="1">
                <div>
                    <Fieldset
                        type="radio"
                        label="Spread Across Multiple Days"
                        default={forMultiDays ? "Yes" : "No"}
                        options={['No', 'Yes']}
                        onChange={(name: string, option: string) => setForMultiDays(option === 'Yes' ? true : false)}
                    />
                </div>
            </Grid>
            {forMultiDays &&
            <Grid cols="2">
                <Divider />
                    <>
                        <div>
                            <Label text="Select Date Range for the duration of this deal." />
                            
                            <RangePicker 
                                onChange={onDateRangeChange} 
                                defaultValue={formState.start_date && formState.end_date ? [
                                    moment(formState.start_date, dateFormat), 
                                    moment(formState.end_date, dateFormat)
                                ] : undefined}
                                direction="ltr"
                            />
                            {dateRangeError &&
                                <div className={Style.error}>
                                    <Alert
                                        message="Flow date must be within the date range."
                                        type="error"
                                    />
                                </div>
                            }

                                                      
                        </div>
                        <div style={{padding: '20px 0'}}>
                            <Switch 
                                label="Will midpoint price be the same for entire duration?"
                                default={isStaticMidpoint}
                                name="static_midpoint"
                                onChange={(id: string, status: boolean) => setStaticMidpoint(status)} 
                            /> 
                        </div>
                        
                    </>
                
            </Grid>
            }
            <Grid cols="2">
                <div>
                    <TextInput 
                            label="Deal #"
                            name="deal_num"
                            id="deal_num"
                            onChange={setValue}
                            value={formState.deal_num}
                    />
                </div>
                <div style={{ paddingTop: '16px' }}>
                            <Switch 
                                label="Is Gated"
                                default={isGated}
                                name="is_gated"
                                onChange={(id: string, status: boolean) => setIsGated(status)} 
                            />
                </div>
            </Grid>
            <Grid col="2">
                {isValid() &&
                    <Button
                        fullWidth
                        loading={ loading }
                        text="Create Deal"
                        action={ submit }
                    />
                }
                <Button fullWidth outline text='Cancel' action={ () => context.modal.hide(false) } />
            </Grid>
        </div>
    );
}