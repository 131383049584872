import React, { useContext, useState } from 'react';
import Axios from 'axios';
import { ViewContext, Button, Grid, Label, NumberInput, Select, TextInput } from 'components/lib';

import moment, { Moment } from 'moment';

import { KeyPair } from "ts/interfaces/general";
import { IContract } from 'ts/interfaces/contract';

import { Alert, DatePicker, Row} from 'antd';

const { RangePicker } = DatePicker;

export function CreateSplit({ contract } : {contract: IContract }) {
    const context = useContext(ViewContext);

    const getDelLocOptions = () => {
        return contract.delivery_locations
            .filter(dl => dl.final_quantity > 0)
            .map(dl => ({
                label: dl.package_id,
                value: dl.location_id
            }));
    }

    const [loading, setLoading] = useState(false);
    const [delLocOptions] = useState(getDelLocOptions());

    const [selectDelLoc, setSelectDelLoc] = useState<KeyPair<string, string> | undefined>(undefined);
    const [splitQuantity, setSplitQuantity] = useState<number>(0);
    const [startDate, setStartDate] = useState<string | undefined>(undefined);
    const [endDate, setEndDate] = useState<string | undefined>(undefined);
    const [note, setNote] = useState<string>("");

    const noDelLocOptions = () => delLocOptions !== undefined && !delLocOptions.length;

    const getAvailableQuantity = () => {
        if (!selectDelLoc) return 0;
        const delLocObj = contract.delivery_locations.find(dl => dl.location_id === selectDelLoc?.value);
        return !delLocObj ? 0 : delLocObj.final_quantity;
    }

    const overMax = () => getAvailableQuantity() < splitQuantity;
 
    const onDateRangeChange = (dateObjs: any, dates: Array<string>) => {
        if(dates.length === 2) {
            setStartDate(dates[0]);
            setEndDate(dates[1]);
        }
    }

    const getDefaultRange = () => [
        startDate ? moment(startDate) : undefined,
        endDate ? moment(endDate) : undefined
    ];

    const prepareDatePayloadData = (startDate: string | undefined, endDate: string | undefined): { startDate: string, endDate: string} | void => {
        if (!startDate || !endDate) return;
        
        let startDateObj = moment(startDate);
        let endDateObj = moment(endDate);

        startDateObj = startDateObj.startOf('month');
        endDateObj = endDateObj.endOf('month');

        return {
            startDate: startDateObj.toISOString(),
            endDate: endDateObj.toISOString()
        }
    }

    const submit = async () => {
        setLoading(true);

        const dateData = prepareDatePayloadData(startDate, endDate);

        if (!dateData) {
            setLoading(false);
            return;
        }
        
        try {
            const res = await Axios.post('/api/contract/split', { 
                contract_id: contract.id,
                location_id: selectDelLoc?.value,
                start_date: dateData.startDate, 
                end_date: dateData.endDate,
                split_quantity: splitQuantity,
                note
            });
      
            if (res.status === 200) {
                setLoading(false);
                
                // send the user some where else
                context.modal.hide(false, res.data);
            }          
        } catch (err){
            setLoading(false);
            context.handleError(err);
            context.modal.hide(true);
        }
    }

    const renderForm = () => {
        return (
            <>
                {selectDelLoc &&
                    <Row style={{ margin: '20px 0' }}>
                        <Alert
                            message={`Total Quantity available on delivery Location: ${getAvailableQuantity()}`}
                            type="info"
                        />
                    </Row>
                }
                {overMax() &&
                    <Row style={{ margin: '20px 0' }}>
                        <Alert
                            message="Split amount is over the available total."
                            type="error"
                        />
                    </Row>
                }
                <div>
                        <Select
                            customLabel="Select Delivery Location"
                            required
                            name="delivery_location"
                            value={selectDelLoc?.value}
                            default={selectDelLoc?.value}
                            onChange={(id: string, value: string) => {
                                setSelectDelLoc(value === 'unselected' ? undefined : delLocOptions.find(dl => dl.value === value))
                            }}
                            options={delLocOptions}
                        >
                        </Select>
                </div>
                {selectDelLoc &&
                    <>
                    <Grid cols="2">
                        <div>
                            <NumberInput 
                                label="Split Amount"
                                placeholder="0"
                                name="split_quantity"
                                id="split_quantity"
                                onChange={(id: string, value: string) => {
                                    const newVal = (value as unknown) as number;
                                    setSplitQuantity(newVal);
                                }}
                                value={splitQuantity}
                            />
                        </div>
                        <div>
                                <Label text="Select Date Range for split" />
                                <RangePicker 
                                    onChange={onDateRangeChange} 
                                    defaultValue={startDate && endDate ? [
                                        moment(startDate),
                                        moment(endDate)
                                    ]: undefined}
                                    direction="ltr"
                                    disabledDate={(d) =>  d.isBefore(contract.start_date) || d.isAfter(contract.end_date)}
                                />
                        </div>
                    </Grid>
                    <Grid cols="1">
                        <div>
                            <TextInput 
                                label="Reason For Split"
                                name="note"
                                id="note"
                                onChange={(id: string, value: string) => setNote(value)}
                                value={note}
                            />
                        </div>
                    </Grid>
                    </>
                }
                <Grid col="2">
                    {selectDelLoc && splitQuantity > 0 && !overMax() && 
                        <Button
                            loading={ loading }
                            text="Submit"
                            action={ submit }
                        />
                    }
                    <Button outline text='Cancel' action={ () => context.modal.hide(true) } />
                </Grid>
            </>
        );
    }

    return (
        <div>
            {noDelLocOptions() &&
                <>
                    <Alert
                        message="Warning"
                        description="All of the delivery locations for this contract are empty."
                        type="warning"
                        showIcon
                    />
                    <Row style={{ margin: '20px 0' }}>
                        <Button outline text='Cancel' action={ () => context.modal.hide(true) } />
                    </Row>
                </>
            }
            {!noDelLocOptions() && renderForm()}
        </div>
    );
}